import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from 'parse';
import Moment from "moment";

import { setSelecetdTeacher, editTeacher, setSelecetdSchool } from "../../store/actions";
const AddTeacher = (props) => {


  const [designations, setDesignations] = useState([
    { title: "Teacher", role: 1 },
    { title: "CenterHead", role: 2 },
    { title: "Director", role: 3 },
    { title: "Centre Director", role: 2 },
    { title: "Academic Coordinator", role: 2 },
    { title: "Principal", role: 2 },
    { title: "Admission Coordinator", role: 2 },
    { title: "Nanny", role: 1 },
    { title: "Driver", role: 5 },
    { title: "Helper", role: 6 },
    { title: "Security", role: 7 },
    { title: "Others", role: 1 },
  ]);

  const [dl, setDl] = useState(["Teacher", "CenterHead", "Centre Director", "Academic Coordinator", "Principal", "Admission Coordinator", "Nanny", "Driver", "Helper", "Security", "Others"]);



  const match1 = matchPath(props.history.location.pathname, {
    path: "/editstaff/:schoolId/:param",
    exact: true,
    strict: false,
  });
  const match2 = matchPath(props.history.location.pathname, {
    path: "/editCoordinator/:schoolId/:param",
    exact: true,
    strict: false,
  });
  const match = match1 ?? match2;

  const addmatch1 = matchPath(props.history.location.pathname, {
    path: "/addstaff/:schoolId",
    exact: true,
    strict: false,
  });
  const addmatch2 = matchPath(props.history.location.pathname, {
    path: "/addCoordinator/:schoolId",
    exact: true,
    strict: false,
  });
  const addmatch = addmatch1 ?? addmatch2;

  const schoolId = addmatch ? addmatch.params.schoolId : match.params.schoolId;

  const parameter = match && match.params.param;
  const [name, setName] = useState("");
  const [email, setEmail] = useState(undefined);
  const [phone, setPhoneNumber] = useState(undefined);
  const [role, setRole] = useState(1);
  const [designation, setDesignation] = useState();
  const [dob, setDOB] = useState(undefined);
  const [doj, setDOJ] = useState(undefined);
  const [gender, setGender] = useState(undefined);
  const [about, setAbout] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [emergencyContact, setEmergencyContact] = useState(undefined);
  const [emoployeCode, setEmoployeCode] = useState(undefined);
  const [isOther, setIsOther] = useState(false);
  const [qualification, setQualification] = useState("");

  const [customDesignation, setCustomDesignation] = useState(undefined);

  const [saveLoding, setSaveLoding] = useState(false);
  const [saveError, setSaveError] = useState(undefined);

  const [isWebAccess, setWebAccess] = useState(false);

  useEffect(() => {
    if (props.userTeacher && props.userTeacher.attributes.instituteId
      && props.userTeacher.attributes.instituteId.length > 0
      && props.userTeacher.attributes.Role === 3) {
      if (Parse.User.current().attributes.isSuperUser) {
        if (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) {
          if (addmatch) {
            setDl(["Admin", "Course Director", "Course Coordinator", "Speaker", "Others"]);

            setRole(3);
            setDesignation("Admin")
            setDesignations([
              // { title: "Teacher", role: 2 },
              { title: "Course Director", role: 2 },
              { title: "Course Coordinator", role: 2 },
              { title: "Speaker", role: 2 },
              { title: "Admin", role: 3 },
              { title: "Others", role: 2 },

            ]);
          } else {
            setDl(["Others"]);
            setDesignations([
              // { title: "Teacher", role: 2 },
              { title: "Admin", role: 3 },

            ]);
          }


        } else {
          setDl(["Teacher", "CenterHead", "Centre Director", "Director", "Academic Coordinator", "Principal", "Admission Coordinator", "Nanny", "Driver", "Helper", "Security", "Others"]);
        }
      }
    } else {
      if (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) {
        setDl(["Course Director", "Course Coordinator", "Speaker", "Others"]);
        setDesignations([
          // { title: "Teacher", role: 2 },
          { title: "Course Director", role: 2 },
          { title: "Course Coordinator", role: 2 },
          { title: "Speaker", role: 2 },
          { title: "Others", role: 2 },

        ]);
        if (addmatch) {
          setRole(2);
          setDesignation("Course Director")
        }
      }
    }
  }, [Parse.User.current(), props.selectedSchool]);

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    if (parameter) {
      props.setSelecetdTeacher(parameter);
    }
  }, [parameter]);

  useEffect(() => {
    var teacher = props.selectedTeacher;
    if (teacher && teacher.id && parameter === teacher.id) {
      setName(teacher.attributes.Name);
      setEmail(teacher.attributes.EMail);
      setPhoneNumber(teacher.attributes.PhoneNumber);
      setQualification(teacher.attributes.qualification);
      setRole(teacher.attributes.Role);

      var dob = teacher.attributes.dob ? formatDate(teacher.attributes.dob) : "";
      var doj = teacher.attributes.doj ? formatDate(teacher.attributes.doj) : "";

      setDOB(dob);
      setDOJ(doj);
      setGender(teacher.attributes.Gender);
      setAbout(teacher.attributes.about);
      setEmoployeCode(teacher.attributes.empCode);
      setEmergencyContact(teacher.attributes.emergencyContact);

      setWebAccess(teacher.attributes.isWebAccess ? true : false);

      if (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) {

        if (teacher.attributes.Role === 3) {
          setDl(["Admin", "Others"]);
        } else {
          setDl(["Course Director", "Course Coordinator", "Speaker", "Others"]);
        }

      } else if (teacher.attributes.Role === 3) {
        setDl(["Director", "Others"]);
      }
      if (teacher.attributes.designation && teacher.attributes.designation.length > 0) {

        var temp = designations.filter((item) => item.title === teacher.attributes.designation);

        if (temp.length > 0) {
          setDesignation(teacher.attributes.designation);
          setIsOther(false);
          setCustomDesignation(teacher.attributes.designation);
        } else {
          setDesignation("Others");
          setIsOther(true);
          setCustomDesignation(teacher.attributes.designation);
        }


      } else {
        if (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) {
          if (role === 3) {
            setDesignation("Admin");
            setIsOther(false);
            setCustomDesignation(undefined);
          } else {
            setDesignation("Course Director");
            setIsOther(false);
            setCustomDesignation(undefined);
          }
        } else {
          if (role === 2) {
            setDesignation("CenterHead");
            setIsOther(false);
            setCustomDesignation(undefined);
          } else if (role === 3) {
            setDesignation("Director");
            setIsOther(false);
            setCustomDesignation(undefined);
          } else {
            setDesignation("Teacher");
            setIsOther(false);
            setCustomDesignation(undefined);
          }
        }
      }



      if (teacher.attributes.Photo) {
        setImage(teacher.attributes.Photo._url);
      } else {
        setImage(undefined);
      }
    }
  }, [props.selectedTeacher]);

  function ValidateEmail(value) {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(mailformat)) {
      return true;
    }
    else {
      return false;
    }
  }

  const saveTeacher = () => {
    if (!name && name.trim().length === 0) {
      return;
    }
    let check = false;
    if (email && email.trim().length > 0) {
      if (!ValidateEmail(email.trim())) {
        return;
      }
      check = true
    }
    if (phone && phone.trim().length > 0) {
      check = true
    }
    if (!check) {
      return;
    }
    const Teacher = Parse.Object.extend("Teacher");
    var teacher = new Teacher();
    if (parameter) {
      teacher.id = parameter;
    }else{
      teacher.set('featurePermissions',[0,2,2,0,2,2,0,2,2,0,0,0,2,0,0,0,0,0])
    }

    teacher.set("Name", name);
    teacher.set("EMail", email);
    teacher.set("PhoneNumber", phone);
    teacher.set("Role", role);
    if (dob) {
      teacher.set("dob", new Date(dob));
    }
    if (doj) {
      teacher.set("doj", new Date(doj));
    }
    teacher.set("Gender", gender);
    teacher.set("about", about);
    teacher.set("emergencyContact", emergencyContact);
    teacher.set("empCode", emoployeCode);
    teacher.set("qualification", qualification);

    var temp = designations.filter((item) => item.title === customDesignation);
    var temp2 = designations.filter((item) => item.title === designation);

    if (temp.length > 0) {
      teacher.set("Role", temp[0].role);
      teacher.set("designation", temp[0].title);
    } else
      if (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 && temp2.length > 0) {
        teacher.set("Role", temp2[0].role);
        teacher.set("designation", temp2[0].title);
      }
    if (designation === "Others") {
      teacher.set("designation", customDesignation);
    }
    // if (designation === "CenterHead") {
    //   teacher.set("Role", 2);
    // }


    if (role === 3) {
      teacher.set("SchoolID", undefined);
      teacher.set("instituteId", addmatch ? Parse.User.current().attributes.instituteId : teacher.attributes.instituteId);
    } else {
      teacher.set("SchoolID", addmatch ? schoolId : teacher.attributes.SchoolID);
      teacher.set("instituteId", undefined);
    }
    console.log(teacher);
    setSaveError(undefined);
    setSaveLoding(true);
    teacher.save().then(
      (result) => {
        result.pin();
        window.history.back();
      },
      (error) => {
        setSaveError(error.message);
        setSaveLoding(false);
        console.log(error.message);
      }
    );
  }



  function formatDate(d) {
    if (d === "") {
      return ""
    } else {
      return Moment(d).format("YYYY-MM-DD");
    }
  }

  return (


    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          <Row className='d-flex align-items-start'>
            <Col className="d-flex align-items-start">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => { window.history.back() }}>
                <ul className=" list-unstyled">
                  <div className=''>
                    <i className="bx bx-chevron-left h1 text-primary"></i>
                  </div>
                </ul>
              </div>
              <div className='m-2'>
                <Breadcrumbs title=
                  {parameter ?
                    props.selectedSchool && props.selectedSchool.attributes.schoolType == 10 ? props.t('Edit Coordinator') : props.t('Edit Staff')
                    :
                    props.selectedSchool && props.selectedSchool.attributes.schoolType == 10 ? props.t('Add Coordinator') : props.t('Add Staff')}
                  breadcrumbItem=""

                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm className="outer-repeater" onValidSubmit={saveTeacher}>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup >
                          <Label htmlFor="formname">{props.t('Name')}: </Label>
                          <AvField
                            type="text"
                            name="name"
                            placeholder={props.t('Enter Name...')}
                            className="form-control"
                            value={name}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t('Enter Name...'),
                              },
                            }}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </FormGroup>

                        <Row>
                          <Col lg={isOther ? 6 : 12} >
                            <Label> {props.t('Designation')}:</Label>
                            <FormGroup >
                              <select
                                className="form-control"

                                onChange={(e) => {
                                  if (e.target.value === "Others") {
                                    setIsOther(true);
                                  } else {
                                    setIsOther(false);
                                    if (e.target.value === "Teacher" || e.target.value === "CenterHead" || e.target.value === "Director") {
                                      setCustomDesignation(undefined);
                                    } else {
                                      setCustomDesignation(e.target.value);
                                    }
                                  }

                                  if (props.selectedTeacher && props.selectedTeacher.attributes.Role === 3 && match) {
                                    setRole(3);
                                  } else {
                                    var temp = designations.filter((des) => des.title === e.target.value);

                                    if (temp && temp.length > 0) {
                                      setRole(temp[0].role);
                                    } else {
                                      setRole(1);
                                    }
                                  }
                                  setDesignation(e.target.value);

                                }}
                                value={designation}
                              >
                                {dl.map((item, key) => (
                                  <option key={key} value={item}>{item}</option>
                                ))}
                              </select>
                            </FormGroup>

                          </Col>
                          {
                            isOther && <Col lg={6} >

                              <FormGroup >
                                <Label className="text-white">.</Label>
                                <AvField
                                  type="text"
                                  name="designation"
                                  placeholder={props.t('Enter Designation...')}
                                  className="form-control"
                                  value={customDesignation}
                                  onChange={(e) => {
                                    setCustomDesignation(e.target.value);
                                  }}
                                />
                              </FormGroup>

                            </Col>
                          }

                        </Row>

                        <FormGroup>
                          <Label htmlFor="formemail"> {props.t('Email')}:</Label>
                          <AvField
                            type="email"
                            name="email"
                            placeholder={props.t('Enter Email...')}
                            className="form-control"
                            value={email}
                            disabled={isWebAccess}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            validate={{
                              required: {
                                value: !phone || phone.length === 0 ? true : false,
                                errorMessage: props.t('Enter Email...'),
                              },
                              pattern: {
                                value:
                                  "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                errorMessage: props.t("You have entered an invalid email address!"),
                              },
                            }}
                          />
                          {isWebAccess && <p className="text-danger">{
                            props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 ?
                              props.t('Coordinator linked with web access, email cannot be edited') :
                              props.t('Staff linked with web access, email cannot be edited')
                          }</p>}
                        </FormGroup>

                        <FormGroup >
                          <Label> {props.t('Phone no')}:</Label>
                          <AvField
                            // type="tel"
                            name="phone"
                            className="inner form-control noscroll"
                            placeholder={props.t('Enter phone number')}
                            value={phone}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                            validate={{
                              required: {
                                value: !email || email.length === 0 ? true : false,
                                errorMessage: props.t('Enter phone number'),
                              },
                              pattern: {
                                value:
                                  "^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$",
                                errorMessage: props.t("You have entered an invalid phone no!"),
                              },
                              minLength: { value: 10 },
                              maxLength: { value: 16 },

                            }}

                          />
                        </FormGroup>

                        {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <FormGroup >
                          <Label>{props.t('Employe Code')} :</Label>
                          <Input
                            type="text"
                            name="emoployeCode"
                            className="inner form-control"
                            placeholder="Employe Code"
                            value={emoployeCode}
                            onChange={(e) => {
                              setEmoployeCode(e.target.value);
                            }}
                            validate={{ required: { value: false } }}
                          />
                        </FormGroup>}

                        <FormGroup>
                          <Label>{props.t('Emergency Contact')} :</Label>
                          <Input
                            type="number"
                            name="emergencyContact"
                            className="inner form-control noscroll"
                            placeholder="Emergency Contact"
                            value={emergencyContact}
                            onChange={(e) => {
                              setEmergencyContact(e.target.value);
                            }}
                          />
                        </FormGroup>


                        <FormGroup>
                          <Label> {props.t('Date of birth')}:</Label>

                          <AvField
                            type="date"
                            name="dob"
                            className="inner form-control"
                            placeholder="dd/mm/yyyy"
                            value={dob}
                            onChange={(e) => {
                              setDOB(e.target.value);
                            }}

                          />
                        </FormGroup>

                        <FormGroup>
                          <Label> {props.t('Date of joining')} :</Label>
                          <AvField
                            type="date"
                            name="doj"
                            className="inner form-control"
                            placeholder="dd/mm/yyyy"
                            value={doj}
                            onChange={(e) => {
                              setDOJ(e.target.value);
                            }}
                          />
                        </FormGroup>

                        <FormGroup >
                          <Label htmlFor="formname">{props.t('Qualification')}: </Label>
                          <AvField
                            type="text"
                            name="qualification"
                            placeholder={props.t('Enter Qualification...')}
                            className="form-control"
                            value={qualification}

                            onChange={(e) => {
                              setQualification(e.target.value);
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="d-flex ">
                          <Label className="d-block mb-3">{props.t('Gender')} :</Label>
                          <Col xs={1}>
                          <div className="custom-control custom-radio custom-control-inline">
                            <Input
                              type="radio"
                              id="customRadioInline1"
                              name="customRadioInline1"
                              className="custom-control-input m-1"
                              checked={gender === "Male"}
                              onChange={(e) => {
                                setGender("Male");
                              }}
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="customRadioInline1"

                            >
                              {props.t('Male')}
                            </Label>
                          </div>
                          </Col>
                          &nbsp;
                          <Col xs={1}>
                          <div className="custom-control custom-radio custom-control-inline">
                            <Input
                              type="radio"
                              id="customRadioInline2"
                              name="customRadioInline1"
                              className="custom-control-input"
                              checked={gender === "Female"}
                              onChange={(e) => {
                                setGender("Female");
                              }}
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="customRadioInline2"
                            >
                              {props.t('Female')}

                            </Label>
                          </div>
                          </Col>
                        </FormGroup>

                        {/* <FormGroup >
                          <Label htmlFor="formname">{props.t('Name')}: </Label>
                          <AvField
                            type="text"
                            name="name"
                            placeholder={props.t('Enter Name...')}
                            className="form-control"
                            value={name}
                            
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </FormGroup> */}

                        <FormGroup>
                          <Label htmlFor="formmessage">{props.t('About')} :</Label>
                          <AvField
                            type="textarea"
                            name="about"
                            className="form-control"
                            rows="3"
                            value={about}
                            onChange={(e) => {
                              setAbout(e.target.value);
                            }}
                          />


                        </FormGroup>

                        {
                          saveLoding ?

                            <button type="button" className="btn btn-light waves-effect">
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Saving...')}
                            </button> :
                            <Button type="submit"
                              color="primary">
                              {props.t('SAVE')}
                            </Button>

                        }


                        {saveError && <p className="text-danger">{saveError}</p>}

                      </div>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { error, success, selectedTeacher, loading, } = state.Teacher;
  const { selectedSchool } = state.School;
  const { userTeacher } = state.Login;
  return {
    error,
    success,
    selectedTeacher,
    loading,
    selectedSchool,
    userTeacher,
  };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdTeacher,
      editTeacher,
      setSelecetdSchool,
    })(AddTeacher)
  )
);
