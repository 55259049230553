import Parse from "parse";

export const getInventoryItemFromParse = (inventoryItemId) => {
  try {
    return new Promise((resolve, reject) => {
      var InventoryItem = Parse.Object.extend("InventoryItems");
      var query = new Parse.Query(InventoryItem);
      query.get(inventoryItemId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getInventoryItemFromLocal = (inventoryItemId) => {
  try {
    return new Promise((resolve, reject) => {
      var InventoryItem = Parse.Object.extend("InventoryItems");
      var query = new Parse.Query(InventoryItem);
      query.fromLocalDatastore();
      query.get(inventoryItemId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const deleteInventoryItemFromParse = (inventoryItem) => {
  try {
    return new Promise((resolve, reject) => {
      inventoryItem.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        inventoryItem.set(
          "deletedBy",
          Parse.User.current().attributes.teacherId);
      } else {
        inventoryItem.set("deletedBy", Parse.User.current().id);
      }

      inventoryItem.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("error");
        }
      );
    });
  } catch (error) { }
};

export const getInventoryItemForOwnerId = (ownerId, ownerType, showInStore, categories, categorySetting, updatedDate, isLocal,) => {
  try {
    return new Promise((resolve, reject) => {

      var mainQuery;

      var InventoryItem = Parse.Object.extend("InventoryItems");
      var query1 = new Parse.Query(InventoryItem);
      var query2 = new Parse.Query(InventoryItem);

      if (categorySetting) {
        query2.containedIn("categories", categories);
        query1.doesNotExist("categories")
        mainQuery = Parse.Query.or(query1, query2);
        if (!updatedDate) {
          mainQuery.notEqualTo("isDeleted", true);
        }
        // mainQuery = query2
      } else {
        mainQuery = new Parse.Query(InventoryItem);

        if (updatedDate) {
          mainQuery.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
        } else {
          mainQuery.notEqualTo("isDeleted", true);
        }
      }

      mainQuery.equalTo("ownerId", ownerId);
      mainQuery.equalTo("ownerType", ownerType);
      mainQuery.doesNotExist("itemId")
      if (showInStore) {
        mainQuery.equalTo("showInStore", showInStore);
      }

      if (isLocal) {
        mainQuery.fromLocalDatastore();
        mainQuery.notEqualTo("isDeleted", true);
      }

      mainQuery.descending("createdAt");
      mainQuery.find().then(
        (result) => {
          // console.log(result)
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};


export const editInventoryItem = (inventoryItem) => {
  try {
    return new Promise((resolve, reject) => {
      inventoryItem.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};
export const saveCustomObjects = (object) => {
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

export const getInventoryItemForItemIds = (itemIds) => {
  try {
    return new Promise((resolve, reject) => {
      var InventoryItem = Parse.Object.extend("InventoryItems");
      var query = new Parse.Query(InventoryItem);
      query.containedIn("objectId", itemIds);

      query.addAscending("order");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getInventoryItemMatrixForItemId = (itemId) => {
  try {
    return new Promise((resolve, reject) => {
      var InventoryItemMatrix = Parse.Object.extend("InventoryItemMatrix");
      var query = new Parse.Query(InventoryItemMatrix);
      query.equalTo("itemId", itemId);

      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getInventoryItemMatrixForItemIds = (itemIds) => {
  try {
    return new Promise((resolve, reject) => {
      var InventoryItemMatrix = Parse.Object.extend("InventoryItemMatrix");
      var query = new Parse.Query(InventoryItemMatrix);
      query.containedIn("itemId", itemIds);
      query.limit(1000)
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getInventorySubItemForItemIds = (itemIds) => {
  try {
    return new Promise((resolve, reject) => {
      var InventoryItemMatrix = Parse.Object.extend("InventoryItems");
      var query = new Parse.Query(InventoryItemMatrix);
      query.containedIn("itemId", itemIds);
      query.limit(1000)
      query.find().then(
        (result) => {
          resolve(result);
          console.log(result)
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
