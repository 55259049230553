import React, {useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {  Row, Col,  } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Parse from 'parse';
import {
    setInitialLoading, setSelecetdSchool,
    getCurriculumsForOwnerIds, addNewCurriculum, getAcademicCycleForCurriculumId, deleteCurriculum,
    getTeachersForSchool,getLearningCurriculumSchoolMapForSchoolId
} from '../../store/actions';


import Curriculums from './Curriculums';



const Index = (props) => {

    
    const [pagePosition, setPagePosition] = useState("Curriculum");
    const [ownerIds, setOwnerIds] = useState([]);


    const match = matchPath(props.history.location.pathname, {
        path: "/lms-curriculum/:id",
        exact: true,
        strict: false,
      });
    
      const schoolId = match.params.id;
    
      useEffect(() => {
          if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId!=="franchise") {
              props.setSelecetdSchool(schoolId);
          } else if (schoolId === "franchise" && !Parse.User.current().attributes.franchiseId) {
              window.history.back();
          }
      }, [props.selectedSchool,schoolId]);

    useEffect(() => {
        if (schoolId != "franchise") {
            props.getTeachersForSchool(schoolId);
            props.getLearningCurriculumSchoolMapForSchoolId(schoolId);
        }
        props.setInitialLoading(true);
    }, [schoolId]);


    useEffect(() => {

        var ids = [];
        if (schoolId === "franchise") {
            props.getCurriculumsForOwnerIds([Parse.User.current().attributes.franchiseId]);
        }else
        if (props.selectedSchool) {
            ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }

            let curriculumId = props.selectedSchool.attributes.curriculumId;

            if (ownerIds.length === ids.length) {

                var call = false;
                for (const i in ownerIds) {
                    if (ownerIds[i] !== ids[i]) {
                        call = true;
                    }
                }
                if (call) {
                    props.getCurriculumsForOwnerIds(ids,curriculumId);
                }
                
            } else {
                setOwnerIds(ids);
                props.getCurriculumsForOwnerIds(ids,curriculumId);
            }
        }



       
    }, [props.selectedSchool]);
    

    function createCurriculum( curriculumObject,forInstitute) {
        var Curriculum = Parse.Object.extend("Curriculum");
        var curriculum = new Curriculum();
        curriculum.set("name", curriculumObject.name);

        if (Parse.User.current().attributes.franchiseId) {
            curriculum.set("ownerId", Parse.User.current().attributes.franchiseId);
            curriculum.set("type", "Franchise");
        }else if (Parse.User.current().attributes.isSuperUser && forInstitute) {
            curriculum.set("ownerId", props.selectedSchool.attributes.instituteId);
            curriculum.set("type", "Institute");
        } else {
            curriculum.set("ownerId", props.selectedSchool.id);
            curriculum.set("type", "School");
        }
        if (Parse.User.current().attributes.teacherId) {
            curriculum.set("createdBy", Parse.User.current().attributes.teacherId);
          } else {
            curriculum.set("createdBy", Parse.User.current().id);
          }
        if (curriculumObject.cycles && curriculumObject.cycles.length > 0) {
            var list = [];
            for (const i in curriculumObject.cycles) {
                list.push(curriculumObject.cycles[i].school);
            }

            curriculum.set("cycleYears", list);
        }
        props.addNewCurriculum(curriculum,curriculumObject.cycles,schoolId,props.history);
    }
    function handelEditCurriculam(curriculum){
        props.addNewCurriculum(curriculum);
    }
    function handelDeleteCurriculam(curriculum) {
        props.deleteCurriculum(curriculum);
    }
    



    function handelPage(page) {
        setPagePosition(page);
    }

    function handelCurriculumCycles(curriculumId) {
        props.getAcademicCycleForCurriculumId(curriculumId)
    }
    
    
        
    return (
            
        <React.Fragment >
            <div className="page-content">


        
                {props.initialLoding  && <Row>
                    <Col xs="12">
                    <div className="text-center my-3">
                        <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Link>
                    </div>
                    </Col>
                </Row>}
                    
                {!props.initialLoding && pagePosition === "Curriculum" &&
                    <Curriculums
                        
                        curriculums={props.curriculums}
                        selectedSchool={props.selectedSchool}
                        schoolId={schoolId}
                        history={props.history}
                        curriculumAcademicCycles={props.curriculumAcademicCycles }
                        loadState={props.loadState}
                        teachers={props.teachers}
                        userTeacher={props.userTeacher}
                        onClicItem={handelPage}
                        onAddNew={createCurriculum}
                        onSelectCurriculum={handelCurriculumCycles}
                        onEditCurriculum={handelEditCurriculam}
                        onDeleteCurriculum={handelDeleteCurriculam}
                        learningCurriculumsSchoolMapForSchoolId={props.learningCurriculumsSchoolMapForSchoolId}
                    />
                }

            </div> 
                   
        </React.Fragment>
    );
    
}
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { curriculums, initialLoding } = state.Curriculum;
    const { curriculumAcademicCycles, loadState } = state.AcademicCycles;
    const { learningCategories } = state.LearningCategory;
    const { learningSubcategories, subcategoryState } = state.LearningSubcategory;
    const { learningActivities } = state.LearningActivity;
    const { teachers } = state.Teacher;
    const { userTeacher } = state.Login;
    const { learningCurriculumsSchoolMapForSchoolId } = state.LearningCurriculumsSchoolMap;
    return { schools,selectedSchool,curriculums,initialLoding,curriculumAcademicCycles,loadState,learningCategories,learningSubcategories,subcategoryState,learningActivities,teachers,userTeacher, learningCurriculumsSchoolMapForSchoolId};
}
            
export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setInitialLoading, setSelecetdSchool,
    getCurriculumsForOwnerIds, addNewCurriculum, deleteCurriculum, getAcademicCycleForCurriculumId,
    getTeachersForSchool, getLearningCurriculumSchoolMapForSchoolId
    
})(Index)));