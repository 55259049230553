import React, { useEffect, useState } from "react";
import Iframe from 'react-iframe';
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col, Container, Input } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Parse from "parse";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import { setSelecetdSchool } from "../../store/actions";

function HelpVideo(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/help-videos/:id",
        exact: true,
        strict: false,
    });


    const schoolId = match.params.id;

    const [videocontent, setVideoContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [displaylist, setDisplayList] = useState([]);

    useEffect(() => {

        let query = new Parse.Query('Help')
        query.find().then((videosobj) => {

            console.log(videosobj);
            setIsLoading(false)
            setVideoContent(videosobj)
            setDisplayList(videosobj)

        });
    }, [schoolId])

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    function searchVideo(search) {
        var tempList = [...videocontent];
        if (search === undefined || search === null || search.length === 0) {
            setDisplayList(tempList);
        } else if (tempList) {
            let filter = tempList.filter((item) => {
                return (item.attributes.title.toLowerCase()).includes(search.toLowerCase()) ||
                    (item.attributes.description && (item.attributes.description.toLowerCase()).includes(search.toLowerCase()))
            });
            setDisplayList(filter);
        }
    }

    return (

        <React.Fragment>

            <div className="page-content">
                <Container>
                    <Row>
                        <Col><Breadcrumbs title="HELP VIDEOS" breadcrumbItem="Video" /></Col>
                        <Col lg={6} md={6} xs={12}>
                            <div className="mt-4 mt-sm-0 float-sm-right form-inline">
                                <div className="search-box mr-2">
                                    <div className="position-relative">
                                        <Input type="text" className="form-control border-1 mb-3 
                                        " placeholder="Search..."
                                            onChange={(e) => {
                                                searchVideo(e.target.value)
                                            }}
                                        />
                                        <i className="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isLoading || displaylist.length === 0 && (
                                <h3 className="text-center text-info">
                                    No videos found!!
                                </h3>
                            )}
                        </Col>
                    </Row>
                    <Row>

                        {isLoading && (
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                        Loading....{" "}
                                    </Link>
                                </div>
                            </Col>
                        )}
                        {
                            displaylist.map((item) => {
                                return (

                                    <Col xl="6" sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <Iframe title="test" className="embed-responsive-item" src={item.attributes.videoURL + "?rel=0"} allowFullScreen></Iframe>
                                                </div>
                                                <CardTitle>{item.attributes.title}</CardTitle>
                                                <CardSubtitle className="mb-3">{item.attributes.description}</CardSubtitle>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            })}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool })(HelpVideo)));
