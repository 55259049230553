import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Alert, Label, Form, FormGroup, Card, CardBody, CardHeader, CardTitle, ModalHeader } from "reactstrap";
import { setSelecetdSchool, getClassroomsForSchoolId, getDeletedTeachersForMonth, updateDeletedTeachersForMonth } from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import defaultKidPhoto from "../../assets/images/users/user.png";
import { Parse } from "parse";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

const MonthlyReport = (props) => {

  const match = matchPath(props.history.location.pathname, {
    path: "/lms-monthly-progress-report/:schoolId/:kidId",
    exact: true,
    strict: false,
  });

  var schoolId = match && match.params.schoolId;
  var kidId = match && match.params.kidId;

  const [schoolObject, setSchoolObject] = useState(undefined)
  const [schoolLogo, setSchoolLogo] = useState(undefined);
  const [kidPhoto, setKidPhoto] = useState(defaultKidPhoto)
  const [kidObject, setKidObject] = useState(undefined)
  const [studentClassroom, setStudentClassroom] = useState("")

  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [learningData, setLearningData] = useState([])
  const [allLearningData, setAllLearningData] = useState([])

  const [learningMeasureModal, setLearningMeasureModal] = useState(false);
  const [allLearningMeasures, setAllLearningMeasure] = useState([])
  const [selectingSubjectModal, setSelectingSubjectModal] = useState(false);
  const [selectedLearningCategoryIds, setSelectedLearningCategoryIds] = useState([])


  const [loading, setLoading] = useState(false);
  const [noData, setNoDate] = useState(false);



  useEffect(() => {
    if (schoolId && schoolId.length > 0) {
      const currentDate = new Date();
      if (currentDate.getDate() <= 10) {
        const previousMonth = new Date();
        previousMonth.setMonth(previousMonth.getMonth() - 1);
        setSelectedMonth(previousMonth);
      }
      fetchSchoolFromServer(schoolId);
      fetchKidObjectFunction()
    }
  }, [schoolId]);

  const fetchKidObjectFunction = () => {
    Parse.Cloud.run("function_kidDetailsForKidId", {
      kidId: kidId,
    }).then(
      (result) => {
        if (result != null) {
          if (result.kid) {
            setKidObject(result.kid)

            if (result.kid.attributes.Photo) {
              setKidPhoto(result.kid.attributes.Photo._url);
            }
          }
          if (result.classRoom) {
            setStudentClassroom(result.classRoom.attributes.Name)
          }

        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const fetchSchoolFromServer = (schoolId) => {
    Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
      schoolId: schoolId,
    }).then(
      (result) => {
        if (result != null) {
          setSchoolObject(result);
          if (result.attributes.logo) {
            setSchoolLogo(result.attributes.logo._url);
          }
          getLearningMonthlyReport()
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  

  const getLearningMonthlyReport = () => {

    setLearningData([])
    setNoDate(false)
    setLoading(true);

    var startDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
    var endDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0, 23, 59);

    let loop = true
    let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
    var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
    let dateList = [];
    while (loop) {
      if (newDate < newEndDate) {

        newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
        let dateString = Moment(newDate).format('MMM YYYY')
        dateList.push({ date: newDate, dateString: dateString })
      } else {
        loop = false;
      }
    }

    let query = new Parse.Query("LearningSession");

    query.greaterThanOrEqualTo('createdAt', startDate);
    query.lessThanOrEqualTo('createdAt', endDate);
    query.contains('recipients', kidId);

    query.limit(1000);

    query.find().then(
      (learningSession) => {
        // console.log(learningSession);

        if (learningSession && learningSession.length > 0) {
          let learningSessionIds = [];
          for (const session of learningSession) {
            learningSessionIds.push(session.id)
          }

          let activityIds = [];
          for (const session of learningSession) {
            activityIds.push(session.attributes.activityId)
          }

          let categoryIds = [];
          for (const session of learningSession) {
            categoryIds.push(session.attributes.categoryId)
          }
          getLearningTrancking(learningSession, learningSessionIds, activityIds, categoryIds)
        } else {
          setNoDate(true)
          setLoading(false)
        }

        


      },
      (error) => {
        console.log(error);
      }
    );
  }

  const getLearningTrancking = (learningSession, learningSessionIds, activityIds, categoryIds) => {

    let query = new Parse.Query("LearningTracking");

    query.containedIn('learningSessionId', learningSessionIds);
    query.equalTo('kidId', kidId);

    query.limit(10000);
    query.find().then(
      (learningTracking) => {
        // console.log(learningTracking);
        getCategoryForIds(learningTracking, learningSession, activityIds, categoryIds)
      },
      (error) => {
        console.log(error);
      }
    );

  }

  const getCategoryForIds = (learningTracking, learningSession, activityIds, categoryIds) => {

    let query = new Parse.Query("LearningCategory");

    query.containedIn('objectId', categoryIds);

    query.limit(1000);
    query.find().then(
      (learningCategory) => {
        // console.log(learningCategory);
        getActivityForIds(learningCategory, learningTracking, learningSession, activityIds, categoryIds)
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const getActivityForIds = (learningCategory, learningTracking, learningSession, activityIds, categoryIds) => {

    let query = new Parse.Query("LearningActivity");

    query.containedIn('objectId', activityIds);

    query.limit(1000);
    query.find().then(
      (learningActivity) => {
        // console.log(learningActivity);
        getLearningCategoryMeasureMap(learningSession, learningTracking, learningCategory, learningActivity, categoryIds)
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const getLearningCategoryMeasureMap = (learningSession, learningTracking, learningCategory, learningActivity, categoryIds) => {

    let query = new Parse.Query("LearningCategoryMeasuresMap");

    query.containedIn('category', categoryIds);

    query.limit(1000);
    query.find().then(
      (learningCategoryMeasures) => {
        // console.log(learningCategoryMeasures);

        let measureIds = [];
        for (const learningCategoryMeasure of learningCategoryMeasures) {
          if (!measureIds.includes(learningCategoryMeasure.attributes.measure)) {
            measureIds.push(learningCategoryMeasure.attributes.measure)
          }
        }
        getLearningMeasures(learningSession, learningTracking, learningCategory, learningActivity, learningCategoryMeasures, measureIds)
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const getLearningMeasures = (learningSession, learningTracking, learningCategory, learningActivity, learningCategoryMeasures, measureIds) => {
    let query = new Parse.Query("LearningMeasures");

    query.containedIn('objectId', measureIds);

    query.limit(1000);
    query.find().then(
      (learningMeasures) => {
        // console.log(learningMeasures);
        let scaleIds = [];
        for (const measure of learningMeasures) {
          if (!scaleIds.includes(measure.attributes.scaleId)) {
            scaleIds.push(measure.attributes.scaleId)
          }
        }
        getLearningScaleValues(learningSession, learningTracking, learningCategory, learningActivity, learningCategoryMeasures, learningMeasures, scaleIds)
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const getLearningScaleValues = (learningSession, learningTracking, learningCategory, learningActivity, learningCategoryMeasures, learningMeasures, scaleIds) => {
    let query = new Parse.Query("LearningScaleValues");

    query.containedIn('scale', scaleIds);

    query.limit(1000);
    query.find().then(
      (learningScale) => {
        // console.log(learningScale);
        prepareData(learningSession, learningTracking, learningCategory, learningActivity, learningCategoryMeasures, learningMeasures, learningScale)
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const prepareData = (learningSession, learningTracking, learningCategory, learningActivity, learningCategoryMeasures, learningMeasures, learningScale) => {

    let list = [];
    let index = 0;
    let categoryIds = [];

    for (const category of learningCategory) {
      let activityList = [];
      for (const activity of learningActivity) {
        if (activity.attributes.category == category.id) {
          let learningSessions = learningSession.filter(ls => ls.attributes.activityId == activity.id);
          let learningTrackings = learningTracking.filter(lt => lt.attributes.activityId == activity.id);

          categoryIds.push(category.id),
            activityList.push(
              {
                learningActivity: activity,
                learningSessions: learningSessions,
                learningTrackings: learningTrackings,
              }
            )

        }
      }

      let learningCategoryMeasure = learningCategoryMeasures.filter(lcm => lcm.attributes.category == category.id);
      let LearningMeasureMap = [];
      let selectedMaps = []
      for (const lcm of learningCategoryMeasure) {
        for (const lm of learningMeasures) {
          if (lm.id == lcm.attributes.measure) {

            let learningScaleValues = learningScale.filter(ls => ls.attributes.scale == lm.attributes.scaleId)


            selectedMaps.push(lm.id)
            LearningMeasureMap.push({
              id: lm.id,
              learningMeasure: lm,
              LearningMap: lcm,
              learningScaleValues: learningScaleValues
            })

          }
        }
      }
      list.push({
        id: category.id,
        index: index,
        learningCategory: category,
        learningActivities: activityList,
        selectedMaps: selectedMaps,
        learningMeasures: LearningMeasureMap,
        allLearningMeasures: LearningMeasureMap,
      })
      index++;

    }
    setLoading(false);
    setSelectedLearningCategoryIds(categoryIds)
    setLearningData(list)
    setAllLearningData(list)
  }


  function printPoster() {
    var css = "@page { size: landscape ; overflow-x: auto; margin:10mm 0 10mm 0; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    window.print();
  }

  const getCols = (measures) => {
    let list = []

    for (let m of measures) {
      if (m.learningScaleValues) {
        for (let sv of m.learningScaleValues) {
          list.push({
            mesure: m.learningMeasure,
            scaleValue: sv
          })
        }
      }
    }

    return list;
  }

  const getScaleCount = (activity, trackings, mesure, scaleValue) => {

    let count = 0;
    for (const t of trackings) {

      if (activity.id = t.attributes.activityId && mesure.id == t.attributes.measure) {
        if (t.attributes.rating === scaleValue.attributes.scaleValue ||
          t.attributes.ratingValue == scaleValue.attributes.numericValue) {

          count++
        }
      }
    }
    return count;
  }

  const getUIForCategoryTable = () => {

    return (
      <div>
        {!loading && learningData.length > 0 && <div className="dont-cut-element-when-printing">

          <Row>
            {learningData.map((learning, key) => {
              let learningActivities = learning.learningActivities;
              let learningMeasures = learning.learningMeasures
              let allLearningMeasures = learning.allLearningMeasures
              return (
                <Col key={key} className="mt-0">
                  <Card
                    // style={{ width: "1300px" }}
                    className="p-4"
                  >
                    <div className="dont-cut-element-when-printing" >

                      <Row className="">
                        <Col>
                          <div className="d-flex justify-content-between">
                            <CardTitle className="float-left">Subject: {""}{learning.learningCategory.attributes.name}</CardTitle>
                            <div className="mb-2 d-print-none">
                              {allLearningMeasures.length > 0 &&
                                <Button color="primary" className="btn btn-sm float-right" onClick={() => {
                                  setAllLearningMeasure(learning.selectedMaps)
                                  setLearningMeasureModal(learning);
                                }}>Add/Remove Measures</Button>}
                            </div>
                          </div>

                          <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table className="table table-striped table-bordered table-sm">

                              <Thead>
                                <Tr>
                                  <Th style={{ width: "4%" }} >Sl No.</Th>
                                  <Th style={{ width: "20%" }}>Activity Name</Th>
                                  <Th style={{ width: "5%" }}>Sessions</Th>
                                  {learningMeasures.length > 0 && getCols(learningMeasures).map((measure, key) =>
                                    <Th>{measure.mesure.attributes.name}<hr className="m-0"></hr>{measure.scaleValue.attributes.scaleValue}</Th>
                                  )}
                                </Tr>
                              </Thead>
                              <Tbody>
                                {learningActivities.map((activity, key) => (
                                  <Tr key={key}>
                                    <Td>{key + 1}</Td>
                                    <Td>{activity.learningActivity.attributes.name}</Td>
                                    <Td>{activity.learningSessions.length}</Td>

                                    {learningMeasures.length > 0 && getCols(learningMeasures).map((measure, key) =>
                                      <Td>{getScaleCount(activity, activity.learningTrackings, measure.mesure, measure.scaleValue)}</Td>
                                    )}
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </div>


                        </Col>
                      </Row>

                    </div>
                  </Card>
                </Col>
              )
            })}
          </Row>

        </div >}
        {!loading && (learningData.length == 0|| noData) &&
          <h1 className="text-center text-info">No Report Found For Selected Month</h1>
        }

        {loading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Data...{" "}
                </Label>
              </div>
            </Col>
          </Row>
        )}
      </div>
    )
  }



  return (
    <React.Fragment>
      <div>
        <center>
          <div className="d-print-none mt-2">
            {false && <Button
              onClick={() => {
                printPoster();
              }}
              className="btn btn-success waves-effect waves-light m-2"
            >
              <i className="fa fa-print mr-2"></i>Print
            </Button>}
            <div style={{ width: "1450px", right: 0, top: 10 }} className="pr-4">
              <Row className="d-flex justify-content-end">
                <Col lg={2}>
                  <Label>Select Month :</Label>
                  <DatePicker
                    selected={selectedMonth}
                    onChange={(date) => {
                      setSelectedMonth(date);
                    }}
                    className="form-control"
                    placeholderText="Select Month"
                    maxDate={new Date()}
                    dateFormat="MMM-yyyy"
                    showMonthYearPicker
                  />
                </Col>

                <Col lg={2}>
                  <Button
                    color="primary"
                    className="mt-4"
                    onClick={() => {
                      getLearningMonthlyReport()
                    }}
                  >
                    Generate
                  </Button>
                </Col>
              </Row>

            </div>
          </div>
          <div style={{ width: "1450px",  marginBottom: '200px', top: 10 }} className="mb-4">
            <h1
              className="my-auto ml-3 text-black"
              style={{
                fontSize: "18px",
              }}
            >
              {schoolObject && schoolObject.attributes.Name}
            </h1>
          </div>
         {schoolLogo &&  <Row className="mt-2">
            <div
              className="d-flex mt-0 mx-auto pb-2"
              style={{
                maxWidth: "7%",
                borderBottom: `2px solid LightGray`,
              }}
            >
              <div className="">
                <img
                  src={schoolLogo}
                  width="70px"
                  height="70px"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
          </Row>}

          <Row className="mt-3 d-flex justify-content-center">
            <div
              className="d-flex mt-0 mx-auto pb-2"
              style={{
                maxWidth: "60%",
                borderBottom: `2px solid LightGray`,
              }}
            >
              <Col className="d-flex justify-content-end m-2" lg={4}>
                <div className="mt-0" >
                  <img
                    src={kidPhoto}
                    width="70px"
                    height="70px"
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Col>
              <Col className="text-start m-2" lg={8}>
                <h5 className="">
                  {kidObject && kidObject.attributes.Name}
                </h5>
                <h5 >
                  {studentClassroom}
                </h5>
                <h6 >
                  Date of birth : {kidObject && kidObject.attributes.DateOfBirth ? Moment(kidObject.attributes.DateOfBirth).format('DD MMM YYYY') : ""}
                </h6>
              </Col>
            </div>
          </Row>
          <h4 className="mt-2 mb-3">Learning Monthly Report</h4>
          {learningData && learningData.length > 0 && <div className="mb-2 d-print-none">
            <Button style={{ marginLeft: "" }} color="primary" className="btn btn-sm " onClick={() => {
              setSelectingSubjectModal(true);
            }}>Select Subject</Button>
          </div>}
          <div
          // style={{
          //   backgroundColor: "#ffffff",
          // }}

          >
            <hr></hr>
            {getUIForCategoryTable()}
          </div>
        </center>
      </div>
      <Modal isOpen={learningMeasureModal ? true : false} scrollable={true}>
        <ModalHeader toggle={() => {
          setAllLearningMeasure([])
          setLearningMeasureModal(undefined);
        }}>
          <div className="modal-title mt-0">Select Category</div>
        </ModalHeader>
        <ModalBody className="modal-body">
          {learningMeasureModal && learningMeasureModal.allLearningMeasures.map((measure, key) => (
            <div className="custom-control custom-checkbox mb-2 ml-3" key={measure.id}>
              <input
                type="checkbox"
                className="custom-control-input m-1"
                id={measure.id}
                onChange={() => {

                  if (allLearningMeasures.includes(measure.id)) {
                    let maps = allLearningMeasures.filter(sm => sm != measure.id)
                    let learningMeasures = learningMeasureModal.learningMeasures.filter(learningMeasures => learningMeasures.id != measure.id)

                    let temp = learningMeasureModal

                    temp.selectedMaps = maps;
                    temp.learningMeasures = learningMeasures;
                    setAllLearningMeasure(maps)
                    setLearningMeasureModal(temp);

                  } else {
                    let maps = [...allLearningMeasures]
                    maps.push(measure.id)
                    let learningMeasures = learningMeasureModal.allLearningMeasures.filter(learningMeasures => maps.includes(learningMeasures.id))

                    let temp = learningMeasureModal

                    temp.selectedMaps = maps;
                    temp.learningMeasures = learningMeasures;

                    console.log(temp);

                    setAllLearningMeasure(maps)
                    setLearningMeasureModal(temp);
                  }

                }}
                checked={allLearningMeasures.includes(measure.id)}
              />
              <label
                className="custom-control-label m-1"
                onClick={() => {

                  if (allLearningMeasures.includes(measure.id)) {
                    let maps = allLearningMeasures.filter(sm => sm != measure.id)
                    let learningMeasures = learningMeasureModal.learningMeasures.filter(learningMeasures => learningMeasures.id != measure.id)

                    let temp = learningMeasureModal

                    temp.selectedMaps = maps;
                    temp.learningMeasures = learningMeasures;
                    setAllLearningMeasure(maps)
                    setLearningMeasureModal(temp);

                  } else {
                    let maps = [...allLearningMeasures]
                    maps.push(measure.id)
                    let learningMeasures = learningMeasureModal.allLearningMeasures.filter(learningMeasures => maps.includes(learningMeasures.id))

                    let temp = learningMeasureModal

                    temp.selectedMaps = maps;
                    temp.learningMeasures = learningMeasures;

                    console.log(temp);

                    setAllLearningMeasure(maps)
                    setLearningMeasureModal(temp);
                  }

                }}
              >
                {measure.learningMeasure.attributes.name}
              </label>
            </div>
          ))}
        </ModalBody>


        {false && <ModalFooter>
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => {


                let learningMeasures = learningMeasureModal.allLearningMeasures.filter(learningMeasures => allLearningMeasures.includes(learningMeasures.id))

                let temp = learningMeasureModal
                temp.selectedMaps = allLearningMeasures;
                temp.learningMeasures = learningMeasures;

                setAllLearningMeasure([])
                setLearningMeasureModal(undefined);

              }}
            >
              Apply
            </button>
            <button
              type="button"
              className="btn btn-secondary "
              onClick={() => {
                setLearningMeasureModal(undefined)
              }}
            >
              {props.t("Close")}
            </button>
          </div>
        </ModalFooter>}
      </Modal>
      <Modal isOpen={selectingSubjectModal} scrollable={true}>
        <ModalHeader toggle={() => {
          setSelectingSubjectModal(false);
        }}>
          <div className="modal-title mt-0">Select Subject</div>
        </ModalHeader>
        <ModalBody className="modal-body">
          {allLearningData.map((learning, key) => (
            <div className="custom-control custom-checkbox mb-2 ml-3" key={learning.id}
              onClick={() => {

                if (selectedLearningCategoryIds.includes(learning.id)) {

                  let ids = selectedLearningCategoryIds.filter(sc => sc != learning.id);
                  let updateAllCategory = learningData.filter((category) => category.id != learning.id);
                  setSelectedLearningCategoryIds(ids)
                  setLearningData(updateAllCategory);

                } else {
                  let ids = [...selectedLearningCategoryIds]
                  ids.push(learning.id)
                  let learningData = allLearningData.filter(learningCategory => ids.includes(learningCategory.id))

                  setSelectedLearningCategoryIds(ids)
                  setLearningData(learningData);
                }

              }}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id={learning.id}
                checked={selectedLearningCategoryIds.includes(learning.id)}
              />
              <label
                className="custom-control-label"
                
              >
                {learning.learningCategory.attributes.name}
              </label>
            </div>
          ))}
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
};


const mapStatetoProps = (state) => {
  const { selectedSchool, schools } = state.School;
  const { classrooms } = state.Classroom;
  const { deletedTeachersForMonth } = state.Teacher;
  return { selectedSchool, classrooms, schools, deletedTeachersForMonth };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getClassroomsForSchoolId,
      getDeletedTeachersForMonth,
      updateDeletedTeachersForMonth,
    })
      (MonthlyReport)
  )
);
