import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Label,
    Card,
    Form,
    Button,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip
} from "reactstrap";
import { Parse } from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import { } from "../../store/actions";
import * as Constants from "../../Constents";
import toastr from "toastr";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";


const SchoolCameraDetails = (props) => {

    const [schoolObject, setSchoolObject] = useState();
    const [displayList, setDisplayList] = useState([]);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [cameraToDelete, setCameraToDelete] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [showAddCCTVModel, setShowAddCCTVModel] = useState(false);
    const [showCameraName, setShowCameraName] = useState(undefined);
    const [showRtspLink, setShowRtspLink] = useState(undefined);
    const [showHlsLink, setShowHlsLink] = useState(undefined);
    const [showHTMLLink, setShowHTMLLink] = useState(undefined);
    const [selectedCameraLiveStreamForEdit, setCameraLiveStreamForEdit] = useState(undefined);


    const match = matchPath(props.history.location.pathname, {
        path: "/tools-school-settings-cameraDetails/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;
    useEffect(() => {
        Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
            schoolId: schoolId,
        }).then((result) => {
            // console.log(result)
            setSchoolObject(result);

            getCCTVCamerasList()
        },
            (error) => {
                console.log(error);
            }
        );
    }, [schoolId]);

    const getCCTVCamerasList = () => {
        var CameraLiveStreams = Parse.Object.extend("CameraLiveStreams");
        var query = new Parse.Query(CameraLiveStreams);

        query.notEqualTo("isDeleted", true);
        query.descending("createdAt");
        query.equalTo("schoolId", schoolId)
        query.limit(1000);
        query.find().then(
            (result) => {
                setIsLoading(false);
                setDisplayList(result);
            },
            (error) => {

            }
        );

    }

    const handleDeleteCamera = () => {
        let cl = cameraToDelete;
        cl.set("isDeleted", true)
        cl.save().then((result) => {
            let others = displayList.filter(c => c.id != result.id)
            setDisplayList(others);
            setShowDeleteAlert(false)
            toastr.success("Camera Deleted Successfully", { timeOut: 1500 });
        })

    }

    const handleAddCCTVSubmit = () => {
        var CameraLiveStreams = Parse.Object.extend("CameraLiveStreams");
        let cameraLiveStreamsObject = new CameraLiveStreams();
        if (selectedCameraLiveStreamForEdit) {
            cameraLiveStreamsObject = selectedCameraLiveStreamForEdit;
        }

        cameraLiveStreamsObject.set("schoolId", schoolId);
        cameraLiveStreamsObject.set("name", showCameraName);
        cameraLiveStreamsObject.set("rtsp", showRtspLink);
        cameraLiveStreamsObject.set("htmlUrl", showHTMLLink);
        cameraLiveStreamsObject.set("hls", showHlsLink);

        cameraLiveStreamsObject.save().then((camera) => {
            let list = [...displayList];

            if (selectedCameraLiveStreamForEdit) {
                let i = list.indexOf(selectedCameraLiveStreamForEdit);
                list[i] = camera;
            } else {
                list.unshift(camera);
            }
            setDisplayList(list);

            setCameraLiveStreamForEdit(undefined)
            setShowCameraName(undefined);
            setShowRtspLink(undefined);
            setShowHlsLink(undefined);
            setShowHTMLLink(undefined)
            setShowAddCCTVModel(false);

        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {!isLoading && (
                    <Container fluid>
                        <Row className='d-flex align-items-start'>
                            <Col className="d-flex align-items-start">
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { window.history.back() }}>
                                    <ul className=" list-unstyled">
                                        <div className=''>
                                            <i className="bx bx-chevron-left h1 text-primary"></i>
                                        </div>
                                    </ul>
                                </div>
                                <div className=''>
                                    <h4 className="mt-2">{schoolObject && schoolObject.attributes.Name} - CCTV Camera ({displayList.length})</h4>
                                </div>
                                <div className='ms-auto m-1'>
                                    <button onClick={() => setShowAddCCTVModel(true)} className="btn btn-primary float-right mb-2">
                                        {props.t("Add CCTV")}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <Card>
                            {showDeleteAlert && (
                                <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Yes"
                                    cancelBtnText="No"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="success"
                                    title="Alert!"
                                    onCancel={() => {
                                        setShowDeleteAlert(false);
                                    }}
                                    onConfirm={() => handleDeleteCamera()}
                                >
                                    Are you sure to delete this DVR/NVR Camera?<br></br>
                                </SweetAlert>
                            )}

                            {displayList.length === 0 && (
                                <p className="text-center">No CCTV Camera to display !!</p>
                            )}
                            <CardBody>
                                {displayList.length > 0 && (
                                    <div className="table-responsive">
                                        <table className="table table-centered mb-0" >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{props.t("#")}</th>
                                                    <th>{props.t("DVR/NVR Camera")}</th>
                                                    <th>{props.t("RTSP Link")}</th>
                                                    <th>{props.t("HTML Link")}</th>
                                                    <th>{props.t("HLS Link")}</th>
                                                    <th >{props.t("Edit")}</th>
                                                    <th >{props.t("Delete")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayList.map((camera, key) => (
                                                    <tr key={key}>
                                                        <td >
                                                            {key + 1}
                                                        </td>
                                                        <td >
                                                            {camera.attributes.name}
                                                        </td>
                                                        <td style={{ maxWidth: "200px" }}>
                                                            {camera.attributes.rtsp}
                                                        </td>
                                                        <td style={{ maxWidth: "200px" }}>
                                                            {camera.attributes.htmlUrl}
                                                        </td>
                                                        <td style={{ maxWidth: "200px" }}>
                                                            {camera.attributes.hls}
                                                        </td>

                                                        <td>
                                                            <div className="flex-fill text-center ">
                                                                <Label
                                                                    onClick={() => {
                                                                        setShowAddCCTVModel(true);
                                                                        setCameraLiveStreamForEdit(camera);
                                                                        setShowCameraName(camera.attributes.name);
                                                                        setShowRtspLink(camera.attributes.rtsp);
                                                                        setShowHlsLink(camera.attributes.hls);
                                                                        setShowHTMLLink(camera.attributes.htmlUrl)
                                                                    }}
                                                                    id={"edit-cctv" + key}>
                                                                    <i className="bx bx-edit-alt"></i>
                                                                    <UncontrolledTooltip placement="top" target={"edit-cctv" + key}>
                                                                        {props.t('Edit')}
                                                                    </UncontrolledTooltip>
                                                                </Label>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="flex-fill text-center">
                                                                <Label
                                                                    onClick={() => {
                                                                        setCameraToDelete(camera);
                                                                        setShowDeleteAlert(true);
                                                                    }}
                                                                    id={"delete-cctv" + key}>
                                                                    <i className="bx bxs-trash text-danger"></i>
                                                                    <UncontrolledTooltip placement="top" target={"delete-cctv" + key}>
                                                                        {props.t('Delete')}
                                                                    </UncontrolledTooltip>
                                                                </Label>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </CardBody>
                        </Card>
                    </Container>
                )}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading CCTV details...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>

            <Modal
                isOpen={showAddCCTVModel}
                toggle={() => {
                    setShowAddCCTVModel(false);
                    setShowCameraName(undefined);
                    setShowRtspLink(undefined);
                    setShowHTMLLink(undefined)
                    setShowHlsLink(undefined);
                    setCameraLiveStreamForEdit(undefined);
                }} className="">
                <ModalHeader toggle={() => {
                    setShowAddCCTVModel(false);
                    setShowCameraName(undefined);
                    setShowRtspLink(undefined);
                    setShowHTMLLink(undefined)
                    setShowHlsLink(undefined);
                    setCameraLiveStreamForEdit(undefined);
                }} tag="h4">
                    Add CCTV
                </ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAddCCTVSubmit}>
                        <Row form>

                            <Col className="col-12">

                                <AvField name="DVR/NVR Camera"
                                    label={props.t('DVR/NVR Camera')}
                                    type="text"
                                    placeholder={props.t('DVR/NVR Camera')}
                                    errorMessage={props.t('DVR/NVR Camera can not be empty')}
                                    validate={{
                                        required: { value: true }
                                    }}
                                    value={showCameraName}
                                    onChange={(e) => {
                                        setShowCameraName(e.target.value);
                                    }}

                                />

                            </Col>
                            <Col className="col-12">
                                <AvField
                                    name="RTSP Link"
                                    label={props.t('RTSP Link')}
                                    type="text"
                                    placeholder={props.t('RTSP Link')}
                                    value={showRtspLink}
                                    onChange={(e) => {
                                        setShowRtspLink(e.target.value);
                                    }}
                                />
                            </Col>
                            <Col className="col-12">

                                <AvField name="HLS Link"
                                    label={props.t('HLS Link')}
                                    type="text"
                                    placeholder={props.t('HLS Link')}
                                    errorMessage={props.t('HLS Link can not be empty')}
                                    validate={{
                                        required: { value: true },
                                        pattern: {
                                            value:
                                                "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$",
                                            errorMessage: props.t("You have entered an invalid HLS Link!"),
                                        },
                                    }}
                                    value={showHlsLink}
                                    onChange={(e) => {
                                        setShowHlsLink(e.target.value);
                                    }}

                                />

                            </Col>
                            <Col className="col-12">
                                <AvField
                                    name="HTML Link"
                                    label={props.t('HTML Link')}
                                    type="text"
                                    placeholder={props.t('HTML Link')}
                                    value={showHTMLLink}
                                    onChange={(e) => {
                                        setShowHTMLLink(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="float-end">
                                    <button type="button" className="btn btn-light m-1" onClick={() => {
                                        setShowAddCCTVModel(false);
                                        setShowCameraName(undefined);
                                        setShowRtspLink(undefined);
                                        setShowHlsLink(undefined);
                                        setShowHTMLLink(undefined)
                                        setCameraLiveStreamForEdit(undefined);
                                    }}>{props.t('Close')}</button>
                                    <button type="submit" className="btn btn-success save-event m-1">{props.t('Save')}</button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );


}

const mapStatetoProps = (state) => {
    return {};
};
export default withNamespaces()(withRouter(connect(mapStatetoProps, {})(SchoolCameraDetails)));