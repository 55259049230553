import React, { useEffect, useState } from 'react';
import { Container,Row,TabContent,TabPane,Card,CardText,Nav,NavItem ,NavLink, CardBody} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { matchPath } from "react-router";

import EnquiryList from './EnquiryList'
import TopBar from './Topbar';
import EnquiryFollowup from './EnquiryFollowup';
import EnquiryImport from './EnquiryImport';
import EnquiryQuestions from './EnquiryQuestions';
import { setSelecetdSchool} from '../../store/actions';

const Index = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/enquiry/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;

    const [schoolType, setSchoolType] = useState(1);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if (props.selectedSchool) {
            setSchoolType(props.selectedSchool.attributes.schoolType);
        }
    }, [props.selectedSchool,schoolId]);
     
    const [customActiveTab, setcustomActiveTab] = useState("1");
    function toggleCustom(tab) {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    }
    const [searchString, setSearchString] = useState('');
    const handelSearch = (string) => {
        setSearchString(string)
    }


    return (
       
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                   

                    <TopBar 
                        title="Enquiry Management "
                        schoolId={schoolId}
                        schoolName={props.selectedSchool && props.selectedSchool.attributes.Name}
                        showSearchBar={customActiveTab === "1" ? true : false}
                        onSearch={handelSearch}
                        hideButton={schoolType===10?true:false}
                        hideSharebutton={false}
                    />
                    {(schoolType!== 10) ? <div>
                        <Card className="mb-0">
                            <CardBody>
                                <Nav pills className="navtab-bg nav-justified ">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "1",
                                            })}
                                            onClick={() => {
                                                toggleCustom("1");
                                            }}
                                        >
                                            {props.t('Enquiries').toUpperCase()}
                                        </NavLink>
                                    </NavItem>
                                
                                    {/* <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2");
                                        }}
                                    >
                                        {props.t('Enquiry Followup').toUpperCase()}
                                    </NavLink>
                                </NavItem> */}
                                    
                                   {schoolType!== 15&&  <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "3",
                                            })}
                                            onClick={() => {
                                                toggleCustom("3");
                                            }}
                                        >
                                            {props.t('Import').toUpperCase()}
                                        </NavLink>
                                        
                                    </NavItem>}
                                       
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "4",
                                            })}
                                            onClick={() => {
                                                toggleCustom("4");
                                            }}
                                        >
                                            {props.t('Setup').toUpperCase()}
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                        
                            </CardBody>
                        </Card>
                        <TabContent activeTab={customActiveTab}>
                            <TabPane tabId="1" > <EnquiryList schoolId={schoolId} schoolType={schoolType } searchString={searchString} /></TabPane>
                            {/* <TabPane tabId="2" >  <EnquiryFollowup schoolId = {schoolId}/></TabPane> */}
                            <TabPane tabId="3" >  <EnquiryImport schoolId={schoolId} /> </TabPane>
                            <TabPane tabId="4" ><EnquiryQuestions schoolId={schoolId} /></TabPane>
                        </TabContent>
  
                    

                    </div> :
                        <EnquiryList schoolType={schoolType }schoolId={schoolId} searchString={searchString} />
                    }
                </Container>
            </div>
        </React.Fragment>
            
    );
}
        
const mapStatetoProps = state => {
    const {  selectedSchool } = state.School;
    return { selectedSchool};
}
            
export default withNamespaces()(withRouter(connect(mapStatetoProps,{setSelecetdSchool})(Index)));
