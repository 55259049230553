import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Label, Form, FormGroup, Input, Table, Button, Collapse } from "reactstrap";
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from "moment";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    setSelecetdSchool, getInventoryVendorForOwnerId, getInventoryItemForOwnerId, setSelectedPurchaseOrder, setSelectedPurchaseOrderItems,
    getInventoryWarehouseForOwnerId, getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds,
} from '../../../store/actions';
import { quantity } from "chartist";

function NewPurchaseOrder(props) {

    const matchAdd = matchPath(props.history.location.pathname, {
        path: "/new-purchase/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatchAdd = matchPath(props.history.location.pathname, {
        path: "/franchise-new-purchase/:franchiseId",
        exact: true,
        strict: false,
    });

    const matchEditItem = matchPath(props.history.location.pathname, {
        path: "/edit-inventory-purchase-order/:schoolId/:purchaseOrderId",
        exact: true,
        strict: false,
    });

    const franchiseMatchEditItem = matchPath(props.history.location.pathname, {
        path: "/franchise-edit-purchase-order/:franchiseId/:purchaseOrderId",
        exact: true,
        strict: false,
    });

    const match = matchEditItem ?? franchiseMatchEditItem;
    const purchaseOrderId = match && match.params.purchaseOrderId;

    const match1 = matchAdd ?? matchEditItem
    const schoolId = match1 && match1.params.schoolId;

    const match2 = franchiseMatchAdd ?? franchiseMatchEditItem;
    const franchiseId = match2 && match2.params.franchiseId;


    const [tableData, setTableData] = useState((franchiseMatchAdd || matchAdd) ? [{
        itemId: uuidv4(),
        item: undefined,
        units: '',
        quantity: 0,
        quantityError: false,
        itemError: false,
        dropdownItem: undefined,
        subItems: [],
    }] : []);

    const [vendorList, setVendorList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [vendor, setVendor] = useState();
    const [date, setDate] = useState(new Date());
    const [vendorListError, setVendorListError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [isDisabled, setIsDesabled] = useState(false);
    const [approveAlert, setApproveAlert] = useState(false);
    const [saveLoding, setSaveLoding] = useState(false);
    const [showItemMatrix, setShowItemMatrix] = useState(false);
    const [subItemList, setSubItemList] = useState([])

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);


    useEffect(() => {
        if (schoolId) {
            props.getInventoryVendorForOwnerId(schoolId, 'school')
            // props.getInventoryItemForOwnerId(schoolId, 'school')
            props.getInventoryWarehouseForOwnerId(schoolId, 'school');
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryVendorForOwnerId(franchiseId, 'franchise')
            props.getInventoryItemForOwnerId(franchiseId, 'franchise')
            props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise');
        }
    }, [franchiseId]);



    useEffect(() => {
        if (purchaseOrderId) {
            props.setSelectedPurchaseOrder(purchaseOrderId);
            props.setSelectedPurchaseOrderItems(purchaseOrderId);
        }
    }, [purchaseOrderId]);

    useEffect(() => {
        if (props.inventoryVendors && props.inventoryVendors.length > 0) {
            let tempSelectList = []
            for (const i in props.inventoryVendors) {
                let tempSelectObj = {}
                let obj = props.inventoryVendors[i].attributes
                tempSelectObj.id = props.inventoryVendors[i].id
                tempSelectObj.companyName = obj.companyName ?? ""
                tempSelectObj.value = props.inventoryVendors[i].id
                tempSelectObj.label = obj.companyName ?? ""
                tempSelectObj.parseObject = props.inventoryVendors[i]
                if ((matchEditItem || franchiseMatchEditItem) && props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.id == purchaseOrderId && props.selectedInventoryPurchaseOrder.attributes.vendorId == tempSelectObj.id) {
                    setVendor(tempSelectObj);
                }
                tempSelectList.push(tempSelectObj)
            }
            setVendorList(tempSelectList)
        }
    }, [props.inventoryVendors, props.selectedInventoryPurchaseOrder]);


    useEffect(() => {
        if (props.inventoryItems && props.inventoryItems.length > 0) {
            let tempSelectList = []
            for (const i of props.inventoryItems) {
                tempSelectList.push(getSelectionObject(i))
            }
            setItemList(tempSelectList)
        }
    }, [props.inventoryItems])

    const getSelectionObject = (inventoryItem) => {
        let tempSelectObj = {}
        let obj = inventoryItem.attributes
        tempSelectObj.id = inventoryItem.id
        tempSelectObj.name = obj.name ?? ""
        tempSelectObj.value = inventoryItem.id
        tempSelectObj.label = obj.name ?? ""
        tempSelectObj.parseObject = inventoryItem
        tempSelectObj.isMatrix = obj.isMatrix
        return tempSelectObj
    }

    function handleNewItem() {
        let items = [...tableData]
        let newItem = {
            itemId: uuidv4(),
            item: undefined,
            itemError: false,
            units: undefined,
            quantity: undefined,
            quantityError: false,
            parseObject: undefined,
        }
        items.push(newItem)
        setTableData(items)
    }

    const handleRemoveItem = (item) => {
        let newItems = tableData.filter((k) => k.itemId !== item.itemId);
        setTableData(newItems);
    }

    function formatDate(d) {
        if (d === "") {
            return ""
        } else {
            return Moment(d).format("YYYY-MM-DD");
        }
    }

    useEffect(() => {
        // console.log("purchase orders", props.selectedInventoryPurchaseOrder);
        if (props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.id == purchaseOrderId) {
            // setVendor(getVendorName(props.selectedInventoryPurchaseOrder.attributes.vendorId));
            setDate(formatDate(props.selectedInventoryPurchaseOrder.attributes.createdOn));

            if (props.selectedInventoryPurchaseOrder.attributes.status == 1) {
                setIsDesabled(true);
            }

        }
    }, [props.selectedInventoryPurchaseOrder]);


    useEffect(() => {
        // console.log("Purchase order items", props.selectedInventoryPurchaseOrderItems);
        if ((matchEditItem || franchiseMatchEditItem) && props.selectedInventoryPurchaseOrderItems && props.selectedInventoryPurchaseOrderItems.length > 0) {
            let items = []
            for (const si of props.selectedInventoryPurchaseOrderItems) {
                if (si.attributes.purchaseOrderId == purchaseOrderId) {
                    let newItem = {
                        itemId: uuidv4(),
                        item: getSelectionItemFromId(si.attributes.itemId),
                        itemError: false,
                        units: si.attributes.unit,
                        quantity: si.attributes.quantity,
                        quantityError: false,
                        subItems: si.attributes.subItemsMatrix,
                        parseObject: si,
                    }
                    items.push(newItem)
                }

            }
            setTableData(items)
        }

    }, [props.selectedInventoryPurchaseOrderItems])

    const getSelectionItemFromId = (id) => {

        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return getSelectionObject(item)
            }
        }
    }

    const handleApproveOrder = () => {
        console.log("1")
        Parse.Cloud.run("function_AprovePurchaseOrder", {
            purchaseOrderId: purchaseOrderId
        }).then((response) => {
            console.log('response', response)
            if (response.state == 1) {
                setIsDesabled(true);
                setApproveAlert(false)
            } else {

            }
        }, (error) => {
            console.log("error" + error.message)
        });
    }

    const handelSave = () => {


        let canSave = true;

        let newList = []
        for (let i of tableData) {
            if (!i.item) {
                i.itemError = true;
                canSave = false
            }

            if (!i.quantity || i.quantity < 0) {
                i.quantityError = true;
                canSave = false
            }
            newList.push(i)
        }
        setTableData(newList);

        if (!vendor) {
            setVendorListError(true);
            canSave = false;
        }

        if (!date) {
            setDateError(true);
            canSave = false;
        }

        if (!canSave) {
            return
        }

        console.log("tableDate", tableData)

        setSaveLoding(true)
        const InventoryPurchaseOrders = Parse.Object.extend("InventoryPurchaseOrders");
        let purchaseOrder = new InventoryPurchaseOrders();
        if ((matchEditItem || franchiseMatchEditItem)) {
            purchaseOrder.id = props.selectedInventoryPurchaseOrder.id
        } else {
            if (franchiseId) {
                purchaseOrder.set("ownerType", 'franchise');
                purchaseOrder.set("ownerId", franchiseId);
            } else {
                purchaseOrder.set("ownerType", 'school');
                purchaseOrder.set("ownerId", schoolId);
            }
            purchaseOrder.set('createdBy', Parse.User.current().attributes.teacherId);
            if (date) {
                purchaseOrder.set("createdOn", new Date(date));
            } else {
                purchaseOrder.set("createdOn", new Date());
            }
        }

        if (!purchaseOrder.attributes.warehouseId && props.inventoryWarehouses && props.inventoryWarehouses.length > 0) {
            purchaseOrder.set("warehouseId", props.inventoryWarehouses[0].id);
        }
        purchaseOrder.set("vendorId", vendor.value);
        purchaseOrder.save().then(
            (result) => {
                setSaveLoding(false)
                result.pin()
                // console.log(result)
                if ((matchEditItem || franchiseMatchEditItem)) {
                    let itemObjects = [];
                    let exItems = [];
                    for (let i = 0; i < tableData.length; i++) {
                        const InventoryPurchaseOrderItems = Parse.Object.extend("InventoryPurchaseOrderItems");
                        let purchaseOrderItem = new InventoryPurchaseOrderItems();
                        if (tableData[i].parseObject) {
                            purchaseOrderItem.id = tableData[i].parseObject.id
                            exItems.push(tableData[i].parseObject.id);
                        } else {
                            purchaseOrderItem.set("purchaseOrderId", result.id);
                            if (franchiseId) {
                                purchaseOrderItem.set("ownerType", 'franchise');
                                purchaseOrderItem.set("ownerId", franchiseId);
                            } else {
                                purchaseOrderItem.set("ownerType", 'school');
                                purchaseOrderItem.set("ownerId", schoolId);
                            }
                        }
                        purchaseOrderItem.set("itemId", tableData[i].item.id);
                        purchaseOrderItem.set("itemName", tableData[i].item.name);
                        purchaseOrderItem.set("quantity", parseInt(tableData[i].quantity + ""));
                        purchaseOrderItem.set("unit", tableData[i].units);
                        if (tableData[i].item.isMatrix) {
                            purchaseOrderItem.set("subItemsMatrix", tableData[i].subItems);
                        }
                        purchaseOrderItem.set("order", i);
                        itemObjects.push(purchaseOrderItem);
                    }
                    let deletedItems = [];
                    for (const si of props.selectedInventoryPurchaseOrderItems) {
                        if (!exItems.includes(si.id)) {
                            si.set("isDeleted", true);
                            si.set("deletedBy", Parse.User.current().attributes.teacherId);
                            deletedItems.push(si)
                        }
                    }
                    Parse.Object.saveAll(itemObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        if (deletedItems.length > 0) {
                            Parse.Object.saveAll(deletedItems).then((dResults) => {
                                setSaveLoding(false)
                                Parse.Object.unPinAll(dResults);
                                // console.log(dResults);
                                if (matchEditItem || franchiseMatchEditItem) {

                                } else {
                                    window.history.back();
                                }
                            });
                        } else {
                            // console.log(results);
                            if (matchEditItem || franchiseMatchEditItem) {

                            } else {
                                window.history.back();
                            }
                        }
                    });
                } else {
                    let itemObjects = [];
                    for (let i = 0; i < tableData.length; i++) {
                        const InventoryPurchaseOrderItems = Parse.Object.extend("InventoryPurchaseOrderItems");
                        let purchaseOrderItem = new InventoryPurchaseOrderItems();

                        purchaseOrderItem.set("purchaseOrderId", result.id);
                        if (franchiseId) {
                            purchaseOrderItem.set("ownerType", 'franchise');
                            purchaseOrderItem.set("ownerId", franchiseId);
                        } else {
                            purchaseOrderItem.set("ownerType", 'school');
                            purchaseOrderItem.set("ownerId", schoolId);
                        }
                        purchaseOrderItem.set("itemId", tableData[i].item.id);
                        purchaseOrderItem.set("itemName", tableData[i].item.name);
                        purchaseOrderItem.set("quantity", parseInt(tableData[i].quantity + ""));
                        if (tableData[i].item.isMatrix) {
                            purchaseOrderItem.set("subItemsMatrix", tableData[i].subItems);
                        }
                        purchaseOrderItem.set("unit", tableData[i].units);
                        purchaseOrderItem.set("order", i);

                        itemObjects.push(purchaseOrderItem);
                    }
                    Parse.Object.saveAll(itemObjects).then((results) => {
                        setSaveLoding(false)
                        Parse.Object.pinAll(results);
                        // console.log(results);
                        if (matchEditItem || franchiseMatchEditItem) {

                        } else {
                            window.history.back();
                        }
                    });
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const handleClick = () => {
        handelSave();
        setApproveAlert(true);
    };

    useEffect(() => {
        if (props.inventoryItems && props.inventoryItems.length > 0) {
            let itemIds = []
            for (const si of props.inventoryItems) {
                itemIds.push(si.id)
            }
            props.getInventorySubItemForItemIds(itemIds)
            props.getInventoryItemMatrixForItemIds(itemIds)
        }
    }, [props.inventoryItems])

    const getsubItemList = (itemId,) => {
        if (props.inventorySubItems && props.inventorySubItems.length > 0) {
            let subItemList = []
            for (const si of props.inventorySubItems) {
                if (si.attributes.itemId == itemId) {
                    subItemList.push({
                        objectId: si.id,
                        quantity: 0,
                        itemId: itemId,
                        matrixSizeId: si.attributes.matrixSizeId,
                        sizeName: getMatrixName(si.attributes.matrixSizeId),
                        matrixColorId: si.attributes.matrixColorId,
                        colorName: getMatrixName(si.attributes.matrixColorId),
                        // subItem: si,
                    })
                }
            }
            return subItemList;
        }
    };

    const getMatrixName = (matrixId) => {
        if (props.inventoryMatrixs && props.inventoryMatrixs.length > 0) {
            let temp = props.inventoryMatrixs.filter(
                (matrix) => matrix.id == matrixId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.value ?? "-")
            }
        }
    }

    const calculateTotalQuantity = (subItems) => {
        return subItems.reduce((total, subItem) => total + (parseInt(subItem.quantity, 10) || 0), 0);
    };

    const handleSubItemQuantityChange = (subItem, newQuantity, itemKey, subItemKey) => {

        let updatedSubItems = [...tableData[itemKey].subItems];
        updatedSubItems[subItemKey] = { ...updatedSubItems[subItemKey], quantity: newQuantity };

        const newTotalQuantity = calculateTotalQuantity(updatedSubItems);

        let updatedTableData = [...tableData];
        updatedTableData[itemKey] = {
            ...updatedTableData[itemKey],
            subItems: updatedSubItems,
            quantity: updatedTableData[itemKey].item.isMatrix ? newTotalQuantity : updatedTableData[itemKey].quantity,
            quantityError: false
        };

        setTableData(updatedTableData);
    };



    return (
        <div className='page-content'>
            <div>
                <Row>
                    <Row className='d-flex align-items-start'>
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <h4>{(matchEditItem || franchiseMatchEditItem) ? "Edit Purchase Order" : "New Purchase Order"}</h4>
                            </div>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            {(matchEditItem || franchiseMatchEditItem) && <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                <Link to={matchEditItem ? `/print-purchase-order/${schoolId}/${purchaseOrderId}` : `/franchise-print-purchase-order/${franchiseId}/${purchaseOrderId}`}>
                                    <Button
                                        color="success"
                                        style={{ float: "right" }}
                                        className="btn waves-effect waves-light float-right  m-2"
                                    >
                                        Print
                                    </Button>
                                </Link>
                            </div>}
                        </Col>
                    </Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <AvForm className="needs-validation" >
                                    <Row>
                                        <Col>
                                            <FormGroup className="position-relative">
                                                <Label>Select vendor:</Label>
                                                <Select
                                                    onChange={(e) => {
                                                        setVendor(e);
                                                        setVendorListError(false)
                                                    }}
                                                    value={vendor}
                                                    options={vendorList}
                                                    className="selectVendors"
                                                    classNamePrefix="purchaseVendor"
                                                    isDisabled={isDisabled}
                                                />
                                                {vendorListError && <small className='text-danger'>Please select vendor</small>}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>Date :</Label>
                                                <AvField
                                                    type="date"
                                                    name="date"
                                                    className="inner form-control"
                                                    placeholder="dd/mm/yyyy"
                                                    onChange={(e) => {
                                                        setDate(e.target.value);
                                                        setDateError(false);
                                                    }}
                                                    value={date}
                                                    disabled={isDisabled}
                                                />
                                                {dateError && <small className='text-danger'>Please select date</small>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </AvForm>
                                {tableData && tableData.length > 0 &&
                                    <Row className="p-3">
                                        <Col>
                                            <div className="mt-1">
                                                <div className="border rounded p-3 mb-1" style={{ backgroundColor: "white" }}>
                                                    <Row style={{ height: "8px", paddingBottom: "20px" }}>
                                                        <Col lg={1}><Label className="mb-1 font-size-14">#</Label></Col>
                                                        <Col lg={4}><Label className="mb-1 font-size-14">All Items</Label></Col>
                                                        <Col><Label className="mb-1 font-size-14">Unit</Label></Col>
                                                        <Col><Label className="mb-1 font-size-14">Quantity</Label></Col>
                                                        <Col><Label className="mb-1 font-size-14">Show Matrix</Label></Col>
                                                    </Row>
                                                </div>
                                                {tableData.map((item, itemKey) => (
                                                    <Card className="mb-1 bg-whites" key={itemKey}>
                                                        <CardBody>
                                                            <Row>
                                                                <Col lg={1}>
                                                                    <div className="d-flex">{itemKey + 1}</div>
                                                                </Col>
                                                                <Col lg={4}>
                                                                    <FormGroup className="position-relative">
                                                                        <Select
                                                                            style={{ width: "14rem" }}
                                                                            onChange={(selectedOption) => {
                                                                                let tempItem = { ...item };
                                                                                tempItem.units = selectedOption.parseObject.attributes.unit;
                                                                                tempItem.item = selectedOption;
                                                                                tempItem.itemError = false;

                                                                                if (selectedOption.isMatrix) {
                                                                                    tempItem.subItems = getsubItemList(selectedOption.id);
                                                                                    tempItem.quantity = calculateTotalQuantity(tempItem.subItems);
                                                                                } else {
                                                                                    tempItem.quantity = '';
                                                                                }

                                                                                let newList = tableData.map((i) =>
                                                                                    i.itemId === item.itemId ? tempItem : i
                                                                                );

                                                                                const updatedItemList = itemList.filter((i) => i.value !== selectedOption.value);
                                                                                setItemList(updatedItemList);
                                                                                setTableData(newList);

                                                                                if (selectedOption.isMatrix) {
                                                                                    setShowItemMatrix(item.itemId);
                                                                                } else {
                                                                                    setShowItemMatrix(false);
                                                                                }
                                                                            }}
                                                                            value={item.item}
                                                                            id="itemList"
                                                                            options={itemList}
                                                                            className="selectItems"
                                                                            classNamePrefix="purchaseItem"
                                                                            isDisabled={isDisabled || franchiseMatchEditItem}
                                                                        />
                                                                        {item.itemError && <small className='text-danger'>Please select item</small>}
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Input
                                                                            name="units"
                                                                            placeholder="Units"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={item.units}
                                                                            id="units"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Input
                                                                            name="quantity"
                                                                            placeholder="No's"
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="quantity"
                                                                            value={item.quantity}
                                                                            disabled={item.item && item.item.isMatrix || isDisabled}
                                                                            onChange={(e) => {
                                                                                if (!item.item.isMatrix) {
                                                                                    let updatedTableData = [...tableData];
                                                                                    updatedTableData[itemKey] = {
                                                                                        ...updatedTableData[itemKey],
                                                                                        quantity: e.target.value,
                                                                                        quantityError: false
                                                                                    };
                                                                                    setTableData(updatedTableData);
                                                                                }
                                                                            }}
                                                                        />
                                                                        {item.quantityError && <small className='text-danger'>Please Enter Quantity</small>}
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    {item.item && item.item.isMatrix && (
                                                                        <p
                                                                            style={{ cursor: 'pointer' }}
                                                                            className="text-primary"
                                                                            onClick={() => {
                                                                                if (showItemMatrix === item.itemId) {
                                                                                    setShowItemMatrix(false);
                                                                                } else {
                                                                                    setShowItemMatrix(item.itemId);
                                                                                    getsubItemList(item.item.id);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <u>{showItemMatrix === item.itemId ? 'Hide Matrix' : 'Show Matrix'}</u>
                                                                        </p>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                            <Collapse isOpen={showItemMatrix === item.itemId && item.item.isMatrix}>
                                                                <div className="m-3">
                                                                    {item && item.subItems && item.subItems.length > 0 && (
                                                                        <Table className="table mb-0 table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: '10%', textAlign: 'center' }}>Sl No</th>
                                                                                    {item.subItems.some(sub => sub.matrixColorId) && (
                                                                                        <th style={{ width: '25%', textAlign: 'center' }}>Color</th>
                                                                                    )}
                                                                                    {item.subItems.some(sub => sub.matrixSizeId) && (
                                                                                        <th style={{ width: '25%', textAlign: 'center' }}>Size</th>
                                                                                    )}
                                                                                    <th className="text-center">Quantity</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {item.subItems.map((subItem, subItemKey) => (
                                                                                    <tr key={subItemKey}>
                                                                                        <td style={{ width: '10%', textAlign: 'center' }}>{subItemKey + 1}</td>
                                                                                        {/* Conditionally render the Color cell */}
                                                                                        {item.subItems.some(sub => sub.matrixColorId) && (
                                                                                            <td style={{ width: '25%', textAlign: 'center' }}>
                                                                                                <h6>{subItem.matrixColorId ? subItem.colorName : "-"}</h6>
                                                                                            </td>
                                                                                        )}
                                                                                        {/* Conditionally render the Size cell */}
                                                                                        {item.subItems.some(sub => sub.matrixSizeId) && (
                                                                                            <td style={{ width: '25%', textAlign: 'center' }}>
                                                                                                <h6>{subItem.matrixSizeId ? subItem.sizeName : "-"}</h6>
                                                                                            </td>
                                                                                        )}
                                                                                        <td>
                                                                                            <Input
                                                                                                name="quantity"
                                                                                                placeholder="Quantity"
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                id="quantity"
                                                                                                value={subItem.quantity || ''}
                                                                                                onChange={(e) => {
                                                                                                    handleSubItemQuantityChange(subItem, e.target.value, itemKey, subItemKey);
                                                                                                }}
                                                                                                disabled={isDisabled}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </Table>
                                                                    )}
                                                                </div>
                                                            </Collapse>
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </div>
                                            {/* {!isDisabled && <Button
                                                color="primary"
                                                className="btn waves-effect waves-light float-right  m-2"
                                                onClick={(e) => {
                                                    handelSave(e.target.value);
                                                }}
                                            >
                                                Save
                                            </Button>} */}
                                            <div className="d-flex justify-content-end">
                                                {!isDisabled && <Button color="success"
                                                    className="btn waves-effect waves-light float-right  m-2"
                                                    onClick={() => {
                                                        handleNewItem()
                                                    }}
                                                >
                                                    Add Items<i className="mdi mdi-plus mr-1" />
                                                </Button>}
                                            </div>
                                            <hr />
                                            <div className="d-flex justify-content-end">
                                                {
                                                    saveLoding ?

                                                        (!isDisabled && <Button type="button" color="primary" className=" waves-effect float-right m-2">
                                                            <i className="bx bx-hourglass bx-spin font-size-16"></i>Saving...
                                                        </Button>) :
                                                        (!isDisabled && <Button type="submit" className='btn waves-effect waves-light float-right  m-2'
                                                            color="primary"
                                                            onClick={() => {
                                                                handelSave();
                                                            }}>
                                                            SAVE
                                                        </Button>)

                                                }
                                                {((matchEditItem || franchiseMatchEditItem) && !isDisabled) && <Button color="success"
                                                    className="btn waves-effect waves-light float-right  m-2"
                                                    onClick={() => {
                                                        handleClick()
                                                    }}
                                                >
                                                    Receive Order
                                                </Button>}
                                            </div>
                                            <div className="d-flex justify-content-start">
                                                {
                                                    isDisabled && <h5 className="text-success d-flex justify-content-start"> This purchase has been approved and it can not be changed further.</h5>
                                                }
                                            </div>
                                        </Col>
                                    </Row>}

                                {/* <tr>
                                    <td
                                        className="text-center text-Secondary h5 pt-5"
                                        colSpan="7"
                                    >
                                    </td>
                                    <td
                                        className="text-center text-Secondary h5 pt-5"
                                        colSpan="7"
                                    >
                                        To create Purchase order{" "}
                                        <span
                                            className="text-info"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <button
                                                className="btn btn-info"
                                            >
                                                Adding items
                                            </button>
                                        </span>
                                    </td>
                                </tr> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            {approveAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Receive"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    title="Alert!"
                    onCancel={() => {
                        setApproveAlert(false);
                    }}
                    onConfirm={() => handleApproveOrder()}
                >
                    <div>
                        {"Are you sure you want to Mark this purchase order as received, On recieving this purchase order stock will be added to the inventory stock"}
                    </div>
                </SweetAlert>
            )}
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryVendors } = state.InventoryVendor;
    const { inventoryItems, inventorySubItems, inventoryMatrixs } = state.InventoryItems;
    const { selectedInventoryPurchaseOrder } = state.InventoryPurchaseOrder;
    const { selectedInventoryPurchaseOrderItems } = state.InventoryPurchaseOrderItem;
    const { inventoryWarehouses } = state.InventoryWarehouse;
    return { selectedSchool, inventoryVendors, inventoryItems, selectedInventoryPurchaseOrder, selectedInventoryPurchaseOrderItems, inventoryWarehouses, inventorySubItems, inventoryMatrixs };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryVendorForOwnerId, getInventoryItemForOwnerId, setSelectedPurchaseOrder, setSelectedPurchaseOrderItems, getInventoryWarehouseForOwnerId, getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds })(NewPurchaseOrder)));