import * as actions from "./actionTypes";

export const addGroupMap = (groupMap) => {
  return {
    type: actions.UPDATE_GROUP_MAP,
    payload: { groupMap },
  };
};

export const checkAndAddGroupMapForKids = (groupId, selectedKidIds, successMsg, errorMsg) => {
  return {
    type: actions.CHECK_AND_ADD_GROUP_MAPS_FOR_KIDS,
    payload: { groupId, selectedKidIds, successMsg, errorMsg },
  };
};


export const deleteGroupMap = (groupMap) => {
  return {
    type: actions.DELETE_GROUP_MAP,
    payload: { groupMap },
  };
};

export const updateGroupmaps = (groupmaps) => {
  return {
    type: actions.UPDATE_GROUP_MAPS,
    payload: { groupmaps },
  };
};

export const getGroupMapsForGroupId = (groupId) => {
  return {
    type: actions.GET_GROUP_MAPS_FOR_GROUP_ID,
    payload: { groupId },
  };
};

export const setLoadingGroupmaps = (boolValue) => {

  return {
    type: actions.SET_LOADING_GROUPMAPS,
    payload: { boolValue },
  };
}

export const getGroupMapsForTeacherId = (teacherId) => {
  return {
    type: actions.GET_GROUP_MAPS_FOR_TEACHER_ID,
    payload: { teacherId },
  };
};

export const getGroupMapsForKidId = (kidId) => {
  return {
    type: actions.GET_GROUP_MAPS_FOR_KID_ID,
    payload: { kidId },
  };
};

export const saveGroupMaps = (groupMaps, successMsg, moveBack,tripIds) => {
  return {
    type: actions.SAVE_GROUP_MAPS,
    payload: { groupMaps, successMsg, moveBack,tripIds },
  };
};

export const updateResultGroupMaps = (resultGroupMapsAfterSave) => {
  return {
    type: actions.UPDATE_RESULT_GROUP_MAPS,
    payload: { resultGroupMapsAfterSave },
  };
};


export const getGroupMapsForProgramGroupIds = (groupIds) => {
  return {
    type: actions.GET_GROUP_MAP_FOR_PROGRAM_GROUPIDS,
    payload: { groupIds },
  };
};

export const updateProgramGroupMaps = (programGroupmaps) => {
  return {
    type: actions.UPDATE_GROUP_MAP_FOR_PROGRAM_GROUPIDS,
    payload: { programGroupmaps },
  };
};