import * as actions from "./actionTypes";

export const updateInvoiceItem = (invoiceItem) => {
  return {
    type: actions.UPDATE_INVOICE_ITEM,
    payload: { invoiceItem },
  };
};

export const deleteInvoiceItem = (invoiceItem) => {
  return {
    type: actions.DELETE_INVOICE_ITEM,
    payload: { invoiceItem },
  };
};

export const updateInvoiceItems = (invoiceItems) => {
  return {
    type: actions.UPDATE_INVOICE_ITEMS,
    payload: { invoiceItems },
  };
};

export const getInvoiceItemForInvoiceId = (invoiceId) => {
  return {
    type: actions.GET_INVOICE_ITEMS,
    payload: { invoiceId },
  };
};

export const getItemsForInvoiceIds = (invoiceIds, isForDiscount) => {
  return {
    type: actions.GET_ITEMS_FOR_INVOICE_IDS,
    payload: { invoiceIds, isForDiscount },
  };
};

export const setItemsForInvoiceIds = (itemsObjs) => {
  return {
    type: actions.SET_ITEMS_FOR_INVOICE_IDS,
    payload: { itemsObjs },
  };
};
