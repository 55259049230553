import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table, CardImg } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import QRCode from 'qrcode'
import Parse from 'parse';
import * as WhiteLable from "../../components/Common/WhiteLable";
import logo from "../../assets/images/logo-dark.png";

const VisitorPoster = (props) => {


    
    const match = matchPath(props.history.location.pathname, {
        path: "/visitor-poster/:id",
        exact: true,
        strict: false,
    });
    const parameter = match.params.id;

    const [loading, setLoading] = useState(true);
    const [formQRCode, setFormQRCode] = useState(undefined);

    const [school, setSchool] = useState(undefined);

    const [schoolName, setSchoolName] = useState(undefined);
    const [schoolCity, setSchoolCity] = useState(undefined);
    const [schoolLogo, setSchoolLogo] = useState(undefined);

    const [posterType,setPosterType]=useState("a4");


    const HOST_NAME = WhiteLable.getAppName() === "lilTriangle" ? "https://dashboard.liltriangle.com" : WhiteLable.getHost();
    const VISITOR_POSTER = HOST_NAME + "/visitor-form/" + parameter;

    useEffect(() => {
        getSchool();
        formQr();
    }, [parameter]);

    function formQr() {
        QRCode.toDataURL(VISITOR_POSTER, { errorCorrectionLevel: 'H' })
            .then(url => {
                setFormQRCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }
    function getSchool() {
        var School = Parse.Object.extend("School");
        var query = new Parse.Query(School);

        query.get(parameter).then(
            (result) => {
                setLoading(false);
                setSchoolName(result.attributes.Name);
                setSchoolCity(result.attributes.city);
                if (result.attributes.logo) {
                    setSchoolLogo(result.attributes.logo._url);
                }
                setSchool(result)
            },
            (error) => {
                console.log(error)
            }
        );
    }
    function printPoster() {

        var css = "@page {size: A4,margin: 0; }",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";

        if (style.styleSheet) {
        style.styleSheet.cssText = css;
        } else {
        style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);

        window.print();
    }
    const getButtonSelectedClass=(type)=>{

        if(type==posterType){
            return "btn btn-primary waves-effect waves-light m-2"
        }else{
            return "btn btn-light waves-effect waves-light border-1 m-2"
        }
    }
    
    
    return (
        <React.Fragment>
            <Container fluid="lg" className='p-0'>



                {loading ?
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Link>
                        </div>
                    </Col>

                    :
                    <Row className="text-center" >
                        <Col xs="12" className="d-print-none m-3">
                            <div className="text-center">
                                <button  onClick={() => { setPosterType("a4") } } className={getButtonSelectedClass("a4")}>A4</button>
                                <button  onClick={() => { setPosterType("card") } } className={getButtonSelectedClass("card")}>Card</button>
                                <button  onClick={() => { setPosterType("qrCode") } } className={getButtonSelectedClass("qrCode")}>QR Code</button>
                                <button  onClick={() => { printPoster() } } className="btn btn-success waves-effect waves-light m-2"><i className="fa fa-print"></i>Print Poster</button>
                            </div>
                        </Col>


                        {posterType=="a4" && <Col xs="12" >

                             <center>
                                <div style={{ width: "211mm", height: "297mm"}}>
                                    <div style={{ height: "150mm", backgroundColor: "#fe1386" }}>

                                    </div>
                                    <div style={{ height: "150mm", backgroundColor: "#ffbd59" }}>
                                    </div>

                                        <div style={{ backgroundColor: "#fff", marginTop: "-135%",marginBottom: "-144%",width: "90%", height: " 90%"}}>
                                                    <table>
                                                        {schoolLogo ? <tr>
                                                            <td style={{ width: "40%",marginTop:"2%"}}>
                                                                <img className="p-2 m-2" src={schoolLogo} width="160" alt="" style={{marginTop:"5%"}}/>
                                                            </td>
                                                            <td style={{ width: "60%" }}>
                                                                <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                                <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                                            </td>
                                                        </tr> :

                                                            <tr>

                                                                <td >
                                                                    <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                                    <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </table>
                                                    <hr style={{ border: "0.1px solid #fe138650",marginLeft: "5cm",marginRight:"5cm"}} />

                                                <div className="col-md-12 m-5">
                                                    <span style={{ fontSize: " 30px", fontWeight: "bolder", color: "#5f5f5f" }}>
                                                        DEAR VISITORS
                                                    </span>
                                                </div>
                                                <div className="col-md-12 mt-5 mb-5" style={{ marginleft: "-15%", marginTop: "4%" }}>
                                                    <img className="img-responsive" src={formQRCode} style={{ width: "280px", marginTop: "1%" }} />
                                                </div>
                                                <div className="col-md-12 mt-5 mb-5">
                                                    <span style={{ fontSize: " 20px", fontWeight: "bolder", color: "#5f5f5f" }}>
                                                        PLEASE SCAN ABOVE QR CODE
                                                    </span>
                                                </div>
                                                <hr style={{ border: "0.1px solid #ffbd59",marginLeft: "5cm",marginRight:"5cm"}} />

                                                <div className="col-md-12 mt-5 mb-5">
                                                    <p>{props.t('Powered By')}</p>
                                                    <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "150px" }} />
                                                </div>
                                        </div>
                                </div>
                            </center>

                        </Col>}
                        {posterType == "card" && <Col>
                            <center>
                                <div 
                                className='p-4'
                                style={{
                                        minHeight: "200mm",
                                        width: "150mm",
                                        backgroundColor: "#fff",
                                    }}>

                               
                                <div
                                
                                    style={{
                                        backgroundColor: "#fff",
                                        border:"7px solid #000",
                                        borderRadius: '30px 0 30px 0',
                                        
                                        // borderColor:"#ff1493"
                                    }}
                                >
                                    <table>
                                            {schoolLogo ? <tr>
                                                <td >
                                                    <img className="p-2" src={schoolLogo} width="160" alt="" />
                                                </td>
                                                <td >
                                                    <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                    <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                                </td>
                                            </tr> :

                                                <tr>
                                                    <td >
                                                    <img className="p2" src={WhiteLable.getAppLogoWithName()} width="0px" height="200px" alt="" />
                                                   </td>

                                                    <td >
                                                        <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                        <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                                    </td>
                                                </tr>
                                            }
                                    </table>
                                    <hr style={{ border: "1px solid #000000", marginLeft: "3cm", marginRight: "3cm" }} />

                                    <div className="col-md-12 ">
                                        <span style={{ fontSize: " 36px", fontWeight: "bolder", color: "#5f5f5f",display: "flex", justifyContent: "center" }}>
                                            DEAR VISITORS
                                        </span>
                                    </div>
                                    <div className="col-md-12">
                                        <span style={{ fontSize: " 22px", fontWeight: "bolder", color: "#5f5f5f",display: "flex", justifyContent: "center"}}>
                                            PLEASE SCAN ABOVE QR CODE
                                        </span>
                                    </div>
                                    <hr style={{ border: "0.1px solid #000000", marginLeft: "3cm", marginRight: "3cm" }} />

                                    <div className="col-md-12 m-0" style={{ marginleft: "-15%",}} >
                                        <img className="img-responsive m-2" src={formQRCode} style={{ width: "280px",border: `7px solid #000`}} />
                                    </div>
                                    <hr style={{ border: "0.1px solid #000080", marginLeft: "4cm", marginRight: "4cm" }} />

                                    <div className="col-md-12 bottom-1">
                                        <p className='m-1' style={{marginLeft:"30mm"}}>{props.t('Powered By')}</p>
                                        <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "160px" }} />
                                    </div>

                                </div>
                                </div>
                            </center>
                        </Col>}
                        {posterType =="qrCode" && <Col>
                            <div className="col-md-12 mt-5 mb-5" style={{ marginleft: "-15%", marginTop: "4%" }}>
                                <img className="img-responsive" src={formQRCode} style={{ width: "280px", marginTop: "1%" }} />
                                <p className='m-4 '>Visitor poster QR code</p>

                            </div>
                        </Col>}
                            


                    </Row>
                }
            </Container>
        </React.Fragment>

    );
}



export default withNamespaces()(VisitorPoster);;