import * as actions from "./actionTypes";

export const updateInvoice = (invoice) => {
  return {
    type: actions.UPDATE_INVOICE,
    payload: { invoice },
  };
};

export const deleteInvoice = (invoice) => {
  return {
    type: actions.DELETE_INVOICE,
    payload: invoice,
  };
};



export const setInvoices = (invoices) => {
  return {
    type: actions.SET_INVOICES,
    payload: { invoices },
  };
};

export const getInvoiceForSchoolId = (schoolId) => {
  return {
    type: actions.GET_INVOICES,
    payload: { schoolId },
  };
};

export const getInvoicesForProgramId = (programId, reload) => {
  return {
    type: actions.GET_INVOICES_FOR_PROGRAM_ID,
    payload: { programId, reload },
  };
};

export const updateInvoicesForProgramId = (invoicesForProgram, reload) => {
  return {
    type: actions.UPDATE_INVOICES_FOR_PROGRAM_ID,
    payload: { invoicesForProgram, reload },
  };
};

export const setProgramInvoiceCreationLoader = (boolValue) => {
  return {
    type: actions.SET_PROGRAM_INVOICE_CREATION_LOADER,
    payload: { boolValue },
  };
}

export const createInvoiceForProgramKid = (kidId, templateId, school, programObject) => {
  return {
    type: actions.CREATE_INVOICE_FOR_PROGRAM_KID,
    payload: { kidId, templateId, school, programObject },
  };
}

export const getInvoicesForFilters = (filters, schoolId) => {
  return {
    type: actions.GET_INVOICES_FOR_FILTERS,
    payload: { filters, schoolId },
  };
};
export const setInvoiceCount = (invoiceCount) => {
  return {
    type: actions.SET_INVOICE_COUNT,
    payload: { invoiceCount },
  };
};
export const getInvoicesCountForFilters = (filters, schoolId) => {
  return {
    type: actions.GET_INVOICES_COUNT_FOR_FILTERS,
    payload: { filters, schoolId },
  };
};
export const createInvoiceForKids = (invoiceObject) => {
  return {
    type: actions.CREATE_INVOICE_FOR_KIDS,
    payload: { invoiceObject },
  };
};

export const clearResponseForCreateInvoiceForKids = () => {
  return {
    type: actions.CLEAR_RESPONSE_FOR_CREATE_INVOICE_FOR_KIDS,
    payload: {},
  };
};
export const responseForCreateInvoiceForKids = (response) => {
  return {
    type: actions.RESPONSE_FOR_CREATE_INVOICE_FOR_KIDS,
    payload: { response },
  };
};
export const createInvoiceWithFunction = (inputParam) => {
  return {
    type: actions.CREATE_INVOICE_WITH_FUNCTION,
    payload: { inputParam },
  };
};
export const updateInvoiceAndItems = (invoiceNItems) => {
  return {
    type: actions.UPDATE_INVOICE_AND_ITEMS,
    payload: { invoiceNItems },
  };
};
export const setInvoiceUpdate = (serverObj) => {
  return {
    type: actions.SET_INVOICE_UPDATE,
    payload: { serverObj },
  };
};
export const setInvoiceSuccessComplete = () => {
  return {
    type: actions.SET_INVOICE_SUCCESS_COMPLETE,
    payload: {},
  };
};
export const setUpdateInvoiceUpdate = (serverObj) => {
  return {
    type: actions.SET_UPDATE_INVOICE_UPDATE,
    payload: { serverObj },
  };
};
export const getInvoiceForIds = (ids) => {
  return {
    type: actions.GET_INVOICE_FOR_IDS,
    payload: { ids },
  };
};
export const getInvoiceDetailsByFunction = (id) => {
  return {
    type: actions.GET_INVOICE_DETAILS_BY_FUNCTION,
    payload: { id },
  };
};
export const setInvoice = (obj) => {
  return {
    type: actions.SET_INVOICE,
    payload: { obj },
  };
};
export const deleteSelectedInvoice = (id, programInvoice, kidProfileInvoice) => {
  return {
    type: actions.DELETE_SELECTED_INVOICE,
    payload: { id, programInvoice, kidProfileInvoice },
  };
};
export const setDeleteSuccess = (delObj) => {
  return {
    type: actions.SET_DELETE_INVOICE,
    payload: { delObj },
  };
};
export const notifyPaymentToParent = (invoiceId, kidId) => {
  return {
    type: actions.NOTIFY_PAYMENT_TO_PARENTS,
    payload: { invoiceId, kidId },
  };
};



export const successUpdateInvoiceValue = () => {
  return {
    type: actions.SUCCESS_UPDATE_INVOICE_VALUE,
    payload: {},
  };
};

export const getInvoiceCountForSchoolId = (schoolId, tags) => {
  return {
    type: actions.GET_INVOICE_COUNT_FOR_SCHOOLID,
    payload: { schoolId, tags },
  };
};

export const setInvoicecountForSchoolId = (counts) => {
  return {
    type: actions.SET_INVOICE_COUNT_FOR_SCHOOLID,
    payload: { counts },
  };
};

export const saveInvoice = (invoice) => {
  return {
    type: actions.SAVE_INVOICE,
    payload: { invoice },
  };
};

export const getRecurringMasterInvoicesForSchoolId = (schoolId) => {
  return {
    type: actions.GET_RECURRING_MASTER_INVOICES_FOR_SCHOOLID,
    payload: { schoolId },
  }
}

export const updateRecurringMasterInvoices = (masterInvoices) => {
  return {
    type: actions.UPDATE_RECURRING_MASTER_INVOICES_FOR_SCHOOLID,
    payload: { masterInvoices },
  };
};

export const getRecurringInvoicesForSchoolId = (schoolId) => {
  return {
    type: actions.GET_RECURRING_INVOICES_FOR_SCHOOLID,
    payload: { schoolId },
  }
}

export const updateRecurringInvoices = (recurringInvoices) => {
  return {
    type: actions.UPDATE_RECURRING_INVOICES_FOR_SCHOOLID,
    payload: { recurringInvoices },
  };
};

export const editRecurringMasterInvoice = (masterInvoice) => {
  return {
    type: actions.EDIT_RECURRING_MASTER_INVOICES_FOR_SCHOOLID,
    payload: { masterInvoice},
  };
}

export const stopRecurringMasterInvoice = (masterInvoice) => {
  return {
    type: actions.STOP_RECURRING_MASTER_INVOICES_FOR_SCHOOLID,
    payload: { masterInvoice },
  };
}

export const removeRecurringMasterInvoiceFromList = (masterInvoice) => {
  return {
    type: actions.REMOVE_RECURRING_MASTER_INVOICE,
    payload: { masterInvoice }
  }
}

