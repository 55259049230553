import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Parse from "parse";
import toastr from "toastr";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { setSelecetdSchool } from "../../store/actions";

const VisitorForm = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/visitor-form/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId

    const [schoolObject, setSchoolObject] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [name, setName] = useState(undefined);
    const [visitingPurpose, setVisitingPurpose] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [meetingPerson, setMeetingPerson] = useState(undefined);
    const [visitingFrom, setVisitingFrom] = useState(undefined);
    const [visitorType, setVisitorType] = useState(undefined);

    const [nameError, setNameError] = useState(false);
    const [visitingPurposeError, setVisitingPurposeError] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [invalidPhoneError, setInvalidPhoneError] = useState(false);
    const [meetingPersonError, setMeetingPersonError] = useState(false);
    const [visitingFromError, setvisitingFromError] = useState(false);
    const [visitorTypeError, setVisitorTypeError] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [alldetails, setAlldetails] = useState(
        []
    );

    const [otprequestResponce, setOtprequestResponce] = useState(undefined);
    const [syncId, setSyncId] = useState(uuidv4());
    function uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    const [cameraState, setCameraState] = useState(false);
    const [otpPopup, setOtpPopup] = useState(false)

    const typesOfVisitor = ["Parent", "Visitor"];
    const purposeOfVisit = ["Official", "Enquiry", "Others"];

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const videoStyle = {
        width: isMobileView ? '100%' : 'auto',
    };

    useEffect(() => {
        if (isFormSubmitted) {
            setAlldetails([
                { left: "Name", right: name },
                { left: "Purpose of visit", right: visitingPurpose.toString() },
                { left: "Whom to meet", right: meetingPerson },
                { left: "Phone Number", right: phoneNumber },
                { left: "Coming from", right: visitingFrom },
                { left: "Visiting the school as", right: visitorType },
            ])
        }
    }, [isFormSubmitted])

    useEffect(() => {
        if (schoolId) {
            fetchSchoolFromServer(schoolId);
        }
    }, [schoolId]);

    useEffect(() => {
        if (
            match &&
            (!props.selectedSchool || props.selectedSchool.id !== schoolId)
        ) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const fetchSchoolFromServer = (schoolId) => {
        Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
            schoolId: schoolId,
        }).then(
            (result) => {
                console.log(result)
                if (result != null) {
                    setSchoolObject(result);
                } else {

                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const [stream, setStream] = useState(null);
    const [photo, setPhoto] = useState(null);
    const videoRef = useRef(null);

    const startCamera = async () => {
        try {

            setCameraState(true);
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(mediaStream);
            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const takePicture = () => {
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const capturedPhoto = canvas.toDataURL('image/jpeg');
            setPhoto(capturedPhoto);

            setCameraState(false);
            stopCamera()
        }
    };

    const stopCamera = () => {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
        }
    };


    const length = 4;
    const [otp, setOtp] = useState(new Array(length).fill(''));
    const inputRefs = useRef([]);

    const handleChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1].focus();
        }
    };


    const isPhoneInvalid = (b) => {
        var re = new RegExp("^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$");
        if (re.test(b)) {
            return false
        }
        else {
            return true
        }
    }

    const isFormValid = () => {

        let canProceedToSave = true

        if (name == undefined || name.trim().length === 0) {
            canProceedToSave = false
            setNameError(true)
        } else {
            setNameError(false)
        }

        if (visitingPurpose == undefined || visitingPurpose.length === 0) {
            canProceedToSave = false
            setVisitingPurposeError(true)
        } else {
            setVisitingPurposeError(false)
        }

        if (phoneNumber == undefined || phoneNumber.trim().length === 0) {
            canProceedToSave = false
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false)
        }

        if (isPhoneInvalid(phoneNumber)) {
            canProceedToSave = false
            setInvalidPhoneError(true)
        } else {
            setInvalidPhoneError(false)
        }

        if (meetingPerson == undefined || meetingPerson.trim().length === 0) {
            canProceedToSave = false
            setMeetingPersonError(true)
        } else {
            setMeetingPersonError(false)
        }

        if (visitingFrom == undefined || visitingFrom.trim().length === 0) {
            canProceedToSave = false
            setvisitingFromError(true)
        } else {
            setvisitingFromError(false)
        }

        if (visitorType == undefined || visitorType.trim().length === 0) {
            canProceedToSave = false
            setVisitorTypeError(true)
        } else {
            setVisitorTypeError(false)
        }


        if (canProceedToSave) {
            requestOTP()
        }
    }

    const requestOTP = () => {
        Parse.Cloud.run("function_RequestVisitorOTP", {
            userPhoneNumber: phoneNumber,
            syncId: syncId,
            schoolId: schoolId,
        }).then((response) => {
            setOtprequestResponce(response)
            setOtpPopup(true)
            // OTP Waiting screen
        }, (error) => {
            console.log("error" + error.message)
        });
    }

    const verifyOtp = (otp) => {
        Parse.Cloud.run("function_VerifyVisitorOTP", {
            userPhoneNumber: otprequestResponce.userPhoneNumber,
            syncId: syncId,
            schoolId: schoolId,
            otp: otp
        }).then((response) => {

            if (response == 1) {
                // success
                setOtpPopup(!otpPopup)
                saveForm()
            } else {
                // show invalid otp screen
            }
        }, (error) => {
            console.log("error" + error.message)
        });
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
            console.error('Error reading file:', error);
        };
    }

    const saveForm = () => {
        setSubmittingForm(true);
        const Visitor = Parse.Object.extend('Visitor');
        const visitor = new Visitor();
    
        visitor.set("name", name);
        visitor.set("visitingPurpose", visitingPurpose);
        visitor.set("phoneNumber", phoneNumber);
        visitor.set("meetingPerson", meetingPerson);
        visitor.set("visitingFrom", visitingFrom);
        visitor.set("visitorType", visitorType);
        visitor.set("schoolId", schoolId);
    
        if (photo) {
            const parseFile = new Parse.File("visitorPhoto.jpg", { base64: photo });
            parseFile.save().then(
                (result) => {
                    visitor.set("visitorPhoto", result);
                    visitor.save().then(
                        (result) => {
                            console.log(result)
                            setSubmittingForm(false);
                            setIsFormSubmitted(true);
                        },
                        (error) => {
                            setSubmittingForm(false);
                            console.error('Error saving visitor:', error);
                        }
                    );
                },
                (error) => {
                    setSubmittingForm(false);
                    console.error('Error saving photo:', error);
                }
            );
        } else {
            visitor.save().then(
                (result) => {
                    console.log(result)
                    setSubmittingForm(false);
                    setIsFormSubmitted(true);
                },
                (error) => {
                    setSubmittingForm(false);
                    console.error('Error saving visitor:', error);
                }
            );
        }
    };
    

    //// Utilities functions
    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }
    const changeVisitingPurpose = (choice) => {
        var tempArray = [...visitingPurpose];
        if (visitingPurpose.includes(choice)) {
            //remove
            removeElement(tempArray, choice);

        } else {
            tempArray.push(choice);
        }
        if (tempArray.length === 0) {
            setVisitingPurposeError(true)
        } else {
            setVisitingPurposeError(false)
        }
        setVisitingPurpose(tempArray);
    }

    const borderStyle = {
        border: "2px solid lightGray",
        borderRadius: "50px 50px",
        minHeight: `calc(100vh - 170px)`,
        position: "relative"
    };

    const getTextUI = (leftLabel, rightLabel, key) => (
        <React.Fragment key={key}>
            <Row lg={5} md={8} sm={12} xs={12} className="justify-content-center mt-1">
                <div className="text-left col-6">{leftLabel}</div>
                <div className="text-left col-6">
                    <Label className="float-right">:</Label>
                    {rightLabel}
                </div>
            </Row>
        </React.Fragment>
    );


    return (
        <React.Fragment>
            <Container
                fluid={true}
                className={match ? "" : "page-content"}
            >
                {!isLoading && !isFormSubmitted && (
                    <Row>
                        <Col lg={!match ? 12 : 8} className="mx-auto">
                            {(match) && (
                                <div className="text-center mt-3">
                                    {schoolObject && schoolObject.attributes.logo && (
                                        <img
                                            src={schoolObject.attributes.logo._url}
                                            width="100"
                                            alt=""
                                            className="img-thumbnail rounded-circle"
                                        />
                                    )}
                                    <h3>
                                        {schoolObject
                                            ? props.t(`Welcome to ${schoolObject.attributes.Name}`)
                                            : props.t("Welcome")}{" "}
                                    </h3>

                                    <h6 className="mt-3">
                                        {schoolId
                                            ? props.t("Please provide the details")
                                            : props.t("Please provide the details")}
                                    </h6>
                                </div>
                            )}
                            <Card>
                                <CardBody>
                                    <AvForm
                                        className="outer-repeater"
                                        style={{ border: "1px" }}
                                        onValidSubmit={isFormValid}
                                    >
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                                <h5>{props.t("Visitor Form")}: </h5>
                                                <hr />
                                                <FormGroup>
                                                    <Label htmlFor="vf-name">
                                                        {"Name of the person"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-name" className="text-danger">
                                                        *
                                                    </Label>
                                                    {nameError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <AvField
                                                        type="text"
                                                        name={"vf-name-f"}
                                                        placeholder={"Type here"}
                                                        className="form-control"
                                                        value={name}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setNameError(true)
                                                            } else {
                                                                setNameError(false)
                                                            }
                                                            setName(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>

                                                <div className="widget mb-2 pb-2">
                                                    <Label className="">{"Purpose of visit"}</Label>
                                                    <Label className="text-danger">*</Label>

                                                    {visitingPurposeError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <div>
                                                        {purposeOfVisit.map((choice, keyInside) => (
                                                            <div
                                                                className="form-check  ml-3"
                                                                key={"purposeOfVisit" + keyInside}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={"checkbox" + "purposeOfVisit" + keyInside}
                                                                    value={choice}
                                                                    checked={visitingPurpose.includes(choice)}
                                                                    onChange={() => {

                                                                        changeVisitingPurpose(choice);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={"checkbox" + "purposeOfVisit" + keyInside}
                                                                >
                                                                    {choice}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>


                                                <FormGroup>
                                                    <Label htmlFor="vf-meetingPerson">
                                                        {"Whom to meet"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-meetingPerson" className="text-danger">
                                                        *
                                                    </Label>
                                                    {meetingPersonError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}


                                                    <AvField
                                                        type="text"
                                                        name={"vf-meetingPerson-f"}
                                                        placeholder={"Type here"}
                                                        className="form-control"
                                                        value={meetingPerson}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setMeetingPersonError(true)
                                                            } else {
                                                                setMeetingPersonError(false)
                                                            }
                                                            setMeetingPerson(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label htmlFor="vf-phone"> {"Phone number"}</Label>
                                                    <Label htmlFor="vf-phone" className="text-danger">*</Label>
                                                    {phoneNumberError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    {
                                                        invalidPhoneError && <Label className="label-xsmall text-danger ml-2">
                                                            (Please enter a valid phone number)
                                                        </Label>
                                                    }
                                                    <AvField
                                                        name={"vf-phone-f"}
                                                        className="inner form-control noscroll"
                                                        placeholder={props.t("Enter phone no")}
                                                        value={phoneNumber}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setPhoneNumberError(true)
                                                            } else {
                                                                setPhoneNumberError(false)
                                                            }
                                                            setPhoneNumber(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label htmlFor="vf-comingFrom">
                                                        {"Coming from"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-comingFrom" className="text-danger">
                                                        *
                                                    </Label>
                                                    {visitingFromError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <AvField
                                                        type="text"
                                                        name={"vf-comingFrom-f"}
                                                        placeholder={"Type here"}
                                                        className="form-control"
                                                        value={visitingFrom}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setvisitingFromError(true)
                                                            } else {
                                                                setvisitingFromError(false)
                                                            }
                                                            setVisitingFrom(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>

                                                <div className="widget mb-2 pb-2">
                                                    <Label className="">{"Visiting the school as"}</Label>
                                                    <Label className="text-danger">*</Label>

                                                    {visitorTypeError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <div>
                                                        {typesOfVisitor.map((choice, keyInside) => (
                                                            <div
                                                                className="form-check  ml-3"
                                                                key={"typesOfVisitor" + keyInside}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id={"radio" + "typesOfVisitor" + keyInside}
                                                                    value={choice}
                                                                    checked={visitorType === choice}
                                                                    onChange={() => {
                                                                        if (visitorTypeError) {
                                                                            setVisitorTypeError(false)

                                                                        }
                                                                        setVisitorType(choice)
                                                                    }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={"radio" + "typesOfVisitor" + keyInside}
                                                                >
                                                                    {choice}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <FormGroup >
                                                    <Label className="">{"Visitor Photo"}</Label>
                                                    <Label className="text-danger">*</Label>
                                                    <Row>
                                                        <Col>
                                                            {!photo && !cameraState && <button className="btn btn-primary" onClick={startCamera}>Click Photo</button>}
                                                        </Col>
                                                        <Col>
                                                            {photo && !cameraState && <img src={photo} alt="Captured" />}
                                                            {cameraState && <video ref={videoRef} autoPlay style={{ width: isMobileView ? "200%" : "auto" }} />}
                                                        </Col>
                                                        <Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                        </Col>
                                                        <Col className="text-center">
                                                            {cameraState && <button className="btn btn-primary m-1" onClick={takePicture}>CLICK</button>}
                                                            {photo && !cameraState && <button className="btn btn-primary m-1" onClick={startCamera}>Try Again</button>}
                                                        </Col>
                                                        <Col>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>


                                                <div style={{ display: "flex" }}>
                                                    <Button
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            width: "180px",
                                                        }}
                                                        type="submit"
                                                        color="primary"
                                                        disabled={submittingForm}
                                                    >
                                                        {submittingForm && < i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>}
                                                        {props.t("SUBMIT")}
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}

                {isFormSubmitted && (

                    <Card style={{}}>
                        <CardBody>
                            <center>
                                <div style={borderStyle} className="mt-5 pt-5 mb-5 pb-5">
                                    <div className="p-1 pl-4">
                                        <h1 className="mt-1 pt-1">
                                            Form submitted successfully<i style={{ fontSize: "50px" }}
                                                className="fa fa-check-circle align-middle bx-flashing text-success ml-2 pb-2"
                                                aria-hidden="true"
                                            ></i>
                                        </h1>
                                    </div>

                                    <div className="p-1 pl-5" style={{ marginTop: "18%" }}>
                                        {alldetails.map((item, key) => (
                                            getTextUI(item.left, item.right, key)
                                        ))}
                                    </div>

                                    <div style={{ marginTop: "18%" }}>
                                        <div>
                                            <button className="btn btn-success mb-2" onClick={() => window.location.reload()}>
                                                Done
                                            </button>
                                        </div>
                                        <Label>(Please show this to security)</Label>
                                    </div>
                                </div>
                            </center>
                        </CardBody>
                    </Card>
                )}



                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Visitor Form...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}

                <Modal isOpen={otpPopup}>
                    <ModalHeader
                        toggle={() => {
                            setOtpPopup(!otpPopup)
                        }}>
                        Enter OTP Here
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginRight: '10px',
                                        textAlign: 'center',
                                        fontSize: '16px',
                                    }}
                                />

                            ))}
                        </div>
                    </ModalBody>
                    <Row className="p-3">
                        <Col className="text-center">
                            <button
                                onClick={() => {
                                    setOtpPopup(!otpPopup)
                                }}
                                className="btn btn-secondary btn-sm m-1">
                                Cancel
                            </button>

                            <button
                                className="btn btn-primary btn-sm m-1"
                                onClick={() => {

                                    let otpRef = inputRefs.current;
                                    let otpStr;

                                    for (const o of otpRef) {
                                        if (otpStr) {
                                            otpStr = otpStr + o.value
                                        } else {
                                            otpStr = o.value
                                        }
                                    }

                                    console.log(otpStr)

                                    verifyOtp(otpStr)
                                }}>
                                Submit
                            </button>
                        </Col>
                    </Row>
                </Modal>
            </Container>
        </React.Fragment >
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
        })(VisitorForm)
    )
);