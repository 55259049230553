import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Button, Label, InputGroup, InputGroupText, } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { loginUser, apiError, logoutUser } from "../../store/actions";
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/lilLogo.png";
import * as Constants from "../../Constents";
import * as WhiteLable from "../../components/Common/WhiteLable";
import * as CommonFunctions from "../../components/Common/CommonFunctions";

const Login = (props) => {

  const [email, setEmail] = useState('');
  const [en, setEn] = useState(true);
  const [password, setPassword] = useState();
  useEffect(() => {
    if (en) {
      // props.logoutUser();
      setEn(false);
    }

  }, [en])

  function handleValidSubmit(event, values) {
    localStorage.removeItem(Constants.SCREEN_LOCK);
    props.loginUser(values, props.history);
  }

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <React.Fragment>

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          {props.t("Welcome Back !")}
                        </h5>
                        <p>
                          {props.t("Sign in to continue to") +
                            " " +
                            WhiteLable.getAppName()}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    {WhiteLable.isSanfortApp() ? (<div className="avatar-lg ">
                      <span className="avatar-title bg-white">
                        <img
                          src={WhiteLable.getAppLogo()}
                          alt=""
                          className=""
                          height="60"
                          style={{ marginTop: '-2.5cm', marginLeft: '1cm' }}
                        />
                      </span>
                    </div>) : (WhiteLable.isJumpStartApp() ? (
                      <div className="avatar-lg ">
                        <span className="avatar-title bg-white" >
                          <img
                            src={WhiteLable.getAppLogo()}
                            alt=""
                            className="bg-white p-1"
                            height="60"
                            style={{ marginTop: '-2.5cm', marginLeft: '2cm', border: '2px solid #556ee62e' }}
                          />
                        </span>
                      </div>
                    ) : (<div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={WhiteLable.getAppLogo()}
                          alt=""
                          className="rounded-circle"
                          height="50"
                        />
                      </span>
                    </div>))
                    }
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error !== "" && (
                        <Alert color="danger">{props.error}{props.verifyUserError && <Link className="text-success ml-3" onClick={() => { props.apiError("") }} to={(email && email.length > 0) ? `email-verification/${email}` : "email-verification"}>Verify?</Link>}</Alert>
                      )}


                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          value={email}
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={(e) => { setEmail(e.target.value) }}
                          required
                        />
                      </div>

                      {/* <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div> */}
                      <div className="position-relative">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type={passwordVisible ? 'text' : 'password'}
                          className="form-control"
                          required
                          placeholder="Enter Password"
                          onChange={(e) => {
                            setPassword(e.target.value)
                          }}
                        />
                        {password && <div className="position-absolute end-0 bottom-0 mb-2 me-2" style={{ cursor: 'pointer', }} onClick={togglePasswordVisibility}>
                          {passwordVisible ? <i className="mdi mdi-eye" /> : <i className="mdi mdi-eye-off" />}
                        </div>}
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input m-1"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          {props.t("Remember me")}
                        </label>
                      </div>

                      {props.loading ? (
                        <div className="mt-3">
                          <button className="btn btn-primary btn-block waves-effect waves-light" style={{ width: '100%' }}>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                          </button>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <button
                            style={{ width: '100%' }}
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            {props.t("Log In")}
                          </button>
                        </div>
                      )}



                      {!CommonFunctions.isTools() && <div className="mt-4 text-center">
                        <Link to={"/forgot-password-verification/" + email} className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i>{" "}
                          {props.t("Forgot your password?")}
                        </Link>
                      </div>}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              {!CommonFunctions.isTools() && <div className="mt-5 text-center">
                {/* {console.log("App name ", WhiteLable.getAppName() ,WhiteLable.getAppName() === "lilTriangle")} */}
                {(WhiteLable.getAppName() === "lilTriangle" || "Sanfort" || "JumpStart") && <p>
                  {props.t("Dont have an account ? ")}
                  <Link
                    to="register"
                    className="font-weight-medium text-primary"
                  >
                    {props.t("Signup now")}
                  </Link>
                </p>}
                <p>
                  © {new Date().getFullYear()} {WhiteLable.getFooterName()}.
                  {WhiteLable.getDesignTag()}
                </p>
              </div>}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, loading, verifyUserError } = state.Login;
  return { error, loading, verifyUserError };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, { loginUser, apiError, logoutUser })(Login))
);
