import React from 'react';
import logo from "../../assets/images/lilLogo.png";
import logoWithName from "../../assets/images/logo-dark.png";
import adigurukulName2 from "../../assets/images/adigurukul/adigurukul2.png";
import asciLogoWithName from '../../assets/images/asci/asci_name_logo.png'
import asciLogo from '../../assets/images/asci/asci_logo.png'
import SanfortLogo from '../../assets/images/sanfort/sanfortlogo.png';
import JumpStartLogo from "../../assets/images/jumpStart/Jumpstart-Logo.png"


export const getAppName = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "Adigurukul";
    }
    if (isASCI(currentLocation)) {
        return "IWE";
    }
    if (isSanfort(currentLocation)) {
        return "Sanfort"
    }
    if (isJumpStart(currentLocation)) {
        return "JumpStart"
    }
    return "lilTriangle";
};
export const getAppLogo = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return adigurukulName2;
    }
    if (isASCI(currentLocation)) {
        return asciLogo;
    }
    if (isSanfort(currentLocation)) {
        return SanfortLogo;
    }
    if (isJumpStart(currentLocation)) {
        return JumpStartLogo;
    }
    return logo;
};
export const getAppLogoHeight = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "70";
    }
    if (isASCI(currentLocation)) {
        return "70";
    }
    if (isSanfort(currentLocation)) {
        return "70";
    }
    if (isJumpStart(currentLocation)) {
        return "50";
    }
    //35
    return "35";
};

export const getAppLogoWithName = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return adigurukulName2;
    }
    if (isASCI(currentLocation)) {
        return asciLogoWithName;
    }
    if (isSanfort(currentLocation)) {
        return SanfortLogo;
    }
    if (isJumpStart(currentLocation)) {
        return JumpStartLogo;
    }
    return logoWithName;
};
export const getAppLogoWithNameHeight = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "70";
    }
    if (isASCI(currentLocation)) {
        return "50";
    }
    if (isSanfort(currentLocation)) {
        return "50";
    }
    if (isJumpStart(currentLocation)) {
        return "50";
    }
    //30
    return "30";
};
export const getAppOneLink = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return 
    }
    if (isSanfort(currentLocation)) {
        return "https://onelink.to/jreb7m"
    }
    if (isJumpStart(currentLocation)) {
        return "https://onelink.to/2ur8uq";
    }
    return "https://onelink.to/liltriangle";
};
export const getFooterName = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "Innomonk Technologies";
    }
    if (isASCI(currentLocation)) {
        return "Institute for Wash Excellence";
    }
    if (isSanfort(currentLocation)) {
        return "Sanfort";
    }
    if (isJumpStart(currentLocation)) {
        return "JumpStart";
    }
    return "lilTriangle";
};

export const getDesignTag = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return " learning for all";
    }
    if (isASCI(currentLocation)) {
        return " ";
    }
    if (isSanfort(currentLocation)) {
        return " ";
    }
    if (isJumpStart(currentLocation)) {
        return " ";
    }
    return (<div>Crafted with{" "}<i className="mdi mdi-heart text-danger"></i> by lilTriangle</div>);
};
export const getHost = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "https://adigurukul.innomonk.com";
    } if (isASCI(currentLocation)) {
        return "https://lms.iwe-asci.org";
    } else if (isSanfort(currentLocation)) {
        return "https://sanfort.liltriangle.com"
    }else if (isJumpStart(currentLocation)) {
        return "https://jumpstart.liltriangle.com"
    } else {
        return "https://dashboard.liltriangle.com"
    } 
};

export const isAdigurkulApp = () => {
    var currentLocation = window.location;
    return isAdigurkul(currentLocation);
};

let isAdigurkul = (currentLocation) => {
    if (currentLocation.host === "adigurukul.innomonk.com") {
        return true;
    } else if (currentLocation.hostname.toLowerCase().startsWith("adigurukul")) {
        return true;
    }
    if (currentLocation.ancestorOrigins) {
        var link = currentLocation.ancestorOrigins[0];
        if (link && link.includes("adigurukul")) {
            return true;
        }
        return false;
    } else {
        return false
    }
}

let isASCI = (currentLocation) => {
    if (currentLocation.host === "lms.iwe-asci.org") {
        return true;
    } else if (currentLocation.hostname.toLowerCase().startsWith("lms.iwe-asci.org")) {
        return true;
    }
    if (currentLocation.ancestorOrigins) {
        var link = currentLocation.ancestorOrigins[0];
        if (link && link.includes("lms.iwe-asci.org")) {
            return true;
        }
        return false;
    } else {
        return false
    }
}

export const isSanfortApp = () => {
    var currentLocation = window.location;
    return isSanfort(currentLocation);
};

let isSanfort = (currentLocation) => {
    if (currentLocation.host === "sanfort.liltriangle.com") {
        return true;
    } else if (currentLocation.hostname.toLowerCase().startsWith("sanfort")) {
        return true;
    }
    if (currentLocation.ancestorOrigins) {
        var link = currentLocation.ancestorOrigins[0];
        if (link && link.includes("sanfort")) {
            return true;
        }
        return false;
    } else {
        return false
    }
}

export const isJumpStartApp = () => {
    var currentLocation = window.location;
    return isJumpStart(currentLocation);
};

let isJumpStart = (currentLocation) => {
    if (currentLocation.host === "jumpstart.liltriangle.com") {
        return true;
    } else if (currentLocation.hostname.toLowerCase().startsWith("jumpstart")) {
        return true;
    }
    if (currentLocation.ancestorOrigins) {
        var link = currentLocation.ancestorOrigins[0];
        if (link && link.includes("jumpstart")) {
            return true;
        }
        return false;
    } else {
        return false
    }
}