import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
  CustomInput,
  UncontrolledTooltip,

} from "reactstrap";

import * as WhiteLable from "../../components/Common/WhiteLable";

import SweetAlert from "react-bootstrap-sweetalert";

import Moment from "moment";
import * as Scroll from "react-scroll";
import toastr from "toastr";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import classnames from "classnames";
import UpdatePhoto from "../../components/Common/UpdatePhoto";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";

import * as Constants from "../../Constents";
import {
  getFranchise,
  sendAdmissionLinkToParent,
  getParentsForFamilyIds,
} from "../../store/actions";

const AdmissionFormComponent = (props) => {
  const next = "NEXT";
  const previous = "PREVIOUS";

  //SchoolId is parameter
  const [admissionDataList, setAdmissionDataList] = useState([])
  const [showAdmissionLinkPopupWithoutEnquiryId, setShowAdmissionLinkPopupWithoutEnquiryId] = useState(false)
  const [showAdmissionLinkPopupWithEnquiryId, setShowAdmissionLinkPopupWithEnquiryId] = useState(false)
  const [showRemindAdmissionLinkPopup, setShowRemindAdmissionLinkPopup] = useState(false)

  const [parameter, setParameter] = useState(props.schoolId ?? "");
  const [enquiryId, setEnquiryId] = useState(props.enquiryId ?? "");

  const [kidId, setKidId] = useState(props.kidId ?? "")

  const [stateSchoolObject, setStateSchoolObject] = useState(undefined);
  const [admissionTitleList, setAdmissionTitleList] = useState([]);
  const [checkForMandatoryFields, setCheckForMandatoryFields] = useState(props.checkForMandatoryFields ?? false);

  const [stateEnquiryObject, setStateEnquiryObject] = useState(undefined);
  const [stateMotherObject, setStateMotherObject] = useState(undefined);
  const [stateFatherObject, setStateFatherObject] = useState(undefined);
  const [stateKidObject, setStateKidObject] = useState(undefined);
  const [
    stateChildAdditionalDetailsObject,
    setStateChildAdditionalDetailsObject,
  ] = useState(undefined);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isQuestionsAvailable, setIsQuestionsAvailable] = useState(true);
  const [isEditModeOn, setIsEditModeOn] = useState(false);

  const [cardMessage, setCardMessage] = useState("");
  const [cardIcon, setCardIcon] = useState("fa fa-check text-success mr-2");

  const [isLoading, setIsLoading] = useState(true);
  const [formIsSubmites, formIsSubmitesOps] = useState(false);

  const [showReshareFormAlert, setShowReshareFormAlert] = useState(false)

  const [callFranchise, setCallFranchise] = useState(0);

  var childAdditionalDetailsObject = null;
  var fatherObject = null;
  var motherObject = null;
  var enquiryObject = null;
  var kidObject = null;

  var abortSubmitForm = false;

  const [activeTab, setactiveTab] = useState(0);
  const [progressValue, setprogressValue] = useState(0);

  useEffect(() => {
    if (enquiryId !== "") {
      fetchEnquiryObject(enquiryId);
    }
  }, [enquiryId]);


  useEffect(() => {
    if (kidId !== "") {
      fetchKidFlow(kidId);
    }
  }, [kidId]);



  const fetchKidFlow = () => {
    Parse.Cloud.run("function_getAdmissionDetailsForForm", {
      schoolId: parameter,
      kidId: kidId,
    }).then(
      (result) => {
        if (result) {
          if (result.school != null) {
            setStateSchoolObject(result.school);
          } else {
            //Change UI for invalid url
            setIsFormSubmitted(true);
            setCardMessage("Invalid URL !!");
            setCardIcon("fas fa-exclamation-triangle text-danger mr-2");
            return null;
          }

          if (result.enquiry != null) {
            enquiryObject = result.enquiry;
            setStateEnquiryObject(result.enquiry);
          }
          if (result.kid != null) {
            kidObject = result.kid
            setStateKidObject(result.kid);
          }

          if (result.parents != null && result.parents.length > 0) {
            for (const parent of result.parents) {
              if (parent.attributes.Relation === "Mother") {
                motherObject = parent;
                setStateMotherObject(parent);
              } else if (parent.attributes.Relation === "Father") {
                fatherObject = parent;
                setStateFatherObject(parent);
              }
            }
          }

          if (result.childAdditionalDetails) {
            childAdditionalDetailsObject = result.childAdditionalDetails;
            setStateChildAdditionalDetailsObject(result.childAdditionalDetails);
          }

          if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
            props.getFranchise(props.selectedSchool.attributes.franchiseId, true);
            setCallFranchise(1)
          } else {
            fetchAdmissionTitleQuestionsForSchoolId("schoolId", parameter);
          }


        }
      },
      (error) => {
        return null;
      }
    );
  }

  const fetchEnquiryObject = (enquiryId) => {
    var query = new Parse.Query("Enquiry");
    query.equalTo("objectId", enquiryId);
    //query.doesNotExist("admissionFormSubmittedOn");
    query.first().then((result) => {
      fetchSchoolFromServer(parameter);
    });
  };
  const fetchSchoolFromServer = (schoolId) => {
    Parse.Cloud.run("function_getAdmissionDetailsForForm", {
      schoolId: schoolId,
      enquiryId: enquiryId,
    }).then(
      (result) => {
        if (result) {
          if (result.school != null) {
            setStateSchoolObject(result.school);
          } else {
            //Change UI for invalid url
            setIsFormSubmitted(true);
            setCardMessage("Invalid URL !!");
            setCardIcon("fas fa-exclamation-triangle text-danger mr-2");
            return null;
          }

          if (result.enquiry != null) {
            enquiryObject = result.enquiry;
            setStateEnquiryObject(result.enquiry);
          }
          if (result.kid != null) {
            kidObject = result.kid
            setStateKidObject(result.kid);
          }

          if (result.parents != null && result.parents.length > 0) {
            for (const parent of result.parents) {
              if (parent.attributes.Relation === "Mother") {
                motherObject = parent;
                setStateMotherObject(parent);
              } else if (parent.attributes.Relation === "Father") {
                fatherObject = parent;
                setStateFatherObject(parent);
              }
            }
          }

          if (result.childAdditionalDetails) {
            childAdditionalDetailsObject = result.childAdditionalDetails;
            setStateChildAdditionalDetailsObject(result.childAdditionalDetails);
          }


          if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
            props.getFranchise(props.selectedSchool.attributes.franchiseId, true);
            setCallFranchise(1)
          } else {
            fetchAdmissionTitleQuestionsForSchoolId("schoolId", parameter);
          }

        }
      },
      (error) => {
        return null;
      }
    );
  };

  useEffect(() => {

    if (callFranchise === 1) {
      if (props.franchise &&
        props.franchise.attributes.features &&
        props.franchise.attributes.features.length > 1 &&
        props.franchise.attributes.features[1] > 0) {

        fetchAdmissionTitleQuestionsForSchoolId("franchiseId", props.franchise.id);
      } else {
        fetchAdmissionTitleQuestionsForSchoolId("schoolId", parameter);
      }
    }


  }, [props.franchise]);



  // const fetchKidForEnquiryId = (enquiryId) => {
  //   var query = new Parse.Query("Kid");
  //   query.equalTo("enquiryId", enquiryId);
  //   query.first().then((result) => {

  //     if (result != null) {

  //       var tempObject = result
  //       kidObject = tempObject
  //       setStateKidObject(tempObject)

  //       //fetchParentsObjectForFamilyId(tempObject.attributes.FamilyID, results[0])
  //     }
  //   });
  // }

  // const fetchParentsObjectForFamilyId = (familyId, kidObj) => {
  //   //setKidObject(kidObj)
  //   var query = new Parse.Query("Parent");
  //   query.equalTo("FamilyID", familyId);
  //   query.find().then((results) => {
  //     console.log("parent")

  //     if (results != null && results.length > 0) {

  //       var i = 0

  //       for (i = 0; i < results.length; i++) {
  //         if (results[i].attributes.Relation === "Mother") {
  //           motherObject = results[i]
  //           setStateMotherObject(results[i]);

  //         } else if (results[i].attributes.Relation === "Father") {
  //           fatherObject = results[i]
  //           setStateFatherObject(results[i]);

  //         }
  //       }

  //     }
  //     fetchChildAdditionalDetailsObject(kidObj.id)

  //   });
  // }

  // const fetchChildAdditionalDetailsObject = (enquiryId) => {
  //   var query = new Parse.Query("ChildAdditionalDetails");
  //   query.equalTo("enquiryId", enquiryId);
  //   query.find().then((results) => {
  //     console.log("childAdditionalDetails")

  //     if (results != null && results.length > 0) {

  //       var tempObject = results[0]
  //       childAdditionalDetailsObject = tempObject
  //       setStateChildAdditionalDetailsObject(tempObject)
  //     }

  //     console.log("final")
  //     console.log(enquiryObject, kidObject, motherObject, fatherObject, childAdditionalDetailsObject)
  //     setIsLoading(false)
  //     fetchAdmissionTitleQuestionsForSchoolId(parameter)

  //   });
  // }

  useEffect(() => {

    if (stateKidObject) {
      let familyIds = []
      if (stateKidObject.attributes.FamilyID) {
        familyIds.push(stateKidObject.attributes.FamilyID)
      }
      if (familyIds.length > 0) {
        props.getParentsForFamilyIds(familyIds);
      }
    }

  }, [stateKidObject])

  const fetchAdmissionTitleQuestionsForSchoolId = (type, id) => {
    var query = new Parse.Query("AdmissionTitle");
    query.equalTo(type, id);
    query.ascending("pageNumber");
    query.notEqualTo("isDeleted", true);

    query.find().then((results) => {
      console.log("admissionTitle");

      if (results != null) {
        if (results.length > 0) {
          console.log(results);

          var admissionTitleParseList = results;
          var titleIds = [];
          var i = 0;
          for (i = 0; i < admissionTitleParseList.length; i++) {
            titleIds.push(admissionTitleParseList[i].id);
          }
          fetchAdmissionQuestionsForTitleIds(admissionTitleParseList, titleIds);
        } else {
          setIsQuestionsAvailable(false);
        }
      }
    });
  };

  const fetchAdmissionQuestionsForTitleIds = (
    admissionTitleParseList,
    titleIds
  ) => {
    var query = new Parse.Query("AdmissionQuestion");
    query.containedIn("admissionTitleId", titleIds);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);

    query.find().then((results) => {
      var tempTitleList = [];

      if (results != null) {
        console.log(results);
        var i = 0;
        let admissionQuestionIdsToFetch = []
        for (i = 0; i < admissionTitleParseList.length; i++) {
          var j = 0;
          var tempQuestionList = [];

          var customAdmissionTitleObject = new CustomAdmissionTitleObject();
          customAdmissionTitleObject.title =
            admissionTitleParseList[i].attributes.title ?? "";
          customAdmissionTitleObject.admissionTitleParseObject =
            admissionTitleParseList[i];

          for (j = 0; j < results.length; j++) {

            if (
              admissionTitleParseList[i].id ===
              results[j].attributes.admissionTitleId
            ) {

              if (results[j].attributes.databaseTableName === "AdmissionData") {
                admissionQuestionIdsToFetch.push(results[j].id)
              }
              var enqObject = results[j];

              var newEnquiryObject = new CustomAdmissionObject();
              newEnquiryObject.question = enqObject.attributes.question ?? "";
              newEnquiryObject.questionType =
                enqObject.attributes.questionType ?? "";
              newEnquiryObject.questionNumber = i + 1;
              if (enqObject.attributes.dataType) {
                newEnquiryObject.dataType = enqObject.attributes.dataType;
              }
              if (enqObject.attributes.choice) {
                newEnquiryObject.choices = [...enqObject.attributes.choice];
              }
              if (
                enqObject.attributes.isMandatory &&
                enqObject.attributes.isMandatory === true
              ) {
                newEnquiryObject.isMandatory = true;
              } else {
                newEnquiryObject.isMandatory = false;
              }
              newEnquiryObject.dataType = enqObject.attributes.dataType;
              if (
                enqObject.attributes.questionHint != null &&
                enqObject.attributes.questionHint !== ""
              ) {
                newEnquiryObject.questionHint =
                  enqObject.attributes.questionHint;
              } else {
                newEnquiryObject.questionHint = "Type here";
              }
              newEnquiryObject.answerChoices = [];

              if (
                enqObject.attributes.questionType &&
                (enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_COMMENT_ON_NO ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_COMMENT_ON_YES ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_NO_COMMENTS ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_WITH_COMMENT)
              ) {
                if (
                  enqObject.attributes.choice == null ||
                  !enqObject.attributes.choice.length > 0
                ) {
                  newEnquiryObject.choices = ["Yes", "No"];
                }
              }
              newEnquiryObject.answerError = false;

              newEnquiryObject.admissionQuestionParseObject = enqObject;

              tempQuestionList.push(fillInitialValues(newEnquiryObject));
            }
          }

          console.log(tempQuestionList);

          customAdmissionTitleObject.admissionQuestionsList = tempQuestionList;

          tempTitleList.push(customAdmissionTitleObject);
        }
        setIsLoading(false);
        setprogressValue(100 / tempTitleList.length);
        setAdmissionTitleList(tempTitleList);

        if (admissionQuestionIdsToFetch.length > 0) {
          getAdmissionDataResults(tempTitleList, admissionQuestionIdsToFetch)
        }
      }
    });
  };

  const fillInitialValuesForAdmissionData = (formQuestionObject, formDataResults) => {
    var objectToReturn = formQuestionObject;
    if (formDataResults && formDataResults.length > 0) {
      let tempAnswer;
      let tempFormDataAnswer = formDataResults.filter(
        (fd) =>
          fd.attributes.questionId === objectToReturn.admissionQuestionParseObject.id
      );
      if (tempFormDataAnswer && tempFormDataAnswer.length > 0) {
        tempAnswer = tempFormDataAnswer[0].attributes.answer;
      }
      if (
        objectToReturn.questionType === Constants.QESTION_TEXT_SINGLE_LINE ||
        objectToReturn.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
        objectToReturn.questionType === Constants.QUESTION_EMAIL ||
        objectToReturn.questionType === Constants.QUESTION_PHONE_NUMBER
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }

        if (result) {
          objectToReturn.answerText = result;
        }
      } else if (objectToReturn.questionType === Constants.QESTION_NUMBER) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerNumber = result;
        }
      } else if (objectToReturn.questionType === Constants.QESTION_CHECKBOX) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerBool = result;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_NO_COMMENTS
      ) {
        var result = undefined;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result != undefined) {
          objectToReturn.answerBool = result;
        }
      } else if (
        objectToReturn.questionType ===
        Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      } else if (
        objectToReturn.questionType ===
        Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      } else if (objectToReturn.questionType === Constants.QESTION_DATE) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerDate = result;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES
      ) {
        var result1 = undefined;
        var result2 = undefined;
        if (tempAnswer && tempAnswer.length > 0) {

          result1 = tempAnswer[0];
          if (tempAnswer.length > 1) {
            result2 = tempAnswer[1];
          }

        }
        if (result1 != undefined) {
          objectToReturn.answerBool = result1;

        }
        if (result2 && result2.length > 0) {
          objectToReturn.answerText = result2;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO
      ) {
        var result1 = undefined;
        var result2 = undefined;
        if (tempAnswer && tempAnswer.length > 0) {

          result1 = tempAnswer[0];
          if (tempAnswer.length > 1) {
            result2 = tempAnswer[1];
          }

        }
        if (result1 != undefined) {
          objectToReturn.answerBool = result1;

        }
        if (result2 && result2.length > 0) {
          objectToReturn.answerText = result2;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_WITH_COMMENT
      ) {
      } else if (
        objectToReturn.questionType === Constants.QESTION_UPLOAD_IMAGE
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }

        if (result) {
          let name = result._name.split(/_(.+)/)[1];
          let fileExtension = name.split(".");
          let tempFile = {
            actualImageName: name,
            fileExtension: fileExtension[1].toLowerCase(),
            imageName: fileExtension[0],
            image: result,
            imageURL: result._url,
          };
          objectToReturn.answerImageUploadProgress = 100;
          objectToReturn.answerImage = tempFile;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_UPLOAD_DOCUMENT
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          let name = result._name.split(/_(.+)/)[1];
          let fileExtension = name.split(".");

          let tempFile = {
            actualFileName: name,
            fileExtension: fileExtension[1].toLowerCase(),
            fileName: fileExtension[0],
            file: result,
          };

          objectToReturn.answerFileUploadProgress = 100;
          objectToReturn.answerFile = tempFile;
        }
      }
    }

    return objectToReturn;
  };

  const getAdmissionDataResults = (admissionTitleList, questionIds) => {
    var AdmissionData = Parse.Object.extend("AdmissionData");
    var query = new Parse.Query(AdmissionData);
    if (enquiryId && enquiryId.length > 0) {
      query.equalTo("enquiryId", enquiryId);
    } else {
      query.equalTo("kidId", kidId);

    }
    query.containedIn("questionId", questionIds)





    query.limit = 1000;
    query.find().then(
      (results) => {
        if (results && results.length > 0) {
          setAdmissionDataList(results)
          let tempMasterList = [...admissionTitleList]

          for (var i = 0; i < tempMasterList.length; i++) {

            for (var j = 0; j < tempMasterList[i].admissionQuestionsList.length; j++) {
              if (tempMasterList[i].admissionQuestionsList[j].admissionQuestionParseObject.attributes.databaseTableName === "AdmissionData") {
                tempMasterList[i].admissionQuestionsList[j] = fillInitialValuesForAdmissionData(tempMasterList[i].admissionQuestionsList[j], results)
              }
            }

          }
          setAdmissionTitleList(tempMasterList)



        }


      },
      (error) => {

      }
    );
  }

  const checkForObjectTypeAndReturnAnswer = (questionObject) => {
    var databaseFieldName =
      questionObject.admissionQuestionParseObject.attributes.databaseFieldName;

    var databaseTableName =
      questionObject.admissionQuestionParseObject.attributes.databaseTableName;

    if (databaseTableName === "Enquiry") {
      let enqObj = enquiryObject ? enquiryObject : stateEnquiryObject;
      if (
        enqObj &&
        enqObj.attributes[databaseFieldName] != null
      ) {
        return enqObj.attributes[databaseFieldName];
      }

    } else if (databaseTableName === "Kid") {
      let kidObj = kidObject ? kidObject : stateKidObject;
      if (
        kidObj &&
        kidObj.attributes[databaseFieldName] != null
      ) {
        return kidObj.attributes[databaseFieldName];
      }
    } else if (databaseTableName === "ChildAdditionalDetails") {
      let cadObj = childAdditionalDetailsObject ? childAdditionalDetailsObject : stateChildAdditionalDetailsObject;
      if (
        cadObj &&
        cadObj.attributes[databaseFieldName] != null
      ) {
        return cadObj.attributes[databaseFieldName];
      }
    } else if (
      databaseTableName === "Parent" &&
      questionObject.admissionQuestionParseObject.attributes.objectType !=
      null &&
      questionObject.admissionQuestionParseObject.attributes.objectType === 1
    ) {
      let mObj = motherObject ? motherObject : stateMotherObject;
      if (mObj && mObj.attributes[databaseFieldName] != null) {
        return mObj.attributes[databaseFieldName];
      }
    } else if (databaseTableName === "Parent") {
      if (
        questionObject.admissionQuestionParseObject.attributes.objectType !=
        null &&
        questionObject.admissionQuestionParseObject.attributes.objectType === 2
      ) {
        let fObj = fatherObject ? fatherObject : stateFatherObject;
        if (
          fObj &&
          fObj.attributes[databaseFieldName] != null
        ) {
          return fObj.attributes[databaseFieldName];
        }
      } else {
        if (
          fatherObject &&
          fatherObject.attributes[databaseFieldName] != null
        ) {
          return fatherObject.attributes[databaseFieldName];
        }
      }
    }

    return false;
  };

  const fillInitialValues = (admissionQuestionObject) => {
    var objectToReturn = admissionQuestionObject;

    if (
      objectToReturn.questionType === Constants.QESTION_TEXT_SINGLE_LINE ||
      objectToReturn.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
      objectToReturn.questionType === Constants.QUESTION_EMAIL ||
      objectToReturn.questionType === Constants.QUESTION_PHONE_NUMBER
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerText = result;
      }
    } else if (objectToReturn.questionType === Constants.QESTION_NUMBER) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerNumber = result;
      }
    } else if (objectToReturn.questionType === Constants.QESTION_CHECKBOX) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerBool = result;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result === true || result === false) {
        objectToReturn.answerBool = result;
      }
    } else if (
      objectToReturn.questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        if (Array.isArray(result) && result.length > 1) {
          objectToReturn.answerChoices = [result[0]];
        } else {
          if (Array.isArray(result)) {
            objectToReturn.answerChoices = [...result];
          } else {
            objectToReturn.answerChoices = [result];
          }
        }
      }
    } else if (
      objectToReturn.questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerChoices = [...result];
      }
    } else if (objectToReturn.questionType === Constants.QESTION_DATE) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerDate = result;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerBool = true;
        objectToReturn.answerText = result;
      } else {
        objectToReturn.answerBool = false;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerBool = false;
        objectToReturn.answerText = result;
      } else {
        objectToReturn.answerBool = true;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (objectToReturn.questionType === Constants.QESTION_UPLOAD_IMAGE) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        let name = result._name.split(/_(.+)/)[1];
        let fileExtension = name.split(".");
        let tempFile = {
          actualImageName: name,
          fileExtension: fileExtension[1].toLowerCase(),
          imageName: fileExtension[0],
          image: result,
          imageURL: result._url,
        };
        objectToReturn.answerImageUploadProgress = 100;
        objectToReturn.answerImage = tempFile;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        let name = result._name.split(/_(.+)/)[1];
        let fileExtension = name.split(".");

        let tempFile = {
          actualFileName: name,
          fileExtension: fileExtension[1].toLowerCase(),
          fileName: fileExtension[0],
          file: result,
        };

        objectToReturn.answerFileUploadProgress = 100;
        objectToReturn.answerFile = tempFile;
      }
    }

    return objectToReturn;
  };

  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  //on Doc Upload
  function handleFileUploadChange(
    e,
    index,
    admissionQuestionObject,
    admissionTitleObject
  ) {
    let questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    let titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    let tempQuestionObject = admissionQuestionObject;

    let tempTitleObject = admissionTitleList[titleIndex];
    tempQuestionObject.answerFile = undefined;
    tempQuestionObject.answerFileUploadProgress = 1;

    let tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    let tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);

    let fileExtension = e.target.files[0].name.split(".");
    let tempFile = {
      actualFileName: e.target.files[0].name,
      fileExtension: fileExtension[1].toLowerCase(),
      fileName: fileExtension[0],
      file: e.target.files[0],
    };

    var name =
      tempFile.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "") +
      tempFile.fileExtension;

    getBase64(tempFile.file, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result });
      //setUploadProgress(1);
      parseFile
        .save({
          progress: (value) => {
            if (value && value > 0.1 && value < 99.1) {
              //setUploadProgress(value * 100);
              //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

              let tempQuestionObject = admissionQuestionObject;

              tempTitleObject = admissionTitleList[titleIndex];;

              if (value < 1) {
                tempQuestionObject.answerFileUploadProgress = value * 100;
              }


              let tempQuestionList = [
                ...tempTitleObject.admissionQuestionsList,
              ];

              tempQuestionList[questionIndex] = tempQuestionObject;

              tempTitleObject.admissionQuestionsList = [...tempQuestionList];

              let tempTitleList = [...admissionTitleList];

              tempTitleList[titleIndex] = tempTitleObject;

              setAdmissionTitleList(tempTitleList);
            }
          },
        })
        .then(
          (result) => {
            tempFile.file = result;

            let tempQuestionObject = admissionQuestionObject;
            tempQuestionObject.answerFileUploadProgress = 100;

            tempTitleObject = admissionTitleList[titleIndex];

            tempQuestionObject.answerFile = tempFile;
            tempQuestionObject.answerError = false;

            let tempQuestionList = [...tempTitleObject.admissionQuestionsList];

            tempQuestionList[questionIndex] = tempQuestionObject;

            tempTitleObject.admissionQuestionsList = [...tempQuestionList];

            let tempTitleList = [...admissionTitleList];

            tempTitleList[titleIndex] = tempTitleObject;

            setAdmissionTitleList(tempTitleList);
          },
          (error) => {
            //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
          }
        );
    });

    let tempQuestionObject1 = admissionQuestionObject;

    let tempTitleObject1 = admissionTitleObject;

    tempQuestionObject1.answerFile = {
      actualFileName: tempFile.actualFileName,
    };

    let tempQuestionList1 = [...tempTitleObject1.admissionQuestionsList];

    tempQuestionList1[questionIndex] = tempQuestionObject1;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList1];

    let tempTitleList1 = [...admissionTitleList];

    tempTitleList1[titleIndex] = tempTitleObject1;

    setAdmissionTitleList(tempTitleList1);

    // enqObject.answerFile = { actualFileName: tempFile.actualFileName };
    // enquiryQuestionsList[index] = enqObject;

    // var temp = [...enquiryQuestionsList];
    // temp[index] = enqObject;
    // setEnquiryQuestionsList(temp);
  }

  // On image upload
  function handleImageUploadChange(
    index,
    changedImageURL,
    imageFile,
    admissionQuestionObject,
    admissionTitleObject
  ) {
    let questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    let titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    let tempQuestionObject = admissionQuestionObject;

    let tempTitleObject = admissionTitleList[titleIndex];
    tempQuestionObject.answerImage = undefined;
    tempQuestionObject.answerImageUploadProgress = 1;

    let tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    let tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);

    let fileExtension = imageFile._name.split(".");
    let tempFile = {
      actualImageName: imageFile._name,
      fileExtension: fileExtension[1].toLowerCase(),
      imageName: fileExtension[0],
      image: imageFile,
      imageURL: undefined,
    };

    //var name = tempFile.imageName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

    tempFile.image
      .save({
        progress: (value) => {
          if (value && value > 0.1 && value < 99.1) {
            //setUploadProgress(value * 100);
            //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

            let tempQuestionObject = admissionQuestionObject;

            tempTitleObject = admissionTitleList[titleIndex];

            if (value < 1) {
              tempQuestionObject.answerImageUploadProgress = value * 100;
            }


            let tempQuestionList = [...tempTitleObject.admissionQuestionsList];

            tempQuestionList[questionIndex] = tempQuestionObject;

            tempTitleObject.admissionQuestionsList = [...tempQuestionList];

            let tempTitleList = [...admissionTitleList];

            tempTitleList[titleIndex] = tempTitleObject;

            setAdmissionTitleList(tempTitleList);
          }
        },
      })
      .then(
        (result) => {
          tempFile.imageURL = result._url;
          tempFile.image = result;

          let tempQuestionObject = admissionQuestionObject;
          tempQuestionObject.answerImageUploadProgress = 100;

          tempTitleObject = admissionTitleList[titleIndex];

          tempQuestionObject.answerImage = tempFile;
          tempQuestionObject.answerError = false;

          let tempQuestionList = [...tempTitleObject.admissionQuestionsList];

          tempQuestionList[questionIndex] = tempQuestionObject;

          tempTitleObject.admissionQuestionsList = [...tempQuestionList];

          let tempTitleList = [...admissionTitleList];

          tempTitleList[titleIndex] = tempTitleObject;

          setAdmissionTitleList(tempTitleList);
        },
        (error) => {
          //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
        }
      );

    // enqObject.answerImage = { actualImageName: tempFile.actualImageName };
    // enquiryQuestionsList[index] = enqObject;

    // var temp = [...enquiryQuestionsList];
    // temp[index] = enqObject;
    // setEnquiryQuestionsList(temp);

    let tempQuestionObject1 = admissionQuestionObject;

    let tempTitleObject1 = admissionTitleObject;

    tempQuestionObject1.answerImage = {
      actualImageName: tempFile.actualImageName,
    };
    tempQuestionObject1.answerImage.imageURL = changedImageURL;

    let tempQuestionList1 = [...tempTitleObject1.admissionQuestionsList];

    tempQuestionList1[questionIndex] = tempQuestionObject1;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList1];

    let tempTitleList1 = [...admissionTitleList];

    tempTitleList1[titleIndex] = tempTitleObject1;

    setAdmissionTitleList(tempTitleList1);
  }

  const componentForQuestionType = (
    admissionQuestionObject,
    admissionTitleObject,
    key
  ) => {
    var questionType = admissionQuestionObject.questionType;

    switch (questionType) {
      case Constants.QESTION_UPLOAD_DOCUMENT:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formFileUpload" style={{ whiteSpace: "pre-wrap" }} >
              {admissionQuestionObject.question}{" "}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formFileUpload" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}

            {admissionQuestionObject.answerFile == undefined && isEditModeOn && (
              <div className="d-flex">
                <Label
                  htmlFor={"input-file-admission" + key}
                  color="primary"
                  className="btn btn-primary"
                >
                  Upload
                  <input
                    id={"input-file-admission" + key}
                    style={inputStyle}
                    type="file"
                    onChange={(e) => {
                      handleFileUploadChange(
                        e,
                        key,
                        admissionQuestionObject,
                        admissionTitleObject
                      );
                    }}
                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                  />
                </Label>
              </div>
            )}

            {admissionQuestionObject.answerFileUploadProgress === 100 && (
              <Col xl="6" md="12" sm="12" xs="12">
                <div className="border p-3">
                  <Row>
                    <div
                      className="avatar ml-3"
                      style={{ width: "5rem", height: "5rem" }}
                    >
                      <span
                        className="avatar-title bg-white ml-0 text-primary h1"
                        style={{ fontSize: "60px" }}
                      >
                        <i
                          className={getFileTypeImage(
                            admissionQuestionObject.answerFile.fileExtension
                          )}
                        ></i>
                      </span>
                    </div>

                    <div
                      className="float-right ml-4"
                      style={{ width: "200px" }}
                    >
                      <div className="text-muted font-weight-bold text-truncate">
                        {admissionQuestionObject.answerFile.actualFileName}
                      </div>
                      {isEditModeOn && (
                        <div className="d-flex mt-3">
                          <div>
                            <Label
                              htmlFor={"input-file-enquiry-Update" + key}
                              color="primary"
                              className="btn btn-primary mb-0"
                            >
                              Update
                              <input
                                id={"input-file-enquiry-Update" + key}
                                style={inputStyle}
                                type="file"
                                onChange={(e) => {
                                  handleFileUploadChange(
                                    e,
                                    key,
                                    admissionQuestionObject,
                                    admissionTitleObject
                                  );
                                }}
                                accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                              />
                            </Label>
                          </div>

                          <Button
                            className="btn-danger float-right ml-2"
                            onClick={() => {
                              var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
                                admissionQuestionObject
                              );

                              var titleIndex = admissionTitleList.indexOf(
                                admissionTitleObject
                              );

                              var tempQuestionObject = admissionQuestionObject;

                              var tempTitleObject =
                                admissionTitleList[titleIndex];

                              tempQuestionObject.answerFile = undefined;
                              tempQuestionObject.answerFileUploadProgress = 0;

                              var tempQuestionList = [
                                ...tempTitleObject.admissionQuestionsList,
                              ];

                              tempQuestionList[
                                questionIndex
                              ] = tempQuestionObject;

                              tempTitleObject.admissionQuestionsList = [
                                ...tempQuestionList,
                              ];

                              var tempTitleList = [...admissionTitleList];

                              tempTitleList[titleIndex] = tempTitleObject;

                              setAdmissionTitleList(tempTitleList);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      )}
                      {!isEditModeOn && <div className="d-flex mt-3">
                        <Button
                          className="btn btn-primary float-right ml-2"
                          color="primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (admissionQuestionObject.answerFile && admissionQuestionObject.answerFile.file) {
                              window.open(admissionQuestionObject.answerFile.file._url, "_blank")

                            } else {
                              toastr.info("Please refresh and try again...", { timeOut: 1000 })
                            }
                          }}
                        >
                          Open
                        </Button></div>
                      }
                    </div>
                  </Row>
                </div>
              </Col>
            )}

            {admissionQuestionObject.answerFile &&
              admissionQuestionObject.answerFileUploadProgress >= 1 &&
              admissionQuestionObject.answerFileUploadProgress < 100 && (
                <div className="d-flex">
                  <Col xl="6" md="12" sm="12" xs="12" className="ml-0">
                    <div className="border p-3">
                      <Row>
                        <div
                          className="avatar ml-3"
                          style={{ width: "5rem", height: "5rem" }}
                        >
                          <span className="avatar-title bg-white ml-0 text-primary h1">
                            <i
                              className={getFileTypeImage(
                                admissionQuestionObject.answerFile.fileExtension
                              )}
                            ></i>
                          </span>
                        </div>
                        <div
                          className="float-right ml-4"
                          style={{ width: "200px" }}
                        >
                          <div className="text-muted font-weight-bold">
                            {admissionQuestionObject.answerFile.actualFileName}
                          </div>
                          <div className=" mt-3">
                            <Progress
                              color="primary"
                              value={
                                admissionQuestionObject.answerFileUploadProgress
                              }
                            ></Progress>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                </div>
              )}
          </FormGroup>
        );
        break;

      case Constants.QESTION_UPLOAD_IMAGE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formImageUpload" style={{ whiteSpace: "pre-wrap" }}>
              {admissionQuestionObject.question}{" "}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formImageUpload" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            {admissionQuestionObject.answerImage &&
              admissionQuestionObject.answerImageUploadProgress === 100 && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div className="d-flex">
                        <div className="ml-3">
                          <img
                            style={{ width: "5rem", height: "5rem" }}
                            className="rounded-circle avatar"
                            src={admissionQuestionObject.answerImage.imageURL}
                            alt=""
                          />
                        </div>
                        <div
                          className="float-right ml-4"
                          style={{ width: "200px", marginLeft: '30px' }}
                        >
                          <div className="text-muted font-weight-bold text-truncate">
                            {admissionQuestionObject.answerImage.actualImageName}
                          </div>
                          {isEditModeOn && (
                            <div className="d-flex mt-3">
                              <UpdatePhoto
                                id={"imageUpload" + key}
                                tempId={"imageUpload" + key}
                                className="p-1"
                                buttonTitle="Change"
                                isPopupOn={false}
                                onPicChange={(imageFile, url) => {
                                  handleImageUploadChange(
                                    key,
                                    url,
                                    imageFile,
                                    admissionQuestionObject,
                                    admissionTitleObject
                                  );
                                }}
                                imageFileName={
                                  admissionQuestionObject.answerImage
                                    ? admissionQuestionObject.answerImage
                                      .actualImageName
                                    : ""
                                }
                                initialImage={
                                  admissionQuestionObject.answerImage.imageURL
                                }
                              ></UpdatePhoto>
                              <Button
                                className="btn-danger float-right ml-2"
                                onClick={() => {
                                  var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
                                    admissionQuestionObject
                                  );

                                  var titleIndex = admissionTitleList.indexOf(
                                    admissionTitleObject
                                  );

                                  var tempQuestionObject = admissionQuestionObject;

                                  var tempTitleObject =
                                    admissionTitleList[titleIndex];

                                  tempQuestionObject.answerImage = undefined;
                                  tempQuestionObject.answerImageUploadProgress = 0;

                                  var tempQuestionList = [
                                    ...tempTitleObject.admissionQuestionsList,
                                  ];

                                  tempQuestionList[
                                    questionIndex
                                  ] = tempQuestionObject;

                                  tempTitleObject.admissionQuestionsList = [
                                    ...tempQuestionList,
                                  ];

                                  var tempTitleList = [...admissionTitleList];

                                  tempTitleList[titleIndex] = tempTitleObject;

                                  setAdmissionTitleList(tempTitleList);
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          )}
                          {!isEditModeOn && <div className="d-flex mt-3">
                            <Button
                              className="btn btn-primary float-right ml-2"
                              color="primary"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                if (admissionQuestionObject.answerImage && admissionQuestionObject.answerImage.imageURL) {
                                  window.open(admissionQuestionObject.answerImage.imageURL, "_blank")

                                } else {
                                  toastr.info("Please refresh and try again...", { timeOut: 1000 })
                                }
                              }}
                            >
                              Open
                            </Button></div>
                          }
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {admissionQuestionObject.answerImage &&
              admissionQuestionObject.answerImageUploadProgress >= 1 &&
              admissionQuestionObject.answerImageUploadProgress < 100 && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div
                        className="avatar ml-3"
                        style={{ width: "5rem", height: "5rem" }}
                      >
                        <span
                          className={
                            "avatar-title rounded-circle bg-soft-primary text-primary"
                          }
                          style={{ fontSize: "60px" }}
                        ></span>
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {admissionQuestionObject.answerImage.actualImageName}
                        </div>
                        <div className=" mt-3">
                          <Progress
                            color="primary"
                            value={
                              admissionQuestionObject.answerImageUploadProgress
                            }
                          ></Progress>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {admissionQuestionObject.answerImage == undefined && isEditModeOn && (
              <div className="d-flex">
                <div>
                  {" "}
                  <UpdatePhoto
                    id={"imageUploadMain" + key}
                    tempId={"imageUploadMain" + key}
                    className="p-1"
                    isPopupOn={false}
                    onPicChange={(imageFile, url) => {
                      handleImageUploadChange(
                        key,
                        url,
                        imageFile,
                        admissionQuestionObject,
                        admissionTitleObject
                      );
                    }}
                    initialImage={""}
                  ></UpdatePhoto>
                </div>
              </div>
            )}
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_SINGLE_LINE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formSingleLine" style={{ whiteSpace: "pre-wrap" }}>
              {admissionQuestionObject.question}{" "}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="text"
              name={"singleLine" + key}
              placeholder={admissionQuestionObject.questionHint}
              className="form-control"
              value={admissionQuestionObject.answerText}
              disabled={!isEditModeOn}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_MULTI_LINE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formMultiLine" style={{ whiteSpace: "pre-wrap" }}>
              {admissionQuestionObject.question}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="textarea"
              name={"multiLine" + key}
              className="form-control"
              rows="3"
              value={admissionQuestionObject.answerText}
              placeholder={admissionQuestionObject.questionHint}
              disabled={!isEditModeOn}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QESTION_NUMBER:
        return (
          <FormGroup key={key}>
            <Label style={{ whiteSpace: "pre-wrap" }} >{admissionQuestionObject.question}</Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="number"
              name={"numberInput" + key}
              className="inner form-control noscroll"
              placeholder={props.t(admissionQuestionObject.questionHint)}
              disabled={!isEditModeOn}
              value={admissionQuestionObject.answerNumber}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_CHECKBOX:
        return (
          <FormGroup key={key}>
            <Label style={{ whiteSpace: "pre-wrap" }}>
              {admissionQuestionObject.question}
              {admissionQuestionObject.isMandatory && (
                <Label htmlFor="formSingleLine" className="text-danger">
                  *
                </Label>
              )}
            </Label>
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <div>
              <label
                className=""
                onClick={() => {
                  handleCheckBoxChange(
                    admissionQuestionObject,
                    admissionTitleObject
                  );
                }}
              >
                {admissionQuestionObject.choices &&
                  admissionQuestionObject.choices.length > 0
                  ? admissionQuestionObject.choices[0]
                  : ""}
              </label>
              <input
                type="checkbox"
                className="ml-2"
                id={"admissionFormCheckBox" + key}
                disabled={!isEditModeOn}
                onChange={() =>
                  handleCheckBoxChange(
                    admissionQuestionObject,
                    admissionTitleObject
                  )
                }
                checked={admissionQuestionObject.answerBool}
              />
            </div>
          </FormGroup>
        );
        break;

      case Constants.QESTION_YES_NO_NO_COMMENTS:
        return boolTagsWithoutComment(
          admissionQuestionObject,
          admissionTitleObject,
          admissionQuestionObject.choices,
          handleYesNoButtonClick,
          key
        );

        break;
      case Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST:
        return radioButtonForSingleSelection(
          admissionQuestionObject,
          admissionTitleObject,
          admissionQuestionObject.choices,
          handleChoiceButtonClick,
          key
        );
        break;

      case Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST:
        return checkboxButtonForMultiSelection(
          admissionQuestionObject,
          admissionTitleObject,
          admissionQuestionObject.choices,
          handleChoiceButtonClick,
          key
        );

        break;
      case Constants.QESTION_DATE:
        const maxDate = Moment();
        const maxDateString = maxDate.format("YYYY-MM-DD");

        return (
          <FormGroup key={key}>
            <Label style={{ whiteSpace: "pre-wrap" }}> {admissionQuestionObject.question}</Label>
            {admissionQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}

            {admissionQuestionObject.answerDate != null ? (
              <AvField
                type="date"
                name={"questionDate" + key}
                className="inner form-control"
                placeholder="dd/mm/yyyy"
                value={formatDate(admissionQuestionObject.answerDate)}
                disabled={!isEditModeOn}
                onChange={(e) => {
                  handleDateChange(
                    admissionQuestionObject,
                    admissionTitleObject,
                    e.target.value
                  );
                }}
              />
            ) : (
              <AvField
                type="date"
                name={"questionDate" + key}
                className="inner form-control"
                placeholder="dd/mm/yyyy"
                disabled={!isEditModeOn}
                onChange={(e) => {
                  handleDateChange(
                    admissionQuestionObject,
                    admissionTitleObject,
                    e.target.value
                  );
                }}
              />
            )}
          </FormGroup>
        );
        break;
      case Constants.QESTION_RANGE_TIME:
        break;
      case Constants.QESTION_GEO_POINT:
        break;
      case Constants.QESTION_YES_NO_COMMENT_ON_YES:
        if (!admissionQuestionObject.answerBool === true) {
          return boolTagsWithoutComment(
            admissionQuestionObject,
            admissionTitleObject,
            admissionQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        } else {
          return boolTagsWithComment(
            admissionQuestionObject,
            admissionTitleObject,
            admissionQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        }

        break;
      case Constants.QESTION_YES_NO_COMMENT_ON_NO:
        if (admissionQuestionObject.answerBool === false) {
          return boolTagsWithComment(
            admissionQuestionObject,
            admissionTitleObject,
            admissionQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        } else {
          return boolTagsWithoutComment(
            admissionQuestionObject,
            admissionTitleObject,
            admissionQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        }
        break;
      case Constants.QESTION_YES_NO_WITH_COMMENT:
        boolTagsWithComment(
          admissionQuestionObject,
          admissionTitleObject,
          admissionQuestionObject.choices,
          handleYesNoButtonClick,
          key
        );
        break;
      case Constants.QUESTION_EMAIL:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formemail" style={{ whiteSpace: "pre-wrap" }} >
              {" "}
              {admissionQuestionObject.question}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="email"
              name={"email" + key}
              placeholder={props.t("Enter Email")}
              className="form-control"
              value={admissionQuestionObject.answerText}
              disabled={!isEditModeOn}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
              validate={{
                pattern: {
                  value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                  errorMessage: props.t(
                    "You have entered an invalid email address!"
                  ),
                },
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QUESTION_PHONE_NUMBER:
        return (
          <FormGroup key={key}>
            <Label style={{ whiteSpace: "pre-wrap" }}> {admissionQuestionObject.question}</Label>
            {admissionQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="text"
              name={"phone" + key}
              className="inner form-control noscroll"
              placeholder={props.t("Enter phone no")}
              value={admissionQuestionObject.answerText}
              disabled={!isEditModeOn}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
              validate={{
                pattern: {
                  value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                  errorMessage: props.t(
                    "You have entered an invalid phone no!"
                  ),
                },
                minLength: { value: 10 },
                maxLength: { value: 16 },
              }}
            />
          </FormGroup>
        );

        break;
    }
  };

  // //  UI Functions
  const radioButtonForSingleSelection = (
    admissionQuestionObject,
    admissionTitleObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="" style={{ whiteSpace: "pre-wrap" }}>{admissionQuestionObject.question}</Label>
        {admissionQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {admissionQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyInside}>
              <input
                className="form-check-input"
                type="radio"
                id={
                  "radioButton" +
                  admissionQuestionObject.admissionQuestionParseObject.id +
                  "_" +
                  keyInside
                }
                value={choice}
                disabled={!isEditModeOn}
                checked={admissionQuestionObject.answerChoices.includes(choice)}
                onChange={() => {
                  handlerFunction(
                    choice,
                    admissionQuestionObject,
                    admissionTitleObject
                  );
                }}
              />
              <label
                className="form-check-label"
                htmlFor={
                  "radioButton" +
                  admissionQuestionObject.admissionQuestionParseObject.id +
                  "_" +
                  keyInside
                }
              >
                {choice}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const checkboxButtonForMultiSelection = (
    admissionQuestionObject,
    admissionTitleObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="" style={{ whiteSpace: "pre-wrap" }}>{admissionQuestionObject.question}</Label>
        {admissionQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {admissionQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyInside}>
              <input
                className="form-check-input"
                type="checkbox"
                id={
                  "checkbox" +
                  admissionQuestionObject.admissionQuestionParseObject.id +
                  "_" +
                  keyInside
                }
                value={choice}
                checked={admissionQuestionObject.answerChoices.includes(choice)}
                disabled={!isEditModeOn}
                onChange={() => {
                  handlerFunction(
                    choice,
                    admissionQuestionObject,
                    admissionTitleObject
                  );
                }}
              />
              <label
                className="form-check-label"
                htmlFor={
                  "checkbox" +
                  admissionQuestionObject.admissionQuestionParseObject.id +
                  "_" +
                  keyInside
                }
              >
                {choice}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const boolTagsWithoutComment = (
    admissionQuestionObject,
    admissionTitleObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="" style={{ whiteSpace: "pre-wrap" }}>{admissionQuestionObject.question}</Label>
        {admissionQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {admissionQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div className="tagcloud mt-1">
          {choices.map((choice, keyInside) => (
            <Button
              key={keyInside}
              id={choice}
              color={
                ((admissionQuestionObject.answerBool === true && admissionQuestionObject.choices.length > 0 && admissionQuestionObject.choices[0] === choice) || (admissionQuestionObject.answerBool === false && admissionQuestionObject.choices.length > 1 && admissionQuestionObject.choices[1] === choice))
                  ? "primary"
                  : "secondary"
              }
              className="btn rounded m-1"
              disabled={!isEditModeOn}
              onClick={() =>
                handlerFunction(
                  choice,
                  admissionQuestionObject,
                  admissionTitleObject
                )
              }
            >
              {choice}
            </Button>
          ))}
        </div>
      </div>
    );
  };
  const boolTagsWithComment = (
    admissionQuestionObject,
    admissionTitleObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="" style={{ whiteSpace: "pre-wrap" }}>{admissionQuestionObject.question}</Label>
        {admissionQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {admissionQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div className="tagcloud mt-1">
          {choices.map((choice, keyInside) => (
            <Button
              key={keyInside}
              id={choice}
              disabled={!isEditModeOn}
              color={
                ((admissionQuestionObject.answerBool === true && admissionQuestionObject.choices[0] === choice) || (admissionQuestionObject.answerBool === false && admissionQuestionObject.choices[1] === choice))
                  ? "primary"
                  : "secondary"
              }
              className="btn rounded mr-2"
              onClick={() =>
                handlerFunction(
                  choice,
                  admissionQuestionObject,
                  admissionTitleObject
                )
              }
            >
              {choice}
            </Button>
          ))}
        </div>
        <AvField
          type="textarea"
          name="multiLine1"
          className="form-control mt-3"
          rows="3"
          value={admissionQuestionObject.answerText ?? ""}
          disabled={!isEditModeOn}
          placeholder={admissionQuestionObject.questionHint}
          onChange={(e) => {
            handleInputOnChange(
              admissionQuestionObject,
              admissionTitleObject,
              e.target.value
            );
          }}
        />
      </div>
    );
  };

  const checkForDifferentQuestionsValidations = () => {
    var canProceedToNextScreen = true;

    for (var m = 0; m < admissionTitleList.length; m++) {
      var admissionTitleObject = admissionTitleList[m];
      var admissionQuestionsList = admissionTitleObject.admissionQuestionsList;

      for (var i = 0; i < admissionQuestionsList.length; i++) {
        if (admissionQuestionsList[i].isMandatory === true) {
          if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_TEXT_SINGLE_LINE ||
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_TEXT_MULTI_LINE ||
            admissionQuestionsList[i].questionType ===
            Constants.QUESTION_EMAIL ||
            admissionQuestionsList[i].questionType ===
            Constants.QUESTION_PHONE_NUMBER
          ) {
            if (
              admissionQuestionsList[i].answerText == null ||
              admissionQuestionsList[i].answerText === ""
            ) {
              canProceedToNextScreen = false;
              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType === Constants.QESTION_NUMBER
          ) {
            if (admissionQuestionsList[i].answerNumber == null) {
              canProceedToNextScreen = false;
              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_CHECKBOX
          ) {
            if (
              admissionQuestionsList[i].answerBool == null ||
              admissionQuestionsList[i].answerBool === false
            ) {
              canProceedToNextScreen = false;
              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_YES_NO_NO_COMMENTS
          ) {
            if (admissionQuestionsList[i].answerBool == null) {
              canProceedToNextScreen = false;

              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
          ) {
            if (
              admissionQuestionsList[i].answerChoices == null ||
              admissionQuestionsList[i].answerChoices.length === 0
            ) {
              canProceedToNextScreen = false;

              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
          ) {
            if (
              admissionQuestionsList[i].answerChoices == null ||
              admissionQuestionsList[i].answerChoices.length === 0
            ) {
              canProceedToNextScreen = false;

              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType === Constants.QESTION_DATE
          ) {
            if (admissionQuestionsList[i].answerDate == null) {
              canProceedToNextScreen = false;
              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_YES_NO_COMMENT_ON_YES ||
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_YES_NO_COMMENT_ON_NO
          ) {
            if (
              admissionQuestionsList[i].answerText == null ||
              admissionQuestionsList[i].answerText === ""
            ) {
              canProceedToNextScreen = false;

              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_YES_NO_WITH_COMMENT
          ) {
          } else if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_UPLOAD_IMAGE
          ) {
            if (
              admissionQuestionsList[i].answerImage == null ||
              admissionQuestionsList[i].answerImageUploadProgress === 0
            ) {
              canProceedToNextScreen = false;
              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          } else if (
            admissionQuestionsList[i].questionType ===
            Constants.QESTION_UPLOAD_DOCUMENT
          ) {
            if (
              admissionQuestionsList[i].answerFile == null ||
              admissionQuestionsList[i].answerFileUploadProgress === 0
            ) {
              canProceedToNextScreen = false;
              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          }
        }
      }
      if (canProceedToNextScreen === false) {
        return canProceedToNextScreen;
      }
    }
    return canProceedToNextScreen;
  };

  const isFormValid = () => {
    var canProceedToSave = true;

    if (admissionTitleList.length > 0) {
    } else {
      canProceedToSave = false;
    }

    if (checkForMandatoryFields) {
      canProceedToSave = checkForDifferentQuestionsValidations();
    }

    if (canProceedToSave) {
      saveForm(
        stateEnquiryObject,
        stateFatherObject,
        stateMotherObject,
        stateChildAdditionalDetailsObject
      );
    } else {
      toastr.warning("Some mandatory fields are left empty please fill.", {
        timeOut: 1000,
      });
    }
  };

  const checkForDataType = (dataType, answer) => {
    if (dataType === "ARRAY") {
      if (!Array.isArray(answer)) {
        return [answer];
      }
    } else if (dataType === "STRING") {
      if (!(typeof answer === "string" || answer instanceof String)) {
        return answer.toString();
      }
    } else if (dataType === "NUMBER") {
      if (typeof answer === "string" || answer instanceof String) {
        return parseInt(answer, 10);
      }
    }
    return answer;
  };

  const comparingForFillingObjectsWithAnswers = (
    i,
    objectToReturn,
    shouldSaveObject,
    admissionQuestionsList
  ) => {
    if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_SINGLE_LINE ||
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_MULTI_LINE ||
      admissionQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
      admissionQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER
    ) {
      if (admissionQuestionsList[i].answerText != null) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerText
          )
        );
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_NUMBER
    ) {
      if (admissionQuestionsList[i].answerNumber != null) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerNumber
          )
        );
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_CHECKBOX
    ) {
      if (admissionQuestionsList[i].answerBool != null) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          admissionQuestionsList[i].answerBool
        );
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      if (admissionQuestionsList[i].answerBool != null) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          admissionQuestionsList[i].answerBool
        );
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      if (
        admissionQuestionsList[i].answerChoices != null &&
        admissionQuestionsList[i].answerChoices.length > 0
      ) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerChoices[0]
          )
        );
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      if (
        admissionQuestionsList[i].answerChoices != null &&
        admissionQuestionsList[i].answerChoices.length > 0
      ) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerChoices
          )
        );
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_DATE
    ) {
      if (admissionQuestionsList[i].answerDate != null) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          admissionQuestionsList[i].answerDate
        );
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_YES ||
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      if (admissionQuestionsList[i].answerText != null) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerText
          )
        );
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      if (
        admissionQuestionsList[i].answerFile &&
        admissionQuestionsList[i].answerFileUploadProgress === 100
      ) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          admissionQuestionsList[i].answerFile.file
        );
        shouldSaveObject = true;
      } else if (
        admissionQuestionsList[i].answerFileUploadProgress >= 1 &&
        admissionQuestionsList[i].answerFileUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for file upload to complete, then try again",
          { timeOut: 1000 }
        );
      } else {
        objectToReturn.unset(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName

        );
        shouldSaveObject = true;

      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_UPLOAD_IMAGE
    ) {
      if (
        admissionQuestionsList[i].answerImage &&
        admissionQuestionsList[i].answerImage.image != null
      ) {
        objectToReturn.set(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName,
          admissionQuestionsList[i].answerImage.image
        );
        shouldSaveObject = true;
      } else if (
        admissionQuestionsList[i].answerImageUploadProgress >= 1 &&
        admissionQuestionsList[i].answerImageUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for Image upload to complete, then try again",
          { timeOut: 1000 }
        );
      } else {
        objectToReturn.unset(
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName

        );
        shouldSaveObject = true;

      }
    }
    return {
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };

  const fillingObjectsWithAnswers = (
    objectToSave,
    tableName,
    objectType = 0
  ) => {
    var objectToReturn = objectToSave;
    var shouldSaveObject = false;
    let result = undefined;

    var j = 0;
    for (j = 0; j < admissionTitleList.length; j++) {
      var i = 0;
      var admissionQuestionsList = admissionTitleList[j].admissionQuestionsList;
      for (i = 0; i < admissionQuestionsList.length; i++) {
        if (
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseTableName === tableName
        ) {
          if (tableName === "Parent") {
            if (
              admissionQuestionsList[i].admissionQuestionParseObject.attributes
                .objectType === objectType
            ) {
              result = comparingForFillingObjectsWithAnswers(
                i,
                objectToReturn,
                shouldSaveObject,
                admissionQuestionsList
              );
            }
          } else {
            if (objectToReturn) {
              result = comparingForFillingObjectsWithAnswers(
                i,
                objectToReturn,
                shouldSaveObject,
                admissionQuestionsList
              );
            } else {
              result = {
                shouldSaveObject: false,
                objectToReturn: undefined,
              };
            }

          }
        }
        if (result) {
          shouldSaveObject = result.shouldSaveObject;
          objectToReturn = result.objectToReturn;
        }
      }
    }
    if (shouldSaveObject) {
      return objectToReturn;
    }
    return shouldSaveObject;
  };

  const saveForm = (
    enquiryObject,
    fatherObject,
    motherObject,
    childAdditionalDetailsObject
  ) => {
    var enquiryObjectToSave = enquiryObject;
    var kidObjectToSave = stateKidObject;



    var motherObjectToSave = null;

    var fatherObjectToSave = null;

    var shouldSaveEnquiryObject = true;
    var shouldSaveKidObject = true;
    var shouldSaveFatherObject = true;
    var shouldSaveMotherObject = true;
    var shouldSaveChildAdditionalDetailsObject = true;

    if (motherObject != null) {
      motherObjectToSave = motherObject;
    } else {
      const ParentObject = Parse.Object.extend("Parent");
      motherObjectToSave = new ParentObject();
    }
    if (fatherObject != null) {
      fatherObjectToSave = fatherObject;
    } else {
      const ParentObject = Parse.Object.extend("Parent");
      fatherObjectToSave = new ParentObject();
    }
    var childAdditionalDetailObjectToSave = null;
    if (childAdditionalDetailsObject != null) {
      childAdditionalDetailObjectToSave = childAdditionalDetailsObject;
    } else {
      const ChildAdditionalDetailObject = Parse.Object.extend(
        "ChildAdditionalDetails"
      );
      childAdditionalDetailObjectToSave = new ChildAdditionalDetailObject();
    }
    enquiryObjectToSave = fillingObjectsWithAnswers(
      enquiryObjectToSave,
      "Enquiry"
    );
    if (enquiryObjectToSave === false) {
      shouldSaveEnquiryObject = false;
    } else {
      shouldSaveEnquiryObject = true;
    }
    kidObjectToSave = fillingObjectsWithAnswers(
      kidObjectToSave,
      "Kid"
    );
    if (kidObjectToSave === false) {
      shouldSaveKidObject = false;
    } else {
      shouldSaveKidObject = true;
    }

    fatherObjectToSave = fillingObjectsWithAnswers(
      fatherObjectToSave,
      "Parent",
      2
    );
    if (fatherObjectToSave === false) {
      shouldSaveFatherObject = false;
    } else {
      shouldSaveFatherObject = true;
    }

    motherObjectToSave = fillingObjectsWithAnswers(
      motherObjectToSave,
      "Parent",
      1
    );
    if (motherObjectToSave === false) {
      shouldSaveMotherObject = false;
    } else {
      shouldSaveMotherObject = true;
    }

    childAdditionalDetailObjectToSave = fillingObjectsWithAnswers(
      childAdditionalDetailObjectToSave,
      "ChildAdditionalDetails"
    );
    if (childAdditionalDetailObjectToSave === false) {
      shouldSaveChildAdditionalDetailsObject = false;
    } else {
      shouldSaveChildAdditionalDetailsObject = true;
    }
    var familyId = uuidv4();

    if (motherObject != null && motherObject.attributes.FamilyID != null) {
      familyId = motherObject.attributes.FamilyID;
    } else if (
      fatherObject != null &&
      fatherObject.attributes.FamilyID != null
    ) {
      familyId = fatherObject.attributes.FamilyID;
    }
    var groupACL = new Parse.ACL();
    groupACL.setRoleWriteAccess(parameter + "_Admin", true);
    groupACL.setRoleReadAccess(parameter + "_Admin", true);
    groupACL.setRoleWriteAccess(parameter + "_Teacher", false);
    groupACL.setRoleReadAccess(parameter + "_Teacher", true);
    groupACL.setRoleWriteAccess(familyId + "_Role", true);
    groupACL.setRoleReadAccess(familyId + "_Role", true);

    if (abortSubmitForm) {
      return;
    }
    formIsSubmitesOps(true);
    if (shouldSaveEnquiryObject && enquiryObjectToSave) {
      console.log(enquiryObjectToSave);
      //enquiryObjectToSave.set("admissionFormSubmittedOn", new Date());

      enquiryObjectToSave.set("schoolId", parameter);
      //enquiryObjectToSave.setACL(groupACL);
      enquiryObjectToSave.save().then(
        (result) => {
          console.log("saving success");
          saveKidObject(
            groupACL,
            familyId,
            shouldSaveKidObject,
            kidObjectToSave,
            shouldSaveChildAdditionalDetailsObject,
            shouldSaveMotherObject,
            shouldSaveFatherObject,
            childAdditionalDetailObjectToSave,
            motherObjectToSave,
            fatherObjectToSave
          );
        },
        (error) => {
          console.log("saving error");
        }
      );
    } else {
      saveKidObject(
        groupACL,
        familyId,
        shouldSaveKidObject,
        kidObjectToSave,
        shouldSaveChildAdditionalDetailsObject,
        shouldSaveMotherObject,
        shouldSaveFatherObject,
        childAdditionalDetailObjectToSave,
        motherObjectToSave,
        fatherObjectToSave
      );
    }
  };

  const saveKidObject = (groupACL,
    familyId,
    shouldSaveKidObject,
    kidObjectToSave,
    shouldSaveChildAdditionalDetailsObject,
    shouldSaveMotherObject,
    shouldSaveFatherObject,
    childAdditionalDetailObjectToSave,
    motherObjectToSave,
    fatherObjectToSave) => {

    if (shouldSaveKidObject && kidObjectToSave) {
      console.log(kidObjectToSave);
      //childAdditionalDetailObjectToSave.setACL(groupACL);
      kidObjectToSave.save().then(
        (result) => {
          console.log("saving success");
          saveChildAdditional(
            groupACL,
            familyId,
            shouldSaveChildAdditionalDetailsObject,
            shouldSaveMotherObject,
            shouldSaveFatherObject,
            childAdditionalDetailObjectToSave,
            motherObjectToSave,
            fatherObjectToSave
          );
        },
        (error) => {
          console.log("saving error");
        }
      );
    } else {
      saveChildAdditional(
        groupACL,
        familyId,
        shouldSaveChildAdditionalDetailsObject,
        shouldSaveMotherObject,
        shouldSaveFatherObject,
        childAdditionalDetailObjectToSave,
        motherObjectToSave,
        fatherObjectToSave
      );
    }

  }

  const saveChildAdditional = (
    groupACL,
    familyId,
    shouldSaveChildAdditionalDetailsObject,
    shouldSaveMotherObject,
    shouldSaveFatherObject,
    childAdditionalDetailObjectToSave,
    motherObjectToSave,
    fatherObjectToSave
  ) => {
    if (shouldSaveChildAdditionalDetailsObject) {
      console.log(childAdditionalDetailObjectToSave);
      //childAdditionalDetailObjectToSave.setACL(groupACL);
      if (stateEnquiryObject && stateEnquiryObject.id) {
        childAdditionalDetailObjectToSave.set("enquiryId", stateEnquiryObject.id);
      }
      if (stateKidObject && stateKidObject.id) {
        childAdditionalDetailObjectToSave.set("kidId", stateKidObject.id)
      }
      childAdditionalDetailObjectToSave.save().then(
        (result) => {
          console.log("saving success");
          saveFatherObj(
            groupACL,
            familyId,
            shouldSaveFatherObject,
            fatherObjectToSave,
            shouldSaveMotherObject,
            motherObjectToSave
          );
        },
        (error) => {
          console.log("saving error");
        }
      );
    } else {
      saveFatherObj(
        groupACL,
        familyId,
        shouldSaveFatherObject,
        fatherObjectToSave,
        shouldSaveMotherObject,
        motherObjectToSave
      );
    }
  };

  function saveFatherObj(
    groupACL,
    familyId,
    shouldSaveFatherObject,
    fatherObjectToSave,
    shouldSaveMotherObject,
    motherObjectToSave
  ) {
    if (shouldSaveFatherObject) {
      console.log(fatherObjectToSave);
      fatherObjectToSave.set("Relation", "Father");
      fatherObjectToSave.set("FamilyID", familyId);
      fatherObjectToSave.set("NotificationChoice", 16127);
      //fatherObjectToSave.setACL(groupACL);
      fatherObjectToSave.save().then(
        (result) => {
          console.log("saving success");
          saveMotherObj(
            groupACL,
            familyId,
            shouldSaveMotherObject,
            motherObjectToSave
          );
        },
        (error) => {
          console.log("saving error");
        }
      );
    } else {
      saveMotherObj(
        groupACL,
        familyId,
        shouldSaveMotherObject,
        motherObjectToSave
      );
    }
  }

  function saveMotherObj(
    groupACL,
    familyId,
    shouldSaveMotherObject,
    motherObjectToSave
  ) {
    if (shouldSaveMotherObject) {
      console.log(motherObjectToSave);
      motherObjectToSave.set("Relation", "Mother");
      motherObjectToSave.set("FamilyID", familyId);
      motherObjectToSave.set("NotificationChoice", 16127);
      //motherObjectToSave.setACL(groupACL);
      motherObjectToSave.save().then(
        (result) => {
          saveAdmissionDataObjects();

        },
        (error) => {
          console.log("saving error");
        }
      );
    } else {


      saveAdmissionDataObjects();
    }
  }

  function saveAdmissionDataObjects() {
    let listToSave = collectAdmissionDataQuestionsToSave(stateEnquiryObject ? stateEnquiryObject.id : "", stateKidObject.id)
    if (listToSave.length > 0) {
      Parse.Object.saveAll(listToSave).then(
        (result) => {
          setIsEditModeOn(false);
          formIsSubmitesOps(false)
          toastr.success("Successfully saved the details", { timeOut: 1000 });
        },
        (error) => {
          console.log("saving error");
        }
      );


    } else {
      formIsSubmitesOps(false)
      setIsEditModeOn(false);
      toastr.success("Successfully saved the details", { timeOut: 1000 });

    }
  }
  const collectAdmissionDataQuestionsToSave = (enquiryId, kidId) => {

    let listOfAdmissionObjectsToSave = []

    var j = 0;
    for (j = 0; j < admissionTitleList.length; j++) {
      var i = 0;
      var admissionQuestionsList = admissionTitleList[j].admissionQuestionsList;
      for (i = 0; i < admissionQuestionsList.length; i++) {
        let result
        let shouldSaveObject
        if (
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseTableName === "AdmissionData"
        ) {

          let resultObjectList = admissionDataList.filter(
            (fd) =>
              fd.attributes.questionId === admissionQuestionsList[i].admissionQuestionParseObject.id
          );
          let objectToSave
          objectToSave = resultObjectList && resultObjectList.length > 0 && resultObjectList[0]
          if (objectToSave) {

          } else {
            let AdmissionData = Parse.Object.extend("AdmissionData");
            objectToSave = new AdmissionData();
          }

          if (enquiryId.length > 0) {
            objectToSave.set("enquiryId", enquiryId)

          }
          objectToSave.set("kidId", kidId)


          objectToSave.set(
            "admissionTitleId",
            admissionTitleList[j].admissionTitleParseObject.id
          );
          objectToSave.set(
            "questionId",
            admissionQuestionsList[i].admissionQuestionParseObject.id
          );



          result = comparingForFillingObjectsWithAnswersForAdmissionData(
            i,
            objectToSave,
            shouldSaveObject,
            admissionQuestionsList
          );
          if (result) {
            shouldSaveObject = result.shouldSaveObject;
            objectToSave = result.objectToReturn;
            if (shouldSaveObject && objectToSave) {
              listOfAdmissionObjectsToSave.push(objectToSave)
            }
          }

        }


      }
    }
    return listOfAdmissionObjectsToSave
  }

  const comparingForFillingObjectsWithAnswersForAdmissionData = (
    i,
    objectToReturn,
    shouldSaveObject,
    admissionQuestionsList
  ) => {
    if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_SINGLE_LINE ||
      admissionQuestionsList[i].questionType === Constants.QESTION_TEXT_MULTI_LINE ||
      admissionQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
      admissionQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER
    ) {

      if (admissionQuestionsList[i].answerText != null) {
        let tempAnswer = checkForDataType(
          admissionQuestionsList[i].dataType,
          admissionQuestionsList[i].answerText
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }

        shouldSaveObject = true;
      }
    } else if (admissionQuestionsList[i].questionType === Constants.QESTION_NUMBER) {
      if (admissionQuestionsList[i].answerText != null) {
        let tempAnswer = checkForDataType(
          admissionQuestionsList[i].dataType,
          admissionQuestionsList[i].answerText
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_CHECKBOX
    ) {
      if (admissionQuestionsList[i].answerBool != null) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerBool]);
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      if (admissionQuestionsList[i].answerBool != null) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerBool]);
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      if (
        admissionQuestionsList[i].answerChoices != null &&
        admissionQuestionsList[i].answerChoices.length > 0
      ) {
        let tempAnswer = checkForDataType(
          admissionQuestionsList[i].dataType,
          admissionQuestionsList[i].answerChoices[0]
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      if (
        admissionQuestionsList[i].answerChoices != null &&
        admissionQuestionsList[i].answerChoices.length > 0
      ) {
        let tempAnswer = checkForDataType(
          admissionQuestionsList[i].dataType,
          admissionQuestionsList[i].answerChoices
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (admissionQuestionsList[i].questionType === Constants.QESTION_DATE) {
      if (admissionQuestionsList[i].answerDate != null) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerDate]);
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_YES ||
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      let answerList = []

      if (admissionQuestionsList[i].answerBool != null) {
        answerList.push(admissionQuestionsList[i].answerBool)
      }
      if (admissionQuestionsList[i].answerText != null) {
        answerList.push(admissionQuestionsList[i].answerText)
      }
      if (answerList.length > 0) {
        shouldSaveObject = true;
        objectToReturn.set("answer", answerList);
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      if (
        admissionQuestionsList[i].answerFile &&
        admissionQuestionsList[i].answerFileUploadProgress === 100
      ) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerFile.file]);
        shouldSaveObject = true;
      } else if (
        admissionQuestionsList[i].answerFileUploadProgress >= 1 &&
        admissionQuestionsList[i].answerFileUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for file upload to complete, then try again",
          { timeOut: 1000 }
        );
      } else {
        objectToReturn.unset(
          "answer"
        );
        shouldSaveObject = true;

      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_UPLOAD_IMAGE
    ) {
      if (
        admissionQuestionsList[i].answerImage &&
        admissionQuestionsList[i].answerImage.image != null
      ) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerImage.image]);
        shouldSaveObject = true;
      } else if (
        admissionQuestionsList[i].answerImageUploadProgress >= 1 &&
        admissionQuestionsList[i].answerImageUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for Image upload to complete, then try again",
          { timeOut: 1000 }
        );
      } else {
        objectToReturn.unset(
          "answer"
        );
        shouldSaveObject = true;

      }
    }
    return {
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };

  // function saveKidObjectWithSubmitDate() {
  //   Parse.Cloud.run("function_updateKidObject", {
  //     kidId: stateKidObject.id,
  //     admissionFormSubmittedOn: new Date(),
  //   }).then(
  //     (result) => {
  //       if (result) {
  //         setCardMessage("Successfully submitted the form !!");
  //         setIsFormSubmitted(true);
  //       }
  //     },
  //     (error) => {
  //       return null;
  //     }
  //   );
  // }

  //Handler functions
  //  Click/Changes Handlers
  const handleYesNoButtonClick = (
    choice,
    admissionQuestionObject,
    admissionTitleObject,

  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    var choices = [...admissionQuestionObject.choices];

    var tempArray = [...tempQuestionObject.answerChoices];

    if (tempQuestionObject.answerChoices.includes(choice)) {
      //remove
      removeElement(tempArray, choice);
    } else {
      tempArray = [choice];
    }

    tempQuestionObject.answerChoices = [...tempArray];

    if (tempQuestionObject.isMandatory) {
      if (tempArray != null && tempArray.length > 0) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    if (choice === choices[0]) {
      tempQuestionObject.answerBool = true;
      if (tempQuestionObject.questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO) {
        tempQuestionObject.answerText = ""
      }
    } else if (choice === choices[1]) {
      tempQuestionObject.answerBool = false;
      if (tempQuestionObject.questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES) {
        tempQuestionObject.answerText = ""
      }

    } else {
      tempQuestionObject.answerBool = null;
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    var tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  const handleChoiceButtonClick = (
    choice,
    admissionQuestionObject,
    admissionTitleObject
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    if (
      tempQuestionObject.questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      var tempArray = [...tempQuestionObject.answerChoices];

      if (tempQuestionObject.answerChoices.includes(choice)) {
        //remove
        removeElement(tempArray, choice);
      } else {
        tempArray.push(choice);
      }

      tempQuestionObject.answerChoices = tempArray;

      if (tempQuestionObject.isMandatory) {
        if (tempArray != null && tempArray.length > 0) {
          tempQuestionObject.answerError = false;
        } else {
          tempQuestionObject.answerError = true;
        }
      }

      var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

      tempQuestionList[questionIndex] = tempQuestionObject;

      tempTitleObject.admissionQuestionsList = [...tempQuestionList];

      var tempTitleList = [...admissionTitleList];

      tempTitleList[titleIndex] = tempTitleObject;

      setAdmissionTitleList(tempTitleList);
    } else if (
      tempQuestionObject.questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      var tempArray = [...tempQuestionObject.answerChoices];

      if (tempQuestionObject.answerChoices.includes(choice)) {
        //remove
        removeElement(tempArray, choice);
      } else {
        tempArray = [choice];
      }

      tempQuestionObject.answerChoices = [...tempArray];

      if (tempQuestionObject.isMandatory) {
        if (tempArray != null && tempArray.length > 0) {
          tempQuestionObject.answerError = false;
        } else {
          tempQuestionObject.answerError = true;
        }
      }

      var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

      tempQuestionList[questionIndex] = tempQuestionObject;

      tempTitleObject.admissionQuestionsList = [...tempQuestionList];

      var tempTitleList = [...admissionTitleList];

      tempTitleList[titleIndex] = tempTitleObject;

      setAdmissionTitleList(tempTitleList);
    }
  };

  const handleCheckBoxChange = (
    admissionQuestionObject,
    admissionTitleObject
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    tempQuestionObject.answerBool = !admissionTitleObject
      .admissionQuestionsList[questionIndex].answerBool;

    if (tempQuestionObject.isMandatory) {
      if (tempQuestionObject.answerBool === true) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    var tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  const handleInputOnChange = (
    admissionQuestionObject,
    admissionTitleObject,
    changedValue
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    tempQuestionObject.answerText = changedValue;

    if (tempQuestionObject.isMandatory) {
      if (changedValue.length > 0) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    var tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  const handleDateChange = (
    admissionQuestionObject,
    admissionTitleObject,
    changedValue
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    tempQuestionObject.answerDate = new Date(changedValue);
    if (tempQuestionObject.isMandatory) {
      if (changedValue != undefined) {
        tempQuestionObject.answerError = false;
      }
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    admissionTitleList[titleIndex] = tempTitleObject;
  };

  const setAnswerErrorTrue = (
    admissionQuestionsList,
    admissionTitleObject,
    i
  ) => {
    var tempObject = admissionQuestionsList[i];

    tempObject.answerError = true;

    var temp = [...admissionQuestionsList];

    temp[i] = tempObject;

    var tempTitleList = [...admissionTitleList];

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempTitleObject = admissionTitleList[titleIndex];

    tempTitleObject.admissionQuestionsList = temp;

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var resultWithName = { name: file.name, result: reader.result };
      cb(resultWithName);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }
    // else if(fileType === "Video"){
    //     return "bx bx-play-circle";
    // }else if (_audioExtensions.includes(fileExtension)) {
    //     return "bx bxs-music";
    // }
    return "bx bxs-file-blank";
  }

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  function formatDate(d) {
    if (d == null || d === "" || typeof d === "string" || d instanceof String) {
      return "";
    } else {
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      return `${ye}-${mo}-${da}`;
    }
  }
  function toggleTab(
    tab,
    admissionQuestionsList,
    admissionTitleObject,
    choice
  ) {
    if (tab !== activeTab) {
      if (tab - activeTab === 1 || tab - activeTab === -1) {
      } else {
        if (choice === next) {
          tab = activeTab + 1;
        } else if (choice === previous) {
          tab = activeTab - 1;
        }
      }

      if (choice === next) {
        var canProceedToNextScreen = true;

        canProceedToNextScreen = checkForDifferentQuestionsValidations(
          admissionQuestionsList,
          admissionTitleObject
        );

        if (canProceedToNextScreen) {
          if (activeTab !== tab) {
            if (tab >= 0 && tab <= admissionTitleList.length - 1) {
              scroller.scrollTo("topScrollArea", {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page
              });
              setactiveTab(tab);

              var tempRatio = 100 / admissionTitleList.length;
              var tempMultiplier = tab + 1;
              var tempProgress = tempMultiplier * tempRatio;
              setprogressValue(tempProgress);
            }
          }
        } else {
        }
      } else {
        if (activeTab !== tab) {
          if (tab >= 0 && tab <= admissionTitleList.length - 1) {
            scroller.scrollTo("topScrollArea", {
              duration: 500,
              delay: 100,
              smooth: true,
              offset: -100, // Scrolls to element + 50 pixels down the page
            });
            setactiveTab(tab);
            var tempRatio = 100 / admissionTitleList.length;
            var tempMultiplier = tab + 1;
            var tempProgress = tempMultiplier * tempRatio;
            setprogressValue(tempProgress);
          }
        }
      }
    }
  }
  function convertDateToString(date) {
    return Moment(date).format(' DD/MM/YYYY hh:mm A')
  }

  const reshareForm = () => {
    if (stateKidObject) {
      if (stateKidObject.attributes.admissionFormSubmittedOn) {
        stateKidObject.unset("admissionFormSubmittedOn")
        stateKidObject.set("admissionFormResharedOn", new Date())
        stateKidObject.save().then(
          (result) => {
            console.log(result)
            setShowReshareFormAlert(false)
            setShowAdmissionLinkPopupWithoutEnquiryId(true)
            toastr.success("successfully re-shared the form")
            //Send link to parent
            if (stateKidObject && stateSchoolObject) {
              let details = getparentEmailsAndPhoneNubers(stateKidObject.attributes.FamilyID);
              let schoolName = (stateSchoolObject && stateSchoolObject.attributes.Name) ? stateSchoolObject.attributes.Name : "School"
              props.sendAdmissionLinkToParent(
                stateKidObject.attributes.enquiryId ?? "", details.emails, details.phoneNumbers, getAdmissionLink(), schoolName, stateSchoolObject ? stateSchoolObject.id : ""
              );
            }

          },
          (error) => {
            setShowReshareFormAlert(false)
            toastr.success("Some error occurred while sharing form, please try again.")
          }
        );
      } else {
        // setShowReshareFormAlert(false)
        // setShowAdmissionLinkPopupWithoutEnquiryId(true)
        // toastr.success("successfully re-shared the form")
        // //Send link to parent
        // if (stateKidObject && stateSchoolObject) {
        //   let details = getparentEmailsAndPhoneNubers(stateKidObject.attributes.FamilyID);
        //   let schoolName = (stateSchoolObject && stateSchoolObject.attributes.Name)?stateSchoolObject.attributes.Name:"School"
        //   props.sendAdmissionLinkToParent(
        //     stateKidObject.attributes.enquiryId ?? "",details.emails,details.phoneNumbers,getAdmissionLink(),schoolName
        //   );
        // }
      }

    }
  }

  const getAdmissionLink = () => {
    if (props.franchise && stateKidObject &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[1] > 0) {

      if (stateKidObject.attributes.schoolAdmitted) {
        return WhiteLable.getHost() + "/franchise-admission-form/" + props.franchise.id + "/" + stateKidObject.attributes.schoolAdmitted + "/" + stateKidObject.id + "/" + 2;
      } else {
        return WhiteLable.getHost() + "/franchise-admission-form/" + props.franchise.id + "/" + stateKidObject.attributes.SchoolID + "/" + stateKidObject.id + "/" + 2;
      }

    } else {
      if (stateKidObject.attributes.schoolAdmitted) {
        return WhiteLable.getHost() + "/admission-form/" + stateKidObject.attributes.schoolAdmitted + "/" + stateKidObject.id + "/" + 2;
      } else {
        return WhiteLable.getHost() + "/admission-form/" + stateKidObject.attributes.SchoolID + "/" + stateKidObject.id + "/" + 2;
      }

    }
  }

  const getAdmissionLinkWithEnquiryId = () => {
    if (props.franchise && stateKidObject &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[1] > 0) {

      if (stateKidObject.attributes.schoolAdmitted) {
        return WhiteLable.getHost() + "/franchise-admission-form/" + props.franchise.id + "/" + stateKidObject.attributes.schoolAdmitted + "/" + stateKidObject.attributes.enquiryId;
      } else {
        return WhiteLable.getHost() + "/franchise-admission-form/" + props.franchise.id + "/" + stateKidObject.attributes.SchoolID + "/" + stateKidObject.attributes.enquiryId;
      }

    } else {
      if (stateKidObject.attributes.schoolAdmitted) {
        return WhiteLable.getHost() + "/admission-form/" + stateKidObject.attributes.schoolAdmitted + "/" + stateKidObject.attributes.enquiryId;
      } else {
        return WhiteLable.getHost() + "/admission-form/" + stateKidObject.attributes.SchoolID + "/" + stateKidObject.attributes.enquiryId;
      }

    }
  }


  function copyAdmissionLink(str, event) {
    document.oncopy = function (event) {
      event.clipboardData.setData("Text", str);
      event.preventDefault();
    };
    document.execCommand("Copy");
    document.oncopy = undefined;
    toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
  }

  const getparentEmailsAndPhoneNubers = (familyId) => {
    if (props.parents && props.parents.length > 0) {
      var parents = props.parents.filter(
        (parent) => parent.attributes.FamilyID === familyId
      );
      if (parents && parents.length > 0) {
        let emails = [];
        let phoneNumbers = [];
        for (const i in parents) {
          if (parents[i].attributes.EMail) {
            emails.push(parents[i].attributes.EMail);
          }
          if (parents[i].attributes.PhoneNumber) {
            phoneNumbers.push(parents[i].attributes.PhoneNumber);
          }
        }

        return { emails: emails, phoneNumbers: phoneNumbers }
      }
    }
    return { emails: [], phoneNumbers: [] }
  }

  function getParentWhatsppButtons(familyId) {
    if (props.parents && props.parents.length > 0) {
      var parents = props.parents.filter(
        (parent) => parent.attributes.FamilyID === familyId
      );
      if (parents && parents.length > 0) {
        var motherObj = null;
        var fatherObj = null;
        for (const i in parents) {
          if (parents[i].attributes.Relation === "Mother") {
            motherObj = parents[i];
          } else {
            fatherObj = parents[i];
          }
        }

        return (
          <div>
            {motherObj && motherObj.attributes.PhoneNumber && <button
              type="button"
              className="btn btn-success btn-sm mr-2"
              onClick={() => {
                let phoneNumber = motherObj.attributes.PhoneNumber;
                let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name
                let parentName = motherObj.attributes.Name;
                let link = getAdmissionLink();
                const message = `Dear ${parentName},\n\nWe extend a very warm welcome to you into our ${schoolName} Family.\n\nThank you for sharing your most precious treasure with us. Please fill the admission form:\n${link}\n\nWe look forward to having your little one at ${schoolName}.\n\nPowered by lilTriangle`;
                const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                window.open(whatsappUrl);
                // web.whatsapp.com
              }}
            ><a className="bx bxl-whatsapp text-white"></a>{props.t("Mother")}</button>}
            {fatherObj && fatherObj.attributes.PhoneNumber && <button
              type="button"
              className="btn btn-success btn-sm"
              onClick={() => {
                let phoneNumber = fatherObj.attributes.PhoneNumber;
                let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name
                let parentName = fatherObj.attributes.Name;
                let link = getAdmissionLink();
                const message = `Dear ${parentName},\n\nWe extend a very warm welcome to you into our ${schoolName} Family.\n\nThank you for sharing your most precious treasure with us. Please fill the admission form:\n${link}\n\nWe look forward to having your little one at ${schoolName}.\n\nPowered by lilTriangle`;
                const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                window.open(whatsappUrl);
                // web.whatsapp.com
              }}
            ><a className="bx bxl-whatsapp text-white"></a>Father</button>}
          </div>
        );
      }
    }
  }

  const getHeaderUI = (schoolObj) => {
    let school = {}
    school.name = schoolObj.attributes.Name;
    school.address = schoolObj.attributes.Address;
    school.primaryPhoneNumber =
      schoolObj.attributes.primaryPhoneNumber;
    school.secondaryPhoneNumber =
      schoolObj.attributes.secondaryPhoneNumber;
    school.email = schoolObj.attributes.EMail;
    school.logoUrl = schoolObj.attributes.logo
      ? schoolObj.attributes.logo._url
      : "";
    school.trustName = schoolObj.attributes.trustName;

    return (
      <div className="d-flex justify-content-between">
        <div>{school.logoUrl &&
          <img
            src={school.logoUrl}
            style={{ height: "6rem" }}
          />
        }</div>
        <div >
          <strong>{props.t("School Details")}:</strong>
          <br />
          {school.trustName && (
            <strong>
              {school.trustName}
              <br />
            </strong>
          )}
          {school.name && (
            <strong>
              {school.name}
              <br />
            </strong>
          )}
          {school.address}
          {school.address && <br />}
          {school.primaryPhoneNumber && (
            <span>{school.primaryPhoneNumber}</span>
          )}
          {", "}
          {school.secondaryPhoneNumber && (
            <span>{school.secondaryPhoneNumber}</span>
          )}{" "}
          {(school.primaryPhoneNumber ||
            school.secondaryPhoneNumber) && <br />}
          {school.email} {school.email && <br />}
        </div>
      </div>
    )
  }

  // function printPoster() {
  //   var css = "@page { size: A4 ;margin:0; }",
  //     head = document.head || document.getElementsByTagName("head")[0],
  //     style = document.createElement("style");

  //   style.type = "text/css";
  //   style.media = "print";

  //   if (style.styleSheet) {
  //     style.styleSheet.cssText = css;
  //   } else {
  //     style.appendChild(document.createTextNode(css));
  //   }

  //   head.appendChild(style);
  //   window.print();
  // }




  return (
    <React.Fragment>
      <Container fluid={true} className={""}>
        {showAdmissionLinkPopupWithoutEnquiryId && (
          <Modal
            isOpen={showAdmissionLinkPopupWithoutEnquiryId}
            toggle={() => {
              setShowAdmissionLinkPopupWithoutEnquiryId(false);
            }}
          >
            <ModalHeader>
              {props.t("Admission form link for ") + (stateKidObject ? stateKidObject.attributes.Name : "")}

            </ModalHeader>
            <ModalBody>
              <Card className="border-1">
                <Row>
                  <Col lg={10}>
                    <p
                      disabled
                      type="text"
                      className=" m-2 text-primary"
                      id="linkarea"
                    >
                      {getAdmissionLink()}
                    </p>
                  </Col>
                  <Col
                    lg={2}
                    id="admissionlink"
                    onClick={(e) => {
                      copyAdmissionLink(getAdmissionLink());
                    }}
                  >
                    <li className="list-inline-item px-2">
                      <h1 style={{ cursor: "pointer" }} className="text-primary">
                        <i className="bx bxs-copy"></i>
                      </h1>
                      <p style={{ cursor: "pointer" }}>{props.t("copy")}</p>
                      <UncontrolledTooltip placement="top" target="admissionlink">
                        {props.t("copy to clipboard")}
                      </UncontrolledTooltip>
                    </li>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <ModalFooter>

              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setShowAdmissionLinkPopupWithoutEnquiryId(false);
                }}
              >
                {props.t("Close")}
              </button>
            </ModalFooter>
          </Modal>
        )}
        {showRemindAdmissionLinkPopup && (
          <Modal
            isOpen={showRemindAdmissionLinkPopup}
            toggle={() => {
              setShowRemindAdmissionLinkPopup(false);
            }}
          >
            <ModalHeader>
              {props.t("Admission form link for ") + (stateKidObject ? stateKidObject.attributes.Name : "")}

            </ModalHeader>
            <ModalBody>
              <Card className="border-1">
                <Row>
                  <Col lg={10}>
                    <p
                      disabled
                      type="text"
                      className=" m-2 text-primary"
                      id="linkarea"
                    >
                      {getAdmissionLink()}
                    </p>
                  </Col>
                  <Col
                    lg={2}
                    id="admissionlink"
                    onClick={(e) => {
                      copyAdmissionLink(getAdmissionLink());
                    }}
                  >
                    <li className="list-inline-item px-2">
                      <h1 style={{ cursor: "pointer" }} className="text-primary">
                        <i className="bx bxs-copy"></i>
                      </h1>
                      <p style={{ cursor: "pointer" }}>{props.t("copy")}</p>
                      <UncontrolledTooltip placement="top" target="admissionlink">
                        {props.t("copy to clipboard")}
                      </UncontrolledTooltip>
                    </li>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-info"
                //here
                onClick={() => {
                  if (stateKidObject && stateSchoolObject) {
                    let details = getparentEmailsAndPhoneNubers(stateKidObject.attributes.FamilyID);
                    let schoolName = (stateSchoolObject && stateSchoolObject.attributes.Name) ? stateSchoolObject.attributes.Name : "School"
                    props.sendAdmissionLinkToParent(
                      stateKidObject.attributes.enquiryId ?? "", details.emails, details.phoneNumbers, getAdmissionLink(), schoolName, stateSchoolObject ? stateSchoolObject.id : ""
                    );
                  }

                  setShowRemindAdmissionLinkPopup(false);
                }}
              >
                {props.t("Send Link To Parent")}
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setShowRemindAdmissionLinkPopup(false);
                }}
              >
                {props.t("Close")}
              </button>
            </ModalFooter>
          </Modal>
        )}
        {showAdmissionLinkPopupWithEnquiryId && (
          <Modal
            isOpen={showAdmissionLinkPopupWithEnquiryId}
            toggle={() => {
              setShowAdmissionLinkPopupWithEnquiryId(!showAdmissionLinkPopupWithEnquiryId);
            }}
          >
            <ModalHeader
              toggle={() => {
                setShowAdmissionLinkPopupWithEnquiryId(false);
              }}
            >
              {props.t("Admission form link for ") + (stateKidObject ? stateKidObject.attributes.Name : "")}

            </ModalHeader>
            <ModalBody>
              <Card className="border-1">
                <Row>
                  <Col lg={10}>
                    <p
                      disabled
                      type="text"
                      className=" m-2 text-primary"
                      id="linkarea"
                    >
                      {(stateKidObject && stateKidObject.attributes.enquiryId) ? getAdmissionLinkWithEnquiryId() : getAdmissionLink()}
                    </p>
                  </Col>
                  <Col
                    lg={2}
                    id="admissionlink"
                    onClick={(e) => {

                      copyAdmissionLink((stateKidObject && stateKidObject.attributes.enquiryId) ? getAdmissionLinkWithEnquiryId() : getAdmissionLink());
                    }}
                  >
                    <li className="list-inline-item px-2">
                      <h1 style={{ cursor: "pointer" }} className="text-primary">
                        <i className="bx bxs-copy"></i>
                      </h1>
                      <p style={{ cursor: "pointer" }}>{props.t("copy")}</p>
                      <UncontrolledTooltip placement="top" target="admissionlink">
                        {props.t("copy to clipboard")}
                      </UncontrolledTooltip>
                    </li>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <ModalFooter>
              {getParentWhatsppButtons(stateKidObject.attributes.FamilyID)}
              <button
                type="button"
                className="btn btn-info btn-sm"
                //here
                onClick={() => {

                  if (stateKidObject && stateSchoolObject) {
                    let details = getparentEmailsAndPhoneNubers(stateKidObject.attributes.FamilyID);
                    let schoolName = (stateSchoolObject && stateSchoolObject.attributes.Name) ? stateSchoolObject.attributes.Name : "School"
                    props.sendAdmissionLinkToParent(
                      stateKidObject.attributes.enquiryId ?? "", details.emails, details.phoneNumbers, (stateKidObject && stateKidObject.attributes.enquiryId) ? getAdmissionLinkWithEnquiryId() : getAdmissionLink(), schoolName, stateSchoolObject ? stateSchoolObject.id : ""
                    );
                  }
                  setShowAdmissionLinkPopupWithEnquiryId(false);
                }}
              >
                {props.t("Send Link To Parent")}
              </button>
              {/* <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setShowAdmissionLinkPopupWithEnquiryId(false);
                }}
              >
                {props.t("Close")}
              </button> */}
            </ModalFooter>
          </Modal>
        )}
        {showReshareFormAlert && <SweetAlert
          title={props.t('')}
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            reshareForm()
          }}
          onCancel={() => {
            setShowReshareFormAlert(false)
          }}>
          {props.t("Are you sure to reshare this form to parents ?")}

        </SweetAlert>}
        {!isLoading && !isFormSubmitted && isQuestionsAvailable && (
          <div>
            <table style={{ width: "100%" }}>
              {stateSchoolObject && <thead>
                <tr className="border-bottom">
                  <td style={{ paddingBottom: "10px", marginBottom: "10px" }}>
                    <div className="d-none d-print-block">
                      {getHeaderUI(stateSchoolObject)}
                    </div>
                  </td>
                </tr>
                <tr className="text-white">.</tr>
              </thead>}
              {/* For adding space between thead and tbody added dummy tbody */}
              <tbody>
                <tr>
                  <td>
                    <div>
                      <Row>
                        <Col lg={12} className=" m-1">
                          {!props.hideButtons && <div className="d-flex">{stateKidObject && stateKidObject.attributes.admissionFormSubmittedOn &&
                            <Row className="ml-2 mr-2">
                              <p className="text-success mb-0" >Admission form submitted on- </p>
                              <p className="text-muted mb-0">{convertDateToString(stateKidObject.attributes.admissionFormSubmittedOn)}</p>
                            </Row>
                          }
                            {stateKidObject && stateKidObject.attributes.admissionFormSubmittedOn &&
                              <button className="btn btn-sm btn-primary m1-1 d-print-none" style={{ cursor: "pointer" }} onClick={() => {
                                setShowReshareFormAlert(true)
                              }}>

                                <i className="bx bx-share-alt"></i>  Re-share Form
                              </button>
                            }
                            {stateKidObject && !stateKidObject.attributes.Deleted && !stateKidObject.attributes.admissionFormSubmittedOn && !stateKidObject.attributes.admissionFormResharedOn &&
                              <button className="btn btn-sm btn-primary m-1 d-print-none" onClick={() => {
                                setShowAdmissionLinkPopupWithEnquiryId(true)
                              }}>
                                <i className="bx bx-share-alt"></i> Share form with parents
                              </button>
                            }
                            {stateKidObject && stateKidObject.attributes.admissionFormResharedOn && !stateKidObject.attributes.admissionFormSubmittedOn &&
                              <div className="d-flex">
                                <p className="text-success mb-0" >Admission form re-shared on- </p>
                                <p className="text-muted mb-0 mr-2">{convertDateToString(stateKidObject.attributes.admissionFormResharedOn)}</p>
                              </div>
                            }
                            <div className="">
                              {stateKidObject && <button className="btn btn-sm btn-primary m-1 d-print-none" style={{ marginLeft: '5px' }} onClick={() => {
                                window.print()
                              }}>
                                <i className="fa fa-print"></i> Print
                              </button>}
                              {stateKidObject && stateKidObject.attributes.admissionFormResharedOn && !stateKidObject.attributes.admissionFormSubmittedOn && <button className="btn btn-sm btn-primary m-1 d-print-none" style={{ cursor: "pointer" }} onClick={() => {
                                setShowRemindAdmissionLinkPopup(true)
                              }}>
                                <i className="bx bx-share-alt"></i> Remind parents
                              </button>}
                            </div>
                          </div>}
                          <div className="">
                            <AvForm
                              className="outer-repeater"
                              style={{ border: "1px" }}
                              onValidSubmit={isFormValid}
                            >
                              {admissionTitleList.map((admissionTitle, keyTitle) => (
                                <Col lg={12} className="mx-auto" key={keyTitle}>
                                  {/* <Card style={{ backgroundColor: "transparent" }}>
                                    <CardBody> */}
                                  <div
                                    data-repeater-list="outer-group"
                                    className="outer"
                                  >
                                    <div data-repeater-item className="outer">
                                      <div className="d-flex">
                                        <h5 className={keyTitle === 0 ? "mt-auto" : ""}>
                                          {props.t(admissionTitle.title)}:{" "}
                                        </h5>
                                        {!stateKidObject.attributes.Deleted && !props.hideEditButton && keyTitle === 0 && !isEditModeOn && (
                                          <Button
                                            style={{ marginLeft: 'auto' }}
                                            color="primary"
                                            className=" float-right ml-auto d-print-none"
                                            onClick={() => {
                                              setIsEditModeOn(true);
                                            }}
                                          >
                                            Edit
                                          </Button>
                                        )}
                                        {keyTitle === 0 && isEditModeOn && (
                                          <Button
                                            type="submit"
                                            color="primary"
                                            disabled={formIsSubmites}
                                            style={{
                                              marginLeft: "auto",
                                            }}
                                          >
                                            {formIsSubmites && (
                                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                                            )}
                                            {props.t("Save")}
                                          </Button>
                                        )}
                                        {keyTitle === 0 && isEditModeOn && (
                                          <Button
                                            color="danger"
                                            className=" float-right ml-1"
                                            onClick={() => {
                                              setIsEditModeOn(false);
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                        )}
                                      </div>
                                      <hr />
                                      {admissionTitle.admissionQuestionsList.map(
                                        (admissionQuestionObject, keyQuestion) =>
                                          <div className="dont-cut-element-when-printing">{componentForQuestionType(
                                            admissionQuestionObject,
                                            admissionTitle,
                                            keyQuestion
                                          )}</div>
                                      )}
                                      {admissionTitle.admissionQuestionsList.length ===
                                        0 && (
                                          <h6 className="mb-3 dont-cut-element-when-printing">
                                            No Questions in this section.
                                          </h6>
                                        )}
                                    </div>
                                  </div>
                                  {/* </CardBody>
                                  </Card> */}
                                </Col>
                              ))}
                              {admissionTitleList && admissionTitleList.length > 0 && (
                                <div className="d-flex">
                                  {!props.hideEditButton && !isEditModeOn && (
                                    <Button
                                      style={{ marginLeft: "auto" }}
                                      color="primary"
                                      className=" float-right ml-auto d-print-none"
                                      onClick={() => {
                                        setIsEditModeOn(true);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                  {isEditModeOn && (
                                    <Button
                                      type="submit"
                                      color="primary"
                                      disabled={formIsSubmites}
                                      style={{
                                        marginLeft: "auto",
                                      }}
                                    >
                                      {formIsSubmites && (
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                                      )}
                                      {props.t("Save")}
                                    </Button>
                                  )}
                                  {isEditModeOn && (
                                    <Button
                                      color="danger"
                                      className=" float-right ml-1"
                                      onClick={() => {
                                        setIsEditModeOn(false);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  )}
                                </div>
                              )}
                            </AvForm>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {isFormSubmitted && (
          <Card className="p-5 mt-2">
            <CardBody>
              {/* <div className="outer-repeater" style={{ border: '1px' }} >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer"> 
                    <h5 ><i className="fa fa-check text-success text-center mr-2

" aria-hidden="true"></i>
                    {cardMessage}</h5>
                  {/* </div>
                </div>
              </div> */}
              <CardTitle className="text-center">
                <i className={cardIcon} aria-hidden="true"></i>
                {cardMessage}
              </CardTitle>
            </CardBody>
          </Card>
        )}

        {!isLoading && !isQuestionsAvailable && (
          <Card>
            <CardBody>
              <AvForm
                className="outer-repeater"
                style={{ border: "1px" }}
              >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    <h5>Admission form not configured for this branch !</h5>
                  </div>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Admission Form...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};


const mapStatetoProps = (state) => {

  const { selectedSchool } = state.School;
  const { franchise } = state.Franchise;
  const { parents } = state.Parent;
  return {
    selectedSchool,
    franchise,
    parents,
  };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getFranchise,
      sendAdmissionLinkToParent,
      getParentsForFamilyIds,
    })(AdmissionFormComponent)
  )
);

class CustomAdmissionObject {
  question = "";
  questionType = null;
  questionNumber = null;
  admissionQuestionParseObject = null;
  answerText = null;
  answerNumber = null;
  answerBool = null;
  answerDate = null;
  answerChoices = [];
  choices = [];
  dataType = null;
  questionHint = "Type here";
  isMandatory = false;
  answerError = false;
  answerFile = null;
  answerImage = null;
  answerFileUploadProgress = 0;
  answerImageUploadProgress = 0;

  constructor(
    question,
    questionType,
    questionNumber,
    admissionQuestionParseObject,
    answerText,
    answerNumber,
    answerBool,
    dataType,
    answerDate,
    answerChoices,
    choices,
    questionHint,
    isMandatory = false,
    answerError = false,
    answerFile,
    answerImage,
    answerFileUploadProgress,
    answerImageUploadProgress
  ) {
    this.question = question;
    this.questionType = questionType;
    this.questionNumber = questionNumber;
    this.admissionQuestionParseObject = admissionQuestionParseObject;
    this.answerText = answerText;
    this.answerNumber = answerNumber;
    this.answerBool = answerBool;
    this.dataType = dataType;
    this.answerDate = answerDate;
    this.answerChoices = answerChoices;
    this.choices = choices;
    this.questionHint = questionHint;
    this.isMandatory = isMandatory;
    this.answerError = answerError;
    this.answerFile = answerFile;
    this.answerImage = answerImage;
    this.answerFileUploadProgress = answerFileUploadProgress;
    this.answerImageUploadProgress = answerImageUploadProgress;
  }
}
class CustomAdmissionTitleObject {
  admissionQuestionsList = [];
  title = "";
  admissionTitleParseObject = null;

  constructor(admissionQuestionsList, admissionTitleParseObject, title) {
    this.admissionQuestionsList = admissionQuestionsList;
    this.title = title;
    this.admissionTitleParseObject = admissionTitleParseObject;
  }
}
