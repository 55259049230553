import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Label,
} from "reactstrap";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import Topbar from "../Topbar";
import {
    setSelecetdSchool, getInventoryItemForOwnerId, setSelectedPurchaseOrderItems, setSelectedVendor,
    setSelectedPurchaseOrder, getFranchise, getInventoryWarehouseForOwnerId, getInventoryStockForWarehouse,
} from '../../../store/actions';


const PrintPurchaseOrder = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/print-purchase-order/:schoolId/:purchaseOrderId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-print-purchase-order/:franchiseId/:purchaseOrderId",
        exact: true,
        strict: false,
    });

    const tempMatch = match ?? franchiseMatch
    const purchaseOrderId = tempMatch && tempMatch.params.purchaseOrderId;

    const schoolId = match && match.params.schoolId;

    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [tableData, setTableData] = useState([{

        itemId: uuidv4(),
        item: undefined,
        units: undefined,
        quantity: undefined,
    }]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getFranchise(franchiseId);
            props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise');
        }
    }, [franchiseId]);

    useEffect(() => {
        props.setSelectedPurchaseOrder(purchaseOrderId);
        props.setSelectedPurchaseOrderItems(purchaseOrderId);
    }, [purchaseOrderId]);

    useEffect(() => {
        if (props.inventoryWarehouses && props.inventoryWarehouses.length > 0) {
            console.log("props.inventoryWarehouses", props.inventoryWarehouses)
            props.getInventoryStockForWarehouse(props.inventoryWarehouses[0].id);
        }
    }, [props.inventoryWarehouses]);

    useEffect(() => {
        if (props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.id == purchaseOrderId) {
            let vendorId = props.selectedInventoryPurchaseOrder.attributes.vendorId;
            props.setSelectedVendor(vendorId);
        }
    }, [props.selectedInventoryPurchaseOrder])


    useEffect(() => {
        if (purchaseOrderId && props.selectedInventoryPurchaseOrderItems && props.selectedInventoryPurchaseOrderItems.length > 0) {
            let items = []
            for (const si of props.selectedInventoryPurchaseOrderItems) {
                let newItem = {
                    itemId: uuidv4(),
                    item: si,
                    itemName: si.attributes.itemName,
                    units: si.attributes.unit,
                    quantity: si.attributes.quantity,
                    subItems: si.attributes.subItemsMatrix,
                }
                items.push(newItem)
            }
            setTableData(items);
        }
    }, [props.selectedInventoryPurchaseOrderItems])


    useEffect(() => {
        if (props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.id == purchaseOrderId) {
            if (props.selectedInventoryPurchaseOrderItems) {
                if (props.selectedVendor) {
                    if (props.selectedSchool || props.franchise) {
                        setIsLoading(false);
                    }
                }
            }
        }
    }, [props.selectedInventoryPurchaseOrder, props.selectedInventoryPurchaseOrderItems, props.selectedVendor, props.selectedSchool, props.franchise])

    const printOrder = () => {
        var css = "@page { size: A3;margin:10mm 0 10mm 0; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        window.print();
    }

    console.log("tableDate", tableData)
    const getStock = (itemId) => {
        console.log("props.inventoryItemStockMap", props.inventoryItemStockMap)
        if (props.inventoryItemStockMap && props.inventoryItemStockMap.length > 0) {
            let temp = props.inventoryItemStockMap.filter(
                (vendor) => vendor.attributes.itemId === itemId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.stock ?? "-")
            }
        }

        return '-'
    }


    const getUI = () => {
        return (<div className="page-content">
            <div className="d-print-none">
                <Topbar title="Purchase order Receipt " hideButton={true} />
            </div>
            {!isLoading && <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <div className="Reciept-title">
                                <Row className="mb-4">
                                    <Col>
                                        <img
                                            src={match ? (props.selectedSchool && props.selectedSchool.attributes.logo ? props.selectedSchool && props.selectedSchool.attributes.logo._url : "") : (props.franchise && props.franchise.attributes.logo ? props.franchise && props.franchise.attributes.logo._url : "")}
                                            style={{ height: "6rem" }}
                                        />
                                    </Col>
                                    {match && <Col className="d-flex justify-content-end">
                                        <address className="float-right">
                                            <strong>School Details</strong>
                                            <br />
                                            <span><strong>{props.selectedSchool.attributes.Name}</strong></span>
                                            <br />
                                            <span>{props.selectedSchool.attributes.Address}</span>
                                            <br />
                                            <span>{props.selectedSchool.attributes.primaryPhoneNumber}, {props.selectedSchool.attributes.secondaryPhoneNumber}</span>
                                            <br />
                                            <span>{props.selectedSchool.attributes.EMail}</span>
                                        </address>
                                    </Col>}
                                    {
                                        franchiseMatch && <Col className="d-flex justify-content-end">
                                            <address className="float-right">
                                                <strong>Franchise Details</strong>
                                                <br />
                                                <span><strong>{props.franchise.attributes.name}</strong></span>
                                                <br />
                                                <span>{props.franchise.attributes.address}</span>
                                                <br />
                                                <span>{props.franchise.attributes.primaryPhone}, {props.franchise.attributes.secondaryPhone}</span>
                                                <br />
                                                <span>{props.franchise.attributes.email}</span>
                                            </address>
                                        </Col>
                                    }
                                </Row>
                            </div>
                            <hr />
                            <Row>
                                <Col xs="6">
                                    <address>
                                        <strong>Vendor Details:</strong>
                                        <br />
                                        <strong>
                                            {props.selectedVendor && props.selectedVendor.attributes.companyName}
                                        </strong>
                                        <br />
                                        <span>Contact : {(props.selectedVendor && props.selectedVendor.attributes.vendorEmail) ?? (props.selectedVendor && props.selectedVendor.attributes.vendorPhone)}</span>
                                        <br />
                                        <span>Vendor name:{props.selectedVendor && props.selectedVendor.attributes.displayName}</span>
                                        <br />
                                    </address>
                                </Col>
                                <Col xs="6">
                                    <h4 className="d-flex justify-content-end font-size-16">
                                        {props.selectedInventoryPurchaseOrder && " Purchase order number #" + props.selectedInventoryPurchaseOrder.attributes.purchaseOrderNumber}
                                    </h4>
                                </Col>
                            </Row>
                            <hr />
                            <div className="py-2 mt-3">
                                <h3 className="font-size-15 font-weight-bold">
                                    Purchase order summary
                                </h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-centered table-bordered mb-0 table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            <th style={{ width: "70px", textAlign: 'center' }}>#</th>
                                            <th style={{ textAlign: 'center'}}>Items</th>
                                            <th style={{ textAlign: 'center'}}>Unit</th>
                                            <th style={{ textAlign: 'center'}}>quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((item, key) => (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td style={{ textAlign: 'center'}}>
                                                        <Label><b>{key + 1}</b></Label>
                                                    </td>
                                                    <td style={{ textAlign: 'center'}}>
                                                        <Label>{item.itemName}</Label>
                                                    </td>
                                                    <td style={{ textAlign: 'center'}}>
                                                        <Label className="font-size-12 mb-1">{item.units}</Label>
                                                    </td>
                                                    {<td style={{ textAlign: 'center'}}>
                                                        <Label className="font-size-12 mb-1">{item.quantity}</Label>
                                                    </td>}
                                                </tr>
                                                {item.subItems && (
                                                    <tr>
                                                        <td colSpan="5">
                                                            <div className="table-responsive text-center" style={{ width: '100%' }}>
                                                                <table className="table table-centered table-bordered mb-0 table-sm" style={{ width: '90%', margin: 'auto' }}>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th style={{ width: '10%', textAlign: 'center' }}>Sl No</th>
                                                                            {item.subItems.some(sub => sub.matrixColorId) && (
                                                                                <th style={{ width: '25%', textAlign: 'center' }}>Color</th>
                                                                            )}
                                                                            {item.subItems.some(sub => sub.matrixSizeId) && (
                                                                                <th style={{ width: '25%', textAlign: 'center' }}>Size</th>
                                                                            )}
                                                                            <th className='text-center'>Quantity</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {item.subItems.map((subItem, subItemKey) => (
                                                                            <tr key={subItemKey}>
                                                                                <td style={{ width: '10%', textAlign: 'center' }}>{subItemKey + 1}</td>
                                                                                {subItem.matrixColorId && (
                                                                                    <td style={{ width: '25%', textAlign: 'center' }}>
                                                                                        <h6>{subItem.colorName}</h6>
                                                                                    </td>
                                                                                )}
                                                                                {subItem.matrixSizeId && (
                                                                                    <td style={{ width: '25%', textAlign: 'center' }}>
                                                                                        <h6>{subItem.sizeName}</h6>
                                                                                    </td>
                                                                                )}
                                                                                <td style={{ width: '25%', textAlign: 'center' }}>
                                                                                    <h6>{getStock(subItem.objectId)}</h6>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end d-print-none">
                                <Label
                                    onClick={() => {
                                        printOrder()
                                    }}
                                    className="btn btn-success waves-effect waves-light mr-2"
                                >
                                    <i className="fa fa-print"></i>
                                </Label>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>}
            {
                isLoading && <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <button className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Purchase order... </button>
                        </div>
                    </Col>
                </Row>
            }
        </div>
        )
    }
    return (<React.Fragment>
        <div className="account-pages" >
            <Container fluid='md'>{getUI()}</Container>
        </div>
        <h5></h5>
        <br />
    </React.Fragment>

    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryItems } = state.InventoryItems;
    const { selectedInventoryPurchaseOrderItems } = state.InventoryPurchaseOrderItem;
    const { selectedVendor } = state.InventoryVendor;
    const { selectedInventoryPurchaseOrder } = state.InventoryPurchaseOrder;
    const { franchise } = state.Franchise;
    const { inventoryWarehouses, inventoryItemStockMap } = state.InventoryWarehouse;

    return {
        selectedSchool, inventoryItems, selectedInventoryPurchaseOrderItems, selectedVendor,
        selectedInventoryPurchaseOrder, franchise, inventoryWarehouses, inventoryItemStockMap,
    };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getInventoryItemForOwnerId, setSelectedPurchaseOrderItems, setSelectedVendor,
    setSelectedPurchaseOrder, getFranchise, getInventoryWarehouseForOwnerId, getInventoryStockForWarehouse,
})(PrintPurchaseOrder)));