import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import TopBar from "./Topbar";


import Parse from "parse";
import { AvForm } from "availity-reactstrap-validation";

import { Container, Row, Col, Button, Label, FormGroup } from "reactstrap";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";

import { setSelecetdSchool, getFranchise } from "../../store/actions";
import * as Constants from "../../Constents";
import Switch from "react-switch";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';

const PrintFormView = (props) => {
  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  const schoolMatch = matchPath(props.history.location.pathname, {
    path: "/printformview/:schoolId/:formId/:formName/:sessionId",
    exact: true,
    strict: false,
  });

  const franchiseMatch = matchPath(props.history.location.pathname, {
    path: "/franchise-printformview/:franchiseId/:formId/:formName/:sessionId",
    exact: true,
    strict: false,
  });

  const match = schoolMatch ?? franchiseMatch
  const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

  const matchOutside = matchPath(props.history.location.pathname, {
    path: "/printformviewoutside/:schoolId/:formId/:formName/:sessionId",
    exact: true,
    strict: false,
  });


  const [formId, setFormId] = useState(undefined);
  const [sessionId, setSessionId] = useState(undefined);
  const [schoolId, setSchoolId] = useState(undefined);
  const [formName, setFormName] = useState(undefined);
  const [removeNonSelected, setRemoveNonSelected] = useState(false);
  if (match || matchOutside) {
    let m = match ?? matchOutside;
    if (schoolId != m.params.schoolId) {
      setSchoolId(schoolMatch.params.schoolId);
    }
    if (formId != m.params.formId) {
      setFormId(m.params.formId);
    }
    if (sessionId != m.params.sessionId) {
      setSessionId(m.params.sessionId);
    }
    if (formName != m.params.formName) {
      setFormName(strReplace(m.params.formName));
    }

  } else {
    let path = window.location.pathname;
    console.log(window.location, path)
    let pathparams = path.split("/");
    if (pathparams && pathparams.length > 0) {

      if (schoolId != pathparams[2]) {
        setSchoolId(pathparams[2]);
      }
      if (formId != pathparams[3]) {
        setFormId(pathparams[3]);
      }
      if (sessionId != pathparams[pathparams.length - 1]) {
        setSessionId(pathparams[pathparams.length - 1]);
      }



    }
  }

  const getFormObject = (formId) => {
    if (formId) {
      var query = new Parse.Query("Forms");
      query.equalTo("objectId", formId);
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.first().then((result) => {
        if (result) {
          setFormName(result.attributes.name)
        }
      });
    }
  }



  const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];

  const [isLoading, setIsLoading] = useState(false);
  const [isQuestionsAvailable, setIsQuestionsAvailable] = useState(true);
  const [formSectionList, setFormSectionList] = useState([]);
  const [formData, setFormData] = useState(undefined);

  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");
    newStr = stringValue.replace(/%21/g, "/");
    return newStr;
  }

  useEffect(() => {
    fetchFormSections(formId);
    if (!formName && formId) {
      getFormObject(formId);
    }
  }, [formId]);
  useEffect(() => {
    if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolMatch) {
      props.setSelecetdSchool(schoolId);
    }
    if (props.selectedSchool && props.selectedSchool) {
      props.getFranchise(props.selectedSchool.attributes.franchiseId);
    }
  }, [props.selectedSchool, schoolId]);

  const fetchFormSections = (formId) => {
    var query = new Parse.Query("FormSections");
    query.equalTo("formId", formId);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      if (results != null) {
        if (results.length > 0) {
          var formSectionParseList = results;
          var sectionIds = [];
          var i = 0;
          for (i = 0; i < formSectionParseList.length; i++) {
            sectionIds.push(formSectionParseList[i].id);
          }
          fetchFormDataForSessionId(formSectionParseList, sectionIds);
        } else {
          setIsQuestionsAvailable(false);
        }
      }
    });
  };

  const fetchFormDataForSessionId = (formSectionParseList, sectionIds) => {
    var query = new Parse.Query("FormData");
    query.equalTo("sessionId", sessionId);

    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((formDataResults) => {
      if (formDataResults) {
        if (formDataResults.length > 0) {
          setFormData(formDataResults);
        }
        fetchFormQuestionsForFormIds(
          formSectionParseList,
          sectionIds,
          formDataResults
        );
      }
    });
  };

  const fetchFormQuestionsForFormIds = (
    formSectionParseList,
    sectionIds,
    formDataResults
  ) => {
    var query = new Parse.Query("FormQuestions");
    query.containedIn("sectionId", sectionIds);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      var tempSectionList = [];

      if (results != null) {
        var i = 0;

        for (i = 0; i < formSectionParseList.length; i++) {
          var j = 0;
          var tempQuestionList = [];

          var customFormSectionObject = new CustomFormSectionObject();
          customFormSectionObject.title =
            formSectionParseList[i].attributes.sectionName ?? "";
          customFormSectionObject.formSectionParseObject =
            formSectionParseList[i];

          for (j = 0; j < results.length; j++) {
            if (
              formSectionParseList[i].id === results[j].attributes.sectionId
            ) {
              var enqObject = results[j];

              var newEnquiryObject = new CustomFormObject();
              newEnquiryObject.question = enqObject.attributes.question ?? "";
              newEnquiryObject.questionType =
                enqObject.attributes.questionType ?? "";
              newEnquiryObject.questionNumber = i + 1;
              if (enqObject.attributes.dataType) {
                newEnquiryObject.dataType = enqObject.attributes.dataType;
              }
              if (enqObject.attributes.choices) {
                newEnquiryObject.choices = [...enqObject.attributes.choices];
              }
              if (
                enqObject.attributes.isMandatory &&
                enqObject.attributes.isMandatory === true
              ) {
                newEnquiryObject.isMandatory = true;
              } else {
                newEnquiryObject.isMandatory = false;
              }
              newEnquiryObject.dataType = enqObject.attributes.dataType;
              if (
                enqObject.attributes.questionHint != null &&
                enqObject.attributes.questionHint !== ""
              ) {
                newEnquiryObject.questionHint =
                  enqObject.attributes.questionHint;
              } else {
                newEnquiryObject.questionHint = "Type here";
              }
              newEnquiryObject.answerChoices = [];

              if (
                enqObject.attributes.questionType &&
                (enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_COMMENT_ON_NO ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_COMMENT_ON_YES ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_NO_COMMENTS ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_WITH_COMMENT)
              ) {
                if (
                  enqObject.attributes.choice == null ||
                  !enqObject.attributes.choice.length > 0
                ) {
                  newEnquiryObject.choices = ["Yes", "No"];
                }
              }
              newEnquiryObject.answerError = false;

              newEnquiryObject.formQuestionParseObject = enqObject;

              tempQuestionList.push(
                fillInitialValues(newEnquiryObject, formDataResults)
              );
            }
          }

          console.log(tempQuestionList);

          customFormSectionObject.formQuestionsList = tempQuestionList;

          tempSectionList.push(customFormSectionObject);
        }
        //setprogressValue(100 / tempSectionList.length);
        setFormSectionList(tempSectionList);
      }
    });
  };

  function formatDate(d) {
    if (d == null || d === "" || typeof d === "string" || d instanceof String) {
      return "";
    } else {
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      return `${ye}-${mo}-${da}`;
    }
  }

  const fillInitialValues = (formQuestionObject, formDataResults) => {
    var objectToReturn = formQuestionObject;
    if (formDataResults && formDataResults.length > 0) {
      let tempAnswer;
      let tempFormDataAnswer = formDataResults.filter(
        (fd) =>
          fd.attributes.questionId === objectToReturn.formQuestionParseObject.id
      );
      if (tempFormDataAnswer && tempFormDataAnswer.length > 0) {
        tempAnswer = tempFormDataAnswer[0].attributes.answer;
      }
      if (
        objectToReturn.questionType === Constants.QESTION_TEXT_SINGLE_LINE ||
        objectToReturn.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
        objectToReturn.questionType === Constants.QUESTION_EMAIL ||
        objectToReturn.questionType === Constants.QUESTION_PHONE_NUMBER
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }

        if (result) {
          objectToReturn.answerText = result;
        }
      } else if (objectToReturn.questionType === Constants.QESTION_NUMBER) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerNumber = result;
        }
      } else if (objectToReturn.questionType === Constants.QESTION_CHECKBOX) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerBool = result;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_NO_COMMENTS
      ) {
        var result = undefined;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result != undefined) {
          objectToReturn.answerBool = result;
        }
      } else if (
        objectToReturn.questionType ===
        Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      } else if (
        objectToReturn.questionType ===
        Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      } else if (objectToReturn.questionType === Constants.QESTION_DATE) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerDate = result;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES
      ) {
        var result1 = undefined;
        var result2 = undefined;
        if (tempAnswer && tempAnswer.length > 0) {

          result1 = tempAnswer[0];
          if (tempAnswer.length > 1) {
            result2 = tempAnswer[1];
          }

        }
        if (result1 != undefined) {
          objectToReturn.answerBool = result1;

        }
        if (result2 && result2.length > 0) {
          objectToReturn.answerText = result2;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO
      ) {
        var result1 = undefined;
        var result2 = undefined;
        if (tempAnswer && tempAnswer.length > 0) {

          result1 = tempAnswer[0];
          if (tempAnswer.length > 1) {
            result2 = tempAnswer[1];
          }

        }
        if (result1 != undefined) {
          objectToReturn.answerBool = result1;

        }
        if (result2 && result2.length > 0) {
          objectToReturn.answerText = result2;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_WITH_COMMENT
      ) {
      } else if (
        objectToReturn.questionType === Constants.QESTION_UPLOAD_IMAGE
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }

        if (result) {
          let name = result._name.split(/_(.+)/)[1];
          let fileExtension = name.split(".");
          let tempFile = {
            actualImageName: name,
            fileExtension: fileExtension[1].toLowerCase(),
            imageName: fileExtension[0],
            image: result,
            imageURL: result._url,
          };
          objectToReturn.answerImageUploadProgress = 100;
          objectToReturn.answerImage = tempFile;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_UPLOAD_DOCUMENT
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          let name = result._name.split(/_(.+)/)[1];
          let fileExtension = name.split(".");

          let tempFile = {
            actualFileName: name,
            fileExtension: fileExtension[1].toLowerCase(),
            fileName: fileExtension[0],
            file: result,
          };

          objectToReturn.answerFileUploadProgress = 100;
          objectToReturn.answerFile = tempFile;
        }
      }
    }

    return objectToReturn;
  };

  const checkForObjectTypeAndReturnAnswer = (questionObject) => {
    return false;
  };

  const getList = (choices, answers) => {

    if (removeNonSelected) {
      let list = [];
      for (const l of choices) {
        if (answers.includes(l)) {
          list.push(l)
        }
      }
      return list;
    }

    return choices;

  }

  const componentForQuestionType = (
    formQuestionObject,
    formSectionObject,
    key
  ) => {
    var questionType = formQuestionObject.questionType;

    switch (questionType) {
      case Constants.QESTION_UPLOAD_DOCUMENT:
        if (formQuestionObject.answerFile &&
          formQuestionObject.answerFile.fileExtension &&
          _imgExtensions.includes(
            formQuestionObject.answerFile.fileExtension.toLowerCase()
          )
        ) {
          return (
            <FormGroup key={key}>
              <Label style={{ fontWeight: "bold" }} className="m-1">
                {key + 1 + "."}
              </Label>
              <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }} htmlFor="formFileUpload" >
                {formQuestionObject.question}{" "}
              </Label>
              <div
                className="border p-2 ml-3"
                style={{ width: "200px", height: "250px" }}
              >
                <div>
                  {formQuestionObject.answerFile && <img
                    style={{
                      width: "182px",
                      height: "232px",
                    }}
                    src={
                      formQuestionObject.answerFile
                        ? formQuestionObject.answerFile.file._url
                        : ""
                    }
                    alt=""
                  />}
                </div>
              </div>
              <hr
                style={{
                  height: "1%",

                  color: "#fffffc",
                }}
              />
            </FormGroup>
          );
        } else {
          return (
            <FormGroup key={key}>
              <Label style={{ fontWeight: "bold" }} className="m-1">
                {key + 1 + "."}
              </Label>
              <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }} htmlFor="formFileUpload">
                {formQuestionObject.question}{" "}
              </Label>
              <br />

              {formQuestionObject.answerFile && formQuestionObject.answerFile.file && formQuestionObject.answerFile.file._url && <a
                className="ml-3"
                href={formQuestionObject.answerFile.file._url ?? ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formQuestionObject.answerFile
                  ? formQuestionObject.answerFile.actualFileName
                  : ""}
              </a>}

              <hr
                style={{
                  height: "1%",

                  color: "#fffffc",
                }}
              />
            </FormGroup>
          );
        }
        break;

      case Constants.QESTION_UPLOAD_IMAGE:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }} htmlFor="formImageUpload">
              {formQuestionObject.question}{" "}
            </Label>
            <div
              className="border p-2 m-2"
              style={{ width: "200px", height: "250px" }}
            >
              <div>
                {formQuestionObject.answerImage && <img
                  style={{
                    width: "182px",
                    height: "232px",
                  }}
                  src={
                    formQuestionObject.answerImage
                      ? formQuestionObject.answerImage.imageURL
                      : ""
                  }
                  alt=""
                />}
              </div>
            </div>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_SINGLE_LINE:
        return (
          <FormGroup key={key} className="">
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }} htmlFor="formSingleLine">
              {formQuestionObject.question}{" "}
            </Label>
            <br />
            <Label className="m-2" style={{ color: "gray", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.answerText}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_MULTI_LINE:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }} htmlFor="formMultiLine">
              {formQuestionObject.question}
            </Label>
            <br />
            <Label className="m-2" style={{ color: "gray", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.answerText}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QESTION_NUMBER:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>{formQuestionObject.question}</Label>
            <br />
            <Label

              className="m-2"
              style={{ color: "gray" }}
            >
              {formQuestionObject.answerNumber}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_CHECKBOX:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            <br />
            <Label className="m-2" style={{ color: "gray" }}>
              {formQuestionObject.answerBool ? "Yes" : "No"}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_YES_NO_NO_COMMENTS:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            <br />
            {formQuestionObject.answerBool && <Label className="m-2" style={{ color: "gray" }}>
              {formQuestionObject.answerBool ? "Yes" : "No"}
            </Label>}
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST:
        var choices = getList(formQuestionObject.choices, formQuestionObject.answerChoices);

        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            {choices.map((choice, keyInside) => (
              <div className="form-check  m-2" key={keyInside}>
                {removeNonSelected ? <i className="bx bxs-check-circle text-primary m-1"></i> : <input
                  className={"form-check-input"}
                  type="radio"
                  id={"radioButton" + key + keyInside}
                  value={choice}
                  checked={formQuestionObject.answerChoices.includes(choice)}
                />}
                <label
                  className={"form-check-label"}
                  htmlFor={"radioButton" + key + keyInside}
                >
                  {choice}
                </label>
              </div>
            ))}

            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST:
        var choices = getList(formQuestionObject.choices, formQuestionObject.answerChoices);

        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            {choices.map((choice, keyInside) => (
              <div className="form-check  m-2" key={keyInside}>
                {removeNonSelected ? <i className="bx bxs-check-circle text-primary m-1"></i> : <input
                  className={"form-check-input"}
                  type="checkbox"
                  id={"checkBox" + key + keyInside}
                  value={choice}
                  checked={formQuestionObject.answerChoices.includes(choice)}
                />}
                <label
                  className={"form-check-label"}
                  htmlFor={"checkBox" + key + keyInside}
                >
                  {choice}
                </label>
              </div>
            ))}

            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_DATE:
        // const maxDate = moment();
        // const maxDateString = maxDate.format("YYYY-MM-DD");

        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {" "}
              {formQuestionObject.question}
            </Label>
            <br />
            <Label className="m-2" style={{ color: "gray" }}>
              {formQuestionObject.answerDate
                ? formQuestionObject.answerDate.toLocaleDateString()
                : ""}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QESTION_RANGE_TIME:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_GEO_POINT:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_YES_NO_COMMENT_ON_YES:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            <br />
            {formQuestionObject.answerBool != undefined && <Label className="ml-3" style={{ color: "gray" }}>
              {formQuestionObject.answerBool ? "Yes" : "No"}{(formQuestionObject.answerText != undefined && formQuestionObject.answerText.length > 0) ? ` - ${formQuestionObject.answerText}` : ""}
            </Label>}
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_YES_NO_COMMENT_ON_NO:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            <br />
            {formQuestionObject.answerBool != undefined && <Label className="ml-3" style={{ color: "gray" }}>
              {formQuestionObject.answerBool ? "Yes" : "No"}{(formQuestionObject.answerText != undefined && formQuestionObject.answerText.length > 0) ? ` - ${formQuestionObject.answerText}` : ""}
            </Label>}
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_YES_NO_WITH_COMMENT:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {formQuestionObject.question}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QUESTION_EMAIL:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }} htmlFor="formemail">
              {" "}
              {formQuestionObject.question}
            </Label>
            <br />
            <Label className="ml-3" style={{ color: "gray" }}>
              {formQuestionObject.answerText
                ? formQuestionObject.answerText
                : ""}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QUESTION_PHONE_NUMBER:
        return (
          <FormGroup key={key}>
            <Label style={{ fontWeight: "bold" }} className="m-1">
              {key + 1 + "."}
            </Label>
            <Label style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
              {" "}
              {formQuestionObject.question}
            </Label>
            <br />
            <Label className="ml-3" style={{ color: "gray" }}>
              {formQuestionObject.answerText
                ? formQuestionObject.answerText
                : ""}
            </Label>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );

        break;
    }
  };

  function printPoster() {
    var css = "@page { size: A3;margin:10mm 0 10mm 0; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    window.print();
  }

  const getSchoolHeaderUI = (schoolObj) => {
    let school = {}
    school.name = schoolObj.attributes.Name;
    school.address = schoolObj.attributes.Address;
    school.primaryPhoneNumber =
      schoolObj.attributes.primaryPhoneNumber;
    school.secondaryPhoneNumber =
      schoolObj.attributes.secondaryPhoneNumber;
    school.email = schoolObj.attributes.EMail;
    school.logoUrl = schoolObj.attributes.logo
      ? schoolObj.attributes.logo._url
      : "";
    school.hLogoUrl = schoolObj.attributes.idCardLogo
      ? schoolObj.attributes.idCardLogo._url
      : "";
    school.hLogoUrl = schoolObj.attributes.letterheadLogo
      ? schoolObj.attributes.letterheadLogo._url
      : school.hLogoUrl;
    school.trustName = schoolObj.attributes.trustName;
    school.url = schoolObj.attributes.url;

    return (
      <Row className="">
        <Col className="d-flex justify-content-start">{(school.logoUrl || school.hLogoUrl) &&
          <img
            src={school.hLogoUrl ?? school.logoUrl}
            style={{ height: "6rem" }}
          />
        }</Col>
        <Col className="d-flex justify-content-end">
          <address >
            {school.trustName && (
              <strong>
                {school.trustName}
              </strong>
            )}<br />
            {school.name && (
              <strong>
                {school.name}
              </strong>
            )}<br />
            {school.address}
            {school.address && <br />}
            {school.primaryPhoneNumber && (
              <span>{school.primaryPhoneNumber}</span>
            )}
            {", "}
            {school.secondaryPhoneNumber && (
              <span>{school.secondaryPhoneNumber}</span>
            )}{" "}
            {(school.primaryPhoneNumber ||
              school.secondaryPhoneNumber) && <br />}
            {school.email} {school.email && <br />}
            {school.url} {school.url && <br />}
          </address>
        </Col>
      </Row>
    )
  }

  const getFranchiseHeaderUI = (franchiseObj) => {
    let school = {}
    school.name = franchiseObj.attributes.name;
    school.address = franchiseObj.attributes.address;
    school.primaryPhoneNumber =
      franchiseObj.attributes.primaryPhone;
    school.email = franchiseObj.attributes.email;
    school.logoUrl = franchiseObj.attributes.logo
      ? franchiseObj.attributes.logo._url
      : "";

    return (
      <Row className="">
        <Col className="d-flex justify-content-start">{(school.logoUrl || school.hLogoUrl) &&
          <img
            src={school.logoUrl && school.logoUrl}
            style={{ height: "6rem" }}
          />
        }</Col>
        <Col className="d-flex justify-content-end">
          <address >
            {school.trustName && (
              <strong>
                {school.trustName}
              </strong>
            )}<br />
            {school.name && (
              <strong>
                {school.name}
              </strong>
            )}<br />
            {school.address}
            {school.address && <br />}
            {school.primaryPhoneNumber && (
              <span>{school.primaryPhoneNumber}</span>
            )}
            {", "}<br/>
            {school.email} {school.email && <br />}
          </address>
        </Col>
      </Row>
    )
  }

  {/* <Col className="d-flex justify-content-end">
                        <address className="float-right">
                          <strong>{props.t("School Details")}:</strong>
                          <br />
                          {school.trustName && (
                            <strong>
                              {school.trustName}
                              <br />
                            </strong>
                          )}
                          {school.name && (
                            <strong>
                              {school.name}
                              <br />
                            </strong>
                          )}
                          {school.address}
                          {school.address && <br />}
                          {school.primaryPhoneNumber && (
                            <span>{school.primaryPhoneNumber}</span>
                          )}
                          {", "}
                          {school.secondaryPhoneNumber && (
                            <span>{school.secondaryPhoneNumber}</span>
                          )}{" "}
                          {(school.primaryPhoneNumber ||
                            school.secondaryPhoneNumber) && <br />}
                          {school.email} {school.email && <br />}
                          {school.cin && (
                            <span>
                              CIN : {school.cin} <br />
                            </span>
                          )}
                          {school.gstin && (
                            <span>
                              GSTIN : {school.gstin} <br />
                            </span>
                          )}
                          {school.pan && (
                            <span>
                              PAN : {school.pan} <br />
                            </span>
                          )}
                        </address>
                      </Col> */}
  return (
    <React.Fragment>
      <div className={matchOutside ? "" : "page-content"}>
        <Container fluid>
          {isLoading ? (
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  {props.t("Loading form...")}{" "}
                </Link>
              </div>
            </Col>
          ) : (
            <Row className="text-center">
              {match && <Row className='d-flex align-items-start d-print-none'>
                <Col lg={8} className="d-flex align-items-start">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => { window.history.back() }}>
                    <ul className=" list-unstyled">
                      <div className=''>
                        <i className="bx bx-chevron-left h1 text-primary"></i>
                      </div>
                    </ul>
                  </div>
                  <div className=' m-2'>
                    <h4>{formName}</h4>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end">
                  <div className="d-print-none m-1">
                    <label>Hide unselected options</label>
                  </div>
                  <div style={{ display: "inline-block" }} className="m-2 d-print">
                    <Switch
                      height={15}
                      width={30}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor={"#6add7d"}
                      checked={removeNonSelected}
                      onChange={() => {
                        setRemoveNonSelected(!removeNonSelected);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={2} className=' justify-content-end'>
                  {/* <div className="mt-4 mt-sm-0 d-flex justify-content-end mb-2" style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        printPoster()
                      }}
                      className="btn btn-success waves-effect waves-light mr-2"
                    >
                      <i className="fa fa-print m-1"></i>Print
                    </Button>
                  </div> */}
                  <button
                    onClick={() => {
                      printPoster()
                    }}
                    className="btn btn-success waves-effect waves-light mr-2">
                    <i className="fa fa-print m-1"></i>Print
                  </button>
                </Col>
              </Row>}
              <Col lg={12} md={12} sm={12} className="pl-0 pb-0">
                <center>
                  <div
                    style={{
                      minHeight: "1400px",
                      width: "1000px",
                      backgroundColor: "#fff",
                      border: `50px solid #fff`,
                    }}
                  // className="text-center"
                  >
                    <table style={{ width: "100%" }}>
                      {<thead>
                        <tr className="border-bottom ">
                          <td style={{ paddingBottom: "10px", marginBottom: "10px" }}>
                            <div className="d-none d-print-block">
                              {franchiseMatch ? getFranchiseHeaderUI(props.franchise && props.franchise) : getSchoolHeaderUI(props.selectedSchool)}
                            </div>
                          </td>
                        </tr>
                      </thead>}
                      <tbody>
                        <tr >
                          <td style={{ width: "100%" }}>
                            <div className="text-left">
                              <h4 className="text-center">{formName}</h4>

                              {formSectionList.map((formSection, keyTitle) => (
                                <Col
                                  lg={12}
                                  className="p-0 mt-4"
                                  key={keyTitle}
                                >
                                  <div>
                                    <AvForm
                                      className="outer-repeater"
                                      style={{ border: "1px" }}
                                      onValidSubmit={() => { }}
                                    >
                                      <div
                                        data-repeater-list="outer-group"
                                        className="outer"
                                      >
                                        <div data-repeater-item className="outer">
                                          <h5 className="mt-2 d-flex justify-content-start">{props.t(formSection.title) + " :"}</h5>
                                          <hr />
                                          {formSection.formQuestionsList.map(
                                            (formQuestionObject, keyQuestion) =>
                                              componentForQuestionType(
                                                formQuestionObject,
                                                formSection,
                                                keyQuestion
                                              )
                                          )}
                                        </div>
                                      </div>
                                    </AvForm>
                                  </div>
                                </Col>
                              ))}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </center>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { franchise } = state.Franchise;

  return { selectedSchool, franchise };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getFranchise
    })(PrintFormView)
  )
);
class CustomFormObject {
  question = "";
  questionType = null;
  questionNumber = null;
  formQuestionParseObject = null;
  answerText = null;
  answerNumber = null;
  answerBool = null;
  answerDate = null;
  answerChoices = [];
  choices = [];
  dataType = null;
  questionHint = "Type here";
  isMandatory = false;
  answerError = false;
  answerFile = null;
  answerImage = null;
  answerFileUploadProgress = 0;
  answerImageUploadProgress = 0;

  constructor(
    question,
    questionType,
    questionNumber,
    formQuestionParseObject,
    answerText,
    answerNumber,
    answerBool,
    dataType,
    answerDate,
    answerChoices,
    choices,
    questionHint,
    isMandatory = false,
    answerError = false,
    answerFile,
    answerImage,
    answerFileUploadProgress,
    answerImageUploadProgress
  ) {
    this.question = question;
    this.questionType = questionType;
    this.questionNumber = questionNumber;
    this.formQuestionParseObject = formQuestionParseObject;
    this.answerText = answerText;
    this.answerNumber = answerNumber;
    this.answerBool = answerBool;
    this.dataType = dataType;
    this.answerDate = answerDate;
    this.answerChoices = answerChoices;
    this.choices = choices;
    this.questionHint = questionHint;
    this.isMandatory = isMandatory;
    this.answerError = answerError;
    this.answerFile = answerFile;
    this.answerImage = answerImage;
    this.answerFileUploadProgress = answerFileUploadProgress;
    this.answerImageUploadProgress = answerImageUploadProgress;
  }
}
class CustomFormSectionObject {
  formQuestionsList = [];
  title = "";
  formSectionParseObject = null;

  constructor(formQuestionsList, formSectionParseObject, title) {
    this.formQuestionsList = formQuestionsList;
    this.title = title;
    this.formSectionParseObject = formSectionParseObject;
  }
}
