import React, { useEffect, useState } from "react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { withNamespaces } from "react-i18next";
import Parse from "parse";
import { getUserTeacher, setSelecetdSchool, getFranchise } from "../../store/actions";
import { connect } from "react-redux";
import * as Constants from "../../Constents";
import * as CommonFunctions from "../Common/CommonFunctions";
import * as Analytics from "../Common/Analytics";

const SidebarContent = (props) => {
  const [userTeacher, setUserTeacher] = useState(undefined);
  const [currentSchool, setCurrentSchool] = useState(undefined);
  const [showFranchiseReport, setShowFranchiseReport] = useState(false);
  const [showRoyaltySetting, setShowRoyaltySetting] = useState(false);
  const [showCorporatePartners, setShowCorporatePartners] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [showIncidentManagement, setShowIncidentManagement] = useState(false);
  const [showInventoryManagement, setShowInventoryManagement] = useState(false);
  const [showNewBranchEnquiry, setShowNewBranchEnquiry] = useState(false);
  const [isAdminTools, setIsAdminTools] = useState(true);

  useEffect(() => {
    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    if (Parse.User.current()) {
      if (Parse.User.current().attributes.teacherId) {
        props.getUserTeacher(Parse.User.current().attributes.teacherId);
      }
      if (!props.franchise) {
        props.getFranchise(Parse.User.current().attributes.franchiseId);
      }
    }

  }, [props.success]);

  // useEffect(() => {
  //     let schoolId = localStorage.getItem(Constants.SELECTED_SCHOOL_ID);
  //     if (schoolId && !props.selectedSchool) {
  //         props.setSelecetdSchool(schoolId)
  //     }
  // },[localStorage.getItem(Constants.SELECTED_SCHOOL_ID)]);

  useEffect(() => {
    if (props.userTeacher) {
      if (props.userTeacher.attributes.franchiseId && props.userTeacher.attributes.Role == Constants.FRANCHISE_STAFF_SUPERVISOR) {
        setIsSupervisor(true);
      }
      setUserTeacher(props.userTeacher);
    }
  }, [props.userTeacher]);

  useEffect(() => {
    if (props.selectedSchool) {
      setCurrentSchool(props.selectedSchool);
    }
    if (!props.franchise && props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
      props.getFranchise(props.selectedSchool.attributes.franchiseId);
    }
  }, [props.selectedSchool]);

  useEffect(() => {

    if (!props.franchise && props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
      props.getFranchise(props.selectedSchool.attributes.franchiseId);
    }
  }, [props.franchise, props.selectedSchool]);


  useEffect(() => {

    if (props.franchise && props.franchise.attributes.features) {
      if (props.franchise.attributes.features.length > Constants.FRANCHISE_REPORTS_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_REPORTS_INDEX] > 0) {
        setShowFranchiseReport(true)
      } else if (showFranchiseReport) {
        setShowFranchiseReport(false)
      }

      // Royalty settings.
      if (props.franchise.attributes.features.length > Constants.FRANCHISE_ROYALTY_SETTING_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_ROYALTY_SETTING_INDEX] > 1) {
        setShowRoyaltySetting(true)
      }
      else if (showRoyaltySetting) {
        setShowRoyaltySetting(false)
      }
      if (props.franchise.attributes.features.length > Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX] > 0) {
        setShowCorporatePartners(true)
      }
      else if (showCorporatePartners) {
        setShowCorporatePartners(false)
      }

      if (props.franchise.attributes.features.length > Constants.FRANCHISE_TICKETS_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_TICKETS_INDEX] > 0) {
        setShowIncidentManagement(true)
      }
      else if (showIncidentManagement) {
        setShowIncidentManagement(false)
      }

      if (props.franchise.attributes.features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_INVENTORY_INDEX] > 0) {
        setShowInventoryManagement(true)
      }
      else if (showIncidentManagement) {
        setShowInventoryManagement(false)
      }

      if (props.franchise.attributes.features.length > Constants.FRANCHISE_NEW_BRANCH_ENQUIRY &&
        props.franchise.attributes.features[Constants.FRANCHISE_NEW_BRANCH_ENQUIRY] > 0) {
          setShowNewBranchEnquiry(true)
      }
      else if (showIncidentManagement) {
        setShowNewBranchEnquiry(false)
      }
    }
  }, [props.franchise]);


  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  }

  function isFeatureAvilable(schoolPermissionIndex, userPermissionIndex) {
    let currentTeacher = userTeacher;
    let isAppModeDirector = false;
    let isAppModeCenterHead = false;
    let returnValue = false;
    if (currentTeacher && currentTeacher.attributes) {
      if (currentTeacher.attributes.Role === 3) {
        isAppModeDirector = true;
      } else if (currentTeacher.attributes.Role === 2) {
        isAppModeCenterHead = true;
      }
    }
    let school = currentSchool;
    let onlyTeacherFetures = [
      Constants.TEACHER_CHILD_DETAILS_INDEX,
      Constants.TEACHER_GROUPS,
      Constants.TEACHER_ENQUIRY,
      Constants.TEACHER_MESSAGING_INDEX,
    ];
    if (
      currentTeacher &&
      currentTeacher.attributes &&
      school &&
      school.attributes
    ) {
      if (currentTeacher.attributes["featurePermissions"] == undefined) {
        currentTeacher.set("featurePermissions", []);
      }
      if (
        school.attributes.featuresList &&
        school.attributes.featuresList.length > schoolPermissionIndex
      ) {
        if (school.attributes.featuresList[schoolPermissionIndex] > 0) {
          if (Parse.User.current().attributes.isSuperUser || userPermissionIndex== 999 ) {
            returnValue = true;
          } else if (
            currentTeacher.attributes.featurePermissions != undefined &&
            currentTeacher.attributes.featurePermissions.length >
            userPermissionIndex
          ) {
            if (
              currentTeacher.attributes.featurePermissions[
              userPermissionIndex
              ] > 0
            ) {
              returnValue = true;
            } else {
              returnValue = false;
            }
          } else {
            if (
              schoolPermissionIndex == Constants.FEATURE_PAYMENTS_INDEX ||
              schoolPermissionIndex == Constants.FEATURE_EXPENSE_INDEX ||
              schoolPermissionIndex == Constants.FEATURE_MARKS_CARD
            ) {
              if (isAppModeDirector || isAppModeCenterHead) {
                returnValue = true;
              } else {
                returnValue = false;
              }
            } else {
              returnValue = true;
            }
          }
        } else {
          returnValue = false;
        }
      } else if (schoolPermissionIndex === 999) {
        if (Parse.User.current().attributes.isSuperUser) {
          returnValue = true;
        } else if (
          currentTeacher.attributes.featurePermissions != undefined &&
          currentTeacher.attributes.featurePermissions.length >
          userPermissionIndex
        ) {
          if (
            currentTeacher.attributes.featurePermissions[userPermissionIndex] >
            0
          ) {
            if (onlyTeacherFetures.includes(userPermissionIndex)) {
              returnValue = true;
            } else {
              returnValue = false;
            }
          } else {
            returnValue = false;
          }
        }
      }
    }
    return returnValue;
  }

  const featureBasedOnSchoolType = (name, index) => {

    if (currentSchool && currentSchool.attributes.schoolType === 10) {

      let fl = ["All Students", "Ratings", "Classrooms", "Groups", "Admissions", "LMS", "Diary", "CCTV", "TRIP", "Fee", "Programs", "MarksCard", "Expense", "Visitor", "Exams", "Archives", "lilTrips", "Messaging", "HelpVideos", "Gallery", "Incident"];
      if (fl.includes(name)) {
        return false;
      }

    }
    return true;
  }

  const featureClicked = (type) => {
    let user = Parse.User.current()
    if (props.selectedSchool) {
      Analytics.menuFeatureClick(user.id, user.attributes.email, userTeacher.attributes.Name, type, "sideBar", props.selectedSchool.id, props.selectedSchool.attributes.Name)
    } else if(props.franchise){
      Analytics.menuFeatureClick(user.id, user.attributes.email, userTeacher.attributes.Name, type, "sideBar", props.franchise.id, "Franchise: "+props.franchise.attributes.id)
    }
    

  }


  return (
    <React.Fragment>
      {CommonFunctions.isTools() ? (<div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t("Menu")}</li>
          <li>
            <Link to="/tools-schools-subscription" className=" waves-effect">
              <i className="bx bxs-school"></i>
              <span>{props.t("All Schools")}</span>
            </Link>
          </li>
          <li>
            <Link to="/tools-institute-view" className=" waves-effect">
              <i className="bx bx-buildings"></i>
              <span>{props.t("All Institutes")}</span>
            </Link>
          </li>
          <li>
            <Link to="/tools-franchise-view" className=" waves-effect">
              <i className="bx bxs-school"></i>
              <span>{props.t("All Franchise")}</span>
            </Link>
          </li>
          <li>
            <Link to="/tools-schools-analytics" className=" waves-effect">
              <i className="bx bxs-school"></i>
              <span>{props.t("All School Analytics")}</span>
            </Link>
          </li>
        </ul>
      </div>) :
        <div id="sidebar-menu">
          {CommonFunctions.isFranchise() ? (
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")}</li>
              <li>
                <Link
                  to={
                    "/franchise/" + Parse.User.current().attributes.franchiseId
                  }
                  className=" waves-effect"
                  onClick={() => { featureClicked("Dashboard") }}
                >
                  <i className="bx bx-home"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/all-branches/" + Parse.User.current().attributes.franchiseId}
                  className=" waves-effect"
                  onClick={() => { featureClicked("All Branches") }}>
                  <i className="bx bx-buildings"></i>
                  <span>{props.t("All Branches")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admins" className=" waves-effect" onClick={() => { featureClicked("Admins") }}>
                  <i className="bx bx-star"></i>
                  <span>{props.t("Admins")}</span>
                </Link>
              </li>
              {!isSupervisor && <li>
                <Link
                  to={`/message-franchise/${Parse.User.current().attributes.franchiseId}`}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Messaging") }}
                >
                  <i className="bx bxs-chat"></i>
                  <span>{props.t("Messaging")}</span>
                </Link>
              </li>}
              <li>
                <Link to="/lms-curriculum/franchise" className=" waves-effect"
                  onClick={() => { featureClicked("LMS") }}>
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("LMS")}</span>
                </Link>
              </li>
              {showIncidentManagement && <li>
                <Link
                  to={"/franchise-all-tickets/" + Parse.User.current().attributes.franchiseId}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Service Desk") }}>
                  <i className="bx bxs-check-shield"></i>
                  <span>{props.t("Service Desk")}</span>
                </Link>
              </li>}
              {showInventoryManagement && <li>
                <Link
                  to={"/franchise-inventory-management/" + Parse.User.current().attributes.franchiseId}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Inventory Management") }}>
                  <i className="bx bx-cart-alt"></i>
                  <span>{props.t("Inventory Management")}</span>
                </Link>
              </li>}

              {<li>
                <Link
                  to={"/franchise-all-cards/" + Parse.User.current().attributes.franchiseId}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Story Cards") }}>
                  <i className="bx bx-message-alt-dots"></i>
                  <span>{props.t("Story Cards")}</span>
                </Link>
              </li>}

              {showCorporatePartners && <li>
                <Link
                  to={`/corporate-partners/${Parse.User.current().attributes.franchiseId}`}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Corporate Partners") }}
                >
                  <i className="bx bx-briefcase-alt"></i>
                  <span>{props.t("Corporate Partners")}</span>
                </Link>
              </li>}

              {showNewBranchEnquiry && <li>
                <Link
                  to={"/franchise-enquiry/" + Parse.User.current().attributes.franchiseId}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Franchise Enquiry") }}>
                  <i className="bx bx-clipboard"></i>
                  <span>{props.t("New Branch Enquiry")}</span>
                </Link>
              </li>}
              {<li>
                <Link
                  to={"/franchise-forms/" + Parse.User.current().attributes.franchiseId}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Forms") }}>
                 <i className="bx bx-copy-alt"></i>
                  <span>{props.t("Forms")}</span>
                </Link>
              </li>}
              {!isSupervisor && <li>
                <Link
                  to={`/franchise-reports/${Parse.User.current().attributes.franchiseId}`}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Reports") }}
                >
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
              </li>}
              {showFranchiseReport && <li>
                <Link
                  to={`/franchise-custom-reports-view`}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Custom Reports") }}
                >
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Custom Reports")}</span>
                </Link>
              </li>}



              {
                showRoyaltySetting && false && <li>
                  <Link
                    to={`/franchise-settings`}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Settings") }}
                  >
                    <i className="bx bx-cog"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li>
              }

            </ul>

          ) : (
            <ul className="metismenu list-unstyled" id="side-menu">
              <Link
                to={
                  props.selectedSchool && props.selectedSchool.attributes
                    ? `/dashboard/${props.selectedSchool.id}`
                    : "/dashboard"
                }
                onClick={() => { featureClicked("Dashboard") }}
                className=""
              >
                <li className="menu-title">{props.t("Dashboard")}</li>
              </Link>
              <li>
                <Link
                  to={
                    props.selectedSchool && props.selectedSchool.attributes
                      ? `/dashboard/${props.selectedSchool.id}`
                      : "/dashboard"
                  }
                  className=" waves-effect"
                  onClick={() => { featureClicked("Analytics") }}
                >
                  <i className="bx bx-home"></i>
                  <span>{props.t("Analytics")}</span>
                </Link>
              </li>
              {featureBasedOnSchoolType("Ratings") && <li>
                <Link
                  to={
                    props.selectedSchool && props.selectedSchool.attributes
                      ? `/schoolratinginside/${props.selectedSchool.id}`
                      : "/schoolratinginside"
                  }
                  className=" waves-effect"
                  onClick={() => { featureClicked("Ratings") }}
                >
                  <i className="bx bx-star"></i>
                  <span>{props.t("Ratings")}</span>
                </Link>
              </li>}
              {/* <Link to={props.selectedSchool && props.selectedSchool.attributes ? `/schoolrating/${props.selectedSchool.id}` : "/schoolrating"} className=""><li className="menu-title">{props.t('Ratings')}</li></Link> */}
              {props.selectedSchool && (
                <Link to={"/school/" + props.selectedSchool.id} className="">
                  <li className="menu-title">{props.selectedSchool.attributes.schoolType === 10 ? props.t("Institute") : props.t("School")}</li>
                </Link>
              )}
              {props.selectedSchool && (
                <li>
                  <Link
                    to={props.selectedSchool.attributes.schoolType === 10 ? "/coordinators/" + props.selectedSchool.id : "/staff/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Staff") }}
                  >
                    <i className="bx bx-female"></i>
                    <span>{props.selectedSchool.attributes.schoolType === 10 ? props.t("Coordinators") : props.t("Staff")}</span>
                  </Link>
                </li>
              )}
              {props.selectedSchool && featureBasedOnSchoolType("All Students") && (
                <li>
                  <Link
                    to={"/student/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("All Students") }}
                  >
                    <i className="bx bx-face"></i>
                    <span>{props.t("All Students")}</span>
                  </Link>
                </li>
              )}
              {props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 && (
                <li>
                  <Link
                    to={"/student/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("All Participants") }}
                  >
                    <i className="bx bx-face"></i>
                    <span>{props.t("All Participants")}</span>
                  </Link>
                </li>
              )}
              {props.selectedSchool && featureBasedOnSchoolType("Classrooms") && (
                <li>
                  <Link
                    to={"/allClasses/" + props.selectedSchool.id}
                    className="waves-effect"
                    onClick={() => { featureClicked("Classrooms") }}
                  >
                    <i className="bx bx-book-reader"></i>
                    <span>{props.t("Classrooms")}</span>
                  </Link>
                </li>
              )}

              {isFeatureAvilable(999, Constants.TEACHER_GROUPS) && featureBasedOnSchoolType("Groups") && (
                <li>
                  <Link
                    to={"/groupdetail/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Groups") }}
                  >
                    <i className="bx bx-group"></i>
                    <span>{props.t("Groups")}</span>
                  </Link>
                </li>
              )}

              {props.selectedSchool && featureBasedOnSchoolType("Admissions") && (
                <li>
                  <Link
                    to={"/admissions/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Admissions") }}
                  >
                    <i className="bx bx-user-plus"></i>
                    <span>{props.t("Admissions")}</span>
                  </Link>
                </li>
              )}
              {isFeatureAvilable(999, Constants.TEACHER_ENQUIRY) && (
                <li>
                  <Link
                    to={"/enquiry/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Enquiry") }}
                  >
                    <i className="bx bx-clipboard"></i>
                    <span>{props.t("Enquiry")}</span>
                  </Link>
                </li>
              )}
              {isFeatureAvilable(Constants.FEATURE_VISITOR_INDEX, Constants.TEACHER_ENQUIRY) && featureBasedOnSchoolType("Visitor") && (
                <li>
                  <Link
                    to={"/visitor-management/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Visitors") }}
                  >
                    <i className="bx bx-walk"></i>
                    <span>{props.t("Visitors")}</span>
                  </Link>
                </li>
              )}
              {props.selectedSchool && (
                <li>
                  <Link
                    to={"/archives/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Archives") }}
                  >
                    <i className="bx bx-folder-open"></i>
                    <span>{props.t("Archives")}</span>
                  </Link>
                </li>
              )}
              {isFeatureAvilable(999, Constants.TEACHER_MESSAGING_INDEX) && featureBasedOnSchoolType("Messaging") && <li>
                <Link
                  to={`/message-school/${props.selectedSchool.id}`}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Messaging") }}
                >
                  <i className="bx bxs-chat"></i>
                  <span>{props.t("Messaging")}</span>
                </Link>
              </li>}
              {props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 && (<li>
                <Link
                  to={`/messaging/${props.selectedSchool.id}`}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Messaging") }}
                >
                  <i className="bx bxs-chat"></i>
                  <span>{props.t("Messaging")}</span>
                </Link>
              </li>
              )}
              {props.selectedSchool && (
                <li>
                  <Link
                    to={"/school/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Settings") }}
                  >
                    <i className="bx bx-cog"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li>
              )}

              <li className="menu-title">{props.t("Apps")}</li>
              {isFeatureAvilable(
                Constants.FEATURE_PAYMENTS_INDEX,
                Constants.TEACHER_PAYMENTS_INDEX
              ) && featureBasedOnSchoolType("Fee") && (
                  <li>
                    <Link
                      to={"/payments/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("Fee Management") }}
                    >
                      <i className="bx bxs-calculator"></i>
                      <span>{props.t("Fee Management")}</span>
                    </Link>
                  </li>
                )}


              {isFeatureAvilable(Constants.FEATURE_PROGRAM_INDEX, Constants.TEACHER_PROGRAMS) && featureBasedOnSchoolType("Programs") && (
                <li>
                  <Link
                    to={"/programdetail/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Programs") }}
                  >
                    <i className="bx bx-file"></i>
                    <span>{props.t("Programs")}</span>
                  </Link>
                </li>
              )}
              {false && (
                <li>
                  <Link
                    to={"/messaging/" + props.selectedSchool.id}
                    className=" waves-effect"
                  >
                    <i className="bx bx-chat"></i>
                    <span>{props.t("Messaging")}</span>
                  </Link>
                </li>
              )}
              {isFeatureAvilable(
                Constants.FEATURE_EXPENSE_INDEX,
                Constants.TEACHER_EXPENSE_INDEX
              ) && featureBasedOnSchoolType("Expense") && (
                  <li>
                    <Link
                      to={"/expenses/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("Expense Management") }}
                    >
                      <i className="bx bx-credit-card"></i>
                      <span>{props.t("Expense Management")}</span>
                    </Link>
                  </li>
                )}
              {props.selectedSchool && featureBasedOnSchoolType("Calendar") && (
                <li>
                  <Link
                    to={"/calendar/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Calendar") }}
                  >
                    <i className="bx bx-calendar"></i>
                    <span>{props.t("Calendar")}</span>
                  </Link>
                </li>
              )}
              {isFeatureAvilable(
                Constants.FEATURE_MARKS_CARD,
                Constants.FEATURE_MARKS_CARD
              ) && featureBasedOnSchoolType("Marks Card") && (
                  <li>
                    <Link
                      to={"/all-exams/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("Marks Card") }}
                    >
                      <i className="bx bx-receipt"></i>
                      <span>{props.t("Marks Card")}</span>
                    </Link>
                  </li>
                )}
              {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Diary") && (
                <li>
                  <Link
                    to={`/diaryview/${props.selectedSchool.id}`}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Daycare Diary") }}
                  >
                    <i className="bx bx-book-bookmark"></i>
                    <span>{props.t("Daycare Diary")}</span>
                  </Link>
                </li>
              )}
              {isFeatureAvilable(
                Constants.FEATURE_CCTV_INDEX,
                Constants.TEACHER_CCTV_INDEX
              ) && featureBasedOnSchoolType("CCTV") && (
                  <li>
                    <Link
                      to={"/cameradetail/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("CCTV") }}
                    >
                      <i className="bx bx-cctv"></i>
                      <span>{props.t("CCTV")}</span>
                    </Link>
                  </li>
                )}
              {isFeatureAvilable(
                Constants.FEATURE_TRANSPORT_INDEX,
                Constants.TEACHER_TRANSPORT_INDEX
              ) && true && featureBasedOnSchoolType("lilTrips") && (
                  <li>
                    <Link
                      to={"/triplist/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("Trips") }}
                    >
                      <i className="bx bx-bus"></i>
                      <span>{props.t("Trips")}</span>
                    </Link>
                  </li>
                )}
              {(isFeatureAvilable(
                Constants.FEATURE_ACTIVITY_TRACKING_INDEX,
                Constants.TEACHER_CHILD_DEVELOPMENT_INDEX
              ) || isFeatureAvilable(
                Constants.FEATURE_E_LEARNING,
                Constants.TEACHER_CHILD_DEVELOPMENT_INDEX,
              )) && featureBasedOnSchoolType("LMS") && (
                  <li>
                    <Link
                      to={"/lms-curriculum/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("LMS") }}
                    >
                      <i className="bx bx-book-reader"></i>
                      <span>{props.t("LMS")}</span>
                    </Link>
                  </li>
                )}


              {isFeatureAvilable(
                Constants.FEATURE_COURSE,
                Constants.TEACHER_COURSE
              ) && (
                  <li>
                    <Link
                      to={"/allcourses/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("Courses") }}
                    >
                      <i className="bx bx-laptop"></i>
                      <span>{props.t("Courses")}</span>
                    </Link>
                  </li>
                )
              }
              {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Exams") && (
                <li>
                  <Link
                    to={"/exams/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Exams") }}
                  >
                    <i className="bx bx-book-content"></i>
                    <span>{props.t("Exams")}</span>
                  </Link>
                </li>
              )}

              {props.selectedSchool && props.selectedSchool.id
                && props.selectedSchool.attributes.schoolType === 10
                && featureBasedOnSchoolType("Assessments") && (
                  <li>
                    <Link
                      to={"/exams/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("Assessments") }}
                    >
                      <i className="bx bx-book-content"></i>
                      <span>{props.t("Assessments")}</span>
                    </Link>
                  </li>
                )}

              {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Forms") && (
                <li>
                  <Link
                    to={"/forms/" + props.selectedSchool.id}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Forms") }}
                  >
                    <i className="bx bx-copy-alt"></i>
                    <span>{props.selectedSchool.attributes.schoolType === 10 ? props.t("Feedback Forms") : props.t("Forms")}</span>
                  </Link>
                </li>
              )}
              {props.selectedSchool && props.selectedSchool.id &&
                props.selectedSchool.attributes.schoolType === 10 &&
                featureBasedOnSchoolType("Announcements") && (
                  <li>
                    <Link
                      to={"/announcements/" + props.selectedSchool.id}
                      className=" waves-effect"
                      onClick={() => { featureClicked("Announcements") }}
                    >
                      <i className="bx bx-paper-plane"></i>
                      <span>{props.t("Announcements")}</span>
                    </Link>
                  </li>
                )}
              {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Gallery") &&
                <li>
                  <Link
                    to={`/gallery/${props.selectedSchool.id}`}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Gallery") }}
                  >
                    <i className="bx bx-photo-album"></i>
                    <span>{props.t("Gallery")}</span>
                  </Link>
                </li>
              }
              {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Reports") && (
                <li>
                  <Link
                    to={currentSchool && currentSchool.attributes.schoolType === 10 ? `/course-reports/${props.selectedSchool.id}` : `/reports/${props.selectedSchool.id}`}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Reports") }}
                  >
                    <i className="bx bxs-report"></i>
                    <span>{props.t("Reports")}</span>
                  </Link>
                </li>
              )}
              {props.selectedSchool && props.selectedSchool.id && 
              (showIncidentManagement   || 
              isFeatureAvilable(
                Constants.FEATURE_TICKETS,
                999
              ))&& featureBasedOnSchoolType("Incident") && <li>
                <Link
                  to={"/all-tickets/" + props.selectedSchool.id}
                  className=" waves-effect"
                  onClick={() => { featureClicked("Service Desk") }}>
                  <i className="bx bxs-check-shield"></i>
                  <span>{props.t("Service Desk")}</span>
                </Link>
              </li>}
              {props.selectedSchool && props.selectedSchool.id && showFranchiseReport && (
                <li>
                  <Link
                    to={`/custom-reports-view/${props.selectedSchool.id}`}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Franchise Reports") }}
                  >
                    <i className="bx bxs-report"></i>
                    <span>{props.t("Franchise Reports")}</span>
                  </Link>
                </li>
              )}

              {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("HelpVideos") &&
                <li>
                  <Link
                    to={`/help-videos/${props.selectedSchool.id}`}
                    className=" waves-effect"
                    onClick={() => { featureClicked("Help Videos") }}
                  >
                    <i className="bx bx-slideshow"></i>
                    <span>{props.t("Help Videos")}</span>
                  </Link>
                </li>
              }
              {/* {props.selectedSchool && props.selectedSchool.id ==="E8yBGJLLYJ"&& (
              <li>
                <Link
                  to={"/ecommerce-products"}
                  className=" waves-effect"
                >
                  <i className="bx bxs-store"></i>
                  <span>{props.t("School Kits")}</span>
                </Link>
              </li>
            )} */}




              {/* {props.selectedSchool && props.selectedSchool.id && (
              <li>
                <Link
                  to={`/monthly-reports/${props.selectedSchool.id}`}
                  className=" waves-effect"
                >
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Monthly Reports")}</span>
                </Link>
              </li>
            )} */}

              <li>
                <Link
                  to={`/logout`}
                  className=" waves-effect"
                  onClick={() => {
                    let user = Parse.User.current()
                    Analytics.track_Logout(user.id, user.attributes.email)
                  }}
                >
                  <i className="bx bx-power-off"></i>
                  <span>{props.t("Logout")}</span>
                </Link>
              </li>
              {/* <li className="menu-title">{props.t('Menu')}  </li>
                            <li>
                                <Link to="/#" className="waves-effect">
                                    <i className="bx bx-home-circle"></i><span className="badge badge-pill badge-info float-right">03</span>
                                    <span>{props.t('Dashboards')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/dashboard">{props.t('Default')}</Link></li>
                                    <li><Link to="/dashboard-saas">{props.t('Saas')}</Link></li>
                                    <li><Link to="/dashboard-crypto">{props.t('Crypto')}</Link></li>
                                </ul>
                            </li> */}
              {/* <li><Link to="ui-image-cropper">{props.t('Image Cropper')}</Link></li> */}

              {/* <li className="menu-title">{props.t('Apps')}</li>

                            <li>
                                <Link to="calendar" className=" waves-effect">
                                    <i className="bx bx-calendar"></i>
                                    <span>{props.t('Calendar')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="chat" className=" waves-effect">
                                    <i className="bx bx-chat"></i>
                                    <span className="badge badge-pill badge-success float-right">{props.t('New')}</span>
                                    <span>{props.t('Chat')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-store"></i>
                                    <span>{props.t('Ecommerce')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="ecommerce-products">{props.t('Products')}</Link></li>
                                    <li><Link to="ecommerce-product-detail">{props.t('Product Detail')}</Link></li>
                                    <li><Link to="ecommerce-orders">{props.t('Orders')}</Link></li>
                                    <li><Link to="ecommerce-customers">{props.t('Customers')}</Link></li>
                                    <li><Link to="ecommerce-cart">{props.t('Cart')}</Link></li>
                                    <li><Link to="ecommerce-checkout">{props.t('Checkout')}</Link></li>
                                    <li><Link to="ecommerce-shops">{props.t('Shops')}</Link></li>
                                    <li><Link to="ecommerce-add-product">{props.t('Add Product')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-bitcoin"></i>
                                    <span>{props.t('Crypto')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="crypto-wallet">{props.t('Wallet')}</Link></li>
                                    <li><Link to="crypto-buy-sell">{props.t('Buy/Sell')}</Link></li>
                                    <li><Link to="crypto-exchange">{props.t('Exchange')}</Link></li>
                                    <li><Link to="crypto-lending">{props.t('Lending')}</Link></li>
                                    <li><Link to="crypto-orders">{props.t('Orders')}</Link></li>
                                    <li><Link to="crypto-kyc-application">{props.t('KYC Application')}</Link></li>
                                    <li><Link to="crypto-ico-landing">{props.t('ICO Landing')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-envelope"></i>
                                    <span>{props.t('Email')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="email-inbox">{props.t('Inbox')}</Link></li>
                                    <li><Link to="email-read">{props.t('Read Email')} </Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-receipt"></i>
                                    <span>{props.t('Invoices')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="invoices-list">{props.t('Invoice List')}</Link></li>
                                    <li><Link to="invoices-detail">{props.t('Invoice Detail')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-briefcase-alt-2"></i>
                                    <span>{props.t('Projects')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="projects-grid">{props.t('Projects Grid')}</Link></li>
                                    <li><Link to="projects-list">{props.t('Projects List')}</Link></li>
                                    <li><Link to="projects-overview">{props.t('Project Overview')}</Link></li>
                                    <li><Link to="projects-create">{props.t('Create New')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-task"></i>
                                    <span>{props.t('Tasks')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="tasks-list">{props.t('Task List')}</Link></li>
                                    <li><Link to="tasks-kanban">{props.t('Kanban Board')}</Link></li>
                                    <li><Link to="tasks-create">{props.t('Create Task')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bxs-user-detail"></i>
                                    <span>{props.t('Contacts')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="contacts-grid">{props.t('User Grid')}</Link></li>
                                    <li><Link to="contacts-list">{props.t('User List')}</Link></li>
                                    <li><Link to="contacts-profile">{props.t('Profile')}</Link></li>
                                </ul>
                            </li>

                            <li className="menu-title">Pages</li>
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-user-circle"></i>
                                    <span>{props.t('Authentication')}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="pages-login">{props.t('Login')}</Link></li>
                                    <li><Link to="pages-register">{props.t('Register')}</Link></li>
                                    <li><Link to="pages-forget-pwd">{props.t('Forget Password')}</Link></li>
                                    <li><Link to="auth-lock-screen">{props.t('Lock Screen')}</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-file"></i>
                                    <span>{props.t('Utility')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="pages-starter">{props.t('Starter Page')}</Link></li>
                                    <li><Link to="pages-maintenance">{props.t('Maintenance')}</Link></li>
                                    <li><Link to="pages-comingsoon">{props.t('Coming Soon')}</Link></li>
                                    <li><Link to="pages-timeline">{props.t('Timeline')}</Link></li>
                                    <li><Link to="pages-faqs">{props.t('FAQs')}</Link></li>
                                    <li><Link to="pages-pricing">{props.t('Pricing')}</Link></li>
                                    <li><Link to="pages-404">{props.t('Error 404')}</Link></li>
                                    <li><Link to="pages-500">{props.t('Error 500')}</Link></li>
                                </ul>
                            </li>

                            <li className="menu-title">{props.t('Components')}</li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-tone"></i>
                                    <span>{props.t('UI Elements')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="ui-alerts">{props.t('Alerts')}</Link></li>
                                    <li><Link to="ui-buttons">{props.t('Buttons')}</Link></li>
                                    <li><Link to="ui-cards">{props.t('Cards')}</Link></li>
                                    <li><Link to="ui-carousel">{props.t('Carousel')}</Link></li>
                                    <li><Link to="ui-dropdowns">{props.t('Dropdowns')}</Link></li>
                                    <li><Link to="ui-grid">{props.t('Grid')}</Link></li>
                                    <li><Link to="ui-images">{props.t('Images')}</Link></li>
                                    <li><Link to="ui-lightbox">{props.t('Lightbox')}</Link></li>
                                    <li><Link to="ui-modals">{props.t('Modals')}</Link></li>
                                    <li><Link to="ui-rangeslider">{props.t('Range Slider')}</Link></li>
                                    <li><Link to="ui-session-timeout">{props.t('Session Timeout')}</Link></li>
                                    <li><Link to="ui-progressbars">{props.t('Progress Bars')}</Link></li>
                                    <li><Link to="ui-sweet-alert">{props.t('Sweet-Alert')}</Link></li>
                                    <li><Link to="ui-tabs-accordions">{props.t('Tabs & Accordions')}</Link></li>
                                    <li><Link to="ui-typography">{props.t('Typography')}</Link></li>
                                    <li><Link to="ui-video">{props.t('Video')}</Link></li>
                                    <li><Link to="ui-general">{props.t('General')}</Link></li>
                                    <li><Link to="ui-colors">{props.t('Colors')}</Link></li>
                                    <li><Link to="ui-rating">{props.t('Rating')}</Link></li>
                                    <li><Link to="ui-notifications">{props.t('Notifications')}</Link></li>
                                    <li><Link to="ui-image-cropper">{props.t('Image Cropper')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="waves-effect">
                                    <i className="bx bxs-eraser"></i>
                                    <span className="badge badge-pill badge-danger float-right">6</span>
                                    <span>{props.t('Forms')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="form-elements">{props.t('Form Elements')}</Link></li>
                                    <li><Link to="form-validation">{props.t('Form Validation')}</Link></li>
                                    <li><Link to="form-advanced">{props.t('Form Advanced')}</Link></li>
                                    <li><Link to="form-editors">{props.t('Form Editors')}</Link></li>
                                    <li><Link to="form-uploads">{props.t('Form File Upload')} </Link></li>
                                    <li><Link to="form-xeditable">{props.t('Form Xeditable')}</Link></li>
                                    <li><Link to="form-repeater">{props.t('Form Repeater')}</Link></li>
                                    <li><Link to="form-wizard">{props.t('Form Wizard')}</Link></li>
                                    <li><Link to="form-mask">{props.t('Form Mask')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-list-ul"></i>
                                    <span>{props.t('Tables')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="tables-basic">{props.t('Basic Tables')}</Link></li>
                                    <li><Link to="tables-datatable">{props.t('Data Tables')}</Link></li>
                                    <li><Link to="tables-responsive">{props.t('Responsive Table')}</Link></li>
                                    <li><Link to="tables-editable">{props.t('Editable Table')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bxs-bar-chart-alt-2"></i>
                                    <span>{props.t('Charts')}</span>
                                </Link>

                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="apex-charts">{props.t('Apex charts')}</Link></li>
                                    <li><Link to="chartist-charts">{props.t('Chartist Chart')}</Link></li>
                                    <li><Link to="chartjs-charts">{props.t('Chartjs Chart')}</Link></li>
                                    <li><Link to="e-charts">{props.t('E Chart')}</Link></li>
                                    <li><Link to="tui-charts">{props.t('Toast UI Chart')}</Link></li>
                                    <li><Link to="sparkline-charts">{props.t('Sparkline Chart')}</Link></li>
                                    <li><Link to="charts-knob">{props.t('Knob Chart')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-aperture"></i>
                                    <span>{props.t('Icons')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="icons-boxicons">{props.t('Boxicons')}</Link></li>
                                    <li><Link to="icons-materialdesign">{props.t('Material Design')}</Link></li>
                                    <li><Link to="icons-dripicons">{props.t('Dripicons')}</Link></li>
                                    <li><Link to="icons-fontawesome">{props.t('Font awesome')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-map"></i>
                                    <span>{props.t('Maps')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="maps-google">{props.t('Google Maps')}</Link></li>
                                    <li><Link to="maps-vector">{props.t('Vector Maps')}</Link></li>
                                    <li><Link to="maps-leaflet">{props.t('Leaflet Maps')}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-share-alt"></i>
                                    <span>{props.t('Multi Level')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li><Link to="#">{props.t('Level 1.1')}</Link></li>
                                    <li><Link to="#" className="has-arrow">{props.t('Level 1.2')}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="#">{props.t('Level 2.1')}</Link></li>
                                            <li><Link to="#">{props.t('Level 2.2')}</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li> */}
            </ul>
          )}

        </div>}
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { userTeacher } = state.Login;
  const { franchise } = state.Franchise;

  return { selectedSchool, userTeacher, franchise };
};

// export default withRouter(withNamespaces()(SidebarContent));
export default connect(mapStatetoProps, { getUserTeacher, setSelecetdSchool, getFranchise })(
  withRouter(withNamespaces()(SidebarContent))
);
