import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Label, Form, FormGroup, Card, CardBody, Input, } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import TopBar from "../Topbar"
import Parse from 'parse';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool, updateInventoryVendor, setSelectedVendor } from '../../../store/actions';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'



const AddVendor = (props) => {

    const matchAdd = matchPath(props.history.location.pathname, {
        path: "/add-inventory-vendor/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatchAdd = matchPath(props.history.location.pathname, {
        path: "/franchise-add-vendor/:franchiseId",
        exact: true,
        strict: false,
    });

    const matchEditVendor = matchPath(props.history.location.pathname, {
        path: "/edit-inventory-vendor/:schoolId/:vendorId",
        exact: true,
        strict: false,
    });

    const franchiseMatchEditVendor = matchPath(props.history.location.pathname, {
        path: "/franchise-edit-inventory-vendor/:franchiseId/:vendorId",
        exact: true,
        strict: false,
    });

    const match = matchEditVendor ?? franchiseMatchEditVendor
    const vendorId = match && match.params.vendorId;

    const match1 = matchAdd ?? matchEditVendor
    const schoolId = match1 && match1.params.schoolId;

    const match2 = franchiseMatchAdd ?? franchiseMatchEditVendor
    const franchiseId = match2 && match2.params.franchiseId;



    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (vendorId) {
            props.setSelectedVendor(vendorId);
        }
    }, [vendorId])


    const [companyName, setCompanyName] = useState()
    const [vendorEmail, setVendorEmail] = useState();
    const [vendorPhone, setVendorPhone] = useState();
    const [displayName, setDisplayName] = useState();
    const [website, setWebsite] = useState();
    const [gst, setGst] = useState();
    const [pan, setPan] = useState();
    const [contactPersonName, setContactPersonName] = useState();
    const [contactPersonPhone, setContactPersonPhone] = useState();
    const [contactPersonEmail, setContactPersonEmail] = useState();
    // const [address, setAddress] = useState();
    //const [remark, setRemark] = useState();
    const [saveLoding, setSaveLoding] = useState(false);

    useEffect(() => {
        if (props.selectedVendor && props.selectedVendor.id == vendorId) {
            setCompanyName(props.selectedVendor.attributes.companyName);
            setVendorEmail(props.selectedVendor.attributes.vendorEmail);
            setVendorPhone(props.selectedVendor.attributes.vendorPhone);
            setDisplayName(props.selectedVendor.attributes.displayName);
            setWebsite(props.selectedVendor.attributes.website);
            setGst(props.selectedVendor.attributes.gst);
            setPan(props.selectedVendor.attributes.pan);
            setContactPersonName(props.selectedVendor.attributes.contactPersonName);
            setContactPersonEmail(props.selectedVendor.attributes.contactPersonEmail);
            setContactPersonPhone(props.selectedVendor.attributes.contactPersonPhone);
        }
    }, [props.selectedVendor])


    function ValidateEmail(value) {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (value.match(mailformat)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handelSave = () => {

        if (!companyName && companyName.trim().length === 0) {
            return;
        }
        let check = false;
        if (vendorEmail && vendorEmail.trim().length > 0) {
            check = true
        }
        if (vendorPhone && vendorPhone.trim().length > 0) {
            check = true
        }
        if (!check) {
            return;
        }

        if (!contactPersonName && contactPersonName.trim().length === 0) {
            return;
        }

        if (contactPersonEmail && contactPersonEmail.trim().length > 0) {
            check = true
        }
        if (contactPersonPhone && contactPersonPhone.trim().length > 0) {
            check = true
        }
        if (!check) {
            return;
        }

        const InventoryVendors = Parse.Object.extend("InventoryVendors");
        var vendor = new InventoryVendors();
        if (vendorId) {
            vendor.id = vendorId;
        } else {
            if (franchiseMatchAdd) {
                vendor.set('ownerId', franchiseId);
                vendor.set('ownerType', "franchise");
            } else {
                vendor.set('ownerId', schoolId);
                vendor.set('ownerType', "school");
            }
            vendor.set('createdBy', Parse.User.current().attributes.teacherId)
        }

        vendor.set("companyName", companyName);
        vendor.set("displayName", displayName);
        vendor.set("vendorEmail", vendorEmail);
        vendor.set("vendorPhone", vendorPhone);
        vendor.set("website", website);
        vendor.set("gst", gst);
        vendor.set("pan", pan);
        vendor.set("contactPersonName", contactPersonName);
        vendor.set("contactPersonPhone", contactPersonPhone);
        vendor.set("contactPersonEmail", contactPersonEmail);
        // vendor.set("address", address);
        //vendor.set("remark", remark)

        console.log(vendor)
        setSaveLoding(true);
        vendor.save().then(
            (result) => {
                result.pin()
                console.log(result)
                props.updateInventoryVendor(result)
                toastr.success("Saved successfully ", { timeOut: 3000 });
                window.history.back();
            },
            (error) => {
                setSaveLoding(false);
                console.log(error);
            }
        );
    }

    function handleSubmit() {
        // console.log(companyName);
        // console.log(displayName);
        // console.log(vendorEmail);
        // console.log(vendorPhone);
        // console.log(website);
        // console.log(gst);
        // console.log(pan);
        // console.log(contactPersonName);
        // console.log(contactPersonPhone);
        // console.log(contactPersonEmail);
        //console.log(address);
        //console.log(remark);
        handelSave();
    }

    return (
        <div className="page-content">

            <TopBar
                title={(matchEditVendor || franchiseMatchEditVendor) ? "Edit Vendor" : "New vendor"}
                buttonName={"Save"}
                hideButton={true} />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit} >
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-text-input" className="col-md-2 col-form-label">Vendor Company Name:</label>
                                        <div className="col-md-10">
                                            <AvField
                                                name="CompanyName"
                                                placeholder="Company Name"
                                                type="text"
                                                value={companyName}
                                                errorMessage="Enter Company Name..."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="companyName"
                                                onChange={(e) => {
                                                    setCompanyName(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-text-input" className="col-md-2 col-form-label">Vendor Name:</label>
                                        <div className="col-md-10">
                                            <AvField
                                                name="Name"
                                                placeholder="Vendor Name"
                                                type="text"
                                                value={displayName}
                                                className="form-control"
                                                id="vendorName"
                                                onChange={(e) => {
                                                    setDisplayName(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="emailId" className="col-md-2 col-form-label">Vendor Email:</label>
                                        <div className="col-md-10">
                                            <AvField
                                                className="form-control"
                                                name='email'
                                                type="email"
                                                id="emailId"
                                                placeholder="Email"
                                                value={vendorEmail}
                                                validate={{
                                                    required: {
                                                        value: !vendorPhone || vendorPhone.length === 0,
                                                        errorMessage: 'Enter Email...',
                                                    },
                                                    pattern: {
                                                        value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                                        errorMessage: "You have entered an invalid email address!",
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    setVendorEmail(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="phoneNumber" className="col-md-2 col-form-label">Vendor Phone:</label>
                                        <div className="col-md-10">
                                            <AvField
                                                className="form-control"
                                                name='phone'
                                                type="number"
                                                id="phoneNumber"
                                                placeholder="Phone Number"
                                                validate={{
                                                    required: {
                                                        value: !vendorEmail || vendorEmail.length === 0,
                                                        errorMessage: 'Enter phone number',
                                                    },
                                                    pattern: {
                                                        value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$",
                                                        errorMessage: "You have entered an invalid phone number!",
                                                    },
                                                    minLength: { value: 10 },
                                                    maxLength: { value: 16 },
                                                }}
                                                value={vendorPhone}
                                                onChange={(e) => {
                                                    setVendorPhone(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-url-input" className="col-md-2 col-form-label">Website:</label>
                                        <div className="col-md-10">
                                            <AvField className="form-control"
                                                name='website'
                                                type="text"
                                                id="websiteName"
                                                placeholder="Website name"
                                                value={website}
                                                onChange={(e) => {
                                                    setWebsite(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-number-input" className="col-md-2 col-form-label">GST Number:</label>
                                        <div className="col-md-10">
                                            <AvField className="form-control"
                                                name='GST'
                                                type="text"
                                                id="GST"
                                                placeholder="GST"
                                                value={gst}
                                                onChange={(e) => {
                                                    setGst(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-number-input" className="col-md-2 col-form-label">PAN:</label>
                                        <div className="col-md-10">
                                            <AvField className="form-control"
                                                name='PAN'
                                                type="text"
                                                id="PAN"
                                                placeholder="PAN"
                                                value={pan}
                                                onChange={(e) => {
                                                    setPan(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <hr />
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-number-input" className="col-md-2 col-form-label">Primary Contact:</label>
                                        <div className="col-md-10">
                                            <AvField className="form-control"
                                                name='Primay contact'
                                                type="text"
                                                id="pname"
                                                placeholder="Name"
                                                value={contactPersonName}
                                                errorMessage="Please Enter  Name...."
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    setContactPersonName(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-search-input" className="col-md-2 col-form-label">Email:</label>
                                        <div className="col-md-10">
                                            <AvField className="form-control"
                                                name='email'
                                                type="text"
                                                id="emailId"
                                                placeholder="Email"
                                                value={contactPersonEmail}
                                                validate={{
                                                    required: {
                                                        value: !contactPersonPhone || contactPersonPhone.length === 0 ? true : false,
                                                        errorMessage: 'Enter Email...',
                                                    },
                                                    pattern: {
                                                        value:
                                                            "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                                        errorMessage: "You have entered an invalid email address!",
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    setContactPersonEmail(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-number-input" className="col-md-2 col-form-label">Phone:</label>
                                        <div className="col-md-10">
                                            <AvField className="form-control"
                                                name='phone'
                                                type="number"
                                                id="phoneNumber"
                                                placeholder="Phone Number"
                                                value={contactPersonPhone}
                                                validate={{
                                                    required: {
                                                        value: !contactPersonEmail || contactPersonEmail.length === 0 ? true : false,
                                                        errorMessage: 'Enter phone number',
                                                    },
                                                    pattern: {
                                                        value:
                                                            "^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$",
                                                        errorMessage: "You have entered an invalid phone no!",
                                                    },
                                                    minLength: { value: 10 },
                                                    maxLength: { value: 16 },

                                                }}
                                                onChange={(e) => {
                                                    setContactPersonPhone(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                {/* <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-number-input" className="col-md-2 col-form-label">Address:</label>
                                        <div className="col-md-10">
                                            <AvField
                                                name='address'
                                                type="textarea"
                                                className='form-control'
                                                rows="4"
                                                placeholder="Write here..."
                                                value={address}
                                                onChange={(e) => {
                                                    setAddress(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <label htmlFor="example-number-input" className="col-md-2 col-form-label">Remark:</label>
                                        <div className="col-md-10">
                                            <Input
                                                type="textarea"
                                                className='form-control'
                                                rows="4"
                                                placeholder="Write here..."
                                                value={remark}
                                                onChange={(e) => {
                                                    setRemark(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup> */}
                                <div className='d-flex justify-content-end'>
                                    {
                                        saveLoding ?

                                            <Button type="button" color="primary" className=" waves-effect float-right">
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>Saving...
                                            </Button> :
                                            <Button type="submit" className='float-right'
                                                color="primary">
                                                SAVE
                                            </Button>

                                    }
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { selectedVendor } = state.InventoryVendor;
    return { selectedSchool, selectedVendor };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, updateInventoryVendor, setSelectedVendor })(AddVendor)));