import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
import {
  getInvoicesForFilters,
  getVerifiedStatusForInvoices,
  deleteSelectedInvoice,
  getUserTeacher,
  setDeleteSuccess,
  notifyPaymentToParent,
} from "../../store/actions";
import { Link } from "react-router-dom";
import ViewTriansaction from "../payment/viewTriansaction";
import ReceivePayment from "../payment/receivePayment";
import ViewInvoice from "../payment/viewInvoice";
import Parse from "parse";

const FeeTab = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [activeTriansaction, setActiveTriansaction] = useState("");
  const [togTriansaction, setTogTriansaction] = useState(false);
  const [activeInvoiceView, setActiveInvoiceView] = useState("");
  const [activeInvoiceId, setActiveInvoiceId] = useState("");
  const [togInvoice, setTogInvoice] = useState(false);
  const [receivePaymentForObj, setReceivePaymentForObj] = useState({});
  const [receivePaymentModal, setReceivePaymentModal] = useState(false);
  const [deleteInvoiceId, setDeleteInvoiceId] = useState(undefined);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const list = ["2022-23", "2021-22", "2020-21", "2019-20", "2018-19", "2017-18"];
  const [teacherPaymenrWrite, setTeacherPaymenrWrite] = useState(false);
  const [paymentAdmin, setPaymentAdmin] = useState(false);

  useEffect(() => {
    props.getUserTeacher(Parse.User.current().attributes.teacherId);
  }, [props.schoolId]);

  useEffect(() => {
    if (props.userTeacher) {
      if (props.userTeacher.attributes.featurePermissions[3] === 2) {
        setTeacherPaymenrWrite(true);
      }
      if (props.userTeacher.attributes.featurePermissions[11] === 1) {
        setPaymentAdmin(true);
      }
      if (Parse.User.current().attributes.isSuperUser) {
        setPaymentAdmin(true);
        setTeacherPaymenrWrite(true);
      }
    }
  }, [props.userTeacher]);

  useEffect(() => {
    if (props.invoiceList) {
      setInvoiceList(props.invoiceList)
    }
  }, [props.invoiceList])



  const tog_triansaction = (id) => {
    if (id) {
      setActiveTriansaction(id);
    } else {
      setActiveTriansaction("");
    }
    if (togTriansaction) {
      getAllInvoices();
    }
    setTogTriansaction(!togTriansaction);
  };
  const tog_invoice = (id) => {
    if (id) {
      setActiveInvoiceView(id);
      setActiveInvoiceId(id);
    } else {
      setActiveInvoiceView("");
      setActiveInvoiceId("");
    }
    if (togInvoice) {
      //getAllInvoices();
    }
    setTogInvoice(!togInvoice);
  };
  const getAllInvoices = () => {
    props.reloadInvoices()
  };
  const activeDropDown = (id) => {
    let tempVals = Object.assign([], invoiceList);
    for (let val of tempVals) {
      if (val.id === id) {
        val.isActionActive = !val.isActionActive;
      } else {
        val.isActionActive = false;
      }
    }
    setInvoiceList(tempVals);
  };
  const tog_receivePayment = (invoice, setEmpty) => {
    if (invoice) {
      setReceivePaymentForObj(invoice);
    } else {
      if (setEmpty) {
        setReceivePaymentForObj({});
      }
    }
    setReceivePaymentModal(!receivePaymentModal);
  };
  const confirmDelete = (id) => {
    setDeleteInvoiceId(id);
    setShowConfirmDelete(true);
  };
  const getInvoice = (id) => {
    for (const val of invoiceList) {
      if (val.id === id) {
        return val;
      }
    }
    return {};
  };
  useEffect(() => {
    if (props.deleteSuccess) {
      setShowConfirmDelete(false);
      setDeleteInvoiceId(undefined);
      props.setDeleteSuccess(false)

      // toastr.success("Invoice Deleted Successfully", { timeOut: 1500 });
    }
  }, [props.deleteSuccess]);
  const handelDeleteInvoice = () => {
    setShowConfirmDelete(false);
    props.deleteSelectedInvoice(deleteInvoiceId, true);
  };
  return (
    <React.Fragment>
      {showConfirmDelete && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Remove"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setShowConfirmDelete(false);
          }}
          onConfirm={() => handelDeleteInvoice()}
        >
          {props.t("Are you sure you want to delete invoice")} <br></br>
        </SweetAlert>
      )}
      {receivePaymentModal && <ReceivePayment
        receivePaymentModal={receivePaymentModal}
        tog_receivePayment={tog_receivePayment}
        activeInvoice={receivePaymentForObj}
        currency={props.currency}
        activeInvoiceId={receivePaymentForObj.id}
        getAllInvoices={getAllInvoices}
        teacherPaymenrWrite={teacherPaymenrWrite}
        paymentAdmin={paymentAdmin}
        schoolId={props.schoolId}
      />}
      {togInvoice && <ViewInvoice
        invoice={getInvoice(activeInvoiceId)}
        togInvoice={togInvoice}
        tog_invoice={tog_invoice}
        activeInvoiceView={activeInvoiceView}
        yearList={list}
        getAllInvoices={getAllInvoices}
        teacherPaymenrWrite={teacherPaymenrWrite}
        currency={props.currency}
        showRoyalty={props.showRoyalty}
        completeDisable={props.completeDisable}
      />}
      {togTriansaction && <ViewTriansaction
        invoice={getInvoice(activeTriansaction)}
        currency={props.currency}
        activeInvoiceId={activeTriansaction}
        togTriansaction={togTriansaction}
        teacherPaymenrWrite={teacherPaymenrWrite}
        tog_triansaction={tog_triansaction}
        paymentAdmin={paymentAdmin}
      />}
      <Table className="table mb-0 table-bordered">
        <thead>
          <tr>
            <th>{props.t("Invoice Detail")}</th>
            <th>{props.t("Date")}</th>
            <th>{props.t("Status")}</th>
            <th>
              {props.t("Payable")}({" "}
              <span style={{ fontFamily: "Arial, sans-serif" }}>
                {props.currency}
              </span>
              )
            </th>
            <th>
              {props.t("Received")}({" "}
              <span style={{ fontFamily: "Arial, sans-serif" }}>
                {props.currency}
              </span>
              )
            </th>
            <th>
              {props.t("Balance")}({" "}
              <span style={{ fontFamily: "Arial, sans-serif" }}>
                {props.currency}
              </span>
              )
            </th>
            <th>{props.t("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {showLoader && (
            <tr>
              <td colSpan="10" className="pt-4 text-center">
                <Link to="#" className="text-success">
                  <i className="bx bx-hourglass bx-spin mr-2"></i> Loading...{" "}
                </Link>
              </td>
            </tr>
          )}
          {invoiceList.length === 0 && !showLoader && (
            <tr>
              <td colSpan="10" className="pt-4">
                <h5 className="text-center text-info">
                  {props.t("No invoices to show")}
                </h5>
              </td>
            </tr>
          )}
          {invoiceList.map((val, key) => (
            <tr key={key}>
              {/* <td></td> */}
              <td>
                {val.invoiceTitle}
                <br />
                {val.invoiceNumber}
              </td>
              <td>{val.date}</td>
              <td>
                <p className={`text-${val.status.color}`}>{val.status.stat}</p>
                {val.showPendingVerify && (
                  <p
                    className="text-danger m-o p-0"
                    style={{ fontSize: "10px", cursor: "pointer" }}
                    onClick={() => tog_triansaction(val.id)}
                  >
                    {props.t("Not Verified")}
                  </p>
                )}
              </td>
              <td>
                <a className="float-right" onClick={() => tog_invoice(val.id)}>
                  {parseFloat(val.totalAmount).toFixed(2)}
                </a>
              </td>
              <td>
                <a
                  className="float-right"
                  onClick={() => val.paidAmount > 0 && tog_triansaction(val.id)}
                >
                  {parseFloat(val.paidAmount).toFixed(2)}
                </a>
              </td>
              <td>
                <a className="float-right">
                  {parseFloat(val.balanceAmount).toFixed(2)}
                </a>
              </td>
              <td>
                <Dropdown
                  isOpen={val.isActionActive}
                  toggle={() => activeDropDown(val.id)}
                >
                  <DropdownToggle tag="button" className="btn btn-secondary">
                    {(val.paidStatus === 0 && !props.disableWrite) ? props.t("Receive Payment") :
                      props.t("Print Invoice")}{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    {val.paidStatus !== 1 && !props.disableWrite && (
                      <DropdownItem onClick={() => tog_receivePayment(val)}>
                        <a className="text-dark">
                          {props.t("Receive Payment")}
                        </a>
                      </DropdownItem>
                    )}

                    {(
                      <DropdownItem>
                        <a className="text-dark" target="_blank" href={`/invoice/${val.id}`}>
                          {props.t("Print Invoice")}
                        </a>
                      </DropdownItem>
                    )}
                    {val.paidStatus !== 0 && !props.disableWrite && (
                      <DropdownItem onClick={() => tog_triansaction(val.id)}>
                        <a className="text-dark">
                          {props.t("View Transactions")}
                        </a>
                      </DropdownItem>
                    )}
                    {val.paidStatus !== 1 && !props.disableWrite && (
                      <DropdownItem
                        onClick={() =>
                          props.notifyPaymentToParent(val.id, val.Obj.attributes.recipientId)
                        }
                      >
                        <a className="text-dark">{props.t("Notify Parents")}</a>
                      </DropdownItem>
                    )}
                    {val.paidAmount === 0 && !props.disableWrite && (
                      <DropdownItem onClick={() => confirmDelete(val.id)}>
                        <a className="text-dark">{props.t("Delete")}</a>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>{" "}
              </td>
            </tr>
          ))}
          {invoiceList.length === 1 && (
            <tr>
              <td colSpan="8"></td>
            </tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {deleteInProgress,deleteSuccess,} = state.Invoice;
  const { userTeacher } = state.Login;
  const { pendingVerifyMT } = state.MoneyTransactions;
  return {
    deleteInProgress,deleteSuccess,
    pendingVerifyMT,
    userTeacher,
  };
};
export default connect(mapStatetoProps, {
  getInvoicesForFilters,
  getVerifiedStatusForInvoices,
  deleteSelectedInvoice,
  getUserTeacher,
  setDeleteSuccess,
  notifyPaymentToParent,
})(withRouter(withNamespaces()(FeeTab)));
