import React, { useEffect, useState } from "react";
import {
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Table
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import Moment from "moment";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import DatePicker from "react-datepicker";
import FeeAnalytics from './FeeAnalytics';
import ProgramClassReport from './ProgramClassReport'
import ProgramTearmsReport from './ProgramTearmsReport'
import Parse from "parse";
import * as Constants from "../../../Constents";
import {
  getInvoicesForFilters,
  getAllKids,
  getClassroomsForSchoolId,
  getAdmissionKidsForSchool,
  getAllDeletedKidForSchool,
  getTriansactionForInvoiceIds,
  getAllTransactionForFilter,
  getInvoiceForIds,
  getTeachersForSchool,
  getProgramsForSchoolId,
  getItemsForInvoiceIds,
  getFeeType,
  getMTDForItemIds,
  getFranchise,
} from "../../../store/actions";
//get discount report for school
//get consoladieate report for school
const FeeReport = (props) => {
  const [reportType, setReportType] = useState("0");
  const [gotReport, gotReportOps] = useState(false);
  const [reportList, reportListOps] = useState([]);
  const [startDate, startDateOps] = useState(new Date());
  const [endDate, endDateOps] = useState(new Date());
  const [activeTag, activeTagOps] = useState("All");
  const [showResults, showResultsOps] = useState(false);

  //util
  const [totalAmount, totalAmountOps] = useState(0);
  const [paidAmount, paidAmountOps] = useState(0);
  const [balanceAmount, balanceAmountOps] = useState(0);

  //dec
  const [allKids, allKidsOps] = useState([]);
  const [allClasses, allClassesOps] = useState([]);
  const [allTeachers, allTeachersOps] = useState([]);
  const [allPrograms, allProgramsOps] = useState([]);
  const [kidDec, kidDecOps] = useState([]);
  const [allInvoice, allInvoiceOps] = useState([]);

  //const
  const [feeTypes, feeTypesOps] = useState([]);

  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);

  const [toDateError, setToDateError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);

  const [onClickApply, setOnClickApply] = useState(false);
  const [onClickProgramClass, setOnClickProgramClass] = useState(false);
  const [transactionStats, setTransactionStats] = useState({});

  const [showRoyalty, setShowRoyalty] = useState(false)

  const [loading, setLoading]=useState(false)

  const handelDateChange = (bool) => {
    setOnClickApply(bool);
  };

  const yearList = [
    "All",
    "2024-25",
    "2023-24",
    "2022-23",
    "2021-22",
    "2020-21",
  ];
  const filterTypes = [
    "Invoice",
    "Transaction",
    "Program",
    "Consolidated",
    "Discount",
    "Analytics",
    "Program Consolidated",
    "Program Term analytics",
  ];

  useEffect(() => {
    if (Parse.User && Parse.User.current() && Parse.User.current().attributes.franchiseId) {
      props.getFranchise(Parse.User.current().attributes.franchiseId);
    }

  }, []);

  useEffect(() => {
    if (props.franchise && props.franchise.attributes.features) {
      if (props.franchise.attributes.features.length > Constants.FRANCHISE_ROYALTY_SETTING_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_ROYALTY_SETTING_INDEX] > 0) {
        setShowRoyalty(true)
      }
    }
  }, [props.franchise]);

  useEffect(() => {
    if (props.schoolId) {
      props.getAllKids(props.schoolId);
      props.getAdmissionKidsForSchool(props.schoolId);
      props.getAllDeletedKidForSchool(props.schoolId);
      props.getClassroomsForSchoolId(props.schoolId);
      props.getTeachersForSchool(props.schoolId);
      props.getProgramsForSchoolId(props.schoolId);
      props.getFeeType();
    }
  }, [props.schoolId]);
  useEffect(() => {
    let tempTeacher = [];
    for (const val of props.teachers) {
      let temp = {};
      temp.id = val.id;
      temp.name = val.attributes.Name;
      temp.obj = val;
      tempTeacher.push(temp);
    }
    allTeachersOps(tempTeacher);
  }, [props.teachers]);
  useEffect(() => {
    let tempProgram = [];
    tempProgram.push({ id: "All", name: "All" });
    for (const val of props.programs) {
      let temp = {};
      temp.id = val.id;
      temp.name = val.attributes.name;
      temp.obj = val;
      tempProgram.push(temp);
    }
    allProgramsOps(tempProgram);
  }, [props.programs]);
  useEffect(() => {
    let tempType = [];
    for (const val of props.feeTypes) {
      let temp = {};
      temp.id = val.id;
      temp.name = val.attributes.name;
      temp.obj = val;
      tempType.push(temp);
    }
    feeTypesOps(tempType);
  }, [props.feeTypes]);

  const generateReport = () => {
    setLoading(true);
    let filters = {};
    filters.containedIn = false;
    gotReportOps(false);
    if (reportType === "0" || reportType === "3" || reportType === "4") {
      filters.attributes = "schoolId";
      filters.value = props.schoolId;
      filters.containedIn = false;
      if (startDate && reportType !== "3") {
        filters.fromDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          0,
          0
        );
      }
      if (endDate && reportType !== "3") {
        filters.toDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59
        );
      }
      if (activeTag !== "All") {
        let tempTags = [];
        if (activeTag === "2017-18") {
          tempTags.push("2017 - 18");
          tempTags.push("2017-18");
          tempTags.push("2017- 18");
          tempTags.push("2017 -18");
        } else if (activeTag === "2018-19") {
          tempTags.push("2018 - 19");
          tempTags.push("2018-19");
          tempTags.push("2018- 19");
          tempTags.push("2018 -19");
        } else if (activeTag === "2019-20") {
          tempTags.push("2019 - 20");
          tempTags.push("2019-20");
          tempTags.push("2019- 20");
          tempTags.push("2019 -20");
        } else if (activeTag === "2020-21") {
          tempTags.push("2020 - 21");
          tempTags.push("2020-21");
          tempTags.push("2020- 21");
          tempTags.push("2020 -21");
        } else if (activeTag === "2021-22") {
          tempTags.push("2021 - 22");
          tempTags.push("2021-22");
          tempTags.push("2021- 22");
          tempTags.push("2021 -22");
        } else if (activeTag === "2022-23") {
          tempTags.push("2022 - 23");
          tempTags.push("2022-23");
          tempTags.push("2022- 23");
          tempTags.push("2022 -23");
        } else if (activeTag === "2023-24") {
          tempTags.push("2023 - 24");
          tempTags.push("2023-24");
          tempTags.push("2023- 24");
          tempTags.push("2023 -24");
        } else if (activeTag === "2024-25") {
          tempTags.push("2024 - 25");
          tempTags.push("2024-25");
          tempTags.push("2024- 25");
          tempTags.push("2024 -25");
        }
        filters.tags = tempTags;
      }
      filters.limit = 1000;
      filters.skipValue = 1;
      props.getInvoicesForFilters(filters, props.schoolId);
      showResultsOps(true);
    } else if (reportType === "1") {
      let filters = {};
      filters.schoolId = props.schoolId;
      if (startDate) {
        filters.fromDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          0,
          0
        );
      }
      if (endDate) {
        filters.toDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59
        );
      }
      if (activeTag) {
        let tempTags = [];
        if (activeTag === "2017-18") {
          tempTags.push("2017 - 18");
          tempTags.push("2017-18");
          tempTags.push("2017- 18");
          tempTags.push("2017 -18");
        } else if (activeTag === "2018-19") {
          tempTags.push("2018 - 19");
          tempTags.push("2018-19");
          tempTags.push("2018- 19");
          tempTags.push("2018 -19");
        } else if (activeTag === "2019-20") {
          tempTags.push("2019 - 20");
          tempTags.push("2019-20");
          tempTags.push("2019- 20");
          tempTags.push("2019 -20");
        } else if (activeTag === "2020-21") {
          tempTags.push("2020 - 21");
          tempTags.push("2020-21");
          tempTags.push("2020- 21");
          tempTags.push("2020 -21");
        } else if (activeTag === "2021-22") {
          tempTags.push("2021 - 22");
          tempTags.push("2021-22");
          tempTags.push("2021- 22");
          tempTags.push("2021 -22");
        } else if (activeTag === "2022-23") {
          tempTags.push("2022 - 23");
          tempTags.push("2022-23");
          tempTags.push("2022- 23");
          tempTags.push("2022 -23");
        } else if (activeTag === "2023-24") {
          tempTags.push("2023 - 24");
          tempTags.push("2023-24");
          tempTags.push("2023- 24");
          tempTags.push("2023 -24");
        }
        filters.tags = tempTags;
      }
      filters.limit = 1000;
      filters.skipValue = 1;
      props.getAllTransactionForFilter(filters);
    } else if (reportType === "2") {
      filters.containedIn = true;
      filters.attributes = "programId";
      filters.value = [];
      if (activeTag === "All") {
        for (const val of allPrograms) {
          filters.value.push(val.id);
        }
      } else {
        filters.value.push(activeTag);
      }
      filters.schoolId = props.schoolId;
      filters.fromDate = startDate;
      filters.toDate = endDate;
      filters.limit = 1000;
      filters.skipValue = 1;
      props.getInvoicesForFilters(filters, props.schoolId);
    } else if (reportType === "6") {
      setOnClickProgramClass(true)
    }
  };
  useEffect(() => {
    allInvoiceOps([]);
    if (reportType === "0" || reportType === "2") {
      let tempList = [];
      let totalAmount = 0;
      let totalRecived = 0;
      let tempInvoiceIds = [];
      for (const val of props.invoices) {
        let temp = {};
        let gotKid = false;
        tempInvoiceIds.push(val.id);
        temp.id = val.id;
        temp.obj = val;
        temp.programId = val.attributes.programId;
        if (val.attributes.programId) {
          for (const prog of allPrograms) {
            if (prog.id === val.attributes.programId) {
              temp.programName = prog.name;
              temp.programObj = prog;
            }
          }
        }
        for (const kid of allKids) {
          if (kid.id === val.attributes.recipientId) {
            temp.name = kid.attributes.Name;
            temp.kidObj = kid;
            gotKid = true;
            break;
          }
        }
        if (!gotKid) {
          temp.name = "--";
        } else {
          if (temp.kidObj.attributes.ClassRoomID) {
            let gotValue = false;
            for (const classVal of allClasses) {
              if (classVal.id === temp.kidObj.attributes.ClassRoomID) {
                temp.className = classVal.obj.attributes.Name;
                temp.classObj = classVal.obj;
                gotValue = true;
                break;
              }
            }
            if (!gotValue) {
              temp.className = "--";
            }
          } else {
            temp.className = "Admission";
          }
        }
        temp.invoiceNumber = val.attributes.invoiceNumber;
        temp.date = getDateInDDMMYYYY(val.createdAt);
        temp.status = getPaidStatus(val.attributes.paidStatus);
        temp.payable = +val.attributes.amount;
        temp.paid = val.attributes.paidAmount ? val.attributes.paidAmount : 0;

        totalAmount += val.attributes.amount ? +val.attributes.amount : 0;
        totalRecived += val.attributes.paidAmount
          ? +val.attributes.paidAmount
          : 0;
        tempList.push(temp);
      }
      if (tempInvoiceIds.length > 0) {
        props.getTriansactionForInvoiceIds(tempInvoiceIds);
      }
      reportListOps(tempList);
      totalAmountOps(totalAmount.toFixed(2));
      paidAmountOps(totalRecived.toFixed(2));
      balanceAmountOps(totalAmount.toFixed(2) - totalRecived.toFixed(2));
    } else if (reportType === "1") {
      let tempTrainsaction = Object.assign([], reportList);
      for (const val of tempTrainsaction) {
        for (const invoice of props.invoices) {
          let gotKid = false;
          if (val.invoiceId === invoice.id) {
            val.invoiceObj = invoice;
            val.invoiceNumber = invoice.attributes.invoiceNumber;
            for (const kid of allKids) {
              if (kid.id === invoice.attributes.recipientId) {
                val.name = kid.attributes.Name;
                val.kidObj = kid;
                gotKid = true;
                break;
              }
            }
            if (!gotKid) {
              val.name = "--";
            } else {
              if (val.kidObj.attributes.ClassRoomID) {
                let gotValue = false;
                for (const classVal of allClasses) {
                  if (classVal.id === val.kidObj.attributes.ClassRoomID) {
                    val.className = classVal.obj.attributes.Name;
                    val.classObj = classVal.obj;
                    gotValue = true;
                    break;
                  }
                }
                if (!gotValue) {
                  val.className = "--";
                }
              } else {
                val.className = "Admission";
              }
            }
            break;
          }
        }
      }
      reportListOps(tempTrainsaction);
    } else if (reportType === "3") {
      let tempList = [];
      for (const kid of allKids) {
        let temp = {};
        temp.id = kid.id;
        temp.name = kid.attributes.Name;
        temp.kidObj = kid;
        if (kid.attributes.ClassRoomID) {
          for (const classRoom of allClasses) {
            if (classRoom.id === kid.attributes.ClassRoomID) {
              temp.className = classRoom.name;
              temp.classObj = classRoom;
              break;
            }
          }
        } else {
          temp.className = "Admission";
        }
        temp.invoices = [];
        temp.totalRised = 0;
        temp.totalRevived = 0;
        temp.totalPending = 0;

        temp.numberOfInvoice = 0;
        temp.totalRecivedInvoice = 0;
        for (const invoice of props.invoices) {
          if (invoice.attributes.recipientId === kid.id) {
            temp.invoices.push(invoice);
            temp.numberOfInvoice += 1;
            temp.totalRised += +invoice.attributes.amount;
            temp.totalPending += +invoice.attributes.amount;
            if (invoice.attributes.paidAmount) {
              temp.totalRevived += invoice.attributes.paidAmount;
              temp.totalPending -= invoice.attributes.paidAmount;
            }
          }
        }
        temp.payable = temp.totalRised;
        temp.paid = temp.totalRevived;
        tempList.push(temp);
      }
      let invoiceIds = [];
      for (const val of props.invoices) {
        invoiceIds.push(val.id);
      }
      props.getItemsForInvoiceIds(invoiceIds);
      //all items cat
      reportListOps(tempList);
    } else if (reportType === "4") {
      let invoiceIds = [];
      for (const val of props.invoices) {
        invoiceIds.push(val.id);
      }
      allInvoiceOps(props.invoices);
      if (invoiceIds.length > 0) {
        props.getItemsForInvoiceIds(invoiceIds, true);
      }
    }
    setLoading(false);
  }, [props.invoices]);
  useEffect(() => {
    // if(props.kids.length > 0 && props.admissionKids.length > 0){
    let tempKids = [];
    let tempKidObj = [];
    for (const val of props.kids) {
      tempKids[val.id] = val;
      tempKidObj.push(val);
    }
    for (const val of props.admissionKids) {
      tempKids[val.id] = val;
      tempKidObj.push(val);
    }
    for (const val of props.allDeletedKids) {
      tempKids[val.id] = val;
      tempKidObj.push(val);
    }
    kidDecOps(tempKids);
    allKidsOps(tempKidObj);
    // }`
  }, [props.kids, props.admissionKids, props.allDeletedKids]);
  useEffect(() => {
    let tempClass = [];
    for (const val of props.classrooms) {
      let temp = {};
      temp.id = val.id;
      temp.name = val.attributes.Name;
      temp.type = val.attributes.classroomType;
      temp.obj = val;
      tempClass.push(temp);
    }
    allClassesOps(tempClass);
  }, [props.classrooms]);
  const getPaidStatus = (status) => {
    if (status === 1) {
      return "Paid";
    } else if (status === 3) {
      return "Partially Paid";
    } else {
      return "Pending";
    }
  };
  const getDateInDDMMYYYY = (date) => {
    return Moment(date).format("DD/MM/YYYY");
  };
  useEffect(() => {
    if (!props.showLoader || props.gotAllTransaction) {
      gotReportOps(true);
    } else {
      gotReportOps(false);
    }
  }, [props.showLoader, props.gotAllTransaction]);
  useEffect(() => {
    if (props.moneyTransactionsForInvoiceIds.length > 0) {
      let tempAllInvoices = Object.assign([], reportList);
      for (const val of tempAllInvoices) {
        val.triansactions = [];
        for (const mt of props.moneyTransactionsForInvoiceIds) {
          if (mt.attributes.invoiceId === val.id) {
            let temp = {};
            temp.type = mt.attributes.transactionType;
            temp.amount = mt.attributes.amount;
            temp.date = getDateInDDMMYYYY(mt.attributes.paidDate);
            temp.transactionId = mt.attributes.transactionId;
            temp.remark = mt.attributes.remarks;
            temp.obj = mt;
            val.triansactions.push(temp);
          }
        }
      }
      reportListOps(tempAllInvoices);
    }
  }, [props.moneyTransactionsForInvoiceIds]);

  useEffect(() => {
    let tAmount = 0;
    let successAmount = 0;
    let outStandingAmount = 0;
    let cAmount = 0;
    let oAmount = 0;
    let chAmount = 0;
    let apAmount = 0;
    let gpAmount = 0;
    let ccAmount = 0;
    let puAmount = 0;
    let rpAmount = 0;
    let opAmount = 0;
    let cancelAmount = 0;
    let schoolAmount = 0;
    let royaltyAmount = 0;
    let tempTrainsaction = [];
    let invoiceIds = [];
    for (const val of props.moneyTransactions) {
      let temp = {};
      temp.id = val.id;
      temp.obj = val;
      temp.name = "";
      temp.invoiceNumber = "";
      temp.amount = val.attributes.amount;
      temp.schoolAmount = val.attributes.schoolAmount;
      temp.royaltyAmount = val.attributes.royaltyAmount;
      temp.paid = val.attributes.amount;
      temp.jsPaidDate = val.attributes.paidDate
        ? val.attributes.paidDate
        : val.attributes.createdAt;
      temp.date = Moment(temp.jsPaidDate).format("DD-MMM-YYYY");
      temp.type = val.attributes.transactionType;
      temp.invoiceId = val.attributes.invoiceId;
      invoiceIds.push(val.attributes.invoiceId);
      temp.recivedBy = val.attributes.receivedBy;
      for (const teacher of allTeachers) {
        if (temp.recivedBy === teacher.id) {
          temp.teacherName = teacher.name;
          temp.teacherObj = teacher;
        }
      }
      temp.verifiedBy = val.attributes.verifiedBy;
      if (val.attributes.deleted) {
        temp.deletedBy = val.attributes.deletedBy;
        temp.deleted = true;
      }
      temp.status = val.attributes.status
        ? val.attributes.status === 1
          ? "Outstanding"
          : val.attributes.status === 2
            ? "Verified"
            : "Success"
        : "Success";
      if (temp.deleted) {
        temp.status = "Cancelled";
      }
      if (val.attributes.transactionType === "Cheque") {
        temp.transactionId = val.attributes.chequeNo;
      } else if (val.attributes.transactionType === "Online") {
        temp.transactionId = val.attributes.transactionId;
      } else if (val.attributes.transactionType === "AirPay" || val.attributes.transactionType === "RazorPay" || val.attributes.transactionType === "OnePay") {
        temp.transactionId = val.attributes.transactionId;
      }
      temp.remark = val.attributes.transactionStatus;
      if (temp.status != "Cancelled") {

        tAmount += temp.amount;
        if (typeof temp.schoolAmount === 'number') {
          schoolAmount += temp.schoolAmount
        }
        if (typeof temp.royaltyAmount === 'number') {
          royaltyAmount += temp.royaltyAmount
        }
        if (temp.status == "Outstanding") {
          outStandingAmount += temp.amount;
        } else {
          successAmount += temp.amount;
        }

        if (temp.type == "AirPay") {
          apAmount += temp.amount;
        } else if (temp.type == "RazorPay") {
          rpAmount += temp.amount;
        } else if (temp.type == "OnePay") {
          opAmount += temp.amount;
        } else if (temp.type == "PayU") {
          puAmount += temp.amount;
        } else if (temp.type == "CCAvenue") {
          ccAmount += temp.amount;
        } else if (temp.type == "GooglePay") {
          gpAmount += temp.amount;
        } else if (temp.type == "Online") {
          oAmount += temp.amount;
        } else if (temp.type == "Cheque") {
          chAmount += temp.amount;
        } else {
          cAmount += temp.amount;
        }
      } else {
        cancelAmount += temp.amount;
      }
      tempTrainsaction.push(temp);
    }
    reportListOps(tempTrainsaction);
    setTransactionStats({
      tAmount: tAmount,
      outStandingAmount: outStandingAmount,
      successAmount: successAmount,
      schoolAmount: schoolAmount,
      royaltyAmount: royaltyAmount,
      cAmount: cAmount,
      oAmount: oAmount,
      chAmount: chAmount,
      apAmount: apAmount,
      cancelAmount: cancelAmount,
      gpAmount: gpAmount,
      ccAmount: ccAmount,
      puAmount: puAmount,
      rpAmount: rpAmount,
      opAmount: opAmount,
    });
    showResultsOps(true)
    if (invoiceIds.length > 0) {
      props.getInvoiceForIds(invoiceIds);
    }
  }, [props.moneyTransactions]);

  useEffect(() => {
    if (reportType === "3") {
      if (props.invoiceItems.length > 0) {
        let tempAllInvoices = Object.assign([], reportList);
        for (const val of tempAllInvoices) {
          val.items = [];
          for (const type of feeTypes) {
            let temp = {};
            temp.itemIds = [];
            temp.id = type.id;
            temp.title = type.name;
            temp.paid = 0;
            temp.totalAmount = 0;
            val.items.push(temp);
          }
          let temp = {};
          temp.itemIds = [];
          temp.id = "";
          temp.title = "Other";
          temp.paid = 0;
          temp.totalAmount = 0;
          val.items.push(temp);
        }
        for (const val of tempAllInvoices) {
          for (const invoice of val.invoices) {
            for (const item of props.invoiceItems) {
              if (invoice.id === item.attributes.invoiceId) {
                if (item.attributes.feeType) {
                  for (const type of val.items) {
                    if (type.id === item.attributes.feeType) {
                      type.itemIds.push(item.id);
                      type.totalAmount += item.attributes.totalAmount;
                      break;
                    }
                  }
                } else {
                  val.items[val.items.length - 1].itemIds.push(item.id);
                  val.items[val.items.length - 1].totalAmount +=
                    item.attributes.totalAmount;
                }
              }
            }
          }
        }
        let itemIds = [];
        for (const val of props.invoiceItems) {
          itemIds.push(val.id);
        }
        if (itemIds.length > 0) {
          props.getMTDForItemIds(itemIds);
        }
        reportListOps(tempAllInvoices);
      }
    } else if (reportType === "4") {
      let tempList = [];
      for (const val of props.invoiceItems) {
        let temp = {};
        temp.id = val.id;
        temp.obj = val;
        temp.itemDescription = val.attributes.itemDescription;
        temp.itemAmount = val.attributes.amount;
        temp.discountAmount = getDiscountAmount(
          val.attributes.discountType,
          val.attributes.discount,
          val.attributes.amount
        );
        if (val.attributes.discountGivenBy) {
          for (const teacher of allTeachers) {
            if (teacher.id === val.attributes.discountGivenBy) {
              temp.discountGivenBy = teacher.name;
              break;
            }
          }
        } else {
          temp.discountGivenBy = "--";
        }
        temp.remark = val.attributes.discountRemark;
        for (const invoice of allInvoice) {
          if (invoice.id === val.attributes.invoiceId) {
            let gotKid = false;
            temp.invoiceNumber = invoice.attributes.invoiceNumber;
            for (const kid of allKids) {
              if (kid.id === invoice.attributes.recipientId) {
                temp.name = kid.attributes.Name;
                temp.kidObj = kid;
                gotKid = true;
                break;
              }
            }
            if (!gotKid) {
              temp.name = "--";
            } else {
              if (temp.kidObj.attributes.ClassRoomID) {
                let gotValue = false;
                for (const classVal of allClasses) {
                  if (classVal.id === temp.kidObj.attributes.ClassRoomID) {
                    temp.className = classVal.obj.attributes.Name;
                    temp.classObj = classVal.obj;
                    gotValue = true;
                    break;
                  }
                }
                if (!gotValue) {
                  temp.className = "--";
                }
              } else {
                temp.className = "Admission";
              }
            }
            break;
          }
        }
        tempList.push(temp);
      }
      reportListOps(tempList);
    }
  }, [props.invoiceItems]);
  useEffect(() => {
    if (reportType === "3") {
      if (props.triansactionsItems.length > 0) {
        setTimeout(() => {
          let tempAllInvoices = Object.assign([], reportList);
          for (const val of tempAllInvoices) {
            if (val.items) {
              for (const item of val.items) {
                for (const tItem of props.triansactionsItems) {
                  if (item.itemIds.includes(tItem.attributes.itemId)) {
                    item.paid += tItem.attributes.amount;
                  }
                }
              }
            }
          }
          reportListOps(tempAllInvoices);
        }, 800);
      }
    }
  }, [props.triansactionsItems]);
  const downloadTheReport = () => {
    let rawData = reportList;
    let finalData = [];
    let titles = [];
    let fileName = "";
    let sheetName = "";
    if (reportType === "0" || reportType === "2") {
      fileName = `Invoice Report ${Moment(startDate).format(
        "DD/MM/YYYY"
      )}-${Moment(endDate).format("DD/MM/YYYY")}.xlsx`;
      sheetName = "Fee Report";

      finalData.push([`Report Type:`, filterTypes[reportType]]);
      finalData.push([`Year Tag:`, activeTag]);
      finalData.push([
        `Date Range:`,
        `${Moment(startDate).format("DD/MM/YYYY")}-${Moment(endDate).format(
          "DD/MM/YYYY"
        )}`,
      ]);
      finalData.push([]);
      finalData.push([`Total Amount:`, totalAmount]);
      finalData.push([`Paid Amount:`, paidAmount]);
      finalData.push([`Balance Amount:`, balanceAmount]);
      if (reportType === "0") {
        titles = [
          "Date",
          "Receipent",
          "Class",
          "Invoice Number",
          "Title",
          "Status",
          "Payable",
          "Received",
          "Balance",
          "Transactions",
        ];
      } else if (reportType === "2") {
        titles = [
          "Date",
          "Receipent",
          "Class",
          "Program Name",
          "Invoice Number",
          "Title",
          "Status",
          "Payable",
          "Received",
          "Balance",
          "Transactions",
        ];
      }
      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(Moment(val.obj.attributes.createdAt).format("DD/MM/YYYY"));
        temp.push(val.name);
        temp.push(val.className);
        if (reportType === "2") {
          temp.push(val.programName);
        }
        temp.push(val.invoiceNumber);
        temp.push(val.obj.attributes.title);
        temp.push(val.status);
        temp.push(val.payable);
        temp.push(val.paid);
        temp.push(val.payable - val.paid);
        if (val.triansactions && val.triansactions.length > 0) {
          val.triansactions.map((mt, key) => {
            temp.push(`Transaction ${key + 1} :`);
            temp.push(mt.date);
            temp.push(mt.type);
            temp.push(mt.amount);
          });
        } else {
          temp.push(null);
        }
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    } else if (reportType === "1") {
      fileName = `Transactions Report ${Moment(startDate).format(
        "DD/MM/YYYY"
      )}-${Moment(endDate).format("DD/MM/YYYY")}.xlsx`;
      sheetName = "Transactions Report";
      finalData.push([`Report Type:`, filterTypes[reportType]]);
      finalData.push([`Year Tag:`, activeTag]);
      finalData.push([
        `Date Range:`,
        `${Moment(startDate).format("DD/MM/YYYY")}-${Moment(endDate).format(
          "DD/MM/YYYY"
        )}`,
      ]);

      finalData.push(["Total Transaction Amount: ", transactionStats.tAmount]);
      { transactionStats.outStandingAmount > 0 && finalData.push(["Outstanding Amount:", transactionStats.outStandingAmount]) };
      { transactionStats.outStandingAmount > 0 && finalData.push(["Collected Amount:", transactionStats.successAmount]) };
      { showRoyalty && finalData.push(["School Amount:", transactionStats.schoolAmount.toFixed(2)]) }
      { showRoyalty && finalData.push(["Royalty Amount:", transactionStats.royaltyAmount.toFixed(2)]) }

      finalData.push([]);

      finalData.push(["Cash: ", transactionStats.cAmount]);
      finalData.push(["Online:", transactionStats.oAmount]);
      finalData.push(["Cheque:", transactionStats.chAmount]);
      finalData.push(["AirPay(Payment Gateway) :", transactionStats.apAmount]);
      finalData.push(["RazorPay(Payment Gateway) :", transactionStats.rpAmount]);
      finalData.push(["OnePay(Payment Gateway) :", transactionStats.opAmount]);
      finalData.push(["Cancelled Amount:", transactionStats.cancelAmount]);

      { transactionStats.gpAmount > 0 && finalData.push(["GooglePay:", transactionStats.gpAmount]) };
      { transactionStats.puAmount > 0 && finalData.push(["PayU:", transactionStats.puAmount]) };
      { transactionStats.ccAmount > 0 && finalData.push(["CCAvenue", transactionStats.ccAmount]) };

      finalData.push([]);

      titles = [
        "Date",
        "Name",
        "Class",
        "Invoice Number",
        "Title",
        "ReceivedBy",
        "Status",
        "Transaction Id",
        "type",
        "Amount",
        "Details",
        "Gateway ref Id",
      ];
      if (showRoyalty) {
        titles = [
          "Date",
          "Name",
          "Class",
          "Invoice Number",
          "Title",
          "ReceivedBy",
          "Status",
          "Transaction Id",
          "type",
          "Amount",
          "School Amount",
          "Royalty Amount",
          "Details",
          "Gateway ref Id",
        ];
      }
      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(val.date);
        temp.push(val.name);
        temp.push(val.className);
        temp.push(val.invoiceNumber);
        temp.push(val.invoiceObj ? val.invoiceObj.attributes.title : null);
        temp.push(val.teacherName);
        temp.push(val.status);
        temp.push(val.transactionId);
        temp.push(val.type);
        temp.push(val.amount);
        {showRoyalty&&temp.push(val.obj.attributes.schoolAmount);}
        {showRoyalty && temp.push(val.obj.attributes.royaltyAmount);}
        temp.push(val.obj.attributes.remark ? val.obj.attributes.remark : null);
        temp.push( (val.type =="AirPay" ||val.type == "OnePay") ? val.id : (val.type == 'RazorPay' ? val.obj.attributes.razorpayId : ""));
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    } else if (reportType === "3") {
      fileName = `Consolidated Report.xlsx`;
      sheetName = `Consolidated for ${activeTag}`;
      finalData.push([`Report Type:`, filterTypes[reportType]]);
      finalData.push([
        `Year Tag:`,
        activeTag,
        null,
        null,
        null,
        null,
        "Recived/Rised",
      ]);
      finalData.push([]);
      titles = [
        "Name",
        "Class",
        "Number Of Invoice",
        "Total Amount",
        "Paid Amount",
        "Pending Amount",
      ];
      for (const fee of feeTypes) {
        titles.push(fee.name);
      }
      titles.push("Other");
      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(val.name ? val.name : null);
        temp.push(val.className ? val.className : null);
        temp.push(val.numberOfInvoice);
        temp.push(val.totalRised);
        temp.push(val.paid);
        temp.push(val.totalPending);
        for (const item of val.items) {
          temp.push(`${item.paid} / ${item.totalAmount}`);
        }
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    } else if (reportType === "4") {
      fileName = `Discount Report ${Moment(startDate).format(
        "DD/MM/YYYY"
      )}-${Moment(endDate).format("DD/MM/YYYY")}.xlsx`;
      sheetName = "Discount Report";
      finalData.push([`Report Type:`, filterTypes[reportType]]);
      finalData.push([`Year Tag:`, activeTag]);
      finalData.push([
        `Date Range:`,
        `${Moment(startDate).format("DD/MM/YYYY")}-${Moment(endDate).format(
          "DD/MM/YYYY"
        )}`,
      ]);
      finalData.push([]);
      titles = [
        "Name",
        "Class",
        "Invoice Number",
        "Item Description",
        "Item Amount",
        "Discount Amount",
        "Discount Given By",
        "Remark",
      ];
      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(val.name);
        temp.push(val.className);
        temp.push(val.invoiceNumber);
        temp.push(val.itemDescription);
        temp.push(val.itemAmount);
        temp.push(val.discountAmount);
        temp.push(val.discountGivenBy);
        temp.push(val.remark);
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    }
  };
  const getDiscountAmount = (discountType, discount, itemAmount) => {
    return discountType == 1 ? discount : (itemAmount * discount) / 100;
  };
  return (
    <React.Fragment>
      <CardTitle>{props.t("Fee Report")}</CardTitle>
      <CardSubtitle className="mb-3">
        {props.t("Select filter to generate the report")}
      </CardSubtitle>
      <Row className="bg-light">
        <Col md="2" xs="12" xl="2" lg="2">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>{props.t("Report Type")} :</Label>
            <select
              className="form-control "
              value={reportType}
              onChange={(e) => {
                console.log(e.target.value);
                setReportType(e.target.value);
                gotReportOps(false);
                reportListOps([]);
                activeTagOps("All");
                if (e.target.value === "3") {
                  startDateOps();
                  endDateOps();
                } if (e.target.value === "5") {
                  setOnClickApply(false);
                } if (e.target.value === "6") {
                  setOnClickProgramClass(false);
                } else {
                  startDateOps(new Date());
                  endDateOps(new Date());
                }
              }}
            >
              {filterTypes.map((val, key) => (
                <option value={key} key={key}>
                  {props.t(val)}
                </option>
              ))}
            </select>
          </FormGroup>
        </Col>
        {reportType === "5" &&
          <Col >
            <Row className=" bg-light ml-2">

              <Row>
                <Col lg={4} xs={6}>
                  <FormGroup className="mt-2 mb-2 p-1">
                    <Label >
                      {props.t("From")} :
                    </Label>
                    <DatePicker
                      selected={fromDate}
                      onChange={(date) => {
                        onClickApply && handelDateChange(false);
                        setFromDateError(false)
                        setFromDate(date)
                      }}
                      className="form-control"
                      placeholderText={props.t("Select Date")}
                      startDate={fromDate}
                      endDate={toDate}
                      maxDate={toDate}
                      dateFormat="MMM-yyyy"
                      required
                      errorMessage="Please select"
                      showMonthYearPicker
                    />
                  </FormGroup>
                  {fromDateError && <p className="text-danger ml-2">Please select</p>}
                </Col>
                <Col lg={4} xs={6}>
                  <FormGroup className="mt-2 mb-2 p-1">
                    <Label >
                      {props.t("To")} :
                    </Label>
                    <DatePicker
                      selected={toDate}
                      onChange={(date) => {
                        onClickApply && handelDateChange(false);
                        setToDateError(false);
                        setToDate(date)
                      }}
                      className="form-control"
                      placeholderText={props.t("Select Date")}
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      dateFormat="MMM-yyyy"
                      required
                      errorMessage="Please select"
                      showMonthYearPicker
                    />
                  </FormGroup>
                  {toDateError && <p className="text-danger ml-2">Please select</p>}
                </Col>
                <Col lg={3} >
                  <div className="mt-4 mb-1 p-1">
                    <button
                      // disabled={loadingState === 2}
                      onClick={(e, v) => {
                        let next = true;
                        if (!toDate) {
                          setToDateError(true);
                          next = false;
                        }
                        if (!fromDate) {
                          setFromDateError(true);
                          next = false;
                        }
                        if (next) {
                          handelDateChange(true);
                        }

                      }}
                      className="w-md mt-2  btn btn-primary"
                    >
                      {props.t("Apply")}
                    </button>

                  </div>
                </Col>
              </Row>

            </Row>
          </Col>
        }

        {reportType !== "2" && reportType !== "5" && reportType !== "6" && reportType != "7" && (
          <Col md="2" xs="12" xl="2" lg="2">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>{props.t("Year Tag")}:</Label>
              <select
                className="form-control "
                value={activeTag}
                onChange={(e) => {
                  activeTagOps(e.target.value);
                }}
              >
                {yearList.map((val, key) => (
                  <option value={val} key={key}>
                    {val}
                  </option>
                ))}
              </select>
            </FormGroup>
          </Col>
        )}
        {reportType === "2" && reportType !== "5" && reportType !== "6" && reportType != "7" && (
          <Col md="2" xs="12" xl="2" lg="2">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>{props.t("Program List")}:</Label>
              <select
                className="form-control "
                value={activeTag}
                onChange={(e) => {
                  activeTagOps(e.target.value);
                }}
              >
                {allPrograms.map((val, key) => (
                  <option value={val.id} key={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
            </FormGroup>
          </Col>
        )}
        {reportType !== "3" && reportType !== "5" && reportType !== "6" && reportType != "7" && (
          <Col md="3" xs="12" xl="3" lg="3">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>{props.t("From Date")} :</Label>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  startDateOps(date);
                }}
                className="form-control"
                placeholderText={"Select Date"}
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                dateFormat="dd/MM/yyyy"
                disabled={reportType === "3"}
                showFullMonthYearPicker
              />
            </FormGroup>
          </Col>
        )}
        {reportType !== "3" && reportType !== "5" && reportType !== "6" && reportType != "7" && (
          <Col md="3" xs="12" xl="3" lg="3">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>{props.t("To Date")} :</Label>
              <DatePicker
                selected={endDate}
                onChange={(date) => {
                  endDateOps(date);
                }}
                className="form-control"
                placeholderText={"Select Date"}
                startDate={startDate}
                minDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                disabled={reportType === "3"}
                showFullMonthYearPicker
              />
            </FormGroup>
          </Col>
        )}
        {reportType !== "5"&& reportType !="7" && <Col md="2" xs="12" xl="2" lg="2">
          <div className="mt-2">
            <div className="mt-3">
              <button
                className="btn btn-primary mt-4 mr-2"
                onClick={generateReport}
              >
                {props.t("Generate")}
              </button>
            </div>
          </div>
        </Col>}

      </Row>

      {loading&&<p>Generating Report</p>}

      {reportType === "5" && <FeeAnalytics
        isFranchise={false}
        schoolId={props.schoolId}
        onClickApply={onClickApply}
        fromDate={fromDate}
        toDate={toDate}

      />}

      {reportType === "6" && <ProgramClassReport
        isFranchise={false}
        schoolId={props.schoolId}
        onClickApply={onClickProgramClass}
      />}

      {reportType == "7" && <ProgramTearmsReport
        isFranchise={false}
        schoolId={props.schoolId}
        onClickApply={onClickProgramClass}
      />}


      {reportType !== "5" && reportType !== "6" && reportType != "7" && <div>
        <Row>
          {/* {
          console.log("showResults:",showResults,
          "gotReport:",gotReport,"reportList:",reportList
          )
        } */}
          {/* {showResults && gotReport && reportList.length > 0 && (
            <Col md="12" xs="12" xl="12" lg="12" className="d-flex justify-content-end mt-1 mb-1">
              <button
                className="btn btn-info float-right"
                onClick={downloadTheReport}
              >
                <i className="bx bxs-download mr-1" />
                {props.t("Download")}
              </button>

              {reportType == 1 && <div>
                <Table className=" table mb-0 table-bordered table-sm" style={{ width: '500px' }}>
                  <tbody>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Collected Amount </th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.tAmount}</td>
                    </tr>
                    {showRoyalty && <tr>
                      <th scope="row" style={{ width: "200px" }}>School Amount </th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.schoolAmount.toFixed(2)}</td>
                    </tr>}
                    {showRoyalty && <tr>
                      <th scope="row" style={{ width: "200px" }}>Royalty Amount </th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.royaltyAmount.toFixed(2)}</td>
                    </tr>}
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Cash </th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.cAmount}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Online </th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.oAmount}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Cheque</th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.chAmount}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>AirPay </th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.apAmount}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>RazorPay</th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.rpAmount}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>OnePay</th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.opAmount}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Cancelled Amount</th>
                      <td scope="row" style={{ width: "500px" }}>{transactionStats.cancelAmount}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>}
            </Col>
          )} */}

          {showResults && gotReport && reportList.length > 0 && (
            <Row>
              <Col className="d-flex justify-content-start m-2">
                {reportType == 1 && <div>
                  <Table className=" table mb-0 table-bordered table-sm" style={{ width: '500px' }}>
                    <tbody>
                      <tr>
                        <th scope="row" style={{ width: "200px" }}>Collected Amount </th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.tAmount}</td>
                      </tr>
                      {showRoyalty && <tr>
                        <th scope="row" style={{ width: "200px" }}>School Amount </th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.schoolAmount.toFixed(2)}</td>
                      </tr>}
                      {showRoyalty && <tr>
                        <th scope="row" style={{ width: "200px" }}>Royalty Amount </th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.royaltyAmount.toFixed(2)}</td>
                      </tr>}
                      <tr>
                        <th scope="row" style={{ width: "200px" }}>Cash </th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.cAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{ width: "200px" }}>Online </th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.oAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{ width: "200px" }}>Cheque</th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.chAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{ width: "200px" }}>AirPay </th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.apAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{ width: "200px" }}>RazorPay</th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.rpAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{ width: "200px" }}>OnePay</th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.opAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{ width: "200px" }}>Cancelled Amount</th>
                        <td scope="row" style={{ width: "500px" }}>{transactionStats.cancelAmount}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>}
              </Col>
              <Col md="12" xs="12" xl="12" lg="12" className="d-flex justify-content-end mt-1 mb-1">
                <button className="btn btn-info float-right" onClick={downloadTheReport}>
                  <i className="bx bxs-download m-1" />
                  {props.t("Download")}
                </button>
              </Col>
            </Row>
          )}

          {showResults && gotReport && reportList.length > 0 && (
            <div className="table-responsive">
              <table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>{props.t("Name")}</th>
                    {(reportType === "3" || reportType === "4") && (
                      <th>{props.t("Class")}</th>
                    )}
                    {reportType === "3" && (
                      <th>{props.t("Number Of Invoice")}</th>
                    )}
                    {(reportType === "0" ||
                      reportType === "1" ||
                      reportType === "4") && <th>{props.t("Invoice Number")}</th>}
                    {reportType === "2" && <th>{props.t("Program Name")}</th>}
                    {reportType !== "3" && reportType !== "4" && (
                      <th>{props.t("Date")}</th>
                    )}
                    {(reportType === "0" ||
                      reportType === "1" ||
                      reportType === "2") && <th>{props.t("Status")}</th>}
                    {reportType !== "1" && reportType !== "4" && (
                      <th>{props.t("Payable")}</th>
                    )}
                    {reportType !== "4" && <th>{props.t("Paid")}</th>}
                    {showRoyalty && reportType == "1" && <th>{props.t("School Amount")}</th>}
                    {showRoyalty && reportType == "1" && <th>{props.t("Royalty Amount")}</th>}
                    {(reportType === "0" ||
                      reportType === "2" ||
                      reportType === "3") && <th>{props.t("Balance")}</th>}
                    {reportType === "4" && <th>{props.t("Item Description")}</th>}
                    {reportType === "4" && <th>{props.t("Item Amount")}</th>}
                    {reportType === "4" && <th>{props.t("Discount Amount")}</th>}
                    {reportType === "4" && (
                      <th>{props.t("Discount Given By")}</th>
                    )}

                    {reportType === "1" && <th>{props.t("Transaction Id")}</th>}
                    {reportType === "1" && <th>{props.t("Type")}</th>}
                    {(reportType === "4" || reportType === "1") && <th>{props.t("Remark")}</th>}
                  </tr>
                </thead>
                <tbody>
                  {reportList.map((val, key) => (
                    <tr key={key}>
                      <td>{val.name}</td>
                      {(reportType === "3" || reportType === "4") && (
                        <td>{val.className}</td>
                      )}
                      {reportType === "3" && <td>{val.numberOfInvoice}</td>}
                      {(reportType === "0" ||
                        reportType === "1" ||
                        reportType === "4") && <td>{val.invoiceNumber}</td>}
                      {reportType === "2" && <td>{val.programName}</td>}
                      {reportType !== "3" && reportType !== "4" && (
                        <td>{val.date}</td>
                      )}
                      {(reportType === "0" ||
                        reportType === "1" ||
                        reportType === "2") && <td>{val.status}</td>}
                      {reportType !== "1" && reportType !== "4" && (
                        <td>{val.payable}</td>
                      )}
                      {reportType !== "4" && <td>{val.paid}</td>}
                      {showRoyalty && reportType == "1" && <td>{val.obj.attributes.schoolAmount}</td>}
                      {showRoyalty && reportType == "1" && <td>{val.obj.attributes.royaltyAmount}</td>}
                      {(reportType === "0" ||
                        reportType === "2" ||
                        reportType === "3") && <td>{val.payable - val.paid}</td>}
                      {reportType === "4" && <td>{val.itemDescription}</td>}
                      {reportType === "4" && <td>{val.itemAmount}</td>}
                      {reportType === "4" && <td>{val.discountAmount}</td>}
                      {reportType === "4" && <td>{val.discountGivenBy}</td>}
                      {reportType === "1" && <td>{val.transactionId}</td>}
                      {reportType === "1" && <td>{val.type}</td>}
                      {(reportType === "4" || reportType === "1") && <td>{val.remark}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {gotReport && reportList.length === 0 && (
            <Col>
              <h3 className="text-center text-info mt-3">
                {props.t("No Report Found For The Filter")}
              </h3>
            </Col>
          )}
        </Row>
      </div>
      }
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { invoices, showLoader } = state.Invoice;
  const { kids, admissionKids, allDeletedKids } = state.Kid;
  const { classrooms } = state.Classroom;
  const {
    moneyTransactionsForInvoiceIds,
    moneyTransactions,
    gotAllTransaction,
  } = state.MoneyTransactions;
  const { teachers } = state.Teacher;
  const { programs } = state.Program;
  const { invoiceItems } = state.InvoiceItems;
  const { feeTypes } = state.FeeTemplate;
  const { triansactionsItems } = state.MoneyTransactionDetails;
  const { franchise } = state.Franchise;
  return {
    invoices,
    kids,
    admissionKids,
    allDeletedKids,
    classrooms,
    moneyTransactionsForInvoiceIds,
    moneyTransactions,
    showLoader,
    gotAllTransaction,
    teachers,
    programs,
    invoiceItems,
    feeTypes,
    triansactionsItems,
    franchise,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getInvoicesForFilters,
      getAllKids,
      getAdmissionKidsForSchool,
      getAllDeletedKidForSchool,
      getClassroomsForSchoolId,
      getTriansactionForInvoiceIds,
      getAllTransactionForFilter,
      getInvoiceForIds,
      getTeachersForSchool,
      getProgramsForSchoolId,
      getItemsForInvoiceIds,
      getFeeType,
      getMTDForItemIds,
      getFranchise,
    })(FeeReport)
  )
);
