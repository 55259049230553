import React, { useEffect, useState } from "react";
import {
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import classnames from 'classnames';
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Moment from "moment";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import AttendanceDetailModal from './attendanceDetailsModal'

import {
  getTeachersForSchool,
  getClassroomsForSchoolId,
  getAllKids,
  getAttendanceForTeacherIds,
  getAttendanceForKidIds,
  getDaycareAttendanceForKidIds,
} from "../../store/actions";

const AttendanceReport = (props) => {
  const [reportType, setReportType] = useState("1");
  const [teacherList, setTeacherList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [daycareList, setDaycareList] = useState([]);
  const [classStudent, setClassStudent] = useState([]);
  const [daycareStudent, setDaycareStudent] = useState([]);
  const [allClassStudent, setAllClassStudent] = useState([]);
  const [allDaycareStudent, setAllDaycareStudent] = useState([]);
  const [activeTeacher, setActiveTeacher] = useState("all");
  const [activeClass, setActiveClass] = useState("all");
  const [activeChild, setActiveChild] = useState("all");
  const [activemonth, setActiveMonth] = useState(new Date());
  const [gotReport, setGotReport] = useState(false);
  const [attendanceReport, setAttendanceReport] = useState([]);
  const [classWiseAttendanceReport, setClassWiseAttendanceReport] = useState([]);
  const [totalCheckIns, setTotalCheckIns] = useState(undefined);
  const [totalCheckOuts, setTotalCheckOuts] = useState();
  const [selectedDate, selectedDateOps] = useState(new Date());
  const [selectedEndDate, selectedEndDateOps] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(new Date());

  const [attendanceCalenderReport, setAttendanceCalenderReport] = useState([]);
  const [selectedTab, toggleTab] = useState("1");

  const [loading, setloading] = useState(false);

  //dec
  const [teacherDec, setTeacherDec] = useState([]);
  const [kidDec, setKidDec] = useState([]);
  const [classDec, setClassDec] = useState([]);
  const [daycareDec, setDaycareDec] = useState([]);
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState()
  //const


  useEffect(() => {
    if (props.schoolId) {
      props.getTeachersForSchool(props.schoolId);
      props.getClassroomsForSchoolId(props.schoolId);
      props.getAllKids(props.schoolId);
    }
  }, [props.schoolId]);

  useEffect(() => {
    if (props.matchStaff) {
      setReportType('1')
    }
  }, [props.matchStaff])

  useEffect(() => {
    if (props.matchDaycare) {
      setReportType('3')
    }
  }, [props.matchDaycare])

  useEffect(() => {
    if (props.matchClass) {
      setReportType('2')
    }
  }, [props.matchClass])

  useEffect(() => {
    if (props.matchClassWise) {
      setReportType('4')
    }
  }, [props.matchClassWise])
  useEffect(() => {
    let tempList = [];
    let tempDec = [];
    for (const val of props.teachers) {
      let temp = {};
      temp.id = val.id;
      temp.name = val.attributes.Name;
      temp.obj = val;
      tempDec[temp.id] = temp;
      tempList.push(temp);
    }
    setTeacherList(tempList);
    setTeacherDec(tempDec);
  }, [props.teachers]);
  useEffect(() => {
    if (props.classrooms.length > 0) {
      let tempListC = [];
      let tempListD = [];
      let tempClassDec = [];
      let tempDaycareDec = [];
      for (const val of props.classrooms) {
        let temp = {};
        temp.id = val.id;
        temp.name = val.attributes.Name;
        temp.type = val.attributes.classroomType;
        temp.obj = val;
        if (temp.type === 2) {
          tempListD.push(temp);
          tempDaycareDec[temp.id] = temp;
        } else {
          tempClassDec[temp.id] = temp;
          tempListC.push(temp);
        }
      }
      setClassList(tempListC);
      setDaycareList(tempListD);
      setClassDec(tempClassDec);
      setDaycareDec(tempDaycareDec);
    }
  }, [props.classrooms]);
  useEffect(() => {
    if (props.kids.length > 0 && daycareList.length > 0) {
      let kidList = [];
      for (const val of props.kids) {
        if (
          daycareDec[val.attributes.ClassRoomID] ||
          daycareDec[val.attributes.daycareClassId]
        ) {
          let temp = {};
          temp.id = val.id;
          temp.name = val.attributes.Name;
          temp.classId = val.attributes.daycareClassId
            ? val.attributes.daycareClassId
            : val.attributes.ClassRoomID;
          temp.obj = val;
          kidList.push(temp);
        }
      }
      setDaycareStudent(kidList);
      setAllDaycareStudent(kidList);
    }
    if (props.kids.length > 0 && classList.length > 0) {
      let kidList = [];
      for (const val of props.kids) {
        if (classDec[val.attributes.ClassRoomID]) {
          let temp = {};
          temp.id = val.id;
          temp.name = val.attributes.Name;
          temp.classId = val.attributes.ClassRoomID;
          temp.obj = val;
          kidList.push(temp);
        }
      }
      setClassStudent(kidList);
      setAllClassStudent(kidList);
    }
    let tempKidDec = [];
    for (const val of props.kids) {
      let temp = {};
      temp.name = val.attributes.Name;
      temp.id = val.id;
      temp.obj = val;
      tempKidDec[val.id] = temp;
    }
    setKidDec(tempKidDec);
  }, [props.kids, daycareList, classList]);
  useEffect(() => {
    setActiveTeacher("all");
    setActiveClass("all");
    setActiveChild("all");
    setGotReport(false);
  }, [reportType]);

  useEffect(() => {
    if (props.kids && props.classrooms, props.teachers && (reportType == 1 || reportType == 2 || reportType == 3 || reportType == 4)) {
      getAttendanceReport()
    }

  }, [reportType, props.kids, props.classrooms]);

  const showStudentForOnlyClass = (classId) => {
    if (classId === "all") {
      setClassStudent(allClassStudent);
      setDaycareStudent(allDaycareStudent);
      setActiveClass("all");
    } else {
      if (reportType === "2") {
        let tempList = [];
        for (const val of allClassStudent) {
          if (val.classId === classId) {
            tempList.push(val);
          }
        }
        setClassStudent(tempList);
      } else if (reportType === "3") {
        let tempList = [];
        for (const val of allDaycareStudent) {
          if (val.classId === classId) {
            tempList.push(val);
          }
        }
        setDaycareStudent(tempList);
      }
    }
  };
  const getAttendanceReport = () => {
    setloading(true)
    if (reportType === "1") {
      let params = {};
      params.teacherIds = [];
      params.schoolId = props.schoolId;

      params.startDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        0,
        0,
        0,
        0,
      );
      params.endDate = new Date(
        selectedEndDate.getFullYear(),
        selectedEndDate.getMonth(),
        selectedEndDate.getDate(),
        23,
        59,
        59,
        999
      );

      if (activeTeacher === "all") {
        for (const val of teacherList) {
          params.teacherIds.push(val.id);
        }
      } else {
        params.teacherIds.push(activeTeacher);
      }
      props.getAttendanceForTeacherIds(params);
    } else if (reportType === "2") {
      //class
      let params = {};
      params.kidIds = [];
      params.schoolId = props.schoolId;

      params.startDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        0,
        0,
        0,
        0,
      );
      params.endDate = new Date(
        selectedEndDate.getFullYear(),
        selectedEndDate.getMonth(),
        selectedEndDate.getDate(),
        23,
        59,
        59,
        999
      );

      if (activeChild === "all") {
        for (const val of classStudent) {
          params.kidIds.push(val.id);
        }
      } else {
        params.kidIds.push(activeChild);
      }
      props.getAttendanceForKidIds(params);
    } else if (reportType === "3") {
      //daycare
      let params = {};
      params.kidIds = [];
      params.schoolId = props.schoolId;


      params.startDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        0,
        0,
        0,
        0,
      );
      params.endDate = new Date(
        selectedEndDate.getFullYear(),
        selectedEndDate.getMonth(),
        selectedEndDate.getDate(),
        23,
        59,
        59,
        999
      );


      if (activeChild === "all") {
        for (const val of daycareStudent) {
          params.kidIds.push(val.id);
        }
      } else {
        params.kidIds.push(activeChild);
      }
      props.getDaycareAttendanceForKidIds(params);
    } else if (reportType === "4") {
      //class
      let params = {};
      params.schoolId = props.schoolId;
      params.forSchool = true;

      params.startDate = new Date(
        selectedDay.getFullYear(),
        selectedDay.getMonth(),
        selectedDay.getDate(),
        0,
        0,
        0,
        0,
      );
      params.endDate = new Date(
        selectedDay.getFullYear(),
        selectedDay.getMonth(),
        selectedDay.getDate(),
        23,
        59,
        59,
        999
      );
      props.getAttendanceForKidIds(params);
      props.getDaycareAttendanceForKidIds(params);
    }
  };
  useEffect(() => {
    setGotReport(props.gotAttendance);
    if (props.gotAttendance) {
      setloading(false)
    }
  }, [props.gotAttendance]);

  useEffect(() => {
    let tempTotalCheckIns = 0;
    let tempTotalCheckOuts = 0;
    let tempAttendance = [];
    for (const val of props.teacherAttendanceList) {
      let temp = {};
      temp.name = teacherDec[val.attributes.teacherId]
        ? teacherDec[val.attributes.teacherId].name
        : "--";
      temp.jsInTime = val.attributes.ReachedSchoolAt;
      temp.jsOutTime = val.attributes.LeftSchoolAt;
      if (temp.jsInTime) {
        temp.checkInTime = `${getTimeIn12Hr(temp.jsInTime)} ${getDateInDDMMYYYY(temp.jsInTime)}`;
        tempTotalCheckIns += 1;
      } else {
        temp.checkInTime = "--:-- -- --/--/----";
      }
      if (temp.jsOutTime) {
        temp.checkOutTime = `${getTimeIn12Hr(temp.jsOutTime)} ${getDateInDDMMYYYY(temp.jsOutTime)}`;
        tempTotalCheckOuts += 1;
      } else {
        temp.checkOutTime = "--:-- -- --/--/----";
      }
      temp.totalHrs =
        temp.jsInTime && temp.jsOutTime
          ? getTimeDifference(temp.jsInTime, temp.jsOutTime)
          : "-- Hrs -- Min";
      temp.obj = val;
      tempAttendance.push(temp);
    }
    setAttendanceReport(tempAttendance);
    getTeacherCalenderReport(tempAttendance);
    setTotalCheckIns(tempTotalCheckIns);
    setTotalCheckOuts(tempTotalCheckOuts);
  }, [props.teacherAttendanceList]);

  const getTeacherCalenderReport = (rawData) => {
    let finalData = [];
    let title = ["Name"];
    let dayOfWeekRow = ["Day"];
    let sheetName = `Calendar Report `;
    let duration = `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`;

    const noOfDays = getDaysDifference(selectedDate, selectedEndDate) + 1;
    let startDate = selectedDate;
    for (let i = 1; i <= noOfDays; i++) {
      const dateStr = getDateInDDMMYYYY(startDate);
      const dayOfWeek = Moment(startDate).format("dddd"); // Get day of week
      title.push(dateStr); // Date
      dayOfWeekRow.push(dayOfWeek); // Day of the week
      startDate = Moment(startDate).add(1, "days");
    }
    title.push("Total");
    dayOfWeekRow.push(""); // Total column alignment

    for (const val of teacherList) {
      if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
        if (teacherDec[activeTeacher].id !== val.id) {
          continue;
        }
      }
      let temp = [];
      temp.push(val.name);
      let tempAttFor = [];
      for (const attVal of rawData) {
        if (attVal.jsInTime && attVal.obj.attributes.teacherId === val.id) {
          tempAttFor.push(getDateInDDMMYYYY(attVal.jsInTime));
        }
      }
      let p = 0;
      for (let i = 1; i < title.length - 1; i++) {
        if (tempAttFor.includes(title[i])) {
          temp.push("P");
          p++;
        } else {
          temp.push(null);
        }
      }
      temp.push(p);
      finalData.push(temp);
    }

    setAttendanceCalenderReport({
      sheetName: sheetName,
      duration: duration,
      title: title,
      dayOfWeekRow: dayOfWeekRow,
      finalData: finalData,
    });
  };


  useEffect(() => {
    // if (props.kidAttendanceList.length > 0) {
    let tempTotalCheckIns = 0
    let tempTotalCheckOuts = 0
    let tempAttendance = [];
    for (const val of props.kidAttendanceList) {
      let temp = {};
      temp.id = val.id;
      temp.obj = val;
      temp.kidId = val.attributes.Kid;
      temp.name = kidDec[val.attributes.Kid]
        ? kidDec[val.attributes.Kid].name
        : "--";
      temp.jsInTime = val.attributes.ReachedSchoolAt
      // ? val.attributes.ReachedSchoolAt
      // : val.attributes.createdAt;
      temp.jsOutTime = val.attributes.LeftSchoolAt;
      if (temp.jsInTime) {
        temp.checkInTime = `${getTimeIn12Hr(temp.jsInTime)} ${getDateInDDMMYYYY(
          temp.jsInTime
        )}`; //10:30Am 10/1/2020
        tempTotalCheckIns += 1
      } else {
        temp.checkInTime = "--:-- -- --/--/----";
      }
      if (temp.jsOutTime) {
        temp.checkOutTime = `${getTimeIn12Hr(
          temp.jsOutTime
        )} ${getDateInDDMMYYYY(temp.jsOutTime)}`;
        tempTotalCheckOuts += 1
      } else {
        temp.checkOutTime = "--:-- -- --/--/----";
      }
      temp.totalHrs =
        temp.jsInTime && temp.jsOutTime
          ? getTimeDifference(temp.jsInTime, temp.jsOutTime)
          : "-- Hrs -- Min";
      tempAttendance.push(temp);
    }
    getKidCalenderReport(tempAttendance)
    setAttendanceReport(tempAttendance);
    setTotalCheckIns(tempTotalCheckIns)
    setTotalCheckOuts(tempTotalCheckOuts)
    // }
  }, [props.kidAttendanceList]);

  useEffect(() => {
    if (props.classrooms && props.kidAttendanceList && props.kids && props.daycareAttendance) {
      let tempAttendance = [];

      for (const classRoom of props.classrooms) {
        let tempData = {
          id: classRoom.id,
          classRoomName: classRoom.attributes.Name,
        };

        let kidIds = [];
        let checkins = 0;
        let checkiinIds = [];
        let atSchool = 0;
        let atSchoolIds = [];
        let markedAbsent = 0;

        if (classRoom.attributes.classroomType == 2) {

          for (const kid of props.kids) {

            if (kid.attributes.ClassRoomID == classRoom.id || kid.attributes.daycareClassId == classRoom.id) {
              kidIds.push(kid.id);

              for (const dAttendance of props.daycareAttendance) {
                if (dAttendance.attributes.kidId == kid.id) {
                  if (!checkiinIds.includes(kid.id)) {
                    checkiinIds.push(kid.id)
                  }
                  if (!dAttendance.attributes.checkOutTime) {
                    if (!atSchoolIds.includes(kid.id)) {
                      atSchoolIds.push(kid.id)
                    }
                  }
                }
              }
            }
          }

          tempData.kidIds = kidIds;
          tempData.total = kidIds.length;
          tempData.checkIn = checkiinIds.length;
          tempData.atSchool = atSchoolIds.length;
          tempData.checkOut = tempData.checkIn - tempData.atSchool;
          tempData.markedAbsent = tempData.total - tempData.checkIn;

        } else {
          for (const kid of props.kids) {
            if (kid.attributes.ClassRoomID == classRoom.id) {
              kidIds.push(kid.id);
              for (const attendance of props.kidAttendanceList) {
                if (attendance.attributes.Kid == kid.id) {
                  if (attendance.attributes.ReachedSchoolAt) {
                    checkins++;
                    if (!attendance.attributes.LeftSchoolAt) {
                      atSchool++;
                    }
                  } else {
                    markedAbsent++;
                  }
                }
              }
            }
          }
          tempData.kidIds = kidIds;
          tempData.total = kidIds.length;
          tempData.checkIn = checkins;
          tempData.atSchool = atSchool;
          tempData.checkOut = tempData.checkIn - tempData.atSchool;
          tempData.markedAbsent = markedAbsent;

        }
        tempData.totalAbsents = tempData.total - tempData.checkIn;
        tempAttendance.push(tempData);
      }
      setClassWiseAttendanceReport(tempAttendance);
    }
  }, [props.kidAttendanceList, props.classrooms, props.kids, props.daycareAttendance]);




  const getKidCalenderReport = (rawData) => {
    let finalData = [];
    let title = ["Name", "Class"];
    let dayOfWeekRow = ["", "Day"]; 
  
    let sheetName = `Calendar Report`;
    let duration = `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`;
  
    const noOfDays = getDaysDifference(selectedDate, selectedEndDate) + 1;
    let startDate = selectedDate;
    for (let i = 1; i <= noOfDays; i++) {
      const dateStr = getDateInDDMMYYYY(startDate);
      const dayOfWeek = Moment(startDate).format('dddd'); // Get day of the week
      title.push(dateStr); // Add date to title
      dayOfWeekRow.push(dayOfWeek); // Add day of the week to dayOfWeekRow
      startDate = Moment(startDate).add(1, 'days');
    }
    title.push("Total");
    dayOfWeekRow.push(""); // For the "Total" column
  
    for (const val of classStudent) {
      if (activeChild !== "all" && kidDec[activeChild]) {
        if (kidDec[activeChild].id !== val.id) {
          continue;
        }
      }
      let temp = [val.name, classDec[val.classId] ? classDec[val.classId].name : "--"];
      let tempAttFor = [];
      for (const attVal of rawData) {
        if (attVal.jsInTime && attVal.obj.attributes.Kid === val.id) {
          tempAttFor.push(getDateInDDMMYYYY(attVal.jsInTime));
        }
      }
      let p = 0;
      for (let i = 2; i < title.length - 1; i++) { 
        if (tempAttFor.includes(title[i])) {
          temp.push("P");
          p++;
        } else {
          temp.push(null);
        }
      }
      temp.push(p);
      finalData.push(temp);
    }
  
    setAttendanceCalenderReport({
      sheetName: sheetName,
      duration: duration,
      title: title,
      dayOfWeekRow: dayOfWeekRow,
      finalData: finalData
    });
  };
  

  const arrangeBasedOnKids = () => {
    let tempReport = []
    for (const kid of props.kids) {
      for (const ar of attendanceReport) {
        if (ar.kidId && kid.id == ar.kidId) {
          tempReport.push(ar)
        }
      }

    }
    if (tempReport.length > 0) {
      setAttendanceReport(tempReport);
    }
  }

  useEffect(() => {
    let tempTotalCheckIns = 0
    let tempTotalCheckOuts = 0
    let tempAttendance = [];
    for (const val of props.daycareAttendance) {
      let temp = {};
      temp.id = val.id;
      temp.obj = val;
      temp.kidId = val.attributes.kidId
      temp.name = kidDec[val.attributes.kidId]
        ? kidDec[val.attributes.kidId].name
        : "--";
      temp.jsInTime = val.attributes.checkInTime
        ? val.attributes.checkInTime
        : val.attributes.createdAt;
      temp.jsOutTime = val.attributes.checkOutTime;
      if (temp.jsInTime) {
        temp.checkInTime = `${getTimeIn12Hr(temp.jsInTime)} ${getDateInDDMMYYYY(
          temp.jsInTime
        )}`; //10:30Am 10/1/2020
        tempTotalCheckIns += 1

      } else {
        temp.checkInTime = "--:-- -- --/--/----";
      }
      if (temp.jsOutTime) {
        temp.checkOutTime = `${getTimeIn12Hr(
          temp.jsOutTime
        )} ${getDateInDDMMYYYY(temp.jsOutTime)}`;
        tempTotalCheckOuts += 1

      } else {
        temp.checkOutTime = "--:-- -- --/--/----";
      }
      temp.totalHrs =
        temp.jsInTime && temp.jsOutTime
          ? getTimeDifference(temp.jsInTime, temp.jsOutTime)
          : "-- Hrs -- Min";
      tempAttendance.push(temp);
    }
    setAttendanceReport(tempAttendance);
    setTotalCheckIns(tempTotalCheckIns)
    setTotalCheckOuts(tempTotalCheckOuts)
  }, [props.daycareAttendance]);

  const getTimeIn12Hr = (date) => {
    return Moment(date).format("LT");
  };
  const getDateInDDMMYYYY = (date) => {
    return Moment(date).format("DD/MM/YYYY");
  };
  const getTimeDifference = (inTime, outTime) => {
    let startTime = Moment(inTime);
    let endTime = Moment(outTime);
    let duration = Moment.duration(endTime.diff(startTime));
    let hours = parseInt(duration.asHours());
    let minutes = parseInt(duration.asMinutes()) % 60;
    return `${hours} Hrs ${minutes} Min`;
  };

  const getDaysDifference = (inTime, outTime) => {
    let startTime = Moment(inTime);
    let endTime = Moment(outTime);
    let duration = Moment.duration(endTime.diff(startTime));

    let days = parseInt(duration.asDays());

    return days;
  };

  const downloadTheReport = () => {
    let rawData = attendanceReport;
    let finalData = [];
    let titles = [];
    let fileName = "";
    let sheetName = "";

    finalData.push([`School Name: ${props.selectedSchool.attributes.Name}`]);
    if (reportType === "1") {
      sheetName = "All Staff"
      if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
        sheetName = teacherDec[activeTeacher].name
      }
      fileName = `${sheetName} Attendance ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
      let reportType = "Staff Attendance"
      finalData.push([`Report Type: ${reportType}`]);
      if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
        finalData.push([
          `Teacher Details: ${teacherDec[activeTeacher].name}`,
        ]);
      } else {
        finalData.push([
          `Selected Staff: ${activeTeacher === "all"
            ? "All Staff"
            : teacherDec[activeTeacher]
              ? teacherDec[activeTeacher].name
              : "Staff"
          }`,
        ]);
      }

      finalData.push([
        `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
      ]);
      finalData.push([]);

      if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
        titles = [
          "Date",
          "Check-In",
          "Check-Out",
          "Total Hrs",
          "Check In By",
          "Check Out By",
          "Check-In Type",
          "Check-Out Type",
        ];
      } else {
        titles = [
          "Date",
          "Name",
          "Check-In",
          "Check-Out",
          "Total Hrs",
          "Check In By",
          "Check Out By",
          "Check-In Type",
          "Check-Out Type",
        ];

      }

      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(
          `${val.obj.createdAt.getDate()}-${val.obj.createdAt.getMonth() + 1
          }-${val.obj.createdAt.getFullYear()}`
        );

        if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
        } else {
          temp.push(val.name);
        }

        temp.push(val.jsInTime ? getTimeIn12Hr(val.jsInTime) : "--");
        temp.push(val.jsOutTime ? getTimeIn12Hr(val.jsOutTime) : "--");
        temp.push(val.totalHrs);
        temp.push(
          val.obj.attributes.checkedInBy
            ? teacherDec[val.obj.attributes.checkedInBy]
              ? teacherDec[val.obj.attributes.checkedInBy].name
              : "--"
            : "--"
        );
        temp.push(
          val.obj.attributes.checkedOutBy
            ? teacherDec[val.obj.attributes.checkedOutBy]
              ? teacherDec[val.obj.attributes.checkedOutBy].name
              : "--"
            : "--"
        );
        temp.push(
          val.obj.attributes.checkInType ? val.obj.attributes.checkInType : null
        );
        temp.push(
          val.obj.attributes.checkOutType
            ? val.obj.attributes.checkOutType
            : null
        );
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      //monthly sheet
      if (activeTeacher === "all" || true) {
        let finalData = [];

        let sheetName = attendanceCalenderReport.sheetName;
        finalData.push([attendanceCalenderReport.duration,]);
        finalData.push(attendanceCalenderReport.title);
        // finalData.concat(attendanceCalenderReport.finalData);

        for (const fd of attendanceCalenderReport.finalData) {
          finalData.push(fd);
        }

        let ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }

      XLSX.writeFile(wb, fileName);
    } else if (reportType === "2") {


      sheetName = "School"

      if (activeClass !== "all") {
        sheetName = "Class"
      }
      if (activeChild !== "all" && kidDec[activeChild]) {
        sheetName = kidDec[activeChild].name
      }

      fileName = `${sheetName} Attendance ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;


      let reportType = "Class Attendance"
      if (activeChild !== "all" && kidDec[activeChild]) {
        reportType = "Student Attendance"
      }
      finalData.push([`Report Type: ${reportType}`]);
      if (activeChild !== "all" && kidDec[activeChild]) {
        finalData.push([
          `Student Details: ${kidDec[activeChild].name}, ${kidDec[activeChild]
            ? kidDec[activeChild].obj.attributes.ClassRoomID
              ? classDec[
                kidDec[activeChild].obj.attributes.ClassRoomID
              ]
                ? classDec[
                  kidDec[activeChild].obj.attributes.ClassRoomID
                ].name
                : "--"
              : "--"
            : "--"}`,
        ]);
      } else {
        finalData.push([
          `Selected Class: ${activeClass === "all"
            ? "All Class"
            : classDec[activeClass]
              ? classDec[activeClass].name
              : "Class"
          }`,
        ]);
      }

      finalData.push([
        `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
      ]);
      finalData.push([]);

      if (activeChild !== "all" && kidDec[activeChild]) {
        titles = [
          "Date",
          "Class",
          "Check-In",
          "Check-Out",
          "Total Hrs",
          "Check In By",
          "Check Out By",
          "Check-In Type",
          "Check-Out Type",
        ];
      } else {
        titles = [
          "Date",
          "Name",
          "Class",
          "Check-In",
          "Check-Out",
          "Total Hrs",
          "Check In By",
          "Check Out By",
          "Check-In Type",
          "Check-Out Type",
        ];
      }

      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(
          `${val.obj.createdAt.getDate()}-${val.obj.createdAt.getMonth() + 1
          }-${val.obj.createdAt.getFullYear()}`
        );

        if (activeChild !== "all" && kidDec[activeChild]) {
        } else {
          temp.push(val.name);
        }
        temp.push(
          kidDec[val.obj.attributes.Kid]
            ? kidDec[val.obj.attributes.Kid].obj.attributes.ClassRoomID
              ? classDec[
                kidDec[val.obj.attributes.Kid].obj.attributes.ClassRoomID
              ]
                ? classDec[
                  kidDec[val.obj.attributes.Kid].obj.attributes.ClassRoomID
                ].name
                : "--"
              : "--"
            : "--"
        );
        temp.push(val.jsInTime ? getTimeIn12Hr(val.jsInTime) : "--");
        temp.push(val.jsOutTime ? getTimeIn12Hr(val.jsOutTime) : "--");
        temp.push(val.totalHrs);
        temp.push(
          val.obj.attributes.checkedInBy
            ? teacherDec[val.obj.attributes.checkedInBy]
              ? teacherDec[val.obj.attributes.checkedInBy].name
              : "--"
            : "--"
        );
        temp.push(
          val.obj.attributes.checkedOutBy
            ? teacherDec[val.obj.attributes.checkedOutBy]
              ? teacherDec[val.obj.attributes.checkedOutBy].name
              : "--"
            : "--"
        );
        temp.push(
          val.obj.attributes.checkInType ? val.obj.attributes.checkInType : null
        );
        temp.push(
          val.obj.attributes.checkOutType
            ? val.obj.attributes.checkOutType
            : null
        );
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      if (activeChild === "all" || true) {

        let finalData2 = []
        let sheetName = attendanceCalenderReport.sheetName;
        finalData2.push([attendanceCalenderReport.duration,]);
        finalData2.push(attendanceCalenderReport.title);
        // finalData.concat(attendanceCalenderReport.finalData);
        for (const fd of attendanceCalenderReport.finalData) {
          finalData2.push(fd);
        }

        let ws = XLSX.utils.aoa_to_sheet(finalData2);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }

      XLSX.writeFile(wb, fileName);
    } else if (reportType === "3") {

      sheetName = "Daycare"
      let className = ""

      if (activeClass !== "all" && daycareDec[activeClass]) {
        sheetName = daycareDec[activeClass] ? daycareDec[activeClass].name : "Daycare"
        className = daycareDec[activeClass] ? daycareDec[activeClass].name : "Daycare"

      }
      if (activeChild !== "all" && kidDec[activeChild]) {
        sheetName = kidDec[activeChild] ? kidDec[activeChild].name : "Daycare"


        fileName = `${sheetName}_${className} Attendance ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
      } else {
        fileName = `${sheetName} Attendance ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
      }
      sheetName = activeChild !== "all"
        ? `${kidDec[activeChild] ? kidDec[activeChild].name : "Student"
        } Attendance`
        : activeClass !== "all"
          ? `${classDec[activeClass] ? classDec[activeClass].name : "Class"
          } Attendance`
          : "";

      let reportType = "Daycare Attendance"
      finalData.push([`Report Type: ${reportType}`]);

      if (activeChild !== "all" && kidDec[activeChild]) {

        let tempClassName = "Daycare"
        if (kidDec[activeChild] && !kidDec[activeChild].obj.attributes.daycareClassId
          && kidDec[activeChild].obj.attributes.ClassRoomID && classDec[kidDec[activeChild].obj.attributes.ClassRoomID]) {
          tempClassName = classDec[kidDec[activeChild].obj.attributes.ClassRoomID].name ?? "--"
        } else if (kidDec[activeChild] && kidDec[activeChild].obj.attributes.daycareClassId
          && daycareDec[kidDec[activeChild].obj.attributes.daycareClassId]) {
          tempClassName = daycareDec[kidDec[activeChild].obj.attributes.daycareClassId].name ?? "--"
        }


        finalData.push([
          `Student Details: ${kidDec[activeChild].name}, ${tempClassName}`,
        ]);
      } else {
        finalData.push([
          `Selected Class: ${activeClass === "all"
            ? "All Class"
            : daycareDec[activeClass]
              ? daycareDec[activeClass].name
              : "Daycare"
          }`,
        ]);
      }

      finalData.push([
        `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
      ]);

      finalData.push([]);
      if (activeChild !== "all" && kidDec[activeChild]) {
        titles = [
          "Date",
          "Class",
          "Check-In",
          "Check-Out",
          "Total Hrs",
          "Check In By",
          "Check Out By",
          "Check-In Type",
          "Check-Out Type",
        ];
      } else {
        titles = [
          "Date",
          "Name",
          "Class",
          "Check-In",
          "Check-Out",
          "Total Hrs",
          "Check In By",
          "Check Out By",
          "Check-In Type",
          "Check-Out Type",
        ];
      }

      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(
          `${val.obj.createdAt.getDate()}-${val.obj.createdAt.getMonth() + 1
          }-${val.obj.createdAt.getFullYear()}`
        );
        if (activeChild !== "all" && kidDec[activeChild]) {
        } else {
          temp.push(val.name);
        }

        let className = "--"
        if (kidDec[val.obj.attributes.kidId] && !kidDec[val.obj.attributes.kidId].obj.attributes.daycareClassId
          && kidDec[val.obj.attributes.kidId].obj.attributes.ClassRoomID && classDec[kidDec[val.obj.attributes.kidId].obj.attributes.ClassRoomID]) {
          className = classDec[kidDec[val.obj.attributes.kidId].obj.attributes.ClassRoomID].name ?? "--"
        } else if (kidDec[val.obj.attributes.kidId] && kidDec[val.obj.attributes.kidId].obj.attributes.daycareClassId
          && daycareDec[kidDec[val.obj.attributes.kidId].obj.attributes.daycareClassId]) {
          className = daycareDec[kidDec[val.obj.attributes.kidId].obj.attributes.daycareClassId].name ?? "--"
        }
        temp.push(className)


        temp.push(getTimeIn12Hr(val.jsInTime));
        temp.push(val.jsOutTime ? getTimeIn12Hr(val.jsOutTime) : "--");
        temp.push(val.totalHrs);
        temp.push(
          val.obj.attributes.checkedInBy
            ? teacherDec[val.obj.attributes.checkedInBy]
              ? teacherDec[val.obj.attributes.checkedInBy].name
              : "--"
            : "--"
        );
        temp.push(
          val.obj.attributes.checkedOutBy
            ? teacherDec[val.obj.attributes.checkedOutBy]
              ? teacherDec[val.obj.attributes.checkedOutBy].name
              : "--"
            : "--"
        );
        temp.push(
          val.obj.attributes.checkInType ? val.obj.attributes.checkInType : null
        );
        temp.push(
          val.obj.attributes.checkOutType
            ? val.obj.attributes.checkOutType
            : null
        );
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      if (activeChild === "all") {
        let finalData = [];
        let title = [];
        let sheetName = `Calendar Report `;
        finalData.push([
          `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
        ]);
        title.push("Name");
        title.push("Class");
        const noOfDays = getDaysDifference(selectedDate, selectedEndDate) + 1;

        let startDate = selectedDate;
        for (let i = 1; i <= noOfDays; i++) {
          title.push(getDateInDDMMYYYY(startDate));
          startDate = Moment(startDate).add(1, 'days');
        }

        for (const val of daycareStudent) {
          let temp = [];
          temp.push(val.name);
          temp.push(
            daycareDec[val.classId] ? daycareDec[val.classId].name : "--"
          );
          let tempAttFor = [];
          for (const attVal of rawData) {
            if (attVal.obj.attributes.kidId === val.id) {
              tempAttFor.push(getDateInDDMMYYYY(attVal.jsInTime));
            }
          }
          for (let i = 0; i <= title.length; i++) {
            if (i == 0 || i == 1) {

            } else if (tempAttFor.includes(title[i])) {
              temp.push("P");
            } else {
              temp.push(null);
            }
          }
          finalData.push(temp);
        }
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }

      XLSX.writeFile(wb, fileName);
    } else if (reportType == '4') {

      let finalData = [];
      let titles = [];
      let fileName = "";
      let sheetName = "";
      fileName = `Classwise Attendance Report.xlsx`;
      sheetName = "Classwise_Attendance_Report";
      finalData.push([`Classwise Attendance Report`]);


      finalData.push([
      ]);
      finalData.push([
      ]);

      titles.push("Classroom");
      titles.push("Total Strength");
      titles.push("Total Checkin");
      titles.push("At School");
      titles.push("Absent");

      finalData.push(titles);

      for (const item of classWiseAttendanceReport) {
        let temp = [];
        temp.push(item.classRoomName);
        temp.push(item.total)
        temp.push(item.checkIn)
        temp.push(item.atSchool)
        temp.push(item.totalAbsents)
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    }
  };

  const getAttendanceDataUI = () => {
    return (
      <div className="table-responsive">
        <table className="table table-centered table-nowrap">
          <thead>
            <tr>
              <th onClick={arrangeBasedOnKids}>{props.t("Name")}</th>
              <th>{props.t("Check-in")}</th>
              <th>{props.t("Check-out")}</th>
              <th>{props.t("Total Hrs")}</th>
            </tr>
          </thead>
          <tbody>
            {attendanceReport.map((val, key) => (
              <tr key={key}>
                <td>{val.name}</td>
                <td>{val.checkInTime}</td>
                <td>{val.checkOutTime}</td>
                <td>{val.totalHrs}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <React.Fragment>
      {detailsPopup && <AttendanceDetailModal
        detailsPopup={detailsPopup}
        closeDetailsPopUp={() => { setDetailsPopup(false); }}
        kids={props.kids}
        classrooms={props.classrooms}
        kidAttendanceList={props.kidAttendanceList}
        daycareAttendance={props.daycareAttendance}
        selectedClassroom={selectedClassroom} />}
      <CardTitle>{props.t("Attendance Report")}</CardTitle>
      <CardSubtitle className="mb-3">
        {props.t("Select filter to generate the report")}
      </CardSubtitle>

      <Row className="bg-light">
        {reportType === "1" && (
          <Col md="3" xs="12" xl="3" lg="3">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>{props.t("Report Type")} :</Label>
              <select
                className="form-control "
                value={reportType}
                onChange={(e) => {
                  setReportType(e.target.value);
                }}
              >
                <option value={1}>{props.t("Staff")}</option>
                <option value={2}>{props.t("Class")}</option>
                {daycareList.length > 0 && (
                  <option value={3}>{props.t("Daycare")}</option>
                )}
                <option value={4}>{props.t("Classwise Report")}</option>
              </select>
            </FormGroup>
          </Col>
        )}
        {(reportType === "2" || reportType === "3" || reportType === "4") && (
          <Col md="2" xs="12" xl="2" lg="2">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>{props.t("Report Type")} :</Label>
              <select
                className="form-control "
                value={reportType}
                onChange={(e) => {
                  setReportType(e.target.value);
                }}
              >
                <option value={1}>{props.t("Staff")}</option>
                <option value={2}>{props.t("Class")}</option>
                {daycareList.length > 0 && (
                  <option value={3}>{props.t("Daycare")}</option>
                )}
                <option value={4}>{props.t("Classwise Report")}</option>
              </select>
            </FormGroup>
          </Col>
        )}
        {reportType === "1" && (
          <Col md="3" xs="12" xl="3" lg="3">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>{props.t("Select Staff")}</Label>
              <select
                className="form-control "
                value={activeTeacher}
                onChange={(e) => {
                  setActiveTeacher(e.target.value);
                }}
              >
                <option value={`all`}>{props.t("All")}</option>
                {teacherList.map((val, key) => (
                  <option value={val.id} key={key}>
                    {val.name}
                  </option>
                ))}
              </select>
            </FormGroup>
          </Col>
        )}
        {(reportType === "2" || reportType === "3") && (
          <Col md="2" xs="12" xl="2" lg="2">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>{props.t("Select Class")}</Label>
              <select
                className="form-control "
                value={activeClass}
                onChange={(e) => {
                  setActiveClass(e.target.value);
                  showStudentForOnlyClass(e.target.value);
                }}
              >
                <option value={`all`}>{props.t("All")}</option>
                {reportType === "2" &&
                  classList.map((val, key) => (
                    <option value={val.id} key={key}>
                      {val.name}
                    </option>
                  ))}
                {reportType === "3" &&
                  daycareList.map((val, key) => (
                    <option value={val.id} key={key}>
                      {val.name}
                    </option>
                  ))}
              </select>
            </FormGroup>
          </Col>
        )}
        {(reportType === "2" || reportType === "3") && (
          <Col md="2" xs="12" xl="2" lg="2">
            <FormGroup className="mt-2 mb-2 p-1">
              <Label>Select Child</Label>
              <select
                className="form-control "
                value={activeChild}
                onChange={(e) => {
                  setActiveChild(e.target.value);
                }}
              >
                <option value={`all`}>{props.t("All")}</option>
                {reportType === "2" &&
                  classStudent.map((val, key) => (
                    <option value={val.id} key={key}>
                      {val.name}
                    </option>
                  ))}
                {reportType === "3" &&
                  daycareStudent.map((val, key) => (
                    <option value={val.id} key={key}>
                      {val.name}
                    </option>
                  ))}
              </select>
            </FormGroup>
          </Col>
        )}

        {reportType != "4" && <Col md="2" xs="12" xl="2" lg="2">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>From Date</Label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                date ? selectedDateOps(date) : selectedDateOps(new Date());
              }}
              className="form-control"
              placeholderText={"Select Date"}

              maxDate={selectedEndDate}
              dateFormat="dd/MM/yyyy"
              showFullMonthYearPicker
            />
          </FormGroup>
        </Col>}
        {reportType != "4" && <Col md="2" xs="12" xl="2" lg="2">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>To Date</Label>
            <DatePicker
              selected={selectedEndDate}
              onChange={(date) => {
                date
                  ? selectedEndDateOps(date)
                  : selectedEndDateOps(new Date());
              }}
              className="form-control"
              placeholderText={"Select Date"}

              minDate={selectedDate}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              showFullMonthYearPicker
            />
          </FormGroup>
        </Col>}
        {reportType == "4" && <Col md="2" xs="12" xl="2" lg="2">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>Select Date</Label>
            <DatePicker
              selected={selectedDay}
              onChange={(date) => {
                date
                  ? setSelectedDay(date)
                  : setSelectedDay(new Date());
              }}
              className="form-control"
              placeholderText={"Select Date"}
              dateFormat="dd/MM/yyyy"
              showFullMonthYearPicker
            />
          </FormGroup>
        </Col>}
        <Col md="2" xs="12" xl="2" lg="2">
          <div className="mt-2">
            <div className="mt-3">
              <button
                className="btn btn-primary mt-4 mr-2"
                onClick={getAttendanceReport}
              >
                {props.t("Generate")}
              </button>
            </div>
          </div>
        </Col>



      </Row>

      {gotReport && attendanceReport.length > 0 && (
        <Row>
          <Col md="12" xs="12" xl="12" lg="12" className="d-flex align-items-center justify-content-between mt-1 mb-1">
            <div>
              {reportType != '4' && <Label className="align-middle mt-2" style={{ marginRight: '30px' }}>{`Total check in : ${totalCheckIns}`}</Label>}
              {reportType != '4' && <Label className="align-middle ml-3 mt-2">{`Total check out : ${totalCheckOuts}`}</Label>}
            </div>
            <button
              className="btn btn-info"
              onClick={downloadTheReport}
            >
              <i className="bx bxs-download mr-1" />
              {props.t("Download")}
            </button>
          </Col>
        </Row>


      )}
      {(gotReport && reportType == "4" && classWiseAttendanceReport) && (<Row className="m-2">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <table className="table table-striped table-bordered table-sm">
            <thead>
              <tr>
                <th className="text-center">{props.t("Classroom")}</th>
                <th className="text-center">{props.t("Total Strength")}</th>
                <th className="text-center">{props.t("Total Checkin")}</th>
                <th className="text-center">{props.t("At School")}</th>
                <th className="text-center">{props.t("Checkout")}</th>
                <th className="text-center">{props.t("Absent")}</th>
                <th className="text-center">{props.t("Details")}</th>
              </tr>
            </thead>
            <tbody>
              {classWiseAttendanceReport.map((val, key) => (
                <tr className="text-center" key={key}>
                  <td>{val.classRoomName}</td>
                  <td>{val.total}</td>
                  <td>{val.checkIn}</td>
                  <td>{val.atSchool}</td>
                  <td>{val.checkOut}</td>
                  <td>{val.totalAbsents}</td>
                  <td><p className="text-primary" style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDetailsPopup(true);
                      setSelectedClassroom(val.id)
                    }}><u>Details</u></p></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Row>)}
      {gotReport && (reportType == "1" || reportType == "2") && (
        <Row>
          {attendanceReport.length > 0 && (

            <Col>


              <Row>
                <Col className="d-flex justify-content-start">
                  <button
                    className={selectedTab == "1" ? "btn btn-sm btn-primary m-2" : "btn btn-sm btn-secondary m-2"}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Calender Report
                  </button>

                  <button
                    className={selectedTab == "2" ? "btn btn-sm btn-primary m-2" : "btn btn-sm btn-secondary m-2"}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Data Report
                  </button>
                </Col>
              </Row>

              <Row>
                {selectedTab == "1" && attendanceCalenderReport &&
                  <div className="table-responsive mb-0" data-pattern="priority-columns">
                    <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                      <Thead>
                        <Tr>
                          {attendanceCalenderReport.dayOfWeekRow.map((val2, key) => (
                            <Th key={key}>{val2}</Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Thead>
                        <Tr>
                          {attendanceCalenderReport.title.map((val2, key) => (
                            <Th key={key}>{val2}</Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {attendanceCalenderReport.finalData && attendanceCalenderReport.finalData.map((cData, key) => (
                          <Tr key={key}>
                            {cData.map((val2, key2) => (
                              <Td key={key2}>{val2}</Td>
                            ))}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>}

                {selectedTab == "2" && getAttendanceDataUI()}

              </Row>
            </Col>
          )}
          {attendanceReport.length === 0 && (
            <Col>
              <h3 className="text-center text-info mt-3">
                {props.t("No Report Found For The Filter")}
              </h3>
            </Col>
          )}
        </Row>
      )}

      {(gotReport && reportType == "3") && <Row>
        {/* {console.log('1333', attendanceReport)} */}
        {getAttendanceDataUI()}
        {attendanceReport.length === 0 && (
          <Col>
            <h3 className="text-center text-info mt-3">
              {props.t("No Report Found For The Filter")}
            </h3>
          </Col>
        )}

      </Row>}

      {loading && !gotReport &&
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {"Preparing Report"}</Label>
            </div>
          </Col>
        </Row>
      }
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { teachers } = state.Teacher;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  const {
    teacherAttendanceList,
    gotAttendance,
    kidAttendanceList,
    daycareAttendance,
  } = state.Attendance;
  return {
    teachers,
    classrooms,
    kids,
    teacherAttendanceList,
    gotAttendance,
    kidAttendanceList,
    daycareAttendance,
  };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getTeachersForSchool,
      getClassroomsForSchoolId,
      getAllKids,
      getAttendanceForTeacherIds,
      getAttendanceForKidIds,
      getDaycareAttendanceForKidIds,
    })(AttendanceReport)
  )
);
