import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Button,
  Modal,
  Media,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Progress,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert
} from "reactstrap";
import toastr from "toastr";
import * as Constants from "../../Constents";
import ReactApexChart from "react-apexcharts";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from "moment";
import { Parse } from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import KidPicker from "./KidPicker";
import KidPickerForTerms from "./KidPickerForTerms";

import {
  getInvoicesForProgramId,
  getAdmissionKidsForSchool,
  setSelecetdSchool,
  getAllKidsForGroup,
  saveGroup,
  saveGroupMaps,
  getGroupForId,
  getGroupMapsForGroupId,
  getVerifiedStatusForInvoices,
  getClassroomsForSchoolId,
  createInvoiceForProgramKid,
  setProgramInvoiceCreationLoader,
  deleteProgram,
  setDeletedSuccessfullyProgram,
  getUserTeacher,
  editProgramTerms,
  getProgramTermsForProgramId,
  createInvoiceForKids,
  clearResponseForCreateInvoiceForKids,
  updateResultGroupMaps,
  getFeeTemplateForSchoolId,
  getAllDeletedKidsForSchoolIdOnlyForArchives,
  getFranchise,
} from "../../store/actions";
import FeeTab from "./FeeComponent";
import classnames from "classnames";
import COUNTRY_JSON from '../../assets/other/countries.json'


const ProgramView = (props) => {

  const matchProgramView = matchPath(props.history.location.pathname, {
    path: "/programview/:schoolId/:programId/:groupId",
    exact: true,
    strict: false,
  });


  const schoolId = matchProgramView && matchProgramView.params.schoolId;
  const programId = matchProgramView && matchProgramView.params.programId;
  const groupId = matchProgramView && matchProgramView.params.groupId;


  const sortCompletedStyle = "bx bx-right-arrow-circle mt-2"
  const sortPartiallypaidStyle = "bx bx-right-arrow-circle mt-2"
  const sortPendingStyle = "bx bx-right-arrow-circle mt-2"


  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [programName, setProgramName] = useState("");
  const [programObject, setProgramObject] = useState(undefined);
  const [sectionCurrentlySelected, setSectionCurrentlySelected] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [mainList, setMainList] = useState([]);
  const [reload, setReload] = useState(false);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [selectedItemForInvoiceCreation, setSelectedItemForInvoiceCreation] = useState(undefined);
  const [isProgramCompleted, setIsProgramCompleted] = useState(false)
  const [isProgramUpcoming, setIsProgramUpcoming] = useState(false);
  const [isTeacherPermission, setIsTeacherPermission] = useState(false);
  const [isProgramHasTerm, setIsProgramHasTerm] = useState(false);
  const [kidPickerModal, setKidPickerModal] = useState(false);
  const [selectedKidListForPicker, setSelectedKidListForPicker] = useState([]);
  const [termsList, setTermsList] = useState([]);
  const [selectedTerms, setselectedTerms] = useState([]);
  const [selectedKidsFromPickerView, setSelectedKidsFromPickerView] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedKidsForInvoice, setSelectedKidsForInvoice] = useState([]);
  const [activeTab, setactiveTab] = useState(0);
  const [addStudentPopupActiveTab, setAddStudentPopupActiveTab] = useState(0);
  const [addStudentPopupProgressValue, setAddStudentPopupProgressValue] = useState(50.00);
  const [showInvoiceAlert, setShowInvoiceAlert] = useState(false);
  const [kidPickerForTermsNextButtonClick, setKidPickerForTermsNextButtonClick] = useState(false);
  const [clickedOnCreateInvoiceButton, setClickedOnCreateInvoiceButton] = useState(false);
  const [listOfTermsToEnable, setListOfTermsToEnable] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [creatingInvoiceLabelCount, setCreatingInvoiceLabelCount] = useState();
  const [totalKidsForInvoiceForTerms, setTotalKidsForInvoiceForTerms] = useState(undefined);
  const [totalInvoicesForTermsToSave, setTotalInvoicesForTermsToSave] = useState(undefined);
  const [disableInvoiceCreationButton, setDisableInvoiceCreationButton] = useState(false);

  const [removeObject, setRemoveObject] = useState(undefined);
  const [currency, setCurrency] = useState("₹");
  const [showRoyalty, setShowRoyalty] = useState(false);
  const [completeDisable, setCompleteDisable] = useState(false);

  const [showBatchButton, setShowBatchButton] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [programTag, setProgramTag] = useState();
  const [batchSelcetWarning, setbatchSelcetWarning] = useState(false);
  const [selectedGroupKid, setSelectedGroupKid] = useState()
  const [selectedBatche, setSelectedBatche] = useState("All Batches")

  const resetAddStudentPopup = () => {
    setAddStudentPopupActiveTab(0)
    setSelectedKidsFromPickerView([])
    if (isProgramHasTerm) {
      setAddStudentPopupProgressValue(50.00)
    } else {
      setAddStudentPopupProgressValue(100.00)
    }
  }

  const [miniStat, miniStatOps] = useState({
    completedInvoiceCount: 0,
    completedInvoiceAmount: 0,
    partialPaidInvoiceCount: 0,
    partialPaidInvoiceAmount: 0,
    pendingInvoiceCount: 0,
    pendingInvoiceAmount: 0,
  });
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${currency} ${val}`;
      },
      offsety: 100,
      style: {
        fontSize: "14px",
        fontWeight: 800,
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.7,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => `${currency}`,
        },
      },
    },
    legend: {
      show: false,
    },
    colors: ["#34c38f", "#f46a6a"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [`Paid Amount`, `Pending Amount`],
      labels: {
        show: false,
      },
    },
  };
  const [series, seriesOps] = useState([]);


  useEffect(() => {
    clearProps();

    if (matchProgramView && schoolId && programId && groupId) {
      props.getAllKidsForGroup(schoolId);
      props.getAdmissionKidsForSchool(schoolId);
      props.getGroupMapsForGroupId(groupId);
      props.getGroupForId(groupId);
      props.getFeeTemplateForSchoolId(schoolId);
      props.getAllDeletedKidsForSchoolIdOnlyForArchives(schoolId);

      getProgramForId(programId)
      if (Parse.User.current().attributes.teacherId) {
        props.getUserTeacher(Parse.User.current().attributes.teacherId)
      }
      props.getClassroomsForSchoolId(schoolId);
    }
  }, [schoolId, programId]);

  useEffect(() => {
    if (props.programTerms && props.programTerms.length > 0) {
      setTermsList(props.programTerms)
    }
  }, [props.programTerms])


  const saveSelectedKidsToProgramTerms = (selectedKids, selectedTerms) => {
    if (selectedTerms.length > 0) {
      let termsToUpdate = []
      for (const i in selectedTerms) {
        let kidIds = selectedTerms[i].attributes.kidIds ?? []
        let shouldSave = false
        for (const j in selectedKids) {
          if (!kidIds.includes(selectedKids[j].id)) {
            kidIds.push(selectedKids[j].id)
            shouldSave = true
          }
        }
        selectedTerms[i].set("kidIds", kidIds)
        if (shouldSave) {
          termsToUpdate.push(selectedTerms[i])
        }
      }
      if (termsToUpdate.length > 0) {
        props.editProgramTerms(termsToUpdate)
      }
    }
  }

  useEffect(() => {
    if (props.userTeacher && props.userTeacher.attributes.featurePermissions && props.userTeacher.attributes.featurePermissions.length > Constants.TEACHER_PROGRAMS && props.userTeacher.attributes.featurePermissions[Constants.TEACHER_PROGRAMS] === 2) {
      setIsTeacherPermission(true)
    } else {
      setIsTeacherPermission(false)

    }
  }, [props.userTeacher])

  useEffect(() => {

    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[2] > 0) {
      setShowRoyalty(true);
      if (props.franchise.attributes.features[2] == 2) {
        setCompleteDisable(true)
      } else {
        setCompleteDisable(false)
      }
    } else {
      setShowRoyalty(false);
      setCompleteDisable(false)
    }
  }, [props.franchise]);

  const getProgramForId = (programId) => {
    var query = new Parse.Query('Program');
    query.equalTo('objectId', programId);
    query.limit(1000);
    query.find().then((results) => {
      if (results && results.length > 0) {
        setProgramObject(results[0])
        if (results[0].attributes.hasTerm) {
          props.getProgramTermsForProgramId(results[0].id)
        }
        setIsProgramHasTerm(results[0].attributes.hasTerm)
        let todayDate = new Date()
        if (results[0].attributes.startDate < todayDate && results[0].attributes.endDate > todayDate) {
          setIsProgramCompleted(false)
          setIsProgramUpcoming(false)
        } else if (results[0].attributes.startDate > todayDate && results[0].attributes.endDate > todayDate) {
          setIsProgramCompleted(false)
          setIsProgramUpcoming(true)
        } else {
          setIsProgramCompleted(true)
          setIsProgramUpcoming(false)
        }
        setProgramName(results[0].attributes.name ?? "")
      }
    })
  }

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }

    if (props.selectedSchool && props.selectedSchool.attributes.countryCode != "IN") {
      for (const cc of COUNTRY_JSON) {
        if (cc.code == props.selectedSchool.attributes.countryCode) {
          setCurrency(cc.currency.currencySymbol);
          break;
        }
      }
    }
    if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
      props.getFranchise(props.selectedSchool.attributes.franchiseId);
    }
  }, [props.selectedSchool]);

  useEffect(() => {
    if (
      props.groupmaps &&
      props.groupmaps.length > 0 &&
      props.kids &&
      props.admissionKids
    ) {
      let tempList = [];
      let tempDeletedList = [];
      let tempSelectedKidList = [];

      let tempKidList = [];
      tempKidList.push.apply(tempKidList, props.kids);
      tempKidList.push.apply(tempKidList, props.admissionKids);
      tempKidList.push.apply(tempKidList, props.deletedKids);

      for (var i = 0; i < props.groupmaps.length; i++) {
        if (props.groupmaps[i] && props.groupmaps[i].attributes.groupId == groupId) {
          let kidObjList = tempKidList.filter(
            (kid) => kid.id === props.groupmaps[i].attributes.userId
          );
          if (kidObjList.length > 0) {
            let tempObject = {};
            tempObject.kidName = kidObjList[0].attributes.Name;
            tempObject.kidId = kidObjList[0].id;
            tempObject.kidImage = kidObjList[0].attributes.Photo
              ? kidObjList[0].attributes.Photo._url
              : undefined;
            tempObject.kidParseObject = kidObjList[0];
            tempObject.groupmapId = props.groupmaps[i].id;
            tempObject.groupMapObject = props.groupmaps[i];
            let classDec = [];
            for (let i in props.classrooms) {
              classDec[props.classrooms[i].id] = props.classrooms[i];
            }
            let primaryClass = classDec[kidObjList[0].attributes.ClassRoomID];
            tempObject.className = primaryClass
              ? primaryClass.attributes.Name
              : "";
            if (
              kidObjList[0].attributes.SchoolID == undefined ||
              kidObjList[0].attributes.SchoolID.length === 0
            ) {
              tempObject.className = "Admission";
            }
            if (kidObjList[0].attributes.Deleted) {
              tempObject.className = "Archived/Deleted";
              tempDeletedList.push(tempObject);
            } else {
              if (
                selectedBatche === "All Batches" ||
                (props.groupmaps[i].attributes.tags &&
                  props.groupmaps[i].attributes.tags[0] === selectedBatche)
              ) {
                tempList.push(tempObject);
                tempSelectedKidList.push(kidObjList[0]);
              }
            }
          }
        }
      }
      if (tempDeletedList && tempDeletedList.length > 0) {
        tempList.push.apply(tempList, tempDeletedList);
      }
      setMainList(tempList);
      setSelectedKidListForPicker(tempSelectedKidList);
      props.getInvoicesForProgramId(programId, false);
    }
  }, [props.groupmaps, props.kids, props.classrooms, props.admissionKids, props.deletedKids, selectedBatche]);


  useEffect(() => {
    if (reload) {
      setReload(false)
      props.getInvoicesForProgramId(programId, true);
    }
  }, [reload])

  useEffect(() => {
    if (props.invoicesForProgramLoader === false) {
      setShowCreateInvoiceModal(true)
      props.setProgramInvoiceCreationLoader(undefined)
      setReload(true)
    }
  }, [props.invoicesForProgramLoader])

  const getInvoiceTermStatusMsg = (invoices) => {
    let tempTermsList = []
    let msg = "Includes invoice for "

    for (const invoice of invoices) {
      for (const term of termsList) {
        if (invoice.attributes.programTermIds.includes(term.id)) {
          tempTermsList.push(term.attributes.termName)
        }
      }
    }

    return msg + tempTermsList.join(", ")
  }

  useEffect(() => {

    if (mainList.length > 0 && termsList.length > 0 && isProgramHasTerm) {

      let term = termsList[activeTab]
      let tempDisplay = []
      for (const kidId of term.attributes.kidIds) {
        if (selectedBatche != "All Batches") {
          let groupMap = props.groupmaps.filter((gm) =>
            kidId == gm.attributes.userId
          );

          if (groupMap && groupMap.length > 0 &&
            groupMap[0].attributes.tags && groupMap[0].attributes.tags[0] == selectedBatche) {

          } else {
            continue;
          }
        }
        for (const item of mainList) {
          if (item.kidParseObject.id === kidId) {
            let newItem = { ...item }
            let termInvoices = []
            if (item.invoiceParseList) {
              termInvoices = item.invoiceParseList.filter((invoice) =>
                invoice.attributes.programTermIds.includes(term.id))
              newItem.invoiceParseList = termInvoices
            }
            if (termInvoices.length > 0) {
              newItem.invoiceCount = termInvoices.length
              newItem.invoiceParseList = termInvoices
              let processedInvoice = processInvoices(termInvoices)
              newItem.invoiceList = processedInvoice.invoiceList
              newItem.pendingInvoiceCount = processedInvoice.pendingInvoiceCount
              newItem.partiallyPaidInvoiceCount = processedInvoice.partiallyPaidInvoiceCount
              newItem.paidInvoiceCount = processedInvoice.paidInvoiceCount
            }
            else {
              newItem.invoiceCount = 0
              newItem.invoiceList = []
              newItem.invoiceParseList = []
              newItem.pendingInvoiceCount = 0
              newItem.partiallyPaidInvoiceCount = 0
              newItem.paidInvoiceCount = 0

            }
            tempDisplay.push(newItem)
          }
        }
      }

      setDisplayList(tempDisplay)
    }
  }, [activeTab, mainList, termsList, selectedBatche])

  useEffect(() => {

    if (props.invoicesForProgram) {

      calculateMiniStat(props.invoicesForProgram);
      let tempMainList = [...mainList]
      for (var i = 0; i < tempMainList.length; i++) {
        let invoiceList



        if (isProgramHasTerm) {
          invoiceList = props.invoicesForProgram.filter((invoice) =>
            tempMainList[i].kidId === invoice.attributes.recipientId && termsList.length > activeTab
          )
        } else {
          invoiceList = props.invoicesForProgram.filter((invoice) =>
            tempMainList[i].kidId === invoice.attributes.recipientId
          )
        }


        tempMainList[i].invoiceCount = invoiceList.length
        tempMainList[i].invoiceParseList = invoiceList
        let processedInvoice = processInvoices(invoiceList)
        tempMainList[i].invoiceList = processedInvoice.invoiceList
        tempMainList[i].pendingInvoiceCount = processedInvoice.pendingInvoiceCount
        tempMainList[i].partiallyPaidInvoiceCount = processedInvoice.partiallyPaidInvoiceCount
        tempMainList[i].paidInvoiceCount = processedInvoice.paidInvoiceCount


      }
      setMainList(tempMainList)

    }
    if (props.invoicesForProgram) {
      setIsLoading(false)
    }

    if (showCreateInvoiceModal) {
      setShowCreateInvoiceModal(false)
    }
  }, [props.invoicesForProgram]);

  const processInvoices = (invoiceList) => {

    let tempInvoice = [];
    let pendingInvoiceCount = 0
    let partiallyPaidInvoiceCount = 0
    let paidInvoiceCount = 0
    for (const val of invoiceList) {
      let temp = {};
      temp.Obj = val;
      temp.id = val.id;
      temp.invoiceNumber = val.attributes.invoiceNumber;
      temp.date = val.attributes.createdAt
        ? Moment(val.attributes.createdAt).format("DD-MMM-YYYY")
        : "--";
      temp.invoiceTitle = val.attributes.title;
      temp.status = {};
      temp.paidStatus = val.attributes.paidStatus
        ? val.attributes.paidStatus
        : 0;
      if (val.attributes.paidStatus === 1) {
        temp.status.stat = "Paid";
        temp.status.color = "success m-0 p-0";
        paidInvoiceCount += 1
      } else if (val.attributes.paidStatus === 3) {
        partiallyPaidInvoiceCount += 1
        temp.status.stat = "Partially Paid";
        temp.status.color = "warning m-0 p-0";
      } else {
        pendingInvoiceCount += 1
        temp.status.stat = "Pending";
        temp.status.color = "danger m-0 p-0";
      }
      if (val.attributes.tags) {
        temp.tag = val.attributes.tags[0] ? val.attributes.tags[0] : "";
      }
      temp.totalAmount = val.attributes.amount ? val.attributes.amount : "0";
      temp.paidAmount = val.attributes.paidAmount
        ? val.attributes.paidAmount
        : 0;
      temp.balanceAmount = +temp.totalAmount - temp.paidAmount;
      temp.isActionActive = false;
      temp.showPendingVerify = false;
      tempInvoice.push(temp);
    }
    return ({
      invoiceList: tempInvoice,
      pendingInvoiceCount: pendingInvoiceCount,
      partiallyPaidInvoiceCount: partiallyPaidInvoiceCount,
      paidInvoiceCount: paidInvoiceCount
    })
  }


  useEffect(() => {
    if (mainList != undefined) {
      setIsLoading(false)

    }
  }, [mainList])

  const handleCreateInvoiceForKid = (selectedItem) => {
    if (programObject) {
      props.createInvoiceForProgramKid(selectedItem.kidId, programObject.attributes.templateId, props.selectedSchool, programObject)
    }
  }

  function getTimeStringForDate(date) {
    return Moment(date).format("MMM Do YY");
  }

  const calculateMiniStat = (invocies) => {
    let tempMap = {
      completedInvoiceCount: 0,
      completedInvoiceAmount: 0,
      partialPaidInvoiceCount: 0,
      partialPaidInvoiceAmount: 0,
      pendingInvoiceCount: 0,
      pendingInvoiceAmount: 0,
    };
    for (const val of invocies) {
      if (val.attributes.paidStatus === 1) {
        tempMap.completedInvoiceCount += 1;
        tempMap.completedInvoiceAmount += val.attributes.paidAmount;
      } else if (val.attributes.paidStatus === 3) {
        tempMap.partialPaidInvoiceCount += 1;
        tempMap.completedInvoiceAmount += val.attributes.paidAmount;
        tempMap.pendingInvoiceAmount +=
          +val.attributes.amount - val.attributes.paidAmount;
      } else {
        tempMap.pendingInvoiceCount += 1;
        tempMap.pendingInvoiceAmount += +val.attributes.amount;
      }
    }
    const tempseries = [
      {
        data: [tempMap.completedInvoiceAmount, tempMap.pendingInvoiceAmount],
      },
    ];
    miniStatOps(tempMap);
    seriesOps(tempseries);
  };

  const handleDeleteProgram = () => {
    if (programObject != null) {
      let tempProgramId = programObject.id
      programObject.set("deleted", true)
      props.deleteProgram(programObject)
      if (props.singleGroupHolder != null) {
        props.singleGroupHolder.set("deleted", true)
        props.singleGroupHolder.save()
      }
      if (isProgramHasTerm) {
        if (props.programTerms && props.programTerms.length > 0) {
          let termObjToDelete = []
          for (const term of props.programTerms) {
            if (term.attributes.programId === tempProgramId) {
              term.set("isDeleted", true)
              termObjToDelete.push(term)
            }
          }
          if (termObjToDelete.length > 0) {
            Parse.Object.saveAll(termObjToDelete).then(
              (results) => {

              },
              (error) => {
                console.log("Error", error);
              }
            );
          }
        }
      }
      setShowDeleteAlert(false)
    }
  }
  useEffect(() => {

    if (props.deletedSuccessfullyProgram) {
      props.setDeletedSuccessfullyProgram(undefined)
      window.history.back()
    }
  }, [props.deletedSuccessfullyProgram]);




  const handleSaveButtonPressedForAddStudent = (selectedKids, isWithoutTerm = false) => {
    if (props.singleGroupHolder) {

      handleEditSaveGroupMaps(props.singleGroupHolder, selectedKids, isWithoutTerm);
    } else {
      //Show toast Please try again.
      toastr.warning("Please refresh, and try again")
    }
  };

  const handleEditSaveGroupMaps = (resultGroup, selectedKids, isWithoutTerm = false) => {
    const GroupMapObject = Parse.Object.extend("GroupMap");

    let newCount = 0;
    var groupMapTeacherIds = [];
    var groupMapKidIds = [];
    var groupMapsToDelete = [];

    for (var l = 0; l < props.groupmaps.length; l++) {
      if (props.groupmaps[l].attributes.userType === 2) {
        groupMapTeacherIds.push(props.groupmaps[l].attributes.userId);
      } else if (props.groupmaps[l].attributes.userType === 1) {
        groupMapKidIds.push(props.groupmaps[l].attributes.userId);
      }
    }

    for (var i = 0; i < props.groupmaps.length; i++) {
      var shouldDelete = true;
      if (props.groupmaps[i].attributes.userType === 2) {
        for (var j = 0; j < selectedTeachers.length; j++) {
          if (props.groupmaps[i].attributes.userId === selectedTeachers[j].id) {
            shouldDelete = false;
          }
        }
      } else if (props.groupmaps[i].attributes.userType === 1) {
        for (var j = 0; j < selectedKids.length; j++) {
          if (props.groupmaps[i].attributes.userId === selectedKids[j].id) {
            shouldDelete = false;
          }
        }
      }
      if (shouldDelete) {
        //groupMapsToDelete.push(props.groupmaps[i]);
      }
    }

    var teacherObjectsToSave = selectedTeachers.filter(
      (selectedTeacher) => !groupMapTeacherIds.includes(selectedTeacher.id)
    );

    var kidObjectsToSave = selectedKids.filter(
      (selectedKid) => !groupMapKidIds.includes(selectedKid.id)
    );

    var groupMapObjectList = [];

    if (teacherObjectsToSave.length > 0) {
      var i = 0;
      for (i = 0; i < teacherObjectsToSave.length; i++) {
        var groupMapObject = new GroupMapObject();
        groupMapObject.set("groupId", resultGroup.id);
        groupMapObject.set("userId", teacherObjectsToSave[i].id);
        groupMapObject.set("userType", 2);
        groupMapObjectList.push(groupMapObject);
      }
    }
    if (kidObjectsToSave.length > 0) {
      setSelectedKidsForInvoice(kidObjectsToSave);

      var j = 0;
      for (j = 0; j < kidObjectsToSave.length; j++) {
        var groupMapObject = new GroupMapObject();
        groupMapObject.set("groupId", resultGroup.id);
        groupMapObject.set("userId", kidObjectsToSave[j].id);
        groupMapObject.set("userType", 1);
        groupMapObjectList.push(groupMapObject);
      }
    }
    if (isWithoutTerm) {
      if (groupMapsToDelete.length > 0) {
        var i = 0;
        for (i = 0; i < groupMapsToDelete.length; i++) {
          var tempObject = groupMapsToDelete[i];
          tempObject.set("isDeleted", true);
          groupMapObjectList.push(tempObject);
        }
      }
      newCount = mainList.length + kidObjectsToSave.length - groupMapsToDelete.length
    } else {
      newCount = mainList.length + kidObjectsToSave.length
    }

    if (props.singleGroupHolder) {
      var groupObject = props.singleGroupHolder;
      if (
        groupObject.attributes.participantsCount !== newCount
      ) {
        groupObject.set("participantsCount", newCount);
        props.saveGroup(groupObject);
        if (programObject) {
          programObject.set("participantsCount", newCount);
          programObject.save().then(
            (result) => {
              setProgramObject(result)
            },
            (error) => {

            }
          );
        }
      }
    }

    if (groupMapObjectList.length > 0) {
      props.saveGroupMaps(groupMapObjectList);
    } else {
      //setSaveSuccess(true);
    }
  };

  const closeAddStudentModal = () => {
    setDisableInvoiceCreationButton(false)
    setKidPickerModal(false)
    setAddStudentPopupActiveTab(0)
    setselectedTerms([])
    setClickedOnCreateInvoiceButton(false)
    setListOfTermsToEnable([])
  }

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const getListOfTermsToEnable = (invoices) => {
    let termsToReturn = []
    for (const term of termsList) {

      let shouldAddTerm = true
      for (const invoice of invoices) {

        if (invoice.attributes.programTermIds.includes(term.id)) {
          shouldAddTerm = false
        }
      }
      if (shouldAddTerm) {
        termsToReturn.push(term.id)
      }
    }
    return termsToReturn
  }

  const getInvoiceListFromMainList = (kidId) => {
    for (const item of mainList) {
      if (item.kidId === kidId) {
        return item.invoiceParseList
      }
    }
    return []
  }

  const getTableView = (listToDisplay) => {
    return (
      <div className="mt-1">
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <div className="m-1">
              <select
                className="form-select"
                value={selectedBatche}
                onChange={(e) => { setSelectedBatche(e.target.value) }}
              >
                <option value="All Batches">All Batches</option>
                {programObject && programObject.attributes && programObject.attributes.tags && programObject.attributes.tags
                  .map((batch) => (
                    <option key={batch} value={batch}>
                      {batch}
                    </option>
                  ))}
              </select>
            </div>
          </Col>
        </Row>
        <div className={"border rounded p-3 mb-1 m-1"}>
          <Row style={{ height: "8px", paddingBottom: "20px" }}>
            <Col lg={4} md={5}>
              <div >
                <div className=" ml-5">
                  <Label className="mb-1 font-size-14">
                    Student Name/Class
                  </Label>
                </div>
              </div>
            </Col>
            <Col lg={5} md={4}>
              <div >
                <div className="">
                  <Label className="mb-1 font-size-14">
                    Status
                  </Label>
                </div>
              </div>
            </Col>
            <Col lg={3} md={3}>
              <div className="float-right">
                <Label className="mb-1 font-size-14">
                  Actions
                </Label>
              </div>
            </Col>
          </Row>
        </div>
        {listToDisplay && listToDisplay.length > 0 ? listToDisplay.map((sectionObject, sectionKey) => (
          <Card className="mb-1 bg-whites" key={sectionKey}>
            <div className={sectionCurrentlySelected === sectionObject.groupmapId ? "border border-primary rounded p-3" : "border rounded p-3"}>
              <Row>
                <Col lg={4} md={5}>
                  <div className="d-flex">
                    <div style={{ marginRight: '10px' }}>{
                      !sectionObject.kidImage
                        ? <div className="avatar-xs">
                          <span className="avatar-title rounded-circle">
                            {sectionObject.kidName ? sectionObject.kidName.charAt(0) : ''}
                          </span>
                        </div>
                        : <div className="">
                          <img className="rounded-circle avatar-xs" src={sectionObject.kidImage} alt="" />
                        </div>
                    }</div>
                    <div className=" ml-3 float-right ">
                      <Label className="pt-1 font-size-14">
                        {sectionObject.kidName} ({sectionObject.className})
                      </Label>
                    </div>
                  </div>
                  <div >
                    <div style={{ marginLeft: '1cm' }}>
                      {<small  >Batch:&nbsp;</small>}
                      {!sectionObject.groupMapObject.attributes.tags ? (<small className='text-success' style={{ cursor: 'pointer' }}
                        onClick={(() => {
                          setShowTagModal(true)
                          setSelectedGroupKid(sectionObject.groupMapObject)
                          setProgramTag(sectionObject.groupMapObject.attributes.tags ? sectionObject.groupMapObject.attributes.tags[0] : "")
                        })}
                      ><u>Set</u></small>) :
                        (<small className='text-success' style={{ cursor: 'pointer' }}
                          onClick={(() => {
                            setShowTagModal(true)
                            setSelectedGroupKid(sectionObject.groupMapObject)
                            setProgramTag(sectionObject.groupMapObject.attributes.tags ? sectionObject.groupMapObject.attributes.tags[0] : "")
                          })}
                        ><u>{sectionObject.groupMapObject.attributes.tags ? sectionObject.groupMapObject.attributes.tags[0] : ""}</u></small>)}
                    </div>
                  </div>
                </Col>
                <Col lg={5} md={4}>
                  {sectionObject.invoiceCount > 0 && <div className="pt-1">
                    <div className=" ml-0">
                      {sectionObject.pendingInvoiceCount > 0 && <small className="pt-1 mr-4 font-size-14 text-danger">
                        Pending : <Label className="text-muted">{sectionObject.pendingInvoiceCount}</Label>
                      </small>}
                      {sectionObject.partiallyPaidInvoiceCount > 0 && <small className="pt-1 mr-4 font-size-14 text-warning">
                        Partially Paid : <Label className="text-muted">{sectionObject.partiallyPaidInvoiceCount}</Label>
                      </small>}
                      {sectionObject.paidInvoiceCount > 0 && <small className="pt-1 mr-4 font-size-14 text-success">
                        Paid : <Label className="text-muted">{sectionObject.paidInvoiceCount}</Label>
                      </small>}
                      <br />{isProgramHasTerm && getInvoiceTermStatusMsg(sectionObject.invoiceParseList)}
                    </div>
                  </div>}
                </Col>
                <Col
                  lg={3}
                  md={3}
                >
                  <div className="float-right"
                  >
                    {props.invoicesForProgram !== undefined && <div>
                      {(sectionObject.invoiceCount == null || sectionObject.invoiceCount === 0) ?
                        <>{isTeacherPermission && <> <Button
                          color="primary"
                          className="btn btn-sm btn-primary m-1"
                          onClick={() => {
                            if (isProgramHasTerm) {
                              setClickedOnCreateInvoiceButton(true)
                              setListOfTermsToEnable(getListOfTermsToEnable(getInvoiceListFromMainList(sectionObject.kidId) ?? []))
                              setKidPickerModal(true)
                              setSelectedKidsFromPickerView([sectionObject.kidParseObject])
                              setAddStudentPopupActiveTab(1)
                            } else {
                              setSelectedItemForInvoiceCreation(sectionObject)
                              setShowCreateInvoiceModal(true)
                            }
                          }}
                        >
                          Create Invoice
                        </Button>
                          {<button
                            className="btn btn-sm btn-danger mt-1 m-1"
                            onClick={() => {
                              setRemoveObject(sectionObject)
                            }}
                          >
                            Remove
                          </button>}

                        </>



                        }</> :
                        <div className="mt-1 pt-1">
                          <Link
                            className="font-size-14"
                            to={"#"}
                            onClick={(e) => {
                              e.preventDefault()
                              if (
                                sectionCurrentlySelected ===
                                sectionObject.groupmapId
                              ) {
                                setSectionCurrentlySelected("");
                              } else {
                                setSectionCurrentlySelected(
                                  sectionObject.groupmapId
                                );
                              }
                            }}
                          >
                            Number of Invoices ( {sectionObject.invoiceCount} )
                          </Link></div>}
                    </div>}
                  </div>
                </Col>
              </Row>
            </div>
            <Collapse
              isOpen={sectionCurrentlySelected === sectionObject.groupmapId}
            >
              <div className="m-3">
                {sectionObject.invoiceList && sectionObject.invoiceList.length > 0 &&
                  <FeeTab
                    disableWrite={!isTeacherPermission}
                    invoiceList={sectionObject.invoiceList}
                    schoolId={schoolId}
                    currency={currency}
                    reloadInvoices={() => {
                      setReload(true)
                    }}
                    showRoyalty={showRoyalty}
                    completeDisable={completeDisable}
                  ></FeeTab>}
              </div>
            </Collapse>
          </Card>
        )) :
          <div className="text-center mt-4">
            <Label className="text-muted  font-size-16">
              No Participants to Show !!
            </Label>
          </div>
        }
      </div>
    )
  }

  const getFilterTermList = () => {
    let filteredList
    if (termsList.length > 0 && mainList.length > 0) {
      filteredList = mainList.filter((listItem) =>
        (termsList[activeTab].attributes.kidIds.includes(listItem.kidParseObject.id)))
    }

    return filteredList ?? []
  }
  useEffect(() => {
    if (props.resultGroupMapsAfterSave) {
      setSaveSuccess(true);
    }
  }, [props.resultGroupMapsAfterSave]);

  function clearProps() {
    props.updateResultGroupMaps(null);
    props.clearResponseForCreateInvoiceForKids();
  }

  const createInvoiceForTerms = (selectedKids, terms, createInvoice) => {

    let selectedTerms = terms.sort(function (a, b) {
      var order1 = a.order,
        order2 = b.order;
      if (order1 < order2)
        //sort string ascending
        return -1;
      if (order1 > order2) return 1;
      return 0; //default return value (no sorting)
    });
    let templateIds = []
    for (const st of selectedTerms) {
      templateIds.push(st.attributes.feeTemplateId)
    }
    //Collecting templateIds

    var paymentOptions = Parse.Object.extend("FeeTemplateItem");
    var query = new Parse.Query(paymentOptions);
    query.containedIn("feeTemplateId", templateIds);
    query.notEqualTo("isDeleted", true);
    query.ascending("slno")
    query.find().then(
      (allTemplateItems) => {
        let createInvoiceCount = 0

        if (createInvoice) {
          createInvoiceCount = 1
        } else {
          for (const selectedKid of selectedKids) {
            let kidTermIds = []

            if (selectedTerms.length > 0) {
              for (const st of selectedTerms) {
                let kidIds = st.attributes.kidIds ?? []
                if (!kidIds.includes(selectedKid.id)) {
                  kidTermIds.push(st.id)
                }
              }
            }
            if (kidTermIds.length > 0) {
              createInvoiceCount += 1
            }
          }
        }

        setTotalKidsForInvoiceForTerms(createInvoiceCount)
        if (!(createInvoiceCount > 0)) {
          closeAddStudentModal()
        }


        let totalInvoices = []
        for (const selectedKid of selectedKids) {
          let kidTermIds = []
          let kidTemplateIds = []


          if (createInvoice) {
            for (const st of selectedTerms) {
              kidTermIds.push(st.id)
              kidTemplateIds.push(st.attributes.feeTemplateId)
            }

          } else if (selectedTerms.length > 0) {
            for (const st of selectedTerms) {
              let kidIds = st.attributes.kidIds ?? []
              if (!kidIds.includes(selectedKid.id)) {
                kidTermIds.push(st.id)
                kidTemplateIds.push(st.attributes.feeTemplateId)
              }
            }
          }


          let invoiceItems = []
          let totalAmount = 0;
          let totalTax = 0;
          let totalRoyalty = 0;

          if (kidTermIds.length > 0) {
            for (const i in kidTemplateIds) {
              let templateId = kidTemplateIds[i]
              let termId = kidTermIds[i]
              let counter = 0
              let items = allTemplateItems.filter((item) =>
                templateId === item.attributes.feeTemplateId
              )

              for (let val of items) {
                let temp = {};

                temp.slno = counter;
                counter++;
                temp.itemDescription = val.attributes.itemDescription;
                temp.dueDate = val.attributes.dueDate;
                temp.amount = val.attributes.amount;
                temp.discount = val.attributes.discount;
                temp.discountType = val.attributes.discountType;
                temp.discountDisabled = val.attributes.discount > 0;
                temp.discountRemark = val.attributes.discountRemark;
                temp.hsn = val.attributes.hsn;
                temp.totalAmount =
                  val.attributes.discountType === 1
                    ? val.attributes.amount - val.attributes.discount
                    : val.attributes.amount -
                    (val.attributes.amount * val.attributes.discount) / 100;
                temp.tax = val.attributes.tax
                totalAmount += temp.totalAmount
                if (temp.tax > 0) {
                  let tTax = roundToTwo(temp.totalAmount * 0.01 * temp.tax);
                  temp.taxAmount = tTax
                  totalTax = totalTax + tTax
                  temp.totalAmount = temp.totalAmount + tTax
                }
                temp.royalty = val.attributes.royalty;
                if (temp.royalty > 0) {
                  temp.royaltyAmount = (temp.totalAmount * temp.royalty) / 100
                  totalRoyalty += temp.royaltyAmount;
                } else {
                  temp.royaltyAmount = 0;
                }
                temp.termId = termId
                invoiceItems.push(temp);
              }
            }
            var currentdate = programObject.attributes.startDate ?? new Date();
            let year = currentdate.getFullYear();
            if (currentdate.getMonth() <= 2) {
              year = year - 1;
            }
            var invoiceObject = {
              instituteId: props.selectedSchool.attributes.instituteId,
              schoolId: schoolId,
              title: programObject.attributes.name,
              createdBy: Parse.User.current().attributes.teacherId,
              amount: totalAmount,
              recipientType: 6,
              recipientIds: [selectedKid.id],
              tags: [
                `${year}-${year + 1 - 2000}`,
              ],
              items: invoiceItems,
            };
            if (totalRoyalty > 0) {
              invoiceObject.royaltyAmount = totalRoyalty;
              invoiceObject.isRoyaltyInvoice = true;
            }
            if (programId != undefined) {
              invoiceObject["programId"] = programId;
            } else {
              invoiceObject["programId"] = resultProgramAfterSave.id;
            }
            invoiceObject.programTermIds = kidTermIds

            totalInvoices.push(invoiceObject)
          }
        }
        if (totalInvoices.length > 0) {
          setCreatingInvoiceLabelCount((creatingInvoiceLabelCount ?? 0) + 1)
          setTotalInvoicesForTermsToSave(totalInvoices)
        }
        saveSelectedKidsToProgramTerms(selectedKids, selectedTerms)
      },
      (error) => { }
    );
  }
  useEffect(() => {
    if (totalInvoicesForTermsToSave && totalInvoicesForTermsToSave.length > 0) {

      var i = 0

      var check = function () {
        if (i === totalInvoicesForTermsToSave.length) {
          return
        } else {
          setTimeout(() => {
            props.createInvoiceForKids(totalInvoicesForTermsToSave[i]);
            console.log("Creating Invoice")
            i++
            check()
          }, 1000);
        }
      }
      check()
    }
  }, [totalInvoicesForTermsToSave])


  useEffect(() => {
    if (props.responseForCreateInvoice) {
      clearProps();
      if (totalKidsForInvoiceForTerms != undefined) {
        if (totalKidsForInvoiceForTerms === creatingInvoiceLabelCount) {
          setTotalKidsForInvoiceForTerms(undefined)
          setCreatingInvoiceLabelCount(undefined)
          setReload(true)
          closeAddStudentModal()
          toastr.success("Invoices created successfully", { timeOut: 1500 });
        } else {
          setCreatingInvoiceLabelCount((creatingInvoiceLabelCount ?? 0) + 1)
        }
      } else {
        setReload(true)
        toastr.success("Invoices created successfully", { timeOut: 1500 });
      }
    }
  }, [props.responseForCreateInvoice]);


  const createInvoice = () => {
    //instituteId, schoolId, title //name of the template, createdBy //teacherId, amount //in string, recipientType //always 6, recipientIds //recipientIds, tags //, programId, items

    setShowInvoiceAlert(false);

    var paymentOptions = Parse.Object.extend("FeeTemplateItem");
    var query = new Parse.Query(paymentOptions);
    query.equalTo("feeTemplateId", programObject.attributes.templateId);
    query.notEqualTo("isDeleted", true);
    query.find().then(
      (result) => {
        let totalAmount = 0;
        let totalTax = 0;
        let totalRoyalty = 0;
        for (var i = 0; i < result.length; i++) {
          totalAmount += result[i].attributes.totalAmount;
        }

        var kidIds = [];

        for (var i = 0; i < selectedKidsForInvoice.length; i++) {
          kidIds.push(selectedKidsForInvoice[i].id);
        }

        let tempList = props.feeTemplates.filter(
          (feeTemplate) => feeTemplate.id === programObject.attributes.templateId);
        let template
        if (tempList.length > 0) {
          template = tempList[0];
        }

        var currentdate = programObject.attributes.startDate ?? new Date();
        let year = currentdate.getFullYear();
        if (currentdate.getMonth() <= 2) {
          year = year - 1;
        }
        var invoiceObject = {
          instituteId: props.selectedSchool.attributes.instituteId,
          schoolId: schoolId,
          title: template.attributes.name,
          createdBy: Parse.User.current().attributes.teacherId,
          amount: totalAmount,
          recipientType: 6,
          recipientIds: kidIds,
          tags: [
            `${year}-${year + 1 - 2000}`,
          ],
        };

        if (result) {
          let tempItems = [];
          let totalAmount = 0;
          for (let val of result) {
            let temp = {};
            temp.slno = val.attributes.slno;
            temp.itemDescription = val.attributes.itemDescription;
            temp.dueDate = val.attributes.dueDate;
            temp.amount = val.attributes.amount;
            temp.discount = val.attributes.discount;
            temp.discountType = val.attributes.discountType;
            temp.discountDisabled = val.attributes.discount > 0;
            temp.discountRemark = val.attributes.discountRemark;
            temp.hsn = val.attributes.hsn;
            temp.totalAmount =
              val.attributes.discountType === 1
                ? val.attributes.amount - val.attributes.discount
                : val.attributes.amount -
                (val.attributes.amount * val.attributes.discount) / 100;
            temp.tax = val.attributes.tax
            totalAmount += temp.totalAmount
            if (temp.tax > 0) {
              let tTax = roundToTwo(temp.totalAmount * 0.01 * temp.tax);
              temp.taxAmount = tTax
              totalTax = totalTax + tTax
              temp.totalAmount = temp.totalAmount + tTax
            }
            temp.royalty = val.attributes.royalty;
            if (temp.royalty > 0) {
              temp.royaltyAmount = (temp.totalAmount * temp.royalty) / 100
              totalRoyalty += temp.royaltyAmount;
            } else {
              temp.royaltyAmount = 0;
            }
            tempItems.push(temp);
          }
          invoiceObject["items"] = tempItems;
        }
        if (totalRoyalty > 0) {
          invoiceObject.royaltyAmount = totalRoyalty;
          invoiceObject.isRoyaltyInvoice = true;
        }

        if (programId != undefined) {
          invoiceObject["programId"] = programId;
        } else {
          invoiceObject["programId"] = resultProgramAfterSave.id;
        }

        props.createInvoiceForKids(invoiceObject);
      },
      (error) => { }
    );
  };

  const setSaveSuccess = (success) => {
    if (success) {
      if (selectedKidsForInvoice.length > 0) {
        if (!isProgramHasTerm) {
          setShowInvoiceAlert(true);
        }
      } else {
        clearProps()
      }
    } else {
      clearProps()
    }
  };

  const changeAddStudentPopupProgress = (tab, changeTab = true) => {
    if (addStudentPopupActiveTab !== tab) {
      if (tab >= 0 && tab <= 1) {
        if (changeTab) {
          setAddStudentPopupActiveTab(tab);
        }
        var tempRatio = 100 / 2;
        var tempMultiplier = tab + 1;
        var tempProgress = tempMultiplier * tempRatio;
        setAddStudentPopupProgressValue(tempProgress);
      }
    }
  }

  const addStudentPopupToggleTab = (tab, choice) => {
    if (tab !== addStudentPopupActiveTab) {
      if (tab - addStudentPopupActiveTab === 1 || tab - addStudentPopupActiveTab === -1) {
      } else {
        if (choice === previous) {
          tab = addStudentPopupActiveTab - 1;
        }
      }
      changeAddStudentPopupProgress(tab)
    }
  };


  const next = "NEXT";
  const previous = "PREVIOUS";

  const getCreatingInvoiceForKidsMsg = (kidList, term) => {
    let returnMsg = "Creating invoice for "
    let shouldShowMsg = false
    let kidListNames = []
    for (const i in kidList) {
      if (!(term.attributes.kidIds && term.attributes.kidIds.includes(kidList[i].id))) {
        kidListNames.push(kidList[i].attributes.Name)
        shouldShowMsg = true
      }
    }
    let kidListNamesString = kidListNames.length === 0 ? "" : kidListNames.join(", ")
    return { returnMsg: returnMsg + kidListNamesString, shouldShowMsg: shouldShowMsg }
  }

  const removeKidFromTerm = () => {
    let term = termsList[activeTab]
    let kidIds = term.attributes.kidIds;
    let newKidIds = kidIds.filter((kidId) => kidId != removeObject.kidId);
    term.set('kidIds', newKidIds);
    let updateTearmList = [term];

    let invoices = props.invoicesForProgram.filter((i) => i.attributes.recipientId == removeObject.kidId);
    let removeFromGroup = true;
    for (const t of termsList) {
      if (t.id != term.id) {
        if (t.attributes.kidIds && t.attributes.kidIds.includes(removeObject.kidId)) {
          if (invoices.length > 0) {
            let ti = invoices.filter((i) => i.attributes.programTermIds.includes(t.id));
            if (ti.length > 0) {
              removeFromGroup = false;
            } else {
              let newKidIds = t.attributes.kidIds.filter((kidId) => kidId != removeObject.kidId);
              t.set('kidIds', newKidIds);
              updateTearmList.push(t)
            }
          } else {
            let newKidIds = t.attributes.kidIds.filter((kidId) => kidId != removeObject.kidId);
            t.set('kidIds', newKidIds);
            updateTearmList.push(t)
          }
        }
      }
    }
    props.editProgramTerms(updateTearmList)
    if (removeFromGroup) {
      removeStudent();
    } else {
      setRemoveObject(undefined);
    }

  }
  const removeStudent = () => {

    setSelectedKidsForInvoice([]);
    let groupMapObjectList = []
    if (props.groupmaps.length > 0) {
      var i = 0;
      for (i = 0; i < props.groupmaps.length; i++) {
        if (removeObject.kidId === props.groupmaps[i].attributes.userId) {
          var tempObject = props.groupmaps[i];
          tempObject.set("isDeleted", true);
          groupMapObjectList.push(tempObject);
        }
      }
    }

    let newCount = mainList.length - groupMapObjectList.length;

    if (props.singleGroupHolder) {
      var groupObject = props.singleGroupHolder;
      if (
        groupObject.attributes.participantsCount !== newCount
      ) {
        groupObject.set("participantsCount", newCount);
        props.saveGroup(groupObject);
        if (programObject) {
          programObject.set("participantsCount", newCount);
          programObject.save().then(
            (result) => {
              setProgramObject(result)
            },
            (error) => {

            }
          );
        }
      }
    }

    if (groupMapObjectList.length > 0) {
      props.saveGroupMaps(groupMapObjectList);
    } else {
      //setSaveSuccess(true);
    }
    setRemoveObject(undefined);

  }

  const handleSaveTag = () => {
    let groupMapKid = selectedGroupKid
    groupMapKid.set('tags', [programTag])
    props.saveGroupMaps(groupMapKid)
    console.log(selectedGroupKid)
    setShowTagModal(false)
  }

  const handleUnsetTag = () => {
    let groupMapKid = selectedGroupKid
    groupMapKid.unset('tags')
    props.saveGroupMaps(groupMapKid)
    console.log(selectedGroupKid)
    setShowTagModal(false)
  }

  return (
    <React.Fragment>
      {showInvoiceAlert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title="Alert!"
          onCancel={() => {
            setShowInvoiceAlert(false);
            //setIsLoading(false);
            //setSaveSuccess(false);
          }}
          onConfirm={() => createInvoice()}
        >
          Do you want to create invoices for students <br></br>
        </SweetAlert>
      )}

      {removeObject && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title="Alert!"
          onCancel={() => {
            setRemoveObject(undefined);
            //setIsLoading(false);
            //setSaveSuccess(false);
          }}
          onConfirm={() => isProgramHasTerm ? removeKidFromTerm() : removeStudent()}
        >
          {
            isProgramHasTerm ?
              "Do you want to remove " + removeObject.kidParseObject.attributes.Name + " from term."
              :
              "Do you want to remove " + removeObject.kidParseObject.attributes.Name + " from program."
          }


          <br></br>
        </SweetAlert>
      )}


      <Modal isOpen={showTagModal ? true : false} scrollable={true}>
        <ModalHeader isOpen={showTagModal}>
          <div className="modal-title mt-0">Programs Batchs</div>
        </ModalHeader>
        <ModalBody className="modal-body">
          <p>
            {props.t(
              "Please select the Programs Tags to Add Student ."
            )}
          </p>
          {console.log(programTag)}
          {programObject && programObject.attributes && programObject.attributes.tags && programObject.attributes.tags
            .map((tagName, i) => (
              <div className="custom-control custom-checkbox mb-2 ml-3" key={tagName}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={tagName}
                  onChange={() => { setProgramTag(tagName) }}
                  checked={tagName == programTag}
                />
                <label
                  className="custom-control-label m-1"
                  htmlFor={tagName}
                  onClick={() => {
                    setProgramTag(tagName);
                  }}
                >
                  {tagName}
                </label>
              </div>
            ))
          }


          {<div className="custom-control custom-checkbox mb-2 ml-3">
            <input
              type="checkbox"
              className="custom-control-input"
              value={"rc"}
              onChange={() => { setProgramTag('rc') }}
              checked={programTag == 'rc'}
            />
            <label
              className="custom-control-label m-1"
              onClick={(e) => {
                setProgramTag('rc')
              }}
            >
              Remove Tag
            </label>
          </div>}
          {batchSelcetWarning && (
            <p className="text-danger ml-2">
              {props.t("Please select the category")}
            </p>
          )}
        </ModalBody>

        <ModalFooter>
          {(
            <div>
              <button
                type="button"
                className="btn btn-secondary m-1"
                onClick={() => {
                  setbatchSelcetWarning(false);
                  setShowTagModal(undefined);
                }}
              >
                {props.t("Close")}
              </button>
              <button
                type="button"
                className="btn btn-primary m-1"
                onClick={() => {
                  if (programTag) {
                    if (programTag == 'rc') {
                      handleUnsetTag()
                    } else {
                      handleSaveTag();
                    }

                    setbatchSelcetWarning(false);
                    setShowTagModal(undefined);
                  } else {
                    setbatchSelcetWarning(true);
                  }
                }}
              >
                {props.t("Save")}
              </button>
            </div>
          )}
        </ModalFooter>
      </Modal>
      <Modal centered isOpen={showCreateInvoiceModal || props.invoicesForProgramLoader}>
        <ModalHeader>Create Invoice</ModalHeader>
        <ModalBody>
          <center>
            <div className="mt-4"><i style={{ fontSize: "50px" }} className="bx bx-receipt text-success"></i></div>
            <Label className="font-size-16 mt-2 mb-3 p-3">{isProgramCompleted ? `Are you sure to create invoice for this completed program for ${selectedItemForInvoiceCreation ? selectedItemForInvoiceCreation.kidName : "this student"} ?` : `Are you sure to create invoice for ${selectedItemForInvoiceCreation ? selectedItemForInvoiceCreation.kidName : "this student"} for this program ?`}</Label>
          </center>
        </ModalBody>
        <ModalFooter>
          {!props.invoicesForProgramLoader && <button className="btn btn-secondary" onClick={() => {
            setShowCreateInvoiceModal(false)
          }}>
            Cancel
          </button>}


          {props.invoicesForProgramLoader ?
            <button type="button" className="btn btn-light waves-effect">
              <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>
              {props.t(" Creating....")}
            </button>
            :
            <button
              onClick={() => {
                handleCreateInvoiceForKid(selectedItemForInvoiceCreation)
              }}
              className="w-md  btn btn-success float-right"
            >
              Create
            </button>
          }
        </ModalFooter>
      </Modal>

      <Modal isOpen={kidPickerModal} className={isProgramHasTerm ? "modal-dialog modal-lg" : "modal-dialog modal-xl"} scrollable={true} backdrop={true}>
        <ModalHeader toggle={() => {
          if (isProgramHasTerm && totalKidsForInvoiceForTerms > 0) {

          } else {
            closeAddStudentModal()
          }
        }}>
        </ModalHeader>
        <ModalBody>
          <div>
            {isProgramCompleted && <Alert
              color="danger"
              role="alert"
            >
              <p className="lead mb-1"><strong>This Program is Completed, are you sure you want to create invoices ?</strong></p>

            </Alert>}
            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                {!clickedOnCreateInvoiceButton && <NavItem>
                  <NavLink
                    className={classnames({
                      active: addStudentPopupActiveTab === 0,
                    })}
                    onClick={() => {
                      addStudentPopupToggleTab(0, previous);
                    }}
                  >
                    <span className="step-number m-2">01</span>
                    Select Students
                  </NavLink>
                </NavItem>}
                {isProgramHasTerm && <NavItem>
                  <NavLink
                    className={classnames({
                      active: addStudentPopupActiveTab === 1,
                    })}
                    onClick={() => {
                      setKidPickerForTermsNextButtonClick(true)
                    }}
                  >
                    <span className="step-number m-2">02</span>
                    Select Terms
                  </NavLink>
                </NavItem>}
              </ul>
              <div id="bar" className="mt-4">
                <Progress
                  color="success"
                  striped
                  animated
                  value={addStudentPopupProgressValue}
                />
                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
              </div>
              <TabContent
                activeTab={addStudentPopupActiveTab}
                className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={0} id="pick-student">
                  {(isProgramHasTerm)
                    ?
                    <KidPickerForTerms
                      showAdmissionKids={true}
                      schoolId={schoolId}
                      termsList={termsList}
                      kids={props.kids}
                      classrooms={props.classrooms}
                      clickNextButtonProgramatically={kidPickerForTermsNextButtonClick}
                      admissionKids={props.admissionKids}
                      onButtonClick={(selectedKids) => {
                        if (selectedKids && selectedKids.length > 0) {
                          setKidPickerForTermsNextButtonClick(false)
                          addStudentPopupToggleTab(1, next)
                          setSelectedKidsFromPickerView(selectedKids)
                        } else {
                          setKidPickerForTermsNextButtonClick(false)
                          toastr.warning("Please select atleast one student to proceed")
                        }
                      }}>
                    </KidPickerForTerms>
                    :
                    <KidPicker
                      schoolId={schoolId}
                      selectedKids={selectedKidListForPicker}
                      showAdmissionKids={true}
                      admissionKids={props.admissionKids}
                      disableRemoveKids={true}
                      onButtonClick={(selectedKids) => {
                        if (selectedKids && selectedKids.length > 0) {
                          setSelectedKidsFromPickerView(selectedKids)
                          handleSaveButtonPressedForAddStudent(selectedKids, true)
                          closeAddStudentModal()
                        } else {
                          toastr.warning("Please select atleast one student to proceed")
                        }

                      }}>
                    </KidPicker>}
                </TabPane>
                <TabPane tabId={1} id="select-terms">
                  <Card className="mt-0 pt-0">
                    <CardBody className="mt-0 pt-0">
                      <Row lg={12} className="mt-0 pt-0">
                        <Col lg={12}>
                          <Label className="mt-0 pt-0">Selected Students -
                            {selectedKidsFromPickerView && selectedKidsFromPickerView.map((kid, key) => (<Label key={key} className="text-muted">{
                              (key === 0) ? kid.attributes.Name : `, ${kid.attributes.Name}`
                            }</Label>))}
                          </Label>
                        </Col>
                      </Row>
                      <hr />
                      <div><h6>Terms</h6></div>
                      {
                        <Row lg={12}>
                          <Col lg={12}>
                            <Row>
                              <div className="table-responsive">
                                <table className="table table-nowrap table-centered mb-0">
                                  <tbody>
                                    {termsList.length === 0 && (
                                      <tr>
                                        <td><p>No terms to show</p></td>
                                      </tr>
                                    )}
                                    {termsList.map(
                                      (term, keyInside) => (
                                        <tr key={keyInside}>
                                          <td style={{ width: "60px" }}>
                                            <input
                                              type="checkbox"
                                              id={"customCheck12_Terms" + keyInside}
                                              disabled={(!getCreatingInvoiceForKidsMsg(selectedKidsFromPickerView, term).shouldShowMsg) && !listOfTermsToEnable.includes(term.id)}
                                              onChange={() => {

                                                if (selectedTerms.includes(term)) {
                                                  let tempSelectedTerms = [...selectedTerms];
                                                  removeElement(tempSelectedTerms, term)
                                                  setselectedTerms(tempSelectedTerms);
                                                } else {
                                                  let tempSelectedTerms = [...selectedTerms];
                                                  tempSelectedTerms.push(term);
                                                  setselectedTerms(tempSelectedTerms);
                                                }
                                              }}
                                              checked={selectedTerms.includes(term)}
                                            />
                                          </td>
                                          <td>
                                            <div className="d-flex mt-0">
                                              <div className="float-right ml-3 my-auto">
                                                <div>
                                                  <Label className="text-dark mt-0 mb-0">
                                                    {term.attributes.termName}
                                                  </Label><br />

                                                  {getCreatingInvoiceForKidsMsg(selectedKidsFromPickerView, term).shouldShowMsg && <label className="text-secondary font-size-12 mt-0 mb-0">
                                                    {getCreatingInvoiceForKidsMsg(selectedKidsFromPickerView, term).returnMsg}
                                                  </label>}

                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      }
                      <hr></hr>
                      <div>
                        {termsList.length > 0 && <button className={totalKidsForInvoiceForTerms > 0 ? "btn btn-secondary float-right" : "btn btn-primary float-right"}
                          onClick={() => {
                            if (selectedTerms && selectedTerms.length > 0) {
                              setDisableInvoiceCreationButton(true)
                              handleSaveButtonPressedForAddStudent(selectedKidsFromPickerView)
                              createInvoiceForTerms(selectedKidsFromPickerView, selectedTerms, clickedOnCreateInvoiceButton)
                            } else {
                              toastr.warning("Please select atleast one term to proceed")
                            }
                          }}
                          disabled={disableInvoiceCreationButton}
                        >
                          {totalKidsForInvoiceForTerms > 0 && <i className="bx bx-loader bx-spin align-middle mr-2"></i>}
                          {totalKidsForInvoiceForTerms == undefined ? "Save & Create invoice" : `creating ${creatingInvoiceLabelCount} of ${totalKidsForInvoiceForTerms} Invoices`}
                        </button>}
                      </div>
                    </CardBody>
                  </Card>

                </TabPane>
              </TabContent>


            </div>
          </div>
        </ModalBody>

      </Modal>

      {
        showDeleteAlert && <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={() => handleDeleteProgram()}
        >
          Are you sure to delete this program ?<br></br>
        </SweetAlert>
      }


      <div className="page-content">
        {!(isLoading || props.loadingGroupMap) && (
          <Container fluid>
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=' m-2'>
                  <h4 className="mb-0">{programName}</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                  {programObject && <div className="mb-3  float-sm-right form-inline">
                    <Link
                      className="btn btn-success waves-effect waves-light m-1"
                      to={
                        "/programAnalytics/" + schoolId + "/" + programId + "/" + groupId
                      }
                      role="button"
                    >
                      Reports
                    </Link>
                    {<button
                      className="btn btn-primary waves-effect waves-light m-1"
                      onClick={() => {
                        //openStudentPicker

                        resetAddStudentPopup()
                        setKidPickerModal(true)
                      }}
                    >
                      Add Students
                    </button>}
                    <Link
                      className="btn btn-warning waves-effect waves-light m-1"
                      to={
                        "/editprogram/" + schoolId + "/" + programId + "/" + groupId
                      }
                      role="button"
                    >
                      Edit
                    </Link>

                    {isProgramUpcoming && <button
                      onClick={() => {
                        setShowDeleteAlert(true)
                      }}
                      className="btn btn-danger waves-effect waves-light m-1"
                    >
                      {props.t("Delete")}
                    </button>}
                  </div>}
                </div>
              </Col>
            </Row>

            <Card>
              <CardTitle><div className="m-3">
                <div>
                  <Label className="text-muted">Participants{mainList.length > 0 ? ` : ${mainList.length}` : ""}</Label>

                  {programObject && <><Label className="text-muted" style={{ marginLeft: '50px' }}>Batch : {programObject.attributes.batch}</Label>
                    <Label className="text-muted" style={{ marginLeft: '50px' }}>Type : {programObject.attributes.type}</Label>
                    <Label className="text-muted" style={{ marginLeft: '50px' }}>Duration : {getTimeStringForDate(programObject.attributes.startDate)} - {getTimeStringForDate(programObject.attributes.endDate)}</Label>
                  </>}

                </div>

              </div></CardTitle>
              <Row className="mb-1 mt-1">
                <Col sm="3">
                  <ul className="verti-timeline list-unstyled">
                    <li
                      className="event-list mb-0 pb-2 d-flex align-items-center"
                      onClick={() => sortByStatus("pending")}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="event-timeline-dot">
                        <i className={sortPendingStyle}></i>
                      </div>
                      <div className="mr-3">
                        <i className="mdi mdi-credit-card-outline h2 text-danger" style={{ marginRight: "1rem" }}></i>
                      </div>
                      <Media>
                        <div className="mt-1">
                          <span className="badge badge-pill badge-soft-danger font-size-13">
                            Pending({miniStat.pendingInvoiceCount})
                          </span>
                        </div>
                      </Media>
                    </li>
                    <li
                      className="event-list mb-0 pb-2 d-flex align-items-center"
                      onClick={() => sortByStatus("partiallypaid")}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="event-timeline-dot">
                        <i className={sortPartiallypaidStyle}></i>
                      </div>
                      <div className="mr-3">
                        <i className="mdi mdi-account-cash-outline h2 text-warning" style={{ marginRight: "1rem" }}></i>
                      </div>
                      <Media body>
                        <div className="mt-1">
                          <span className="badge badge-pill badge-soft-warning font-size-13">
                            Partially Paid ({miniStat.partialPaidInvoiceCount})
                          </span>
                        </div>
                      </Media>
                    </li>
                    <li
                      className="event-list d-flex align-items-center"
                      onClick={() => sortByStatus("completed")}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="event-timeline-dot">
                        <i className={sortCompletedStyle}></i>
                      </div>
                      <div className="mr-3">
                        <i className="mdi mdi-bank h2 text-success" style={{ marginRight: "1rem" }}></i>
                      </div>
                      <Media body>
                        <div className="mt-1">
                          <span className="badge badge-pill badge-soft-success font-size-13">
                            Complete ({miniStat.completedInvoiceCount})
                          </span>
                        </div>
                      </Media>
                    </li>
                  </ul>
                </Col>
                <Col sm="9">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height="120"
                  />
                </Col>
              </Row>
            </Card>
            <Card>
              <CardBody>

                {isProgramHasTerm && <Nav className="navtab-bg nav-justified mb-3" pills>
                  {
                    termsList.map((term, key) => (
                      <NavItem key={key}>
                        <NavLink
                          className={classnames({ active: activeTab === key })}
                          onClick={() => {
                            setactiveTab(key);
                          }}
                        >
                          {props.t(term.attributes.termName).toUpperCase()} {(term.attributes.kidIds && term.attributes.kidIds.length !== 0) ? `( ${term.attributes.kidIds.length} )` : ``}
                        </NavLink>
                      </NavItem>
                    ))
                  }
                </Nav>
                }
                {isProgramHasTerm ? getTableView(displayList) : getTableView(mainList)}
              </CardBody>
            </Card>
          </Container>
        )}
        {(isLoading || props.loadingGroupMap) && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading program details...{" "}
                </Label>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment >
  );
};

const mapStatetoProps = (state) => {
  const { kids, admissionKids, admissionKidsLoasState, deletedKids } = state.Kid;
  const { classrooms } = state.Classroom;
  const { invoicesForProgram, invoicesForProgramLoader, responseForCreateInvoice } = state.Invoice;
  const { resultGroup, singleGroupHolder } = state.Group;
  const { groupmaps, loadingGroupMap, resultGroupMapsAfterSave } = state.GroupMap;
  const { selectedSchool } = state.School;
  const { pendingVerifyMT } = state.MoneyTransactions;
  const { deletedSuccessfullyProgram, programTerms } = state.Program;
  const { userTeacher } = state.Login;
  const { feeTemplates } = state.FeeTemplate;
  const { franchise } = state.Franchise;



  return {
    kids, admissionKids, admissionKidsLoasState, deletedKids,
    classrooms,
    invoicesForProgram, invoicesForProgramLoader, responseForCreateInvoice,
    resultGroup, singleGroupHolder,
    groupmaps, loadingGroupMap, resultGroupMapsAfterSave,
    selectedSchool,
    pendingVerifyMT,
    deletedSuccessfullyProgram, programTerms,
    userTeacher,
    feeTemplates,
    franchise
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getGroupMapsForGroupId,
      getGroupForId,
      saveGroup,
      saveGroupMaps,
      getInvoicesForProgramId,
      getAdmissionKidsForSchool,
      getClassroomsForSchoolId,
      getAllKidsForGroup,
      setSelecetdSchool,
      getVerifiedStatusForInvoices,
      createInvoiceForProgramKid,
      setProgramInvoiceCreationLoader,
      deleteProgram,
      getUserTeacher,
      setDeletedSuccessfullyProgram,
      editProgramTerms,
      getProgramTermsForProgramId,
      createInvoiceForKids,
      clearResponseForCreateInvoiceForKids,
      getFeeTemplateForSchoolId,
      updateResultGroupMaps,
      getAllDeletedKidsForSchoolIdOnlyForArchives,
      getFranchise,
    })(ProgramView)
  )
);
