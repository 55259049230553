import Parse from "parse";
import { printLog } from "./loghelper";
const getMoneyTransactionFromParse = (moneyTransactionId) => {
  printLog("HL: moneyTransactions_helper : getMoneyTransactionFromParse")

  try {
    return new Promise((resolve, reject) => {
      var MoneyTransaction = Parse.Object.extend("MoneyTransactions");
      var query = new Parse.Query(MoneyTransaction);
      query.get(moneyTransactionId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const deleteMoneyTransactionFromParse = (moneyTransactionId) => {
  printLog("HL: moneyTransactions_helper : deleteMoneyTransactionFromParse")
  try {
    return new Promise((resolve, reject) => {
      const MoneyTransaction = Parse.Object.extend("MoneyTransactions");
      var moneyTransaction = new MoneyTransaction();
      moneyTransaction.id = moneyTransactionId;
      moneyTransaction.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        moneyTransaction.set(
          "deletedBy",
          Parse.User.current().attributes.teacherId
        );
      } else {
        moneyTransaction.set("deletedBy", Parse.User.current().id);
      }

      moneyTransaction.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const getMoneyTransactionForSchoolId = (id, isLoacal) => {
  printLog("HL: moneyTransactions_helper : getMoneyTransactionForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var MoneyTransaction = Parse.Object.extend("MoneyTransactions");
      var query = new Parse.Query(MoneyTransaction);
      query.equalTo("schoolId", id);

      if (isLoacal) {
        query.fromLocalDatastore();
      }
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const getMoneyTransactionForInvoiceId = (id, isLoacal) => {
  printLog("HL: moneyTransactions_helper : getMoneyTransactionForInvoiceId")
  try {
    return new Promise((resolve, reject) => {
      var MoneyTransaction = Parse.Object.extend("MoneyTransactions");
      var query = new Parse.Query(MoneyTransaction);
      query.equalTo("invoiceId", id);

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.ascending("Name");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const receivePaymentByFunction = (inputParam, invoiceItems) => {
  printLog("HL: moneyTransactions_helper : receivePaymentByFunction")
  try {
    return new Promise((resolve, reject) => {
      inputParam.userId = Parse.User.current().id;
      inputParam.receivedBy = Parse.User.current().attributes.teacherId;
      Parse.Cloud.run("function_receivePayment", inputParam).then((result) => {
        let currentTransactionDetails = result;
        // if(inputParam.isVerified){
        //   currentTransactionDetails.set('status', 2)
        //   currentTransactionDetails.set("verifiedBy", Parse.User.current().attributes.teacherId)
        //   currentTransactionDetails.save().then((result)=>{
        //     console.log(result)
        //   })
        // }
        let newItems=[];
        let newMTDs=[];
        for (let val of invoiceItems) {
          if (val.isEnableForPay) {
            if (val.paidStatus !== 1) {
              let invoiceItem = val.Obj;
              if (val.id) {
                if (val.maxPay - val.paidAmount === val.editAmount && false) {
                  invoiceItem.set("paidStatus", 1);
                  invoiceItem.set("paidAmount", val.maxPay);
                  invoiceItem.set("collectedRoyalty", invoiceItem.attributes.royaltyAmount);
                } else {
                  if (val.paidAmount) {
                    if (val.totalAmount === val.paidAmount + val.editAmount) {
                      invoiceItem.set("paidStatus", 1);
                      invoiceItem.set("paidAmount", val.totalAmount);
                      invoiceItem.set("collectedRoyalty",invoiceItem.attributes.royaltyAmount)
                    } else {
                      invoiceItem.set("paidStatus", 3);
                      let amount = val.editAmount;
                      let paidAmount = (val.Obj.attributes.paidAmount??0)+amount;
                      invoiceItem.set("paidAmount", paidAmount);

                      let editAmountRoyalty = val.editAmountRoyalty + invoiceItem.attributes.collectedRoyalty;
                      invoiceItem.set("collectedRoyalty", editAmountRoyalty);
                    }
                  } else {
                    if (val.totalAmount === val.editAmount) {
                      invoiceItem.set("paidStatus", 1);
                      invoiceItem.set("paidAmount", val.totalAmount);
                      invoiceItem.set("collectedRoyalty",invoiceItem.attributes.royaltyAmount)
                    } else {
                      invoiceItem.set("paidStatus", 3);
                      let amount = val.editAmount;
                      let paidAmount = (val.Obj.attributes.paidAmount??0)+amount;
                      invoiceItem.set("paidAmount", paidAmount);

                      let editAmountRoyalty = val.editAmountRoyalty ;
                      invoiceItem.set("collectedRoyalty", editAmountRoyalty);
                    }
                  }
                }
                invoiceItem.set("transactionId", result.id);
              } else {
                //for panalty
              }
              newItems.push(invoiceItem)


              var MoneyTransactionDetails = new Parse.Object.extend(
                "MoneyTransactionDetails"
              );
              var mtd = new MoneyTransactionDetails();
              mtd.set("itemId", invoiceItem.id);
              mtd.set("transactionId", currentTransactionDetails.id);
              mtd.set("type", 1);
              mtd.set("amount", val.editAmount);
              newMTDs.push(mtd)
            }
          }
        }
        // resolve(result)

        Parse.Object.saveAll(newItems).then(
          (result) => {
            Parse.Object.saveAll(newMTDs).then(
              (result) => {
                resolve(result);
              },
                (error) => {
                  resolve(error);
                  console.log("Error", error);
              }
            );	
          },
            (error) => {
              resolve(error);
              console.log("Error", error);
          }
        );	
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export const getTransactionForIdParse = (id, uTIme, isLocal) => {
  printLog("HL: moneyTransactions_helper : getTransactionForIdParse")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("MoneyTransactions");
      query.equalTo("invoiceId", id);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      if (uTIme) {
        query.greaterThan("updatedAt", new Date(uTIme));
      }
      query.descending("createdAt");
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateVerifyTrinansaction = (id) => {
  printLog("HL: moneyTransactions_helper : updateVerifyTrinansaction")
  try {
    return new Promise((resolve, reject) => {
      let MoneyTransactions = Parse.Object.extend("MoneyTransactions");
      let mt = new MoneyTransactions();
      mt.id = id;
      mt.set("status", 2);
      mt.set("verifiedBy", Parse.User.current().attributes.teacherId);
      mt.save().then((result) => {
        resolve(result);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteTriansactionForId = (id) => {
  printLog("HL: moneyTransactions_helper : deleteTriansactionForId")
  try {
    return new Promise((resolve, reject) => {
      let MoneyTransactions = Parse.Object.extend("MoneyTransactions");
      let mt = new MoneyTransactions();
      mt.id = id;
      mt.set("deleted", true);
      mt.set("deletedBy", Parse.User.current().attributes.teacherId);
      mt.set("deletedDate", new Date());
      mt.save().then((result) => {
        const deletedTriansaction = result;
        let query = new Parse.Query("MoneyTransactionDetails");
        query.equalTo("transactionId", deletedTriansaction.id);
        query.find().then((results) => {
          let mtd = results;
          let mtdItemIds = []

          if(mtd && mtd.length>0){
            for (const val of mtd) {
              val.set("isDeleted", true);
              mtdItemIds.push(val.attributes.itemId)
            }
            
  
  
            let InvoiceItems = Parse.Object.extend("InvoiceItems");
            let query1 = new Parse.Query(InvoiceItems);
            query1.containedIn("objectId", mtdItemIds);
            query1.find().then((results) => {
              let invoiceItems = results;
              for (const item of invoiceItems) {
  
                for(const nMtd of mtd){
                  if(item.id == nMtd.attributes.itemId){
  
                    let nPaidAmount = (item.attributes.paidAmount??0 )-nMtd.attributes.amount;

                    item.set("transactionId", item.attributes.transactionId == deletedTriansaction.id ?"": item.attributes.transactionId);
                    if(nPaidAmount >0 ){
                      item.set("paidStatus", 3);
                      item.set('paidAmount',nPaidAmount)
                    }else{
                      item.set("paidStatus", 0);
                      item.set('paidAmount',0)
                    }
                    
                  }
                  
                }
                
              }
              Parse.Object.saveAll(invoiceItems).then((results) => {
                Parse.Object.saveAll(mtd).then((results) => {
                  resolve(deletedTriansaction);
                });
              });
            });
            
          }else{

            let InvoiceItems = Parse.Object.extend("InvoiceItems");
            let query1 = new Parse.Query(InvoiceItems);
            query1.equalTo("transactionId", deletedTriansaction.id);
            query1.find().then((results) => {
              let invoiceItems = results;
              for (const val of invoiceItems) {
                val.set("paidStatus", 3);
                val.set("transactionId", "");
              }
              Parse.Object.saveAll(invoiceItems).then((results) => {
              
              })
            })

            resolve(deletedTriansaction);
            
          }
          
        }); 
      });
    });
  } catch (err) {
    console.log(err);
  }
};

const getInvoicesForTransactionFilter = (filters) => {
  printLog("HL: moneyTransactions_helper : getInvoicesForTransactionFilter")
  return new Promise((resolve, reject) => {
    let query = new Parse.Query("Invoice");
    query.equalTo("schoolId", filters.schoolId);
    //only for tag
    // if (filters.fromDate) {
    //   query.greaterThan("createdAt", filters.fromDate);
    // }
    // if (filters.toDate) {
    //   query.lessThan("createdAt", filters.toDate);
    // }
    query.containedIn("tags", filters.tags);
    query.limit(1000);
    query.find().then((invoices) => {
      resolve(invoices);
    });
  });
};
const processGetTransactionForFilters = (filters, findWithInvoice) => {
  printLog("HL: moneyTransactions_helper : processGetTransactionForFilters")
  return new Promise((resolve, reject) => {
    const defType = ["cash", "cheque", "online"];
    const gateway = ["AirPay", "CCAvenue", "GooglePay", "PayU","RazorPay","OnePay"];
    const fromDate = new Date(
      filters.fromDate.getFullYear(),
      filters.fromDate.getMonth(),
      filters.fromDate.getDate(),
      0,
      0,
      0
    );
    const toDate = new Date(
      filters.toDate.getFullYear(),
      filters.toDate.getMonth(),
      filters.toDate.getDate(),
      24,
      59,
      59
    );
    let query1 = new Parse.Query("MoneyTransactions");
    query1.equalTo("schoolId", filters.schoolId);
    query1.greaterThan("paidDate", fromDate);
    query1.lessThan("paidDate", toDate);

    let query2 = new Parse.Query("MoneyTransactions");
    query2.equalTo("schoolId", filters.schoolId);
    query2.doesNotExist("paidDate");
    query2.greaterThan("createdAt", fromDate);
    query2.lessThan("createdAt", toDate);

    let query = Parse.Query.or(query1, query2);
    if (defType.includes(filters.filterBy)) {
      query.equalTo(
        "transactionType",
        `${filters.filterBy.charAt(0).toUpperCase()}${filters.filterBy.slice(
          1
        )}`
      );
    } else if (filters.filterBy === "cancelled") {
      query.equalTo("deleted", true);
    } else if (filters.filterBy == "gateway") {
      query.containedIn("transactionType", gateway);
    }
    query.descending("createdAt");
    if (findWithInvoice) {
      query.containedIn("invoiceId", filters.invoiceIds);
    }
    query.limit(filters.limit);
    query.skip((filters.skipValue - 1) * filters.limit);
    query.find().then((results) => {
      resolve(results);
    });
  });
};
const processGetTransactionCountForFilters = (filters, findWithInvoice) => {
  printLog("HL: moneyTransactions_helper : processGetTransactionCountForFilters")
  return new Promise((resolve, reject) => {
    const defType = ["cash", "cheque", "online"];
    const gateway = ["AirPay", "CCAvenue", "GooglePay", "PayU","RazorPay","OnePay"];
    const fromDate = new Date(
      filters.fromDate.getFullYear(),
      filters.fromDate.getMonth(),
      filters.fromDate.getDate(),
      0,
      0,
      0
    );
    const toDate = new Date(
      filters.toDate.getFullYear(),
      filters.toDate.getMonth(),
      filters.toDate.getDate(),
      24,
      59,
      59
    );
    let query1 = new Parse.Query("MoneyTransactions");
    query1.equalTo("schoolId", filters.schoolId);
    query1.greaterThan("paidDate", fromDate);
    query1.lessThan("paidDate", toDate);

    let query2 = new Parse.Query("MoneyTransactions");
    query2.equalTo("schoolId", filters.schoolId);
    query2.doesNotExist("paidDate");
    query2.greaterThan("paidDate", fromDate);
    query2.lessThan("paidDate", toDate);

    let query = Parse.Query.or(query1, query2);
    if (defType.includes(filters.filterBy)) {
      query.equalTo(
        "transactionType",
        `${filters.filterBy.charAt(0).toUpperCase()}${filters.filterBy.slice(
          1
        )}`
      );
    } else if (filters.filterBy === "cancelled") {
      query.equalTo("deleted", true);
    } else if (filters.filterBy == "gateway") {
      query.containedIn("transactionType", gateway);
    }
    query.descending("paidDate");
    if (findWithInvoice) {
      query.containedIn("invoiceId", filters.invoiceIds);
    }
    query.count().then((results) => {
      resolve(results);
    });
  });
};

export const getTriansactionCountForFilter = (filters) => {
  printLog("HL: moneyTransactions_helper : getTriansactionCountForFilter")
  try {
    return new Promise((resolve, reject) => {
      if (filters.tags.length > 0) {
        let invoiceIds = [];
        let sendFilters = filters;

        getInvoicesForTransactionFilter(filters).then((invoiceList) => {
          for (const val of invoiceList) {
            invoiceIds.push(val.id);
          }
          sendFilters.invoiceIds = invoiceIds;
          resolve(processGetTransactionCountForFilters(sendFilters, true));
        });
      } else {
        let sendFilters = filters;
        sendFilters.invoiceIds = [];
        resolve(processGetTransactionCountForFilters(sendFilters, false));
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const getTransactionForFiltersParse = (filters) => {
  printLog("HL: moneyTransactions_helper : getTransactionForFiltersParse")
  try {
    return new Promise((resolve, reject) => {
      if (filters.tags.length > 0) {
        //get the invloces and process it
        let invoiceIds = [];
        let sendFilters = filters;

        getInvoicesForTransactionFilter(filters).then((invoiceList) => {
          for (const val of invoiceList) {
            invoiceIds.push(val.id);
          }
          sendFilters.invoiceIds = invoiceIds;
          processGetTransactionForFilters(sendFilters, true).then((res)=>{
            resolve(res);
          });
          
        });
      } else {
        let sendFilters = filters;
        sendFilters.invoiceIds = [];
        processGetTransactionForFilters(sendFilters, false).then((res)=>{
          resolve(res);
        })
      }
    });
  } catch (err) {
    console.log(err);
  }
};
export const getVerifiedForPendingInvoices = (invoiceIds) => {
  printLog("HL: moneyTransactions_helper : getVerifiedForPendingInvoices")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("MoneyTransactions");
      query.containedIn("invoiceId", invoiceIds);
      query.notEqualTo("deleted", true);
      query.notEqualTo("status", 2);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (error) {
    console.err(error);
  }
};
export const getTriansactionForInvoiceIds = (invoiceIds) => {
  printLog("HL: moneyTransactions_helper : getTriansactionForInvoiceIds")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("MoneyTransactions");
      query.containedIn("invoiceId", invoiceIds);
      query.notEqualTo("deleted", true);
      query.limit(invoiceIds.length*10 + 100);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export {
  getMoneyTransactionFromParse,
  deleteMoneyTransactionFromParse,
  getMoneyTransactionForSchoolId,
  getMoneyTransactionForInvoiceId,
};
