import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
    Container,
    Row,
    Col,
    Label,
    Card,
    CardBody,
    CardTitle,
    Collapse,
    Button,
    Modal,
    Media,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Nav,
    Progress,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Alert,
    UncontrolledTooltip,
    FormGroup,
} from "reactstrap";
import {
    getRecurringMasterInvoicesForSchoolId,
    getRecurringInvoicesForSchoolId,
    editRecurringMasterInvoice,
    stopRecurringMasterInvoice,

} from "../../store/actions";
import { Parse } from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Constants from "../../Constents";
import FeeTab from "../ProgramDetails/FeeComponent";
import RecurringInvoicePopup from './RecurringInvoicePopup'
import Moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";


const RecurringInvoices = (props) => {

    const [displayList, setDisplayList] = useState([]);
    const [selectedMasterInvoice, setSelectedMasterInvoice] = useState('');
    const [reload, setReload] = useState(false);
    const [isTeacherPermission, setIsTeacherPermission] = useState(false);

    const [showRecurringModal, setShowRecurringModal] = useState(false);
    const [recurringObject, setRecurringObject] = useState(false);
    const [selectedMasterInvoiceForEdit, setSelectedMasterInvoiceForEdit] = useState(false);
    const [selectedMasterInvoiceForStop, setSelectedMasterInvoiceForStop] = useState([]);
    const [showEditPopUp, setShowEditPopUp] = useState(false)
    const [nextInvoiceDate, setNextInvoiceDate] = useState(undefined);
    const [lastInvoiceDate, setLastInvoiceDate] = useState(undefined);
    const [showCreateLoader, setShowCreateLoader] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
     
    const schoolId = props.schoolId;

    useEffect(() => {
        props.getRecurringMasterInvoicesForSchoolId(schoolId);
        props.getRecurringInvoicesForSchoolId(schoolId);

    }, [schoolId, reload, props.activeTab])

    useEffect(() => {
        if (props.userTeacher && props.userTeacher.attributes.featurePermissions && props.userTeacher.attributes.featurePermissions.length > Constants.TEACHER_PAYMENTS_INDEX && props.userTeacher.attributes.featurePermissions[Constants.TEACHER_PAYMENTS_INDEX] === 2) {
            setIsTeacherPermission(true)
        } else {
            setIsTeacherPermission(false)

        }
    }, [props.userTeacher])


    useEffect(() => {

        if (showEditPopUp) {
            handelCloseNextDatePopup()
        }
       
        let list = []
        if (props.recurringMasterInvoices && props.recurringMasterInvoices.length > 0) {

            for (const mi of props.recurringMasterInvoices) {

                let kidObject = getKidForKidId(mi.attributes.recipientId);
                let classroom = getClassroomForKid(kidObject ? kidObject.kid : undefined);
                let invoices = getInvoicesForMasterInvoiceId(mi.id);
                let processedInvoice = processInvoices(invoices)


                let classroomName = classroom ? classroom.attributes.Name : "--";

                if (kidObject.type == "admission") {
                    classroomName = "Admission"
                }
                if (kidObject.type == "delete") {
                    classroomName = "Archived"
                }

                let miObject = {
                    id: mi.id,
                    kidId: mi.attributes.recipientId,
                    masterInvoice: mi,
                    kid: kidObject.kid,
                    classType: kidObject.type,
                    classroom: classroom,
                    classroomName: classroomName,
                    invoices: invoices,
                    processedInvoice: processedInvoice

                }
                list.push(miObject)
            }
            
        }

        // console.log(list);
        setDisplayList(list);

    }, [props.recurringMasterInvoices, props.recurringInvoices, props.kids, props.classrooms])

    const getKidForKidId = (kidId) => {
        for (const kid of props.kids) {
            if (kid.id == kidId) {
                return { kid: kid, type: "class" };
            }
        }

        for (const kid of props.admissionKids) {
            if (kid.id == kidId) {
                return { kid: kid, type: "admission" };
            }
        }

        for (const kid of props.allDeletedKids) {
            if (kid.id == kidId) {
                return { kid: kid, type: "delete" };;
            }
        }
        return { kid: undefined, type: undefined };
    }

    const getClassroomForKid = (kid) => {

        if (kid) {
            let classroomId = kid.attributes.ClassRoomID;
            for (const classroom of props.classrooms) {
                if (classroom.id == classroomId) {
                    return classroom;
                }
            }
        }
        
        return undefined;
    }


    const getInvoicesForMasterInvoiceId = (masterInvoiceId) => {
        return props.recurringInvoices.filter(invoice => invoice.attributes.masterInvoiceId == masterInvoiceId)
       
    }

    const processInvoices = (invoiceList) => {

        let tempInvoice = [];
        let pendingInvoiceCount = 0
        let partiallyPaidInvoiceCount = 0
        let paidInvoiceCount = 0
        for (const val of invoiceList) {
            let temp = {};
            temp.Obj = val;
            temp.id = val.id;
            temp.invoiceNumber = val.attributes.invoiceNumber;
            temp.date = val.attributes.createdAt
                ? Moment(val.attributes.createdAt).format("DD-MMM-YYYY")
                : "--";
            temp.invoiceTitle = val.attributes.title;
            temp.status = {};
            temp.paidStatus = val.attributes.paidStatus
                ? val.attributes.paidStatus
                : 0;
            if (val.attributes.paidStatus === 1) {
                temp.status.stat = "Paid";
                temp.status.color = "success m-0 p-0";
                paidInvoiceCount += 1
            } else if (val.attributes.paidStatus === 3) {
                partiallyPaidInvoiceCount += 1
                temp.status.stat = "Partially Paid";
                temp.status.color = "warning m-0 p-0";
            } else {
                pendingInvoiceCount += 1
                temp.status.stat = "Pending";
                temp.status.color = "danger m-0 p-0";
            }
            if (val.attributes.tags) {
                temp.tag = val.attributes.tags[0] ? val.attributes.tags[0] : "";
            }
            temp.totalAmount = val.attributes.amount ? val.attributes.amount : "0";
            temp.paidAmount = val.attributes.paidAmount
                ? val.attributes.paidAmount
                : 0;
            temp.balanceAmount = +temp.totalAmount - temp.paidAmount;
            temp.isActionActive = false;
            temp.showPendingVerify = false;
            tempInvoice.push(temp);
        }
        return ({
            invoiceList: tempInvoice,
            pendingInvoiceCount: pendingInvoiceCount,
            partiallyPaidInvoiceCount: partiallyPaidInvoiceCount,
            paidInvoiceCount: paidInvoiceCount
        })
    }

    const getIntervel = (value) => {
        if (value == 30) {
            return "Monthly";
        } else if (value == 90) {
            return "Quarterly"
        } else if (value == 180) {
            return "Half Yearly"
        }
    }
    
    const getMinDate = () => {
        let today = new Date();
        return today.setDate(today.getDate() + 1);
    }

    const handleNextInvoiceDateChange = () => {
        setShowCreateLoader(true);
        let mi = selectedMasterInvoiceForEdit.masterInvoice;
        mi.set("nextInvoiceDate", nextInvoiceDate);
        props.editRecurringMasterInvoice(mi);
    }

    const handelCloseNextDatePopup = () => {
        setShowEditPopUp(false);
        setSelectedMasterInvoiceForEdit(undefined);
        setNextInvoiceDate(undefined);
        setLastInvoiceDate(undefined);
        setShowCreateLoader(false);
    }

    const handelStopRecurringInvoice = () => {
        let mi = selectedMasterInvoiceForStop.masterInvoice;
        mi.set("isRecurringInvoice", false)
        props.stopRecurringMasterInvoice(mi)
        setShowDeleteAlert(false)
    }

    const getTableView = (displayList) => {
        return (
            <div >
                <div className={"border rounded p-3 mb-1"}>
                    <Row style={{ paddingBottom: "10px" }}>
                        <Col lg={2} md={4}>
                            <div >
                                <div className="float-right">
                                    <Label className="mb-1 font-size-14">
                                        Student Name/Class
                                    </Label>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} md={4}>
                            <div >
                                <div className="float-right mr-4">
                                    <Label className="mb-1 font-size-14">
                                        Status
                                    </Label>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} md={4}>
                            <div className="">
                                <Label className="mb-1 font-size-14">
                                    Recurring Interval
                                </Label>
                            </div>
                        </Col>
                        <Col lg={2} md={4}>
                            <div className="">
                                <Label className="mb-1 font-size-14">
                                    Next Invoice Date
                                </Label>
                            </div>
                        </Col>
                        <Col lg={2} md={4}>
                            <div className="">
                                <Label className="mb-1 font-size-14">
                                    Last Invoice Date
                                </Label>
                            </div>
                        </Col>
                        <Col lg={2} md={4}>
                            <div className="float-right">
                                <Label className="mb-1 font-size-14">
                                    Action
                                </Label>
                            </div>
                        </Col>
                    </Row>
                </div>
                {displayList && displayList.length > 0 ? displayList.map((sectionObject, sectionKey) => (
                    <Card className="mb-1 bg-whites" key={sectionKey}>
                        <div className={selectedMasterInvoice === sectionObject.id ? "border border-primary rounded p-3" : "border rounded p-3"}>
                            <Row>
                                <Col lg={2} md={4}>
                                    {sectionObject.kid && <div className="d-flex justify-content-start">
                                        <div style={{ marginBottom: '30px'}}>{
                                            !sectionObject.kid.attributes.Photo
                                                ? <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle">
                                                        {sectionObject.kid.attributes.Name ? sectionObject.kid.attributes.Name.charAt(0) : ''}
                                                    </span>
                                                </div>
                                                : <div className="">
                                                    <img className="rounded-circle avatar-xs" src={sectionObject.kid.attributes.Photo._url} alt="" />
                                                </div>
                                        }</div>
                                        <div className=" m-1 ">
                                            <Label className="pt-1 font-size-14 mb-0">
                                                {sectionObject.kid.attributes.Name}
                                            </Label>
                                            <p className="font-size-8 mb-0">
                                                ({sectionObject.classroomName})
                                            </p>
                                            
                                        </div>
                                    </div>}
                                </Col>
                                <Col lg={2} md={4}>
                                   
                                    {sectionObject.invoices.length > 0 && <div className="pt-1 float-right text-center">
                                        <div className=" ml-0">
                                            {sectionObject.processedInvoice.pendingInvoiceCount > 0 && <small className="pt-1 mr-4 font-size-14 text-danger">
                                                Pending : <Label className="text-muted">{sectionObject.processedInvoice.pendingInvoiceCount}</Label>
                                                <br></br>
                                            </small>}
                                            {sectionObject.processedInvoice.partiallyPaidInvoiceCount > 0 && <small className="pt-1 mr-4 font-size-14 text-warning">
                                                Partially Paid : <Label className="text-muted">{sectionObject.processedInvoice.partiallyPaidInvoiceCount}</Label>
                                                <br></br>
                                            </small>}
                                            {sectionObject.processedInvoice.paidInvoiceCount > 0 && <small className="pt-1 mr-4 font-size-14 text-success">
                                                Paid : <Label className="text-muted">{sectionObject.processedInvoice.paidInvoiceCount}</Label>
                                            </small>}
                                        </div>
                                    </div>}
                                </Col>
                                <Col lg={2} md={4}>
                                    <div className="text-center">
                                        <Label className="pt-1 font-size-14">
                                            {getIntervel(sectionObject.masterInvoice.attributes.recurringInterval)}
                                        </Label>
                                    </div>
                                </Col>
                                <Col lg={2} md={4}>
                                    <div className=" ">
                                        <Label className="pt-1 font-size-14">
                                            {sectionObject.masterInvoice.attributes.nextInvoiceDate ? Moment(sectionObject.masterInvoice.attributes.nextInvoiceDate).format("DD-MMM-YYYY") : ""}
                                        </Label>
                                        <Label id={"edit-nextInvoiceDate"}
                                            style={{ cursor: "pointer" }}
                                            className="text-primary"
                                            onClick={() => {
                                                setShowEditPopUp(true);
                                                setSelectedMasterInvoiceForEdit(sectionObject);
                                                setNextInvoiceDate(sectionObject.masterInvoice.attributes.nextInvoiceDate);
                                                setLastInvoiceDate(sectionObject.masterInvoice.attributes.recurringInvoiceEndDate);
                                            }}
                                        >
                                            <i className="bx bx-edit-alt ml-2"></i>
                                        </Label>
                                    </div>
                                </Col>
                                <Col lg={2} md={4}>
                                    <div className=" ">
                                        <Label className="pt-1 font-size-14">
                                            {sectionObject.masterInvoice.attributes.recurringInvoiceEndDate ? Moment(sectionObject.masterInvoice.attributes.recurringInvoiceEndDate).format("DD-MMM-YYYY") : ""}
                                        </Label>
                                    </div>
                                </Col>
                                <Col
                                    lg={2}
                                    md={4}
                                >
                                    <div className="float-right"
                                    >
                                        <div>
                                            <Row className="mt-1 pt-1">
                                                <Label
                                                    className="font-size-14 text-primary"
                                                    style={{cursor:'pointer'}}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        if (selectedMasterInvoice === sectionObject.id) {
                                                            setSelectedMasterInvoice("");
                                                        } else {
                                                            setSelectedMasterInvoice(sectionObject.id);
                                                        }
                                                    }}
                                                >
                                                    Invoices ( {sectionObject.invoices.length} )
                                                </Label>

                                               {false && <Label
                                                    id="profile-edit"
                                                    className="text-primary ml-4"
                                                    style={{cursor:'pointer'}}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setShowRecurringModal(true);
                                                        let ro= {
                                                            occurrences: sectionObject.masterInvoice.attributes.recurringCount,
                                                            selectedIntervel: sectionObject.masterInvoice.attributes.recurringInterval,
                                                            lastData: sectionObject.masterInvoice.attributes.recurringInvoiceEndDate,
                                                        }
                                                        console.log(ro)
                                                        setRecurringObject(ro);
                                                        
                                                        
                                                    }}
                                                >
                                                    <i className="bx bx-edit-alt"></i>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={"profile-edit"}
                                                    >
                                                        {props.t("Edit")}
                                                    </UncontrolledTooltip>
                                                </Label>}

                                                <div className="ml-2 pl-2" style={{ cursor: 'pointer' }} id={"stop-recurring" + sectionKey}>
                                                    <i className="bx bx-no-entry font-size-16 align-middle text-danger"
                                                        onClick={() => {
                                                            setSelectedMasterInvoiceForStop(sectionObject)
                                                            setShowDeleteAlert(true)
                                                        }}>
                                                    </i>

                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={"stop-recurring" + sectionKey}
                                                    >
                                                        {props.t("Stop Recurring")}
                                                    </UncontrolledTooltip>
                                                </div>


                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                
                            </Row>
                        </div>
                        <Collapse
                            isOpen={selectedMasterInvoice === sectionObject.id}
                        >
                            <div className="m-3">
                                {sectionObject.invoices && sectionObject.invoices.length > 0 &&
                                    <FeeTab
                                        disableWrite={!isTeacherPermission}
                                        invoiceList={sectionObject.processedInvoice.invoiceList}
                                        schoolId={schoolId}
                                        currency={props.currency}
                                        reloadInvoices={() => {
                                            setReload(true)
                                        }}
                                    ></FeeTab>}
                            </div>
                        </Collapse>
                    </Card>
                )) :
                    <div className="text-center mt-4">
                        <Label className="text-muted  font-size-16">
                            No Participants to Show !!
                        </Label>
                    </div>
                }
            </div>
        )
    }

   

    return (
        <React.Fragment>
            <div className="mt-2">
                <Container fluid>
                    <Card>

                        {showDeleteAlert && <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnText="No"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="success"
                            title="Alert!"
                            onCancel={() => {
                                setShowDeleteAlert(false);
                            }}
                            onConfirm={() => handelStopRecurringInvoice()}
                        >
                            Are you sure you want to stop this recurring invoice? <br/>
                            <p className="mt-2" style={{fontSize: "16px"}}> Stopping this will not impact existing invoices, and it will also prevent any future recurrences of this invoice.</p>
                        </SweetAlert>}

                        <Modal
                            isOpen={showEditPopUp}
                        >
                            <ModalHeader toggle={() => {
                                handelCloseNextDatePopup();
                            }}>
                                <h5 className="modal-title mt-0" id="deleteKidModel">
                                    {props.t('Recurring Invoice Date')}
                                </h5>
                            </ModalHeader>

                            <ModalBody >
                                <div>
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label className="mb-0">
                                            {props.t("Next Invoice")} :
                                        </Label>
                                        <DatePicker
                                            selected={nextInvoiceDate}
                                            onChange={(date) => {
                                                setNextInvoiceDate(date);
                                            }}
                                            className="form-control"
                                            placeholderText="Selecte Date"
                                            startDate={getMinDate()}
                                            minDate={getMinDate()}
                                            maxDate={lastInvoiceDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        <p className="mt-4 text-center"><strong className="text-danger">{"Note: "}</strong>Modifying the next invoice date will lead to a corresponding shift in the recurring invoice schedule, thereby influencing the subsequent recurring instances.</p>
                                    </FormGroup>
                                </div>
                            </ModalBody>

                            <ModalFooter >
                               { !showCreateLoader && <button
                                    type="button"
                                    onClick={() => {
                                        handelCloseNextDatePopup();
                                    }}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                    disabled={showCreateLoader}
                                >
                                    {props.t('Close')}
                                </button>}
                                <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {
                                        handleNextInvoiceDateChange();
                                    }}
                                >
                                    {showCreateLoader && (
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                                    )}
                                    {props.t('Save')}
                                </button>
                            </ModalFooter>
                        </Modal>

                        <CardBody>
                            {getTableView(displayList)}
                        </CardBody>
                    </Card>
                </Container>
            </div>

            <RecurringInvoicePopup
                showRecurringModal={showRecurringModal}
                recurringObject={recurringObject}
                onCloseRecurringModal={() => { setShowRecurringModal(false) }}
                onSetRecurringObject={(obj) => {
                    setRecurringObject(obj)
                    setShowRecurringModal(false)
                }}
            />
        </React.Fragment>
    );
}



const mapStatetoProps = (state) => {

    const { kids, admissionKids,allDeletedKids } = state.Kid;
    const { classrooms } = state.Classroom;
    const { invoices, recurringMasterInvoices, recurringInvoices } = state.Invoice;
    const { selectedSchool } = state.School;
    const { userTeacher } = state.Login;
    
    return {
        kids,admissionKids,allDeletedKids,
        classrooms,
        invoices, recurringMasterInvoices, recurringInvoices,
        selectedSchool,
        userTeacher
       
    };
};

export default connect(mapStatetoProps, {
    getRecurringMasterInvoicesForSchoolId,
    getRecurringInvoicesForSchoolId,
    editRecurringMasterInvoice,
    stopRecurringMasterInvoice,

})(withRouter(withNamespaces()(RecurringInvoices)));