import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Input, Label, Button, CardText, CardTitle, Container, Form, InputGroup, InputGroupText, UncontrolledTooltip, FormGroup } from 'reactstrap'
import Topbar from "../Topbar"
import Dropzone from "react-dropzone";
import Parse from 'parse'
import { conformsTo } from 'lodash';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Constants from "../../../Constents";
import { setSelecetdSchool, setSelectedInventoryItem, updateSelectedInventoryItem, getFranchise, getInventoryItemMatrixForItemId, getInventoryWarehouseForOwnerId, getInventorySubItemForItemIds } from '../../../store/actions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import UpdatePhoto from '../../../components/Common/UpdatePhoto';
import { v4 as uuidv4 } from 'uuid';

function AddItems(props) {

    const matchAdd = matchPath(props.history.location.pathname, {
        path: "/add-inventory-items/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-add-items/:franchiseId",
        exact: true,
        strict: false,
    });

    const matchEditItem = matchPath(props.history.location.pathname, {
        path: "/edit-inventory-items/:schoolId/:itemId",
        exact: true,
        strict: false,
    });


    const franchiseMatchEditItem = matchPath(props.history.location.pathname, {
        path: "/franchise-edit-inventory-items/:franchiseId/:itemId",
        exact: true,
        strict: false,
    });

    const match = matchEditItem ?? franchiseMatchEditItem;
    const itemId = match && match.params.itemId;

    const schoolId = matchAdd && matchAdd.params.schoolId;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];

    const [selectedFiles, setselectedFiles] = useState([]);
    const [type, setType] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [sku, setSku] = useState();
    const [unit, setUnit] = useState();
    const [hsnCode, setHsnCode] = useState();
    const [taxPreferences, setTaxPreferences] = useState();
    const [category, setCategory] = useState();
    const [categoriesList, setCategoriesList] = useState([]);
    const [xDimension, setXDimension] = useState();
    const [yDimension, setYDimension] = useState();
    const [weight, setWeight] = useState();
    const [manufacturer, setManufacturer] = useState();
    const [brand, setBrand] = useState();
    const [upc, setUpc] = useState();
    const [mpn, setMpn] = useState();
    const [ean, setEan] = useState();
    const [isbn, setIsbn] = useState();
    const [sellingPrice, setSellingPrice] = useState();
    const [costPrice, setCostPrice] = useState();
    const [sellingAccount, setSellingAccount] = useState();
    const [costAccount, setCostAccount] = useState();
    const [sellingDescription, setSellingDescription] = useState();
    const [costDescription, setCostDescription] = useState();
    const [taxRate, setTaxRate] = useState();
    const [minimumStock, setMinimumStock] = useState()
    const [inventoryAccount, setInventoryAccount] = useState();
    const [openingStock, setOpeningStock] = useState();
    const [openingStockRate, setOpeningStockRate] = useState();
    const [reorder, setReorder] = useState();
    const [preferedVendor, setPreferedVendor] = useState();
    const [saveLoding, setSaveLoding] = useState(false);

    const [typeError, setTypeError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [unitError, setUnitError] = useState(false);
    const [taxPreferencesError, setTaxPreferencesError] = useState(false);
    const [taxRateError, setTaxRateError] = useState(false);
    const [sellingPriceError, setSellingPriceError] = useState(false);
    const [image, setImage] = useState(undefined);
    const [localImage, setLocalImage] = useState(undefined);
    const [showCategoryList, setShowCategoryList] = useState(false);

    const [colorsArray, setColorsArray] = useState([]);
    const [sizeArray, setSizeArray] = useState([]);

    const units = [
        { value: "box", label: "Box" },
        { value: "Cm", label: "cm(Centimeter)" },
        { value: "dz", label: "dz(Dozen)" },
        { value: "ft", label: "ft(Feet)" },
        { value: "g", label: "g(Grams)" },
        { value: "in", label: "in(Inches)" },
        { value: "kg", label: "kg(Kilograms)" },
        { value: "km", label: "km(Kilometers)" },
        { value: "mg", label: "mg(Milli Grams)" },
        { value: "ml", label: "ml(Milli meter)" },
        { value: "m", label: "m(Meter)" },
        { value: "pcs", label: "pcs(Pieces)" },
    ];

    const taxable = [
        { value: "Taxable", label: "Taxable" },
        { value: "Non-taxable", label: "Non-taxable" },
        { value: "Out of Scope", label: "Out of Scope" },
        { value: "Non GST Supply", label: "Non GST Supply" },
    ];

    useEffect(() => {
        if (franchiseId) {
            props.getFranchise(franchiseId);
            props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise')

        }
    }, [franchiseId]);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (itemId) {
            props.setSelectedInventoryItem(itemId);
            props.getInventoryItemMatrixForItemId(itemId)
            props.getInventorySubItemForItemIds([itemId])
        }
    }, [itemId])


    useEffect(() => {
        if (props.franchise && props.franchise.attributes.features &&
            props.franchise.attributes.features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
            props.franchise.attributes.features[Constants.FRANCHISE_INVENTORY_INDEX] > 1) {
            let list = [];
            if (props.franchise.attributes.inventoryCategories) {
                for (const s of props.franchise.attributes.inventoryCategories) {
                    list.push({
                        value: s,
                        label: s
                    })
                }
            }
            setCategoriesList(list)
        }

    }, [props.franchise]);

    const handelSave = () => {

        console.log(props.inventoryWarehouses[0].id)
        let checkSave = true;

        if (!type) {
            setTypeError(true);
            checkSave = false;
        }

        if (!name) {
            setNameError(true);
            checkSave = false;
        }

        if (!unit) {
            setUnitError(true);
            checkSave = false;
        }

        if (!sellingPrice) {
            setSellingPriceError(true);
            checkSave = false;
        }

        if (!checkSave) {
            return
        }


        const InventoryItems = Parse.Object.extend("InventoryItems");
        var item = new InventoryItems();
        if (itemId) {
            item.id = itemId;
        } else {

            if (franchiseMatch) {
                item.set('ownerId', franchiseId);
                item.set('ownerType', "franchise");
            } else {
                item.set('ownerId', schoolId);
                item.set('ownerType', "school");
            }
            item.set('createdBy', Parse.User.current().attributes.teacherId)
            item.set('warehouseId', props.inventoryWarehouses[0].id)
        }

        item.set("type", type);
        item.set("name", name);
        item.set("description", description);
        item.set("sku", sku);
        item.set("unit", unit);
        item.set("hsn", hsnCode);
        item.set("taxPreferences", taxPreferences);
        item.set("xDimension", parseFloat(xDimension));
        item.set("yDimension", parseFloat(yDimension));
        item.set("weight", parseFloat(weight));
        item.set("manufacturer", manufacturer);
        item.set("brand", brand);
        item.set("upc", upc);
        item.set("mpn", mpn);
        item.set("ean", ean);
        item.set("isbn", isbn);
        item.set("sellingPrice", parseFloat(sellingPrice));
        item.set("costPrice", parseFloat(costPrice));
        item.set("sellingAccount", sellingAccount);
        item.set("costAccount", costAccount);
        item.set("sellingDescription", sellingDescription);
        item.set("costDescription", costDescription);
        item.set("taxRate", parseFloat(taxRate));
        item.set("minimumStock", parseInt(minimumStock));
        item.set("categories", [category]);
        if (colorsArray.length > 0 || sizeArray.length > 0) {
            item.set("isMatrix", true);
        }

        // item.set("inventoryAccount", inventoryAccount);
        // item.set("openingStock", openingStock);
        // item.set("openingStockRate", openingStockRate);
        // item.set("reorder", reorder);
        // item.set("preferedVendor", preferedVendor);

        setSaveLoding(true);

        if (localImage) {
            saveDisplayImage(item, localImage.imageFile)
        } else {
            saveItemObject(item);
        }

    }

    const saveDisplayImage = (item, imageFile) => {
        imageFile.save().then(
            (result) => {
                item.set('displayImage', result)
                saveItemObject(item)
            },
            (error) => {
                console.log("File:", error);
            },
            (progress) => {
                console.log(progress);
            }
        );
    }

    const saveItemObject = (item) => {
        item.save().then(
            (result) => {
                result.pin();
                console.log(result);
                props.updateSelectedInventoryItem(result);
                saveColorsAndSizes(result.id);
            },
            (error) => {
                setSaveLoding(false);
                console.log(error);
            }
        );
    };

    const saveColorsAndSizes = (itemId) => {
        const InventoryItemMatrix = Parse.Object.extend("InventoryItemMatrix");


        let list = [];

        for (let i = 0; i < colorsArray.length; i++) {
            let colorMatrix = new InventoryItemMatrix();
            if (colorsArray[i].object) {
                colorMatrix = colorsArray[i].object
            } else {
                colorMatrix.set("itemId", itemId);
                colorMatrix.set("type", "COLOR");
            }

            colorMatrix.set("value", colorsArray[i].value);
            list.push(colorMatrix);
        }
        for (let i = 0; i < sizeArray.length; i++) {
            let sizeMatrix = new InventoryItemMatrix();
            if (sizeArray[i].object) {
                sizeMatrix = sizeArray[i].object
            } else {
                sizeMatrix.set("itemId", itemId);
                sizeMatrix.set("type", "SIZE");
            }
            sizeMatrix.set("value", sizeArray[i].value);
            list.push(sizeMatrix);
        }
        if (list.length > 0) {
            Parse.Object.saveAll(list).then(
                (results) => {
                    if (results.length > 0) {
                        saveSubItems(itemId, results)
                    }
                    window.history.back();
                    console.log(results)
                },
                (error) => {
                    console.log("Error", error);
                }
            );
        }
    };

    const checkInSubItems = (colorId, sizeId) => {

        console.log(props.inventorySubItems)
        if (franchiseMatchEditItem || matchEditItem) {

            if (colorId && sizeId) {

                let colAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixColorId == colorId && si.attributes.itemId == itemId)
                let sizAry = colAry.filter(si => si.attributes.matrixSizeId == sizeId && si.attributes.itemId == itemId)
                if (sizAry.length > 0) {
                    return false;
                }
            } else if (colorId) {
                let colAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixColorId == colorId && si.attributes.itemId == itemId)
                if (colAry.length > 0) {
                    return false;
                }
            } else if (sizeId) {
                let sizAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixSizeId == sizeId && si.attributes.itemId == itemId)
                if (sizAry.length > 0) {
                    return false;
                }
            }
            return true;
        } else {
            return true;
        }
    }

    const saveSubItems = (itemId, matrix) => {
        const InventoryItems = Parse.Object.extend("InventoryItems");
        let list = [];

        let colors = matrix.filter(m => m.attributes.type === "COLOR");
        let sizes = matrix.filter(m => m.attributes.type === "SIZE");

        if (colors.length > 0 && sizes.length > 0) {
            for (let color of colors) {
                for (let size of sizes) {
                    if (checkInSubItems(color.id, size.id)) {
                        let item = new InventoryItems();
                        item.set("itemId", itemId);
                        item.set("warehouseId", props.inventoryWarehouses[0].id);
                        item.set('ownerId', franchiseId);
                        item.set('ownerType', "franchise");
                        item.set("matrixColorId", color.id);
                        item.set("matrixSizeId", size.id);

                        item.save().then(
                            (result) => {
                                result.pin();
                                console.log(result);
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                        list.push(item);
                    }
                }
            }
        }

        else if (colors.length > 0) {
            for (let color of colors) {
                if (checkInSubItems(color.id, undefined)) {
                    let item = new InventoryItems();
                    item.set("itemId", itemId);
                    item.set("warehouseId", props.inventoryWarehouses[0].id);
                    item.set('ownerId', franchiseId);
                    item.set('ownerType', "franchise");
                    item.set("matrixColorId", color.id);

                    item.save().then(
                        (result) => {
                            result.pin();
                            console.log(result);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                    list.push(item);
                }
            }
        }

        else if (sizes.length > 0) {
            for (let size of sizes) {
                if (checkInSubItems(undefined, size.id)) {
                    let item = new InventoryItems();
                    item.set("itemId", itemId);
                    item.set("warehouseId", props.inventoryWarehouses[0].id);
                    item.set('ownerId', franchiseId);
                    item.set('ownerType', "franchise");
                    item.set("matrixSizeId", size.id);

                    item.save().then(
                        (result) => {
                            result.pin();
                            console.log(result);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                    list.push(item);
                }
            }
        }

        if (list.length > 0 && false) {
            Parse.Object.saveAll(list).then(
                (results) => {
                    console.log("Saved subitems:", results);
                },
                (error) => {
                    console.log("Error saving subitems:", error);
                }
            );
        }
    };

    useEffect(() => {
        if (props.selectedInventoryItem && props.selectedInventoryItem.id == itemId) {
            setName(props.selectedInventoryItem.attributes.name);
            setDescription(props.selectedInventoryItem.attributes.description);
            setType(props.selectedInventoryItem.attributes.type);
            setSku(props.selectedInventoryItem.attributes.sku);
            setUnit(props.selectedInventoryItem.attributes.unit);
            setBrand(props.selectedInventoryItem.attributes.brand);
            setHsnCode(props.selectedInventoryItem.attributes.hsnCode);
            setTaxPreferences(props.selectedInventoryItem.attributes.taxPreferences);
            setTaxRate(props.selectedInventoryItem.attributes.taxRate);
            setMinimumStock(props.selectedInventoryItem.attributes.minimumStock)
            setXDimension(props.selectedInventoryItem.attributes.xDimension);
            setYDimension(props.selectedInventoryItem.attributes.yDimension);
            setManufacturer(props.selectedInventoryItem.attributes.manufacturer);
            setUpc(props.selectedInventoryItem.attributes.upc);
            setEan(props.selectedInventoryItem.attributes.ean);
            setIsbn(props.selectedInventoryItem.attributes.isbn);
            setMpn(props.selectedInventoryItem.attributes.mpn);
            setSellingAccount(props.selectedInventoryItem.attributes.sellingAccount);
            setCostAccount(props.selectedInventoryItem.attributes.costAccount);
            setSellingPrice(props.selectedInventoryItem.attributes.sellingPrice);
            setCostPrice(props.selectedInventoryItem.attributes.costPrice);
            setSellingDescription(props.selectedInventoryItem.attributes.sellingDescription);
            setCostDescription(props.selectedInventoryItem.attributes.costDescription);
            setImage(props.selectedInventoryItem.attributes.displayImage ? props.selectedInventoryItem.attributes.displayImage._url : undefined)
            setCategory(props.selectedInventoryItem.attributes.categories);
        }
    }, [props.selectedInventoryItem])

    useEffect(() => {
        if (props.inventoryItemMatrixs) {
            const initialColors = [];
            const initialSizes = [];

            for (const item of props.inventoryItemMatrixs) {
                if (item.attributes.type === 'COLOR') {
                    initialColors.push({ id: item.id || uuidv4(), value: item.attributes.value, object: item });
                } else if (item.attributes.type === 'SIZE') {
                    initialSizes.push({ id: item.id || uuidv4(), value: item.attributes.value, object: item });
                }
            }

            setColorsArray(initialColors);
            setSizeArray(initialSizes);
        }
    }, [props.inventoryItemMatrixs]);

    function handleSubmit() {
        handelSave();
    }

    const handlePicChange = (imageFile, cr) => {

        setLocalImage({ imageFile: imageFile, cr: cr })
        setImage(cr);

    };


    return (
        <div className='page-content'>
            <Topbar
                title={matchEditItem || franchiseMatchEditItem ? "Edit Item" : "New Item"}
                buttonName={"Save"}
                hideButton={true}
            />

            <Card>
                <CardBody>
                    <AvForm className="needs-validation" onValidSubmit={handleSubmit} >
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="form-group d-flex align-items-center">
                                        <Label htmlFor="example-radio-input" className="col-md-2 col-form-label ">Type<i className="fas fa-question-circle" id="types">
                                            <UncontrolledTooltip placement="right" target="types">
                                                Type
                                            </UncontrolledTooltip></i></Label>
                                        <div className="custom-control custom-radio custom-control-inline m-2">
                                            <Input
                                                type="radio"
                                                id="customRadioInline1"
                                                name="customRadioInline1"
                                                checked={type === "Goods"}
                                                className="custom-control-input m-1"
                                                onChange={(e) => {
                                                    setType("Goods");
                                                    setTypeError(false)
                                                }}
                                                required
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="customRadioInline1"
                                            >
                                                Goods
                                            </Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline m-2" style={{ marginLeft: "30px" }}>
                                            <Input
                                                type="radio"
                                                id="customRadioInline2"
                                                name="customRadioInline1"
                                                checked={type === "Service"}
                                                className="custom-control-input m-1"
                                                onChange={(e) => {
                                                    setType("Service");
                                                    setTypeError(false)
                                                }}
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="customRadioInline2"
                                            >
                                                Service
                                            </Label>
                                        </div>
                                    </div>
                                    {typeError && <small className='text-danger'>* Select type of items</small>}
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label  " style={{ color: 'red' }}>Name*</Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="Name"
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                    setNameError(false);
                                                }}
                                            />
                                            {nameError && <small className='text-danger'>* Enter item name</small>}
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Discription:</Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="textarea"
                                                id="description"
                                                placeholder="Write here..."
                                                rows="4"
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">SKU<i className="fas fa-question-circle" id="SKU">
                                            <UncontrolledTooltip placement="right" target="SKU">
                                                Stock keeping unit
                                            </UncontrolledTooltip></i></Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="Stock keeping unit"
                                                required
                                                value={sku}
                                                onChange={(e) => {
                                                    setSku(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Minumum Stock:</Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="number"
                                                id="displayName"
                                                placeholder="Minumum Stock"
                                                value={minimumStock}
                                                onChange={(e) => {
                                                    setMinimumStock(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col className="text-center  ">
                                <div >
                                    {!image ? (
                                        <div
                                            className="avatar-lg mx-auto mb-2"
                                            style={{ width: "10rem", height: "10rem" }}
                                        >
                                            <span
                                                className={
                                                    "avatar-title  bg-soft-primary text-primary"
                                                }
                                            // style={{ fontSize: "10px" }}
                                            >
                                                Upload image
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            <img
                                                style={{ width: "10rem", height: "10rem" }}
                                                className="avatar mb-2"
                                                src={image}
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <UpdatePhoto
                                        className="p-1"
                                        isPopupOn={false}
                                        onPicChange={(imageFile, cr) => {
                                            handlePicChange(imageFile, cr);
                                        }}
                                        initialImage={image}
                                    ></UpdatePhoto>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Selling Price:</Label>
                                        <div className="col-md-10">
                                            <InputGroup>
                                                <InputGroupText>INR</InputGroupText>
                                                <Input
                                                    type="number"
                                                    id="sellingPrice"
                                                    placeholder="Selling price"
                                                    value={sellingPrice}
                                                    required
                                                    onChange={(e) => {
                                                        setSellingPrice(e.target.value)
                                                        setSellingPriceError(false);
                                                    }}
                                                /></InputGroup>
                                            {sellingPriceError && <small className='text-danger'>*Enter selling price</small>}
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                {(props.franchise && props.franchise.attributes.features &&
                                    props.franchise.attributes.features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
                                    props.franchise.attributes.features[Constants.FRANCHISE_INVENTORY_INDEX] > 1) && <FormGroup>
                                        <div className="form-group row">
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Category:</Label>
                                            <div className="col-md-10">
                                                <select
                                                    className="form-control "
                                                    value={category}
                                                    onChange={(e) => {
                                                        setCategory(e.target.value)
                                                    }}
                                                >
                                                    <option disabled selected value>
                                                        Select Category
                                                    </option>

                                                    {categoriesList.map((value, key) => (
                                                        <option key={value.value} value={value.value}>{value.label} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </FormGroup>}
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label" >Unit:</Label>
                                        <div className="col-md-10">
                                            <select
                                                className="form-control "
                                                value={unit}
                                                style={{ width: "14rem" }}
                                                onChange={(e) => {
                                                    setUnit(e.target.value);
                                                    setUnitError(false);
                                                }}
                                            >
                                                <option disabled selected value>
                                                    Select Unit
                                                </option>

                                                {units.map((value, key) => (
                                                    <option key={value.value} value={value.value}>{value.label} </option>
                                                ))}
                                            </select>
                                            {unitError && <small className='text-danger'>* Select unit</small>}
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label" style={{ color: 'red' }}>Tax :</Label>
                                        <div className="col-md-10">
                                            <select
                                                className="form-control "
                                                style={{ width: "14rem" }}
                                                value={taxPreferences}
                                                onChange={(e) => {
                                                    setTaxPreferences(e.target.value)
                                                    setTaxPreferencesError(false);
                                                }}
                                            >
                                                <option disabled selected value>
                                                    Select Preference Tax
                                                </option>

                                                {taxable.map((value, key) => (
                                                    <option key={value.value} value={value.value}>{value.label} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                {taxPreferences !== "Taxable" || <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Tax rate:</Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="number"
                                                id="taxRate"
                                                placeholder="Tax rate"
                                                required
                                                value={taxRate}
                                                onChange={(e) => {
                                                    setTaxRate(e.target.value);
                                                    setTaxRateError(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>}
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Dimensions:</Label>
                                        <div className="col-md-10">
                                            <InputGroup>
                                                <InputGroupText>cm</InputGroupText>
                                                <Input className="form-control"
                                                    type="number"
                                                    id="x"
                                                    placeholder="X"
                                                    value={xDimension}
                                                    required
                                                    onChange={(e) => {
                                                        setXDimension(e.target.value)
                                                    }}
                                                /><Input className="form-control"
                                                    type="number"
                                                    id="y"
                                                    placeholder="Y"
                                                    value={yDimension}
                                                    required
                                                    onChange={(e) => {
                                                        setYDimension(e.target.value)
                                                    }}
                                                /></InputGroup>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Weight:</Label>
                                        <div className="col-md-10">
                                            <InputGroup>
                                                <InputGroupText>Kg</InputGroupText>
                                                <Input className="form-control"
                                                    type="number"
                                                    id="weight"
                                                    placeholder="Weight"
                                                    required
                                                    value={weight}
                                                    onChange={(e) => {
                                                        setWeight(e.target.value)
                                                    }}
                                                /></InputGroup>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Manufacturer:</Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="Manufacturer"
                                                required
                                                value={manufacturer}
                                                onChange={(e) => {
                                                    setManufacturer(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Brand:</Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="Brand"
                                                required
                                                value={brand}
                                                onChange={(e) => {
                                                    setBrand(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <div className="flex-fill m-2">
                                    <Label className="pb-0" style={{ verticalAlign: "middle" }}>
                                        Add Colors
                                    </Label>
                                    <Label
                                        className="m-2 text-primary"
                                        onClick={() => {
                                            const tempList = [...colorsArray];
                                            tempList.push({ id: uuidv4(), value: '' });
                                            setColorsArray(tempList);
                                        }}
                                        id="add-time-camera"
                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                    >
                                        <i className="bx bx-plus-circle"></i>
                                    </Label>
                                </div>
                                <hr />
                                {colorsArray.map((colAry, index) => (
                                    <Row className="d-flex" key={colAry.id}>
                                        <Col lg={10}>
                                            <div className="m-2">
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    id="displayName"
                                                    placeholder={`Color ${index + 1}`}
                                                    required
                                                    value={colAry.value}
                                                    onChange={(e) => {
                                                        const selectedColor = e.target.value;
                                                        const tempList = colorsArray.map(item =>
                                                            item.id === colAry.id ? { ...item, value: selectedColor } : item
                                                        );
                                                        setColorsArray(tempList);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="float-right mt-0" style={{ verticalAlign: "middle" }}>
                                                <Label
                                                    className="ml-2 float-right text-danger"
                                                    onClick={() => {
                                                        const tempList = colorsArray.filter(range => range.id !== colAry.id);
                                                        setColorsArray(tempList);
                                                    }}
                                                    id="add-time-camera"
                                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                                >
                                                    {!franchiseMatchEditItem && <i className="bx bx-minus-circle"></i>}
                                                </Label>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                            <Col>
                                <div className="flex-fill m-2">
                                    <Label className="pb-0" style={{ verticalAlign: "middle" }}>
                                        Add Size
                                    </Label>
                                    <Label
                                        className="m-2 text-primary"
                                        onClick={() => {
                                            const tempList = [...sizeArray];
                                            tempList.push({ id: uuidv4(), value: '' });
                                            setSizeArray(tempList);
                                        }}
                                        id="add-time-camera"
                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                    >
                                        <i className="bx bx-plus-circle"></i>
                                    </Label>
                                </div>
                                <hr />
                                {sizeArray.map((sizeAry, index) => (
                                    <Row className="d-flex" key={sizeAry.id}>
                                        <Col lg={10}>
                                            <div className="m-2">
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    id="displayName"
                                                    placeholder={`Size ${index + 1}`}
                                                    required
                                                    value={sizeAry.value}
                                                    onChange={(e) => {
                                                        const selectedSize = e.target.value;
                                                        const tempList = sizeArray.map(item =>
                                                            item.id === sizeAry.id ? { ...item, value: selectedSize } : item
                                                        );
                                                        setSizeArray(tempList);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="float-right mt-0" style={{ verticalAlign: "middle" }}>
                                                <Label
                                                    className="ml-2 float-right text-danger"
                                                    onClick={() => {
                                                        const tempList = sizeArray.filter(range => range.id !== sizeAry.id);
                                                        setSizeArray(tempList);
                                                    }}
                                                    id="add-time-camera"
                                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                                >
                                                    {!franchiseMatchEditItem && <i className="bx bx-minus-circle"></i>}
                                                </Label>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                        <div className='d-flex justify-content-end'>
                            {
                                saveLoding ?
                                    <Button type="button" color="primary" className=" waves-effect float-right">
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>Saving...
                                    </Button> :
                                    <Button type="submit" className='float-right'
                                        color="primary">
                                        SAVE
                                    </Button>
                            }
                        </div>
                    </AvForm>
                </CardBody>
            </Card >

        </div >
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { selectedInventoryItem, inventoryItemMatrixs, inventorySubItems } = state.InventoryItems;
    const { franchise } = state.Franchise;
    const { inventoryWarehouses } = state.InventoryWarehouse;

    return { selectedSchool, selectedInventoryItem, franchise, inventoryItemMatrixs, inventoryWarehouses, inventorySubItems };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, setSelectedInventoryItem, updateSelectedInventoryItem, getFranchise, getInventoryItemMatrixForItemId, getInventoryWarehouseForOwnerId, getInventorySubItemForItemIds })(AddItems)));
