import React, { useState, useEffect } from 'react';
import * as WhiteLabel from "../../components/Common/WhiteLable";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';
import Switch from "react-switch";
import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Container, Label, Card, CardBody, FormGroup } from "reactstrap";

const header = (props) => {

    const [showSettingsModal, setShowSettingsModal] = useState(false);

    function print() {
        // var css = "@page { size: A4;margin:10mm 0 10mm 0; }",
        //     head = document.head || document.getElementsByTagName("head")[0],
        //     style = document.createElement("style");

        // style.type = "text/css";
        // style.media = "print";

        // if (style.styleSheet) {
        //     style.styleSheet.cssText = css;
        // } else {
        //     style.appendChild(document.createTextNode(css));
        // }

        // head.appendChild(style);
        window.print();
    }

    const getHeader = () => {
        return (
            <div>
                <Row className="text-center bg-white">
                    <Col xs="12" className="d-print-none mt-3 mb-3">
                        <div className="navbar-header p-0" style={{ borderBottom: "1px solid gray" }}>
                            <h4 className="text-center m-4 "><img src={WhiteLabel.getAppLogoWithName()} className="m-5" height="50px" style={{ border: "1px solid lightGray", borderRadius: "10px", padding: "10px" }}></img>{props.certificate}</h4>
                            <div className="d-flex m-4">
                                <Row className="d-flex  m-4">
                                    <Col className='d-flex justify-content-start'>
                                        <div>
                                            <Button
                                                color="primary"
                                                className='float-right btn btn-primary waves-effect waves-light '
                                                onClick={() => {
                                                    setShowSettingsModal(true)
                                                }}
                                            >
                                                Customise
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    print();
                                                }}
                                                className="float-right btn btn-success waves-effect waves-light "
                                            >
                                                <i className="fa fa-print m-1"></i>Print
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal size='sm' isOpen={showSettingsModal} toggle={() => { setShowSettingsModal(!showSettingsModal) }} scrollable={true}>
                    <ModalHeader toggle={() => {
                        setShowSettingsModal(!showSettingsModal)
                    }}>
                        Customise Certificate
                    </ModalHeader>
                    <ModalBody>
                        <div className='mt-2'>
                            <div>
                                <div style={{ display: "inline-block" }}>
                                    <label><b>Header :</b></label>
                                </div>
                                <div style={{ display: "inline-block", marginLeft: "15px" }}>
                                    <Switch
                                        height={15}
                                        width={30}
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        onColor={"#6add7d"}
                                        checked={props.showRow}
                                        onChange={() => {
                                            props.onChangeShowRow(!props.showRow);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }

    return (
        <React.Fragment>
            {getHeader()}
        </React.Fragment>
    )
}

export default header
