import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as GroupMapHelper from "../../../helpers/ParseHelpers/groupMap_helper";
import * as actions from "./actionTypes";
import * as Constants from '../../../Constents';
import * as GroupMapActions from './actions';
import Parse from 'parse';
import { saveGroup } from "../group/actions";
import toastr from 'toastr'


function* getAGroupMap({ payload: { groupMapId } }) {
  try {
    const response = yield call(GroupMapHelper.getGroupMapFromParse, groupMapId);
    yield put(GroupMapActions.addGroupMap(response));
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAGroupMap({ payload: { groupMapId } }) {
  try {
    const response = yield call(GroupMapHelper.deleteGroupMapFromParse, groupMapId);
    if (response) {
      yield put(GroupMapActions.deleteGroupMap(response));
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getGroupMapForGroupId({ payload: { groupId } }) {
  try {
    // const localResponse = yield call(GroupMapHelper.getGroupMapForGroupId, groupId, true);
    // if (localResponse && localResponse.length > 0) {
    //   yield put(GroupMapActions.updateGroupmaps(localResponse));

    // }

    yield put(GroupMapActions.setLoadingGroupmaps(true));

    const serverResponse = yield call(GroupMapHelper.getGroupMapForGroupId, groupId, false);

    yield put(GroupMapActions.updateGroupmaps(serverResponse));
    yield put(GroupMapActions.setLoadingGroupmaps(false));

    //Parse.Object.pinAll(serverResponse);

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getGroupMapForProgramGroupIds({ payload: { groupIds } }) {
  try {
    const serverResponse = yield call(GroupMapHelper.getGroupMapForGroupIds, groupIds);
    console.log("serverResponse",serverResponse)
    yield put(GroupMapActions.updateProgramGroupMaps(serverResponse));
  } catch (error) {
  }
}



function* getGroupMapForTeacherIdParse({ payload: { teacherId } }) {
  try {

    const serverResponse = yield call(GroupMapHelper.getGroupMapsForTeacherId, teacherId, false);

    if (serverResponse && serverResponse.length > 0) {
      yield put(GroupMapActions.updateGroupmaps(serverResponse));
    }
  } catch (error) {

  }
}

function* getGroupMapForKidId({ payload: { kidId } }) {
  try {

    const serverResponse = yield call(GroupMapHelper.getGroupMapsForKidId, kidId);

    if (serverResponse && serverResponse.length > 0) {
      yield put(GroupMapActions.updateGroupmaps(serverResponse));
    }
  } catch (error) {

  }
}

function* saveGroupMaps({ payload: { groupMaps, successMsg, moveBack,tripIds } }) {
  try {
    const response = yield call(GroupMapHelper.saveGroupMaps, groupMaps,tripIds);
    if (response) {
      yield put(GroupMapActions.updateGroupmaps(response));
      yield put(GroupMapActions.updateResultGroupMaps(response));
      if (successMsg && successMsg.length > 0) {
        toastr.success(successMsg, { timeOut: 2000 })
      }
      if (moveBack) {
        window.history.back()
      }
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* checkAndAddGroupMapForKidsSaga({ payload: { groupId, selectedKidIds, successMsg, errorMsg } }) {
  try {
    const response = yield call(GroupMapHelper.checkAndAddGroupMapForKidsParse, groupId, selectedKidIds);
    if (response) {
      //yield put(GroupMapActions.updateResultGroupMaps(response));
      if (successMsg && successMsg.length > 0) {
        toastr.success(successMsg, { timeOut: 2000 })
      }

    } else {
      if (errorMsg && errorMsg.length > 0) {
        toastr.success(errorMsg, { timeOut: 2000 })
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}



export function* watchSaveGroupMaps() {
  yield takeEvery(actions.SAVE_GROUP_MAPS, saveGroupMaps);
}

export function* watchCheckAndAddGroupMapForKidsSaga() {
  yield takeEvery(actions.CHECK_AND_ADD_GROUP_MAPS_FOR_KIDS, checkAndAddGroupMapForKidsSaga);
}



export function* watchGetGroupMapForTeacherIdParse() {
  yield takeEvery(actions.GET_GROUP_MAPS_FOR_TEACHER_ID, getGroupMapForTeacherIdParse);
}

export function* watchGetGroupMapForKidId() {
  yield takeEvery(actions.GET_GROUP_MAPS_FOR_KID_ID, getGroupMapForKidId);
}

export function* watchGetGroupMap() {
  yield takeEvery(actions.UPDATE_GROUP_MAP, getAGroupMap);
}

export function* watchDeleteGroupMap() {
  yield takeEvery(actions.DELETE_GROUP_MAP, deleteAGroupMap);
}
export function* watchGetGroupMapForGroupId() {
  yield takeEvery(actions.GET_GROUP_MAPS_FOR_GROUP_ID, getGroupMapForGroupId);
}

export function* watchGetGroupMapForProgramGroupIds() {
  yield takeEvery(actions.GET_GROUP_MAP_FOR_PROGRAM_GROUPIDS, getGroupMapForProgramGroupIds);
}



function* groupSaga() {
  yield all([
    fork(watchGetGroupMap),
    fork(watchDeleteGroupMap),
    fork(watchGetGroupMapForGroupId),
    fork(watchSaveGroupMaps),
    fork(watchGetGroupMapForTeacherIdParse),
    fork(watchGetGroupMapForKidId),
    fork(watchCheckAndAddGroupMapForKidsSaga),
    fork(watchGetGroupMapForProgramGroupIds),
  ]);
}
export default groupSaga;
