import React, { useState, useEffect } from 'react'
import Topbar from '../Topbar'
import { Card, CardBody, Row, Col, Label, Table, CardTitle, CardHeader, Button, Badge } from 'reactstrap'
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "react-switch";
import ItemImage from "../../../assets/images/school-bag.png"
import { setSelecetdSchool, getInventoryKitsForOwnerId, deleteInventoryKit, editInventoryKit } from '../../../store/actions';

const AllItemGroups = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/inventory-item-group/:id",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-inventory-item-group/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;


    const [showDelete, setShowDelete] = useState(false);
    const [deleteObject, setDeleteObject] = useState(undefined);

    useEffect(() => {
        if (schoolId && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getInventoryKitsForOwnerId(schoolId, 'school')
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryKitsForOwnerId(franchiseId, 'franchise')
        }
    }, [franchiseId]);

    //console.log(props.inventoryKits);


    const handleDeleteItem = () => {
        props.deleteInventoryKit(deleteObject)
        setShowDelete(false);
    };

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };

    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };

    const getShowInStore = (isChecked, kit) => {
        kit.set("showInStore", isChecked);
        props.editInventoryKit(kit);
    }

    return (
        <div className='page-content'>
            <Topbar
                title={"Inventory Kits"}
                buttonName={"New Kit"}
                hideButton={false}
                link={schoolId ? `/new-items-group/${schoolId}` : `/franchise-new-items-group/${franchiseId}`}
            />
            {showDelete && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDelete(false);
                        setDeleteObject(undefined)
                    }}
                    onConfirm={() => {
                        handleDeleteItem();

                    }}
                >
                    {"Are you sure you want to delete"}
                    <br></br>
                </SweetAlert>
            )}
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Kit Name</th>
                                    <th>Cost</th>
                                    <th className='text-center'>Show in store</th>
                                    <th style={{ width: "20px" }}>
                                        Edit
                                    </th>
                                    <th style={{ width: "20px" }}>
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.inventoryKits.map((kit, key) =>
                                        <tr key={key}>
                                            <th>
                                                {kit.attributes.displayImage ?
                                                    <div>
                                                        <img className="rounded-circle avatar-xs" src={kit.attributes.displayImage._url} alt="" />
                                                    </div> :
                                                    <div className="maintenance-img">
                                                        <img src={ItemImage} alt="" className="rounded-circle avatar-xs" />
                                                    </div>

                                                }
                                            </th>
                                            <td>

                                                <Link to={match ? `/edit-inventory-items-group/${schoolId}/${kit.id}` : `/franchise-edit-inventory-items-group/${franchiseId}/${kit.id}`}>
                                                    {kit.attributes.name}
                                                </Link>


                                            </td>

                                            <td>
                                                <h6>{kit.attributes.sellingPrice ? kit.attributes.sellingPrice : "-"}</h6>
                                            </td>

                                            <td className='text-center'>
                                                {/* <Switch
                                                    uncheckedIcon={<Offsymbol />}
                                                    checkedIcon={<OnSymbol />}
                                                    onColor="#626ed4"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        getShowInStore(e.target.checked, kit)
                                                    }}
                                                    checked={kit.attributes.showInStore || false}
                                                /> */}
                                                <div className="custom-control custom-switch mb-2" dir="ltr">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={"recurringModal" + kit.id}
                                                        checked={kit.attributes.showInStore == true}
                                                        onChange={(e) => getShowInStore(e.target.checked, kit)} 
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={"recurringModal" + kit.id}
                                                        onClick={(e) => e.stopPropagation()} 
                                                    ></label>
                                                </div>

                                            </td>

                                            <td>

                                                <div className="flex-fill text-center ">
                                                    <Link to={match ? `/edit-inventory-items-group/${schoolId}/${kit.id}` : `/franchise-edit-inventory-items-group/${franchiseId}/${kit.id}`}>
                                                        <i className="bx bx-edit-alt"></i>
                                                    </Link>
                                                </div>

                                            </td>

                                            <td>
                                                <div
                                                    className="flex-fill text-center"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setDeleteObject(kit)
                                                        setShowDelete(true);
                                                    }}
                                                >
                                                    <i className="bx bxs-trash text-danger"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryKits } = state.InventoryKits;
    return { selectedSchool, inventoryKits };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryKitsForOwnerId, deleteInventoryKit, editInventoryKit })(AllItemGroups)));