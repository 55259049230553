import React, {useEffect,useState} from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table, CardImg } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import QRCode from 'qrcode'
import Parse from 'parse';
import * as WhiteLable from "../../components/Common/WhiteLable";
import logo from "../../assets/images/logo-dark.png";
import html2canvas from 'html2canvas';

const EnquiryPoster = (props) => {


    
    const match = matchPath(props.history.location.pathname, {
        path: "/enquiryposter/:id",
        exact: true,
        strict: false,
    });
    const parameter = match.params.id;

    const [loading, setLoading] = useState(true);
    const [formQRCode, setFormQRCode] = useState(undefined);

    const [school, setSchool] = useState(undefined);

    const [schoolName, setSchoolName] = useState(undefined);
    const [schoolCity, setSchoolCity] = useState(undefined);
    const [schoolLogo, setSchoolLogo] = useState(undefined);

    const [posterType,setPosterType]=useState("a4")
    
    const HOST_NAME = WhiteLable.getAppName() === "lilTriangle" ? "https://forms.liltriangle.com" : WhiteLable.getHost();
    const ENQUIRY_FORM = HOST_NAME+"/enquiry-form/" + parameter;
    
    useEffect(() => {
        getSchool();
        formQr();
    }, [parameter]);

    function formQr() {
        QRCode.toDataURL(ENQUIRY_FORM ,{ errorCorrectionLevel: 'H' })
            .then(url => {
                setFormQRCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }
    function getSchool(){
        var School = Parse.Object.extend("School");
        var query = new Parse.Query(School);
      
        query.get(parameter).then(
            (result) => {
                setLoading(false);
                setSchoolName(result.attributes.Name);
                setSchoolCity(result.attributes.city);
                if (result.attributes.logo) {
                    setSchoolLogo(result.attributes.logo._url);
                }
                setSchool(result)
            },
            (error) => {
                console.log(error)
            }
        );
    }
    function printPoster() {

        if(posterType =="card-" ){
            doenloadDocument()
        }else{
            var css = "@page {size: A4,margin: 0; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

            style.type = "text/css";
            style.media = "print";

            if (style.styleSheet) {
            style.styleSheet.cssText = css;
            } else {
            style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);

            window.print();
        }
    }

    const doenloadDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input, { allowTaint: true, useCORS: true })
            .then((canvas) => {

                // Convert canvas to data URL
                const imgData = canvas.toDataURL('image/png');

                // Create a link element and trigger a download
                const downloadLink = document.createElement('a');
                downloadLink.href = imgData;
                downloadLink.download = schoolName+'EnquiryScanCard.png';
                downloadLink.click();
            });
    };

    const getButtonSelectedClass=(type)=>{

        if(type==posterType){
            return "btn btn-primary waves-effect waves-light m-2"
        }else{
            return "btn btn-light waves-effect waves-light border-1 m-2"
        }
    }

    return (
        <React.Fragment> 
            <Container fluid="lg" className='p-0'>   
                {loading ? 
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Link>
                        </div>
                    </Col>
               
                    :  
                    <Row className="text-center" >
                        <Col xs="12" className="d-print-none m-3">
                            <div className="text-center">
                                <button  onClick={() => { setPosterType("a4") } } className={getButtonSelectedClass("a4")}>A4</button>
                                <button  onClick={() => { setPosterType("card") } } className={getButtonSelectedClass("card")}>Card</button>
                                <button  onClick={() => { setPosterType("qrCode") } } className={getButtonSelectedClass("qrCode")}>QR Code</button>
                                <button  onClick={() => { printPoster() } } className="btn btn-success waves-effect waves-light m-2"><i className="fa fa-print"></i>Print Poster</button>
                            </div>
                        </Col>


                        {posterType=="a4" && <Col xs="12" >
                                    <center>
                                    <div style={{ width: "211mm", height: "297mm"}}>
                                        <div style={{ height: "150mm", backgroundColor: "#fe1386" }}>

                                        </div>
                                        <div style={{ height: "150mm", backgroundColor: "#ffbd59" }}>
                                        </div>
                                            <div style={{backgroundColor: "#fff",marginTop: "-135%",marginBottom:"-144%",width: "90%",height:" 90%"}}>
                                            <hr style={{ border: "0.1px solid #fe138650",marginLeft: "5cm",marginRight:"5cm"}} />

                                                        <table>
                                                            {schoolLogo ? <tr>
                                                                <td style={{ width: "40%" }}>
                                                                    <img className="p2" src={schoolLogo} width="200px" alt="" />
                                                                </td>
                                                                <td style={{width:"60%"}}>
                                                                    <center><span style={{fontSize: "30px",fontWeight: "bold"}}>{schoolName}</span></center>
                                                                    <center><span style={{fontSize: "20px",fontWeight: "bold"}}>{schoolCity }</span></center>
                                                                </td>
                                                            </tr> :
                                                            
                                                            <tr>
                                                                
                                                                <td >
                                                                    <center><span style={{fontSize: "30px",fontWeight: "bold"}}>{schoolName}</span></center>
                                                                    <center><span style={{fontSize: "20px",fontWeight: "bold"}}>{schoolCity }</span></center>
                                                                </td>
                                                            </tr>
                                                            }
                                                        </table>
                                                        <hr style={{ border: "0.1px solid #fe138650",marginLeft: "5cm",marginRight:"5cm"}} />

                                                    <div className="col-md-12 mt-5 mb-5">
                                                        <span style={{fontSize:" 30px",fontWeight: "bolder",color: "#5f5f5f"}}>
                                                            PLEASE SCAN BELOW QR CODE <br/>
                                                            TO SUBMIT YOUR ENQUIRY
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 mt-5 mb-5" style={{marginleft: "-15%",marginTop: "6%"}}>
                                                        <img className="img-responsive" src={formQRCode} style={{width: "280px",marginTop: "1%"}}/>
                                                    </div>
                                                    <hr style={{ border: "0.1px solid #ffbd59",marginLeft: "5cm",marginRight:"5cm"}} />

                                                    <div className="col-md-12 mt-5">
                                                        <p>{props.t('Powered By')}</p>
                                                        <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{width: "150px"}}/>
                                                    </div>
                                            </div>
                                        </div>
                                    </center>
                                
                        </Col>}

                        {posterType =="card" && <Col>
                        <center>
                        
                            <div style={{ minHeight: "200mm", width: "150mm", backgroundColor: '#E8F6EF' }} id='divToPrint'>
                                <div className='position-relative'>

                              
                                    <div
                                        className='position-absolute'
                                        style={{ 
                                            height: "165px",
                                            width: "100%", 
                                            background: "linear-gradient(to top, #BBE9DB, #BBE9DB)", 
                                            clipPath: "polygon(70% 0%, 100% 0%, 100% 100%, 100% 100%)", 
                                        }}
                                        >
                                    </div>
                                    <div className='position-absolute'>


                                        {schoolLogo ? (
                                            <div className="d-flex align-items-center justify-content-center">
                                                <img src={schoolLogo} width="160" alt="" className='m-2'/>
                                                <div className='m-2'>
                                                    <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                    <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                                </div>
                                            </div>
                                        ) : 
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                    <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div
                                        style={{ 
                                            height: "165px",
                                            width: "100%", 
                                        }}
                                        >
                                    </div>


                                </div>
                                <hr style={{ border: "0.1px solid #BBE9DB", marginLeft: "3cm", marginRight: "3cm" , }} />

                                <div className="col-md-12 d-flex justify-content-center">
                                    <img className="img-responsive" src={formQRCode} style={{ width: "230px",  }} />
                                </div>
                                <div style={{ backgroundColor: '#BBE9DB', marginLeft: '2cm', marginRight: '2cm', marginTop:'5mm',borderRadius:'5mm' }}>
                                    <div className="col-md-12 ">
                                        <span style={{ fontSize: " 28px", color: "#1e4954" }}>
                                            PLEASE SCAN <br />
                                            BELOW QR CODE <br />
                                            TO SUBMIT YOUR <br />
                                            ENQUIRY
                                        </span>
                                    </div>
                                </div>
                                <hr style={{ border: "0.1px solid #BBE9DB", marginLeft: "6cm", marginRight: "6cm",marginTop:'5mm', marginBottom:'5mm'}} />

                                <div className="col-md-12 bottom-0">
                                    {/* <p style={{marginLeft:"15mm"}}>{props.t('Powered By')}</p> */}
                                    <p className='m-1' style={{marginLeft:"30mm"}}>{props.t('Powered By')}</p>
                                    <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "160px" }} />
                                </div>
                            </div>
                        </center>

                        </Col>}
                        {posterType =="qrCode" && <Col>
                            <div className="col-md-12 mt-5 mb-5" style={{marginleft: "-15%",marginTop: "6%"}}>
                                <img className="img-responsive" src={formQRCode} style={{width: "280px",marginTop: "1%"}}/>
                                <p className='m-4 '>Enquiry poster QR code</p>
                            </div>
                        </Col>}
                    </Row>
                }    
            </Container> 
        </React.Fragment>
          
    );
}
    

        
export default withNamespaces()(EnquiryPoster);   ;