import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Container, Label, Card, CardBody } from "reactstrap";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import { getAKid, setSelecetdSchool, getClassroomsForSchoolId } from "../../store/actions";
import Header from './header';

const bonafideCertificate = (props) => {

    const match1 = matchPath(props.history.location.pathname, {
        path: "/bonafide-certificate/:schoolId/:id",
        exact: true,
        strict: false,
    });

    const match2 = matchPath(props.history.location.pathname, {
        path: "/bonafide-certificate/:schoolId/:id/:year",
        exact: true,
        strict: false,
    });

    const match = match1 ?? match2;

    const today = new Date()
    const kidId = match && match.params.id;
    const schoolId = match && match.params.schoolId;
    const year = match && match.params.year;

    const [parent, setParent] = useState();
    const [showRow, setShowRow] = useState(true);


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId])

    useEffect(() => {
        if (kidId) {
            props.getAKid(kidId);
        }
    }, [kidId]);


    useEffect(() => {
        props.getClassroomsForSchoolId(schoolId);
    }, [schoolId]);

    const getYear = () => {
        if (year) {
            return year;
        }
        let y = today.getFullYear();
        if (today.getMonth() <= 2) {
            y = y - 1;
        }

        return `${y}-${y + 1 - 2000}`
    }



    useEffect(() => {
        if (props.selectedKid) {
            let parent = {}
            if (props.selectedKid.parents) {
                for (let k in props.selectedKid.parents) {
                    if (props.selectedKid.parents[k].attributes.Relation === "Mother") {
                        parent.mother = props.selectedKid.parents[k];
                    } else {
                        parent.father = props.selectedKid.parents[k];
                    }
                }
            }
            setParent(parent)
        }
    }, [props.selectedKid])

    const getDateInDDMMYYYY = (date) => {
        return moment(date).format("DD/MM/YYYY");
    };

    const getClassRoomName = (classroomId) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === classroomId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name + " " ?? "-")
            }
        }
    }

    function print() {
        var css = "@page { size: A3;margin:10mm 0 10mm 0; }",
          head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");
    
        style.type = "text/css";
        style.media = "print";
    
        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
    
        head.appendChild(style);
        window.print();
      }

    const getUI1 = () => {
        return (<div >
            {
                !(props.selectedSchool && props.selectedKid) ? <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading.....</Label>
                    </div>

                </Col> :
                    <Row style={{ backgroundColor: "#fff" }} className='p-4'>
                        <Col lg="12">
                            <div className="invoice-title">
                                <div className="invoice-certificateName" style={{ visibility: showRow ? 'visible' : 'hidden' }}>
                                    <Row className="d-flex justify-content-between">
                                        {props.selectedSchool.attributes.logo && (
                                            <Col className="d-flex justify-content-start">
                                                <img src={props.selectedSchool.attributes.logo._url} style={{ height: "6rem" }} />
                                            </Col>
                                        )}

                                        <Col className="d-flex justify-content-end">
                                            <div className="d-flex justify-content-start align-items-start">
                                                <address>
                                                    <strong>School Details:</strong>
                                                    <br />

                                                    {props.selectedSchool.attributes.Name && (
                                                        <strong>
                                                            {props.selectedSchool.attributes.Name}
                                                            <br />
                                                        </strong>
                                                    )}
                                                    {props.selectedSchool.attributes.Address}
                                                    {props.selectedSchool.attributes.Address && <br />}
                                                    {props.selectedSchool.attributes.primaryPhoneNumber && (
                                                        <span>{props.selectedSchool.attributes.primaryPhoneNumber}</span>
                                                    )}
                                                    {", "}
                                                    {props.selectedSchool.attributes.secondaryPhoneNumber && (
                                                        <span>{props.selectedSchool.attributes.secondaryPhoneNumber}</span>
                                                    )}
                                                    {(props.selectedSchool.attributes.primaryPhoneNumber ||
                                                        props.selectedSchool.attributes.secondaryPhoneNumber) && <br />}
                                                    {props.selectedSchool.attributes.EMail}
                                                    {props.selectedSchool.attributes.EMail && <br />
                                                    }
                                                </address>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <hr />
                            <Row className="d-flex justify-content-between">
                                <Col className='d-flex justify-content-start'>
                                    <strong>Bonafide Certificate</strong>
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                    <p className="text-right">Date: {getDateInDDMMYYYY(today)}</p>
                                </Col>
                            </Row>

                            <div className='text-center'></div>
                            <br></br>
                            <br></br>

                            <h5 className='text-center'>To Whomsoever it may concern</h5>

                            <br></br>

                            <p className='text-left font-size-16'>
                                This is to certify that&nbsp;
                                <b>{props.selectedKid && props.selectedKid.attributes.Name},</b>&nbsp;
                                {props.selectedKid.attributes.Gender == "Male" ? "son of" : "daughter of"}&nbsp;
                                <b>{parent && parent.father.attributes.Name}</b>&nbsp;
                                <b>{parent && " & " + parent.mother.attributes.Name}</b>&nbsp;
                                is a bonafide Student of&nbsp;
                                <b>{props.selectedSchool && props.selectedSchool.attributes.Name}</b>&nbsp;
                                {props.selectedKid.attributes.Gender == "Male" ? "He" : "She"}&nbsp;
                                has completed &nbsp;
                                {props.selectedKid.attributes.Gender == "Male" ? "His" : "Her"}&nbsp;
                                <b>{getClassRoomName(props.selectedKid && props.selectedKid.attributes.ClassRoomID)}</b>&nbsp;
                                for the academic year&nbsp;
                                <b>{getYear()}</b>.&nbsp;
                                {props.selectedKid.attributes.Gender == "Male" ? "His Date of Birth is" : "Her Date of Birth is"}&nbsp;
                                <b>{getDateInDDMMYYYY(props.selectedKid && props.selectedKid.attributes.DateOfBirth)}</b>&nbsp;
                                as per our records.

                            </p>

                            {props.selectedKid.attributes.address &&
                                <p className='text-left font-size-14'>
                                    {
                                        props.selectedKid.attributes.Gender == "Male" ?
                                            "He is Residing at " + props.selectedKid.attributes.address :
                                            "She is Residing at " + props.selectedKid.attributes.address
                                    }
                                </p>
                            }

                            <div style={{ height: "50mm", }} >

                            </div>

                            <Row className="d-flex justify-content-between">
                                <Col className='d-flex justify-content-start'>
                                    <h6><strong><i>Seal&Signature</i></strong> </h6>
                                </Col>
                                <Col className='d-flex justify-content-end d-print-none'>
                                    <Label
                                        onClick={() => {
                                            print();
                                        }}
                                        className="btn btn-success waves-effect waves-light mr-2"
                                    >
                                        <i className="fa fa-print"></i>
                                    </Label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


            }
        </div>)
    }
    return (<React.Fragment>
        <div  >
            <Header
                certificate={"Bonafide Certificate"}
                hide={true}
                showRow={showRow}
                onChangeShowRow={(v) => { setShowRow(v) }} />
            <div className="account-pages pt-3">
                <Container fluid='md'>
                    {getUI1()}
                </Container>
            </div>
        </div>
    </React.Fragment>

    )
}

const mapStatetoProps = (state) => {

    const { selectedKid } = state.Kid;
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;


    return { selectedKid, selectedSchool, classrooms };
};

export default (withRouter(connect(mapStatetoProps, { getAKid, setSelecetdSchool, getClassroomsForSchoolId })(bonafideCertificate)));