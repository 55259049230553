import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Parse from 'parse'
//i18n
import { withNamespaces } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getUserTeacher } from '../../../store/actions';
import * as Constants from "../../../Constents";
import * as Analytics from "../../Common/Analytics"

const ProfileMenu = (props) => {

    const [menu, setMenu] = useState(false);
    const [username, setusername] = useState("Admin");
    const [userphoto, setuserphoto] = useState("");

    useEffect(() => {
        if (props.userTeacher) {
            let tempUserName = ""
            if (props.userTeacher.attributes.deleted) {
                props.history.push("/profile-access-warning/1")
            }
            if (props.userTeacher.attributes.Role <= 1) {
                props.history.push("/profile-access-warning/2")
            }
            if (props.userTeacher.attributes.Name) {
                tempUserName = props.userTeacher.attributes.Name
                setusername(tempUserName)
                localStorage.setItem(Constants.USER_PROFILE_NAME, tempUserName);

            }
            if (props.userTeacher.attributes.Photo) {
                setuserphoto(props.userTeacher.attributes.Photo._url);
                localStorage.setItem(Constants.USER_PROFILE_PHOTO, props.userTeacher.attributes.Photo._url);
            } else {
                localStorage.removeItem(Constants.USER_PROFILE_PHOTO)
            }
        } else {

        }


    }, [props.userTeacher])

    useEffect(() => {
        if (Parse.User.current()) {
            if (Parse.User.current().attributes.teacherId) {
                props.getUserTeacher(Parse.User.current().attributes.teacherId)
            }
            localStorage.setItem(Constants.USER_PROFILE_EMAIL, Parse.User.current().attributes.username);
            var name = localStorage.getItem(Constants.USER_PROFILE_NAME);
            if (name && name.length > 0) {
                setusername(name);
            } else {
                setusername(Parse.User.current().attributes.username);
            }

            var photo = localStorage.getItem(Constants.USER_PROFILE_PHOTO)
            if (photo && photo.length > 0) {
                setuserphoto(photo);
            }

        }
    }, [props.success]);


    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >

                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">

                    {userphoto !== "" && <img className="rounded-circle header-profile-user" src={userphoto} alt="" />}
                    {userphoto === "" &&
                        <div className="team-member d-inline-block rounded-circle header-profile-user">
                            <span className="avatar-title rounded-circle">
                                {username ? username.charAt(0) : ''}
                            </span>
                        </div>
                    }

                    <span className="d-none d-xl-inline-block ml-1 mr-1">{username}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>


                </DropdownToggle>

                <DropdownMenu end>
                    <DropdownItem ><Link className="text-dark" to={props.schoolId ? "/profile/" + props.schoolId : "/profile"}> <i className="bx bx-user font-size-16 align-middle mr-1"></i>{props.t('Profile')}   </Link> </DropdownItem>
                    {/* <DropdownItem tag="a" href="/crypto-wallet"><i className="bx bx-wallet font-size-16 align-middle mr-1"></i>{props.t('My Wallet')}</DropdownItem>
                    <DropdownItem tag="a" href="#"><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>{props.t('Settings')}</DropdownItem> */}
                    {/* <DropdownItem ><Link className="text-dark" to="/auth-lock-screen">  <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{props.t('Lock screen')}</Link></DropdownItem> */}
                    <div className="dropdown-divider"></div>
                    <Link to="/logout"
                        onClick={() => {
                            let user = Parse.User.current()
                            Analytics.track_Logout(user.id, user.attributes.email)
                        }} className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{props.t('Logout')}</span>
                    </Link>
                </DropdownMenu>

            </Dropdown>

        </React.Fragment>

    );

}

const mapStatetoProps = state => {
    const { error, success } = state.Profile;
    const { userTeacher } = state.Login;

    return { error, success, userTeacher };
}

export default withRouter(connect(mapStatetoProps, { getUserTeacher })(withNamespaces()(ProfileMenu)));

